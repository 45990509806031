import React, {useState} from 'react';
import {useNavigate, Link } from 'react-router-dom';
import {Button,Accordion} from 'react-bootstrap'

const DoubleCheck = () =>{
    const history = useNavigate();

    return(
       <div className='container container_custom doublecheck mb-85'>
        <button className='backbtn my-5' onClick={() => {history('/hosting/areaforguest') }}><span className='fa fa-chevron-left me-2'></span> Back</button>
        <div className='row justify-content-center'>
            <div className='col-xl-5 col-lg-6 col-md-8'>
                <h3>Now double-check these details</h3>
                <p className='mb-5'>Help guests know what to expect</p>
               
               <div className='bord px-3 py-2'>
                <div className='d-md-flex '>
                    <div className='f-14'>
                     <span> Bedroom <span>(4)</span></span>  <span>·</span>
                     <span>  Full bathroom  <span>(4)</span></span>  <span>·</span>
                     <span> Toilet with sink <span>(4)</span></span>  <span>·</span>
                     <span> Living room <span>(4)</span></span>  <span>·</span>
                     <span> Exterior <span>(4)</span></span>  
                    </div>
                    <div>
                        <Button variant="link" className='text-dark f-14 pl-inherit ps-0'>
                            Edit rooms and spaces
                        </Button>
                    </div>
                </div>
               </div>
              
               <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Bedroom</Accordion.Header>
                        <Accordion.Body>
                             <div className=''>
                                <div class="form-check">
                                <label class="form-check-label" for="flexCheckChecked">
                                      <p>Shared with others</p>
                                      <p className='f-14'>Guests might share this space with me, other guests, or someone else</p>
                                    </label>
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked/>
                                </div>
                                <hr />
                                <div className='d-flex justify-content-between'>
                                    <div>
                                       <p>Sleeping arrangements</p>
                                       <p className='f-14'>Add sleeping arrangements</p>
                                    </div>
                                    <Button variant='link' className='tedt-dark'>Add</Button>
                                </div>
                                <hr/>
                                <div class="form-check">
                                    <label class="form-check-label" for="flexCheckChecked">
                                      <p>Has private en suite bathroom</p>
                                      <p className='f-14'>Attached or en suite bathrooms have a private entrance inside the bedroom</p>
                                    </label>
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked/>
                                </div>
                             </div>
                        </Accordion.Body>
                    </Accordion.Item>
                
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Bedroom 2</Accordion.Header>
                        <Accordion.Body>
                             <div className=''>
                                <div class="form-check">
                                <label class="form-check-label" for="flexCheckChecked">
                                      <p>Shared with others</p>
                                      <p className='f-14'>Guests might share this space with me, other guests, or someone else</p>
                                    </label>
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked/>
                                </div>
                                <hr />
                                <div className='d-flex justify-content-between'>
                                    <div>
                                       <p>Sleeping arrangements</p>
                                       <p className='f-14'>Add sleeping arrangements</p>
                                    </div>
                                    <Button variant='link' className='tedt-dark'>Add</Button>
                                </div>
                                <hr/>
                                <div class="form-check">
                                    <label class="form-check-label" for="flexCheckChecked">
                                      <p>Has private en suite bathroom</p>
                                      <p className='f-14'>Attached or en suite bathrooms have a private entrance inside the bedroom</p>
                                    </label>
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked/>
                                </div>
                             </div>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="2">
                        <Accordion.Header>Bedroom 3</Accordion.Header>
                        <Accordion.Body>
                             <div className=''>
                                <div class="form-check">
                                <label class="form-check-label" for="flexCheckChecked">
                                      <p>Shared with others</p>
                                      <p className='f-14'>Guests might share this space with me, other guests, or someone else</p>
                                    </label>
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked/>
                                </div>
                                <hr />
                                <div className='d-flex justify-content-between'>
                                    <div>
                                       <p>Sleeping arrangements</p>
                                       <p className='f-14'>Add sleeping arrangements</p>
                                    </div>
                                    <Button variant='link' className='tedt-dark'>Add</Button>
                                </div>
                                <hr/>
                                <div class="form-check">
                                    <label class="form-check-label" for="flexCheckChecked">
                                      <p>Has private en suite bathroom</p>
                                      <p className='f-14'>Attached or en suite bathrooms have a private entrance inside the bedroom</p>
                                    </label>
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked/>
                                </div>
                             </div>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="3">
                        <Accordion.Header>Bedroom 4</Accordion.Header>
                        <Accordion.Body>
                             <div className=''>
                                <div class="form-check">
                                <label class="form-check-label" for="flexCheckChecked">
                                      <p>Shared with others</p>
                                      <p className='f-14'>Guests might share this space with me, other guests, or someone else</p>
                                    </label>
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked/>
                                </div>
                                <hr />
                                <div className='d-flex justify-content-between'>
                                    <div>
                                       <p>Sleeping arrangements</p>
                                       <p className='f-14'>Add sleeping arrangements</p>
                                    </div>
                                    <Button variant='link' className='tedt-dark'>Add</Button>
                                </div>
                                <hr/>
                                <div class="form-check">
                                    <label class="form-check-label" for="flexCheckChecked">
                                      <p>Has private en suite bathroom</p>
                                      <p className='f-14'>Attached or en suite bathrooms have a private entrance inside the bedroom</p>
                                    </label>
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked/>
                                </div>
                             </div>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="4">
                        <Accordion.Header>Full bathroom</Accordion.Header>
                        <Accordion.Body>
                             <div className=''>
                                <div class="form-check">
                                <label class="form-check-label" for="flexCheckChecked">
                                      <p>Shared with others</p>
                                      <p className='f-14'>Guests might share this space with me, other guests, or someone else</p>
                                    </label>
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked/>
                                </div>
                             </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                        <Accordion.Header>Full bathroom 2</Accordion.Header>
                        <Accordion.Body>
                             <div className=''>
                                <div class="form-check">
                                <label class="form-check-label" for="flexCheckChecked">
                                      <p>Shared with others</p>
                                      <p className='f-14'>Guests might share this space with me, other guests, or someone else</p>
                                    </label>
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked/>
                                </div>
                             </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                        <Accordion.Header>Toilet with sink</Accordion.Header>
                        <Accordion.Body>
                             <div className=''>
                                <div class="form-check">
                                <label class="form-check-label" for="flexCheckChecked">
                                      <p>Shared with others</p>
                                      <p className='f-14'>Guests might share this space with me, other guests, or someone else</p>
                                    </label>
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked/>
                                </div>
                             </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7">
                        <Accordion.Header>Living room</Accordion.Header>
                        <Accordion.Body>
                             <div className=''>
                                <div class="form-check">
                                <label class="form-check-label" for="flexCheckChecked">
                                      <p>Shared with others</p>
                                      <p className='f-14'>Guests might share this space with me, other guests, or someone else</p>
                                    </label>
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked/>
                                </div>
                                <hr />
                                <div className='d-flex justify-content-between'>
                                    <div>
                                       <p>Sleeping arrangements</p>
                                       <p className='f-14'>Add sleeping arrangements</p>
                                    </div>
                                    <Button variant='link' className='tedt-dark'>Add</Button>
                                </div>
                              
                             </div>
                        </Accordion.Body>
                    </Accordion.Item>

                    </Accordion>
                    <div >
                        <p>For any of the spaces you’ve marked as shared, who else might be there?</p>
                        <p className='f-12 mb-3'>Include anyone who might also use or be in the space.</p>
                        <div class="form-check justify-content-end mb-3">
                            <label class="form-check-label ms-2" for="flexCheckChecked">
                                Me
                            </label>
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
                        </div>
                        <div class="form-check justify-content-end mb-3">
                            <label class="form-check-label ms-2" for="flexCheckChecked">
                                Family, friends, or roommates
                            </label>
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
                        </div>
                        <div class="form-check justify-content-end mb-3">
                            <label class="form-check-label ms-2" for="flexCheckChecked">
                                Other guests
                            </label>
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
                        </div>
                    </div>
            
            </div>
        </div>
      
        <div className='fixedbtn'> 
            <Link to="" className='btn btn-theme'>save</Link>
        </div>
       </div>
    )
}

export default DoubleCheck