import React, { Component, useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import { withGoogleMap, GoogleMap } from "react-google-maps";
import { compose, withProps, lifecycle } from "recompose";

import PlaceMarker from "./Mapmarking";
import PlaceInfoWindow from "./Placeinfowindow";
const AnyReactComponent = ({ text }) => (
  <div className="mapvei_card">
    <h1>{text?.name}</h1>
    <p>{text?.description}</p>
    <span>${text?.price}</span>
  </div>
);
const apiKey = "AIzaSyDwQOCtNC-XQRRYsZIg5lXexgr-CrOEXrw";
const mapApiJs = "https://maps.googleapis.com/maps/api/js";
const geocodeJson = "https://maps.googleapis.com/maps/api/geocode/json";
// const places = [<PlaceMarker lat={59.955413} lng={30.337844} price={20}  name={"Hotel"} description={"Hotel desc"} />]
const places = [
  {
    lat: 9.96233,
    lng: 49.80404,
    price: 20,
    name: "hotel",
    desctiption: "hotel desc",
  },
  {
    lat: 6.11499,
    lng: 50.76891,
    price: 20,
    name: "hotel",
    desctiption: "hotel desc",
  },
  {
    lat: 6.80592,
    lng: 51.53548,
    price: 20,
    name: "hotel",
    desctiption: "hotel desc",
  },
];

const AirbnbMap = withGoogleMap((props) => (
  <GoogleMap
    // onDragEnd={props.handleDragend}

    bootstrapURLKeys={{
      key: "AIzaSyDwQOCtNC-XQRRYsZIg5lXexgr-CrOEXrw",
      libraries: ["places", "geometry", "drawing", "visualization"],
    }}
    defaultCenter={props.center}
    defaultZoom={props.zoom}
    onDrag={(e, map) => console.log("boundchange", e, map)}
  >
    {places &&
      places?.map((data, i) => (
        <PlaceMarker
          lat={data?.lat}
          lng={data?.lng}
          price={data?.price}
          name={data?.name}
          description={data?.desctiption}
        />
      ))}
  </GoogleMap>
));

const Searchmap = (props) => {
  console.log("props.position", props);

  const mapRef = React.useRef(null);
  const [place, setPlace] = useState(props?.place);
  console.log("props in map", props?.key);
  const loadMap = (map, maps) => {
    console.log("map , maps", map, maps);
    let marker = new maps.Marker({
      position: props.position, //props.position,
      map,
      //   draggable: true,
    });
  };

  const [mapZoom, setMapZoom] = useState(false);
  useEffect(() => {
    console.log("map zoom in searchMap", mapZoom);
  });

  return (
    <div style={{ height: "100%", width: "100%" }}>
      {props?.keys == "stay" && (
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyDwQOCtNC-XQRRYsZIg5lXexgr-CrOEXrw" }}
          defaultCenter={props.position}
          defaultZoom={10}
          options={{
            disableDoubleClickZoom: mapZoom ? true : false,
            // disableDoubleClickZoom: true,
          }}
          // onClick = {(e)=>{console.log("New Exact lat and long : ",e.lat,e.lng);props.onGetAddress({latitude:e.lat,longitude:e.lng}) }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => loadMap(map, maps)}
          onDragEnd={(map) => {
            console.log(
              "on drag data : ",
              map,
              map.getBounds(),
              map.center.lat(),
              map.center.lng()
            );
            var keys = Object.keys(map.getBounds());
            var ref = map.getBounds();
            console.log("on drag data : ", keys, ref);
            let payload = {
              minlng: ref[keys[1]]["lo"],
              maxlng: ref[keys[1]]["hi"], //map.getBounds().Ta.hi,
              minlat: ref[keys[0]]["lo"], //map.getBounds().rb.lo,
              maxlat: ref[keys[0]]["hi"], //map.getBounds().rb.hi
            };
            props.filter(payload);
          }}
        >
          {props?.place &&
            props?.place?.map(
              (data) =>
                data?.location?.lat &&
                data?.location?.lng && (
                  <PlaceInfoWindow
                    lat={data?.location?.lat}
                    lng={data?.location?.lng}
                    // text="My Marker"
                    name={data?.title}
                    description={data?.descriptionDetails}
                    price={data?.price}
                    refdata={data}
                    setMapZoom={setMapZoom}
                    mapZoom={mapZoom}
                  />
                )
            )}
        </GoogleMapReact>
      )}

      {props?.keys == "experience" && (
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyDwQOCtNC-XQRRYsZIg5lXexgr-CrOEXrw" }}
          defaultCenter={props.position}
          defaultZoom={10}
          // onClick = {(e)=>{console.log("New Exact lat and long : ",e.lat,e.lng);props.onGetAddress({latitude:e.lat,longitude:e.lng}) }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => loadMap(map, maps)}
          onDragEnd={(map) => {
            console.log(
              "on drag data : ",
              map,
              map.getBounds(),
              map.center.lat(),
              map.center.lng()
            );
            var keys = Object.keys(map.getBounds());
            var ref = map.getBounds();
            console.log("on drag data : ", keys, ref);
            let payload = {
              minlng: ref[keys[1]]["lo"],
              maxlng: ref[keys[1]]["hi"], //map.getBounds().Ta.hi,
              minlat: ref[keys[0]]["lo"], //map.getBounds().rb.lo,
              maxlat: ref[keys[0]]["hi"], //map.getBounds().rb.hi
            };
            props.filter(payload);
          }}
        >
          {props?.place &&
            props?.place?.map(
              (data) =>
                data?.meetingLocation?.lat &&
                data?.meetingLocation?.lng && (
                  <PlaceInfoWindow
                    lat={data?.meetingLocation?.lat}
                    lng={data?.meetingLocation?.lng}
                    // text="My Marker"
                    name={data?.title}
                    description={data?.descriptionDetails}
                    price={data?.price}
                    refdata={data}
                    setMapZoom={setMapZoom}
                    mapZoom={mapZoom}
                  />
                )
            )}
        </GoogleMapReact>
      )}

      {/* <AirbnbMap center = {{lat : 9.96233 , lng : 49.80404}} zoom = {10}
        ref = {mapRef}
            containerElement={
                <div style={{ height: `100%` }} />
              }
              mapElement={
                <div style={{ height: `100%` }} />
              }
        /> */}
    </div>
  );
};

export default Searchmap;
