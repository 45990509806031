import React from 'react';
import FilterExp from './FilterExp.js'


import img1 from '../../Assets/images/where/img1.png'
import img2 from '../../Assets/images/where/img2.png'
import img3 from '../../Assets/images/where/img3.png'
import img4 from '../../Assets/images/where/img4.png'
import img5 from '../../Assets/images/where/img5.png'
import img6 from '../../Assets/images/where/img6.png'
import img7 from '../../Assets/images/where/img7.png'
import img8 from '../../Assets/images/where/img8.png'
import img9 from '../../Assets/images/where/img9.png'
import img10 from '../../Assets/images/where/img10.png'
import img11 from '../../Assets/images/where/img11.png'
import img12 from '../../Assets/images/where/img12.png'
import img13 from '../../Assets/images/where/img13.png'
import img14 from '../../Assets/images/where/img14.png'



const Header = React.lazy(() => import('../Common/Header'));
const Footer = React.lazy(() => import('../Common/Footer'));

const Card = React.lazy(() => import('./Card'))


const OnlineExp = () =>{
    const whereImg = [
        {img:[{imginner: img1},{imginner: img2},{imginner: img3},{imginner: img6},{imginner: img2},{imginner: img3},
            {imginner: img5},{imginner: img1},{imginner: img6},{imginner: img12},{imginner: img12},{imginner: img13}] }, 
        {img: [{imginner: img2},{imginner: img4}]}, 
        {img:[{imginner: img3},{imginner: img1},{imginner: img6}]}, 
        {img: [{imginner: img4},{imginner: img12},{imginner: img13}]}, 

        {img:[{imginner: img6},{imginner: img2},{imginner: img3}] }, 
        {img: [{imginner: img7},{imginner: img4}]}, 
        {img:[{imginner: img5},{imginner: img1},{imginner: img6}]}, 
        {img: [{imginner: img8},{imginner: img12},{imginner: img13}]}, 

        {img:[{imginner: img9},{imginner: img2},{imginner: img3}] }, 
        {img: [{imginner: img10},{imginner: img4}]}, 
        {img:[{imginner: img11},{imginner: img1},{imginner: img6}]}, 
        {img: [{imginner: img12},{imginner: img12},{imginner: img13}]},

        {img:[{imginner: img13},{imginner: img2},{imginner: img3}] }, 
        {img: [{imginner: img14},{imginner: img4}]}, 
        {img:[{imginner: img5},{imginner: img1},{imginner: img6}]}, 
        {img: [{imginner: img6},{imginner: img12},{imginner: img13}]}, 



    ]
  
   return(
    <div>
        <Header />
            <FilterExp />
            <div className='container container_custom'>
                <div className='d-grid card-5'>
                {whereImg.map((i)=>
                    <Card/>
                )}
                </div>
            </div>
        <Footer />

    </div>
    )
}

export default OnlineExp;