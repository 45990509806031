
import React, { useState } from 'react';
import bag from '../../../../Assets/images/bag.png'
import AddVat from './Modals/AddVat';


const AccTaxes = () =>{
    const[addvat , setAddVat] = useState(false)

return(<>
{addvat && <AddVat onDismiss={() => setAddVat(false)} />}
      <div className='row mt-5 justify-content-between'>
                            <div className='col-md-7'>
                               <h4>Add taxpayer information</h4>
                               <p>If you're a taxpayer outside of the United States, add details for taxes in your country.</p>
                               <button className='btn btn-theme mt-4'>Add International Taxpayer</button>
                              
                                    <hr className='themehr mt-5' />
                                    <h4 className='mt-5'>VAT</h4>
                                    <p className='text-muted'>If you are registered for VAT or your stay is for business, you may not be charged VAT on FlutterPads service fees. To get started, 
                                    enter your business’s VAT ID Number.
                                    <a href="#" className='text-dark'>Learn more about VAT.</a></p>
                                   
                                    <button className='btn btn-theme mt-4' onClick={() => setAddVat(true)}>Add VAT ID Number</button>
                                
                                 

                                    <h4 className='mt-5'>GST Registration</h4>
                                    <p className='text-muted'>If you are a resident of India and have listings in India, you may be legally required to obtain a GST number and apply this number to your listings.</p>
                                   
                                    <button className='btn btn-theme mb-5 mt-4'>GST Registration</button>
                            </div>
                            <div className='col-lg-4 col-md-5 d-md-block d-none '>
                                <div className='right-cont bord p-4 br-10'>
                                    <img src={bag} className="mb-3"/>
                                    <h6>Where is my VAT invoice?</h6>
                                    <p className='text-muted f-14'>Once you book a place for business travel, you can find a link to the VAT invoice in the booking confirmation email.
                                    <a href="#" className='text-dark'>Learn more</a></p>
                                    
                                    
                                </div>
                            </div>
                        </div>
                    
                        <hr className='themehr mb-5'/>
    </>
)
}
export default AccTaxes