import React, { Component } from 'react';

const Start = () =>{
    return(
        <div className='col-lg-8 col-xl-7 col-md-10'>
            <div style={{height: "calc(100vh - 320px) "}} className="d-flex align-items-center">
                <div className='rightcont placetype mb-5 text-center'>
                    <h2>Become a Host in 10 easy steps</h2>
                    <p>Join us. We'll help you every step of the way.</p>
                </div>
            </div>
        </div>
    )
}


export default Start