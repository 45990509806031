
import React, {useState , useEffect} from "react";

import { Grid, Button, TextField, InputAdornment } from "@material-ui/core";
import { Slider, Rail, Handles, Tracks, Ticks } from "react-compound-slider";
import { MuiRail, MuiHandle, MuiTrack, MuiTick } from "./components";
import BarChart from "./Barchart";


const Rangeslider = (props) =>{


    const sortedData = props.data.slice().sort((a, b) => a - b);
    const range = [sortedData[0], sortedData[sortedData.length - 1]];
    console.log('propsdatagal',props.clear,sortedData[0],sortedData[sortedData.length - 1])
 

    const [min , setMin] = useState(props.data[0]);
    console.log('minssssssssssssssssss',min)
    const [max , setMax] = useState(props.data[1]);

    const [domain, setdomain] = useState(range); 
    const [update, setupdate] = useState(range); 
    console.log('inputValuesupdate',update)
    const [values, setValues] = useState(range); 
    const [inputValues, setinputValues] = useState(range); 
    console.log('inputValues',inputValues)

    useEffect(()=> {
      if(props.clear == 'clear')
      {
        console.log('enter this one')
        console.log("min value -------" , min , max);
        var price = {min : 0 , max: 10000}
      props.onSetPrices(price)
        setMin(0);
        setMax(10000);
        setValues([0,10000])
        setupdate([0,10000])
        setinputValues([0,10000])
        props.onSetClear('')
      
      }
      else
      {
        console.log('enter this two')
        console.log("min value" , min , max);
        var price = {min : min , max:max}
        props.onSetPrices(price)
      }
      
    },[props.clear,min,max])

    return (


      <Grid container>
        <Grid item xs={12}>
          <div className="chart_div_canvas" style={{ margin:"1% 5%", height: 120, width: "90%" }}>
            {console.log("data",update,domain)}
            {/* <BarChart

              data={props.data}
              highlight={update}
              domain={domain}
            /> */}
            <Slider
              mode={3}
              step={1}
              domain={domain}
              rootStyle={{
                position: "relative",
                width: "100%"
              }}
              onUpdate={update =>
                // this.setState({ update, inputValues: update })
{
  {console.log('datawithupdate',update)}
                setupdate(update)
                setinputValues(update)
}


              }

              onChange={values => {setValues(values);
                                  console.log("slider onchange",values[0]);
                                  setMin(values[0]);
                                  setMax(values[1]);
                                }}

              values={values}
            >
              <Rail>
                {({ getRailProps }) => <MuiRail getRailProps={getRailProps} />}
              </Rail>
              <Handles>
                {({ handles, getHandleProps }) => (
                  <div className="slider-handles">
                    {handles.map(handle => (
                      <MuiHandle
                        key={handle.id}
                        handle={handle}
                        domain={domain}
                        getHandleProps={getHandleProps}
                      />
                    ))}
                  </div>
                )}
              </Handles>
              <Tracks left={false} right={false}>
                {({ tracks, getTrackProps }) => (
                  <div className="slider-tracks">
                    {tracks.map(({ id, source, target }) => (
                      <MuiTrack
                        key={id}
                        source={source}
                        target={target}
                        getTrackProps={getTrackProps}
                      />
                    ))}
                  </div>
                )}
              </Tracks>
              <Ticks count={5}>
                {({ ticks }) => (
                  <div className="slider-ticks">
                    {ticks.map(tick => (
                      <MuiTick key={tick.id} tick={tick} count={ticks.length} />
                    ))}
                  </div>
                )}
              </Ticks>
            </Slider>
            <Grid
            className="px-md-2 px-lg-4 mt-4 pt-4"
              container
              alignItems="center"
              justify="space-around"
              style={{ marginTop: "50px" }}
            >
              <Grid item xs={4} style={{ textAlign: "right" }}>
                <TextField className="input_label_range"
                  variant="outlined"
                  label="Min Price"
                  value={inputValues[0]}
                  onChange={evt => {
                    const value = evt.target.value;
                    const newState = [value, inputValues[1]];
                    console.log(newState,"newState1")

                    setinputValues(newState);
                    if (value && value >= domain[0]) {
                    //   this.setState({ values: newState });
                    setValues(newState);

                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">र</InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={4} style={{ textAlign: "center" }}>
                —
              </Grid>
              <Grid item xs={4} style={{ textAlign: "left" }}>
                <TextField
                 className="input_label_range"
                  variant="outlined"
                  label="Max Price"
                  value={inputValues[1]}
                  onChange={evt => {
                    const value = evt.target.value;
                    const newState = [inputValues[0], value];
                    console.log(newState,"newState")
                    // this.setState({ inputValues: newState });
                    setinputValues(newState);

                    if (value && value <= domain[1] && value >= values[0]) {
                    //   this.setState({ values: newState });
                    setValues(newState);

                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">र</InputAdornment>
                    )
                  }}
                />
              </Grid>
            </Grid>
         
          </div>
        </Grid>
      </Grid>
      
    );
  
}

export default Rangeslider;
