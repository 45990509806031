import { isEmpty } from 'lodash';
import React, { useState, useEffect } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import countrycode from "../../../../config/countrycode.json"
import { getExperienceFromLocal } from '../../../../Hooks/useUserExperienceHook';
import AddressModal from '../../../Common/Modals/AddressModal';
import Experiencemap from '../../../Maps/Experiencemap';
import GoogleMaps from '../../../Maps/GMaps';
const MeetingLocation = (props) =>{
    // const [location, setLocation] = useState({
    //     locationName:"",
    //     country:"",
    //     streetAddress:"",
    //     suite:"",
    //     city:"",
    //     state:"",
    //     zip:""
    // });
    const [location , setLocation] = useState({});
    const [locationName, setLocationName] = useState("");
    console.log('locationName',locationName)
    const [country, setCountry] = useState(`${countrycode[0]?.name}(${countrycode[0]?.dial_code})`);
    const [streetAddress, setStreetAddress] = useState("");
    console.log('streetAddress',streetAddress)
    const [suite, setSuite] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");

    const [localData, setLocalData] = useState({});
    const [locationNameErr, setLocationNameErr] = useState(false);
    const [countryErr, setCountryErr] = useState(false);
    const [streetAddressErr, setStreetAddressErr] = useState(false);
    const [suiteErr, setSuiteErr] = useState(false);
    const [cityErr, setCityErr] = useState(false);
    const [stateErr, setStateErr] = useState(false);
    const [zipErr, setZipErr] = useState(false);

    const [position, setPosition] = useState({lat : 59.03 , lng : 33.93});
    const [coordsLoaded, setCoordsLoaded] = useState(false);
    const [showadd, setShowAdd] = useState(false);
    const [address, setAddress] = useState({});
    const [addressmodal, setAddressModal] = useState(false);
    const [manaddress , setManaddress] = useState(false);

    const geocodeJson = "https://maps.googleapis.com/maps/api/geocode/json";
    const apiKey = "AIzaSyDwQOCtNC-XQRRYsZIg5lXexgr-CrOEXrw";
    

    const setMeetingLocation = (e) => {
        if(e.target.id == "LocationName"){
            location.locationName = e.target.value;
            setLocationName(e.target.value)
        }else if(e.target.id == "country"){
            location.country = e.target.value;
            setCountry(e.target.value)
        }
        else if(e.target.id == "streetAddress"){
            location.streetAddress = e.target.value;
            setStreetAddress(e.target.value)
        }
        else if(e.target.id == "suite"){
            location.suite = e.target.value;
            setSuite(e.target.value)
        }
        else if(e.target.id == "city"){
            location.city = e.target.value;
            setCity(e.target.value)
        }
        else if(e.target.id == "state"){
            location.state = e.target.value;
            setState(e.target.value)
        }
        else if(e.target.id == "zipCode"){
            location.zip = e.target.value;
            setZip(e.target.value)
        }
        setLocation(location);
        console.log("location in meeting location :",location);
        
    }

    const createAddress = () =>{
        if(location.locationName&&location.country&&location.streetAddress&&
            location.suite&&location.city&&location.state&&location.zip ){
        props.count();
        props.onSetLocal(location,"meetingLocation")
        }else if(!locationName){
            setLocationNameErr(true)
        }else if(!streetAddress){
            setStreetAddressErr(true)
        }else if(!country){
            setCountryErr(true)
        }else if(!suite){
            setSuiteErr(true)
        }else if(!city){
            setCityErr(true)            
        }else if(!state){
            setStateErr(true)
        }else if(!zip){
            setZipErr(true)            
        }
    }

    useEffect(()=>{
        const localdatas = getExperienceFromLocal();
        setLocalData(localdatas);
        setLocation(localdatas.meetingLocation?localdatas.meetingLocation:location);
        console.log("localData.meetingLocation outside:",localdatas.meetingLocation);
        if(localdatas.meetingLocation && localdatas.meetingLocation.locationName){
            console.log("localData.meetingLocation:",localdatas.meetingLocation);
            setLocationName(localdatas.meetingLocation.locationName)
            setCountry(localdatas.meetingLocation.country)
            setStreetAddress(localdatas.meetingLocation.streetAddress)
            setSuite(localdatas.meetingLocation.suite)
            setCity(localdatas.meetingLocation.city)
            setState(localdatas.meetingLocation.state)
            setZip(localdatas.meetingLocation.zip)
            }
            console.log("locationName :",locationName)
    },[]);




    const getAddressFromGmap = async (data) => {
        console.log("data>>>>>>>>>>>>>....", data);
        setShowAdd(false);
        console.log("getAddressFromGmap", data);
        const pos = { lat: data.latitude, lng: data.longitude };
        setPosition(pos);
        const value = await reverseGeocode(data);
        value.lat = pos.lat;
        value.lng = pos.lng;
        setLocation(value)
        console.log('getAddressFromGmap eiei',value)
        props.onSetLocal(value)
      }

      const reverseGeocode = async ({ latitude: lat, longitude: lng }) => {
        console.log("lat,lng", lat, lng);
        const url = `${geocodeJson}?key=${apiKey}&latlng=${lat},${lng}`;
        // searchInput.current.value = "Getting your location...";
        let addr = {};
        await fetch(url)
          .then((response) => response.json())
          .then((location) => {
            const place = location.results[0];
            console.log("placessssssssssss", place , location);
    
            let _address = extractAddress(place);
            delete _address.plain;
            setAddress(_address);
            //   console.log("_address.plain()",_address.plain());
            //   searchInput.current.value = _address.plain();
            console.log("address object:", _address);
            setCoordsLoaded(true);
            addr = _address;
          });
    
        return addr;
      };

      const extractAddress = (place) => {
        const address = {
          street: "",
          area: "",
          city: "",
          state: "",
          postcode: "",
          countrycode: "",
          plain() {
            const street = this.street ? this.street + ", " : "";
            const area = this.area ? this.area + ", " : "";
            const city = this.city ? this.city + ", " : "";
            const postcode = this.postcode ? this.postcode + ", " : "";
            const state = this.state ? this.state + ", " : "";
            return street + area + city + postcode + state + this.countrycode;
          },
        };
    
        if (!Array.isArray(place?.address_components)) {
          return address;
        }
    
        place.address_components.forEach((component) => {
          const types = component.types;
          console.log("component.types vjj", component.types);
          const value = component.long_name;
          console.log("component.long_name vjj", component.long_name);
    
          if (types.includes("street_number")) {
            address.street = value;
          }
    
          if (types.includes("locality")) {
            address.area = value;
          }
    
          if (types.includes("administrative_area_level_3")) {
            address.city = value;
          }
    
          if (types.includes("administrative_area_level_1")) {
            address.state = value;
          }
    
          if (types.includes("postal_code")) {
            address.postcode = value;
          }
          if (types.includes("country")) {
            address.countrycode = value;
          }
          if(types.includes("plus_code")){
            address.street = value;
          }
    
    
        });
        console.log("addressinside" , address);
        return address;
      };

      const getUserAddress = (data) => {
        setAddress(data);
        setLocation(data);
        var loc = {}
        // locationName:"",
        // country:"",
        // streetAddress:"",
        // suite:"",
        // city:"",
        // state:"",
        // zip:""
        // location.locationName = "";
        // location.country = data?.countrycode;
        // location.streetAddress = data?.area;
        // location.suite = "";
        // location.city = "";
        // location.state = data?.state;
        // location.zip = ""
        setCountry(data)
        props.onSetLocal(data , "meetingLocation");
      };
      const getLat = async (lat, lng) => {
        let data = {
          latitude: lat,
          longitude: lng,
        };
        let pos = {
          lat: lat,
          lng: lng,
        };
        console.log("positionsssssssss", pos);
        setCoordsLoaded(true);
        setPosition(pos);
        const value = await reverseGeocode(data);
        setLocation(value);
        console.log("getLat eiei", value);
      };

      const getCurrentLocation = async () => {
        setShowAdd(false);
        navigator.geolocation.getCurrentPosition(async (location) => {
          console.log("current locatio.mmmn :", location);
          const pos = { lat: location.coords.latitude, lng: location.coords.longitude }
          setPosition(pos);
          const value = await reverseGeocode(location.coords);
          value.lat = pos.lat;
          value.lng = pos.lng;
          setLocation(value);
          console.log('getCurrentLocation eiei',value)
          props.onSetLocal(value , "meetingLocation");
        });
      };
    

    // useEffect(()=>{
    //     if(location.meetingLocation && localData.meetingLocation.locationName){
    //         console.log("localData.meetingLocation:",localData.meetingLocation);
    //         setLocationName(localData.meetingLocation.locationName)
    //         setCountry(localData.meetingLocation.country)
    //         setStreetAddress(localData.meetingLocation.streetAddress)
    //         setSuite(localData.meetingLocation.suite)
    //         setCity(localData.meetingLocation.city)
    //         setState(localData.meetingLocation.state)
    //         setZip(localData.meetingLocation.zip)
    //         }
    //         console.log("locationName :",locationName)
    // },[location]);

    return(
        <>
                <div>
                    <h2 className='conthead'>Add a meeting location</h2>
                    <p className='text-muted pb-3'>Choose the category that best describe your experience. Add a second,
                        if you think it fits into another category.
                    </p>

                    <div className='mapComponent map_height_loc'>   
                        <GoogleMaps position={position} onGetAddress={getAddressFromGmap} />
                    </div>
                    <div className='col-lg-5 col-xl-5 col-md-5 col-sm-8 position-absolute map-custom-position map_cst_posti'>
        {addressmodal && <AddressModal onGetUserAddress={getUserAddress} onGetLatLng={getLat} onDismiss={() => setAddressModal(false)} />}
        <div className='placetype  mb-5 describeplace mt-5'>
          <div className='row  px-3 align-items-center height-100 mb-90'>
            <div>
     </div>

            <div className=''>
              <InputGroup className="input_with_ma">
                <InputGroup.Text id="basic-addon1"><span className='fa fa-map-marker'></span></InputGroup.Text>
                <Form.Control
                  placeholder={(address.street || address.area || address.city) ? (address.street + "," + address.area + "," + address.city) : "Your Hosting Place"}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  onFocus={() => setShowAdd(true)}
                />

              </InputGroup>

              {/* <PlacesAutocomplete
                    value={addresss}
                    onChange={setAddress}
                    // onSelect={handleSelect}
                    onFocus={() => setShowAdd(true)}
                ></PlacesAutocomplete> */}

              <div className={showadd ? 'address ps-0 addrss_div_j' : 'address d-none'}>

                <button className='currentloc ps-0' onClick={getCurrentLocation}><span className='fa fa-location-arrow'></span>Use My Current Location</button>

                <Button variant='link' className='manualadd orsnge_y' onClick={() => setAddressModal(true)}>Enter address manually</Button>

              </div>
            </div>
          </div>
        </div>
      </div>
                    <div className=' mt-4'>
                     {/* {manaddress && <div className='h-55'> 
                     <input type="text" onChange={(e)=>{setMeetingLocation(e)}} className='form-control mb-3' id='LocationName' 
                     placeholder={'Location name'} 
                     value={locationName&&locationName}
                     />
                     {(locationNameErr)?<p>Location name is a mandatory field</p>:<></>}
                     <select
                      value={country} 
                      className='form-control mb-3' onChange={(e)=>{setMeetingLocation(e)}} id='country'>
                        {countrycode.map((result)=><option>{`${result.name}(${result.dial_code})`}</option>)}
                    </select>
                    {(countryErr)?<p>Country is a mandatory field</p>:<></>}
                    <input type="text" onChange={(e)=>{setMeetingLocation(e)}} className='form-control mb-3' id='streetAddress' 
                    value={streetAddress}
                    placeholder={'Street address'} 
                    />
                    {(streetAddressErr)?<p>Street Address is a mandatory field</p>:<></>}
                    <input type="text" onChange={(e)=>{setMeetingLocation(e)}} className='form-control mb-3' id='suite'
                     value={suite}
                     placeholder={'Area'} 
                     />
                      {(suiteErr)?<p>Area is a mandatory field</p>:<></>}
                    <input type="text" onChange={(e)=>{setMeetingLocation(e)}} className='form-control mb-3' id='city' 
                    value={city}
                    placeholder={'City'} 
                    />
                     {(cityErr)?<p>City is a mandatory field</p>:<></>}
                    <input type="text" onChange={(e)=>{setMeetingLocation(e)}} className='form-control mb-3' id='state' 
                    value={state}
                    placeholder={'State'} 
                    />
                     {(stateErr)?<p>Street Address is a mandatory field</p>:<></>}
                    <input type="number" onChange={(e)=>{setMeetingLocation(e)}} className='form-control mb-3 -webkit-appearance: none' id='zipCode' 
                    value={zip}
                    placeholder={'Zip code'} 
                    />
                     {(zipErr)?<p>Zip is a mandatory field</p>:<></>}
                    
                 </div> } */}
                    {console.log(location , `locationName&&country&&streetAddress&&suite&&city&&state&&zip`)}
                   <div className='mt-4'>
                    <button 
                    className='btn btn-theme btn_location_ap'
                    disabled={!(location?.city)}
                    // manaddress ?
                    //     !(locationName&&
                    //     country&&
                    //     streetAddress&&
                    //     suite&&
                    //     city&&
                    //     state&&
                    //     zip) : 
                    onClick={() => {props.count()}}>Save & Continue</button>
                    </div> 
                    </div><br></br>
              </div>  
        </>

    )
}

export default MeetingLocation