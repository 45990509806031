import React, { useEffect, useState } from 'react';
import {Modal} from 'react-bootstrap'
import { toast } from 'react-hot-toast';
import { Elements, LinkAuthenticationElement, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import paypal from "../../Assets/images/paypal1.png"
import stripe from "../../Assets/images/stripe2.png"
import { getUserDatas, getusersdatahooks, guestsubscriptionhooks } from '../../Hooks/useUserHook';
import Confirmguestsubscribepay from './Guestsubscribepay';

const GuestSubscribeModal = (props) =>{
    const[guestsubscribe , setGuestsubscribe] = useState(true);
    const [userdata , setUserdata] = useState({});
    const [secret , setSecret] = useState("");
    const [initiatedata , setInitiatedata] = useState({});
    const [pay , setPay] = useState(false);
    const [squaredata , setSquaredata] = useState({});

    useEffect(() => {
        setUserdata(getUserDatas())
    } , [])

    const handlepaid = async(data) => {
        let payload = {
            id : props?.sub?._id,
            userid : userdata?._id,
            type : data,
            // createddata :
        }
        console.log('handlepaiddetails', data)
        guestsubscriptionhooks(payload).then((result) => {
            if(data == "Stripe"){
                getusersdatahooks(userdata?.email).then(() => {
                    console.log("result" , result);
                    setSecret(result?.data?.paymentid?.client_secret);
                    setInitiatedata(result?.data?.paymentid);
                    getusersdatahooks(userdata?.email).then(() => {
                        setPay(true);
                     });
                    // toast.success("Paid Successfully!");
                    // props.onDismiss();
                    // window.location.reload();
                })
            }
            if(data == "Square"){
                setSquaredata(result?.data?.paymentid)
                window.open(result?.data?.paymentid?.url)
                getusersdatahooks(userdata?.email).then(() => {
                })
            }
        });
    }
   
    return(
        <>
          <Modal show={guestsubscribe} centered scrollable className='modal_style'>
            <Modal.Header className='border-0 pb-0'>
                 <button type="button" className="btn close close_modal" onClick={() =>props.onDismiss()}><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
            </Modal.Header>
            <Modal.Body>
            {!pay &&<div className='bg-white listingmodals'>
                <h5>Subscription</h5>
                
                <div className='mt-4'>

                <div className="graybox br-0 mt-0 py-3 mobi_fle_ger">
                    <div className="d-flex jc-between">
                        <h6 className="mb-3 f-14">
                            <p className="themeclr">Plan</p>
                            <p className="text-muted">{props?.sub?.name}</p>
                            </h6>
                            </div>
                            <div className="d-flex jc-between">
                                <h6 className="mb-3 f-14">
                                    <p className="themeclr">Price</p>
                                    <p className="text-muted">${props?.sub?.price}</p>
                                    </h6>
                                    </div>
                                    <div className="d-flex jc-between">
                                        <h6 className="mb-0 f-14">
                                            <p className="themeclr">Days</p>
                                            <p className="text-muted">{props?.sub?.days} days</p>
                                            </h6>
                                            </div>
                                          
                                                    </div>
<hr />
<h5>Pay Via</h5>

<button className='btn btn_payemnt' onClick={() => handlepaid("Stripe")}>
    <img src={paypal} className='img-fluid' />
    Square
</button>

<button className='btn btn_payemnt'>
    <img src={stripe} className='img-fluid' onClick={() => handlepaid("Stripe")}/>
    Stripe
</button>

                  
                </div>
            </div>}

            {secret && <div className='test_pay'>
                        <Elements
                            stripe={props.stripe}
                            options={{
                                clientSecret: secret,
                                appearance: {
                                    theme: "flat",
                                    variables: { colorPrimaryText: "#262626" },
                                },
                            }}
                        >
                           <Confirmguestsubscribepay />
                        </Elements>
                    </div> }   
          </Modal.Body>
          <Modal.Footer>
            {/* <button onClick={() =>props.onDismiss()} className='btn btn-theme'>Done</button> */}
          </Modal.Footer>
      </Modal>
        </>
    )
}

export default GuestSubscribeModal