import React, { useState, useEffect } from "react";
import HostHeader from "../BecomeHost/AfterHost/HostHeader";
import FiltersCopy from "./FiltersCopy";
import Card from "./Card";
import { getUserDatas } from "../../Hooks/useUserHook";
import Cookies from "universal-cookie";
import { useParams } from "react-router-dom";
import LottieImg from "../../Assets/images/lotties/loader.json";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "react-js-loader";
import { getHostedDatas } from "../../Hooks/useUserHostingHook.js";
import {
  addwishlist,
  experiencemapfilterhooks,
  getwishlist,
  hostmapfilter,
} from "../../Hooks/filterHook";
import GoogleMapReact from "google-map-react";
import { withGoogleMap, GoogleMap } from "react-google-maps";
import CustomFilterHeader from "../BecomeHost/AfterHost/CustomFilterHeader";
import getSymbolFromCurrency from "currency-symbol-map";
import { experiencehostingfilterhook, filter } from "../../Hooks/filterHook";
import RoomMap from "../Maps/RoomMap";
import Searchmap from "./Searchmap";
import { getExperienceDatas } from "../../Hooks/useUserExperienceHook";
import ExpListCard from "./ExpListCard";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

function FilterMap(props) {
  const cookies = new Cookies();
  let { useremail } = useParams();
  console.log("useremail>>>>>>>>>>>>", useremail);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(10);
  const [hostedDatas, setHostedDatas] = useState([]);
  const [filterdata, setFilterdata] = useState([]);
  const [isfilter, setIsfilter] = useState(false);
  const [isicon, setIsicon] = useState(false);
  const [iconfiltereddata, setIconfiltereddata] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [isfilterbutton, setIsfilterbutton] = useState(false);
  const [buttonfiltereddata, setButtonfiltereddata] = useState([]);
  const [userDatas, setUserDatas] = useState({});
  const [wishedlist, setWishedlist] = useState([]);
  const [wishlist, setWishlist] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [selectedcurrency, setSelectedcurrency] = useState(
    localStorage.getItem("PCURENCY") ? localStorage.getItem("PCURENCY") : "USD"
  );
  const [selectedsymbol, setSelectedsymbol] = useState("");
  const [mapdiv, setmapdiv] = useState(false);
  const [filterpayload, setFilterpayload] = useState([]);
  const [mapfiltereddata, setMapfiltereddata] = useState([]);

  //Experience
  const [selection, setSelection] = useState("Stay");
  const [experienceDatas, setExperienceDatas] = useState([]);
  const [exhasMore, setExhasMore] = useState(true);
  const [exstart, setExstart] = useState(0);
  const [exend, setExend] = useState(20);
  const [exisfilter, setExisfilter] = useState(false);
  const [exfiltereddata, setExfiltereddata] = useState([]);
  const [exfiterpayload, setExfilterpayload] = useState({});
  const [exmapfiltereddata, setExmapfiltereddata] = useState([]);

  useEffect(() => {
    getExperienceDatas(start, end).then((data) => {
      console.log("dsjvfjhasdfgjhadfyu", data);
      setExperienceDatas(data);
    });
  }, []);

  const exfetchData = () => {
    getExperienceDatas(experienceDatas.length, end, selectedcurrency).then(
      (data) => {
        console.log("THE NEXT 10 DATAS :", data);
        if (data != "") {
          setExperienceDatas(experienceDatas.concat(data));
        } else {
          setExhasMore(false);
        }
      }
    );
  };

  const filterdataexperience = async (datas) => {
    datas.skip = 0;
    datas.limit = 10;
    console.log("experience filter data");
    setIsfilter(true);
    setExfilterpayload(datas);
    // setTopsec(false)
    // console.log("date", startDate, endDate);
    //  let payload = {}
    // {
    //   country: country,
    //   checkInDate: startDate,
    //   checkOutDate: endDate,
    //   adult: adult,
    //   children: children,
    //   infants: infants,
    //   pets: pets,
    //   guest: (adult + children)
    //   //  startdate : startDate,
    //   //  enddate : endDate
    //   //  count : counter
    // }
    datas.currency = selectedcurrency;
    console.log("payload", datas);
    await experiencehostingfilterhook(datas)
      .then((data) => {
        console.log("filter return : ", data);
        // props.onSetFilteredData(data);
        setExfiltereddata(data?.data?.record);
      })
      .catch((e) => console.log("filter return error", e));
  };

  const moreexfilter = async (datas) => {
    datas.skip = exfiltereddata?.length;
    datas.limit = 10;
    datas.currency = selectedcurrency;
    await experiencehostingfilterhook(datas)
      .then((data) => {
        console.log("filter return : ", data?.data?.record);
        // props.onSetFilteredData(data);
        if (data != "") {
          setFilterdata(filterdata.concat(data?.data?.record));
          setMapfiltereddata(filterdata.concat(data?.data?.record));
          setIsfilter(true);
        } else {
          setHasMore(false);
        }
      })
      .catch((e) => console.log("filter return error", e));
  };

  const filterexperiencemap = async (data) => {
    data.currency = selectedcurrency;
    let filt = await experiencemapfilterhooks(data);
    console.log("filter host map", filt);
    setExmapfiltereddata(filt?.data?.record);
  };

  //Experience End

  useEffect(() => {
    // debugger
    setUserDatas(getUserDatas());
    console.log("window", window.location.pathname.split("/"));
    if (window.location.pathname.split("/")[2] == "filter") {
      let params = new URLSearchParams(document.location.search);
      let name = params.get("payload");
      console.log("payload in url", name);
      if (name) {
        let payload = JSON.parse(name);
        // let payload = JSON.parse(localStorage.getItem("FIL-PAY-LOAD"))
        payload.currency = selectedcurrency;
        // let reffil = parseURLParams(window.location.href);
        setFilterpayload(payload);
        if (window.location.pathname.split("/")[3] == "stay") {
          console.log(
            `window.location.pathname.split("/")[3]`,
            window.location.pathname.split("/")[3]
          );
          setSelection("Stay");
          filterhostingdata(payload);
        }
        if (window.location.pathname.split("/")[3] == "experience") {
          console.log(
            `window.location.pathname.split("/")[3]`,
            window.location.pathname.split("/")[3]
          );
          setSelection("Experience");
          filterdataexperience(payload);
        }
      }
    }
    // else{
    getHostedDatas(start, end, selectedcurrency).then((data) => {
      showLoader();
      var symbol = getSymbolFromCurrency(selectedcurrency);
      console.log("Symbol", symbol);
      setSelectedsymbol(symbol);
      setHostedDatas(data);
      setMapfiltereddata(data);
      hideLoader();
    });
    // }
  }, [useremail]);

  const filteredData = (val) => {
    console.log("value -----------", val.data);
    if (val.data.type == "success") setIsfilter(true);
    setFilterdata(val.data.record);
  };

  const icondata = (val) => {
    console.log("valueeeee", val);
    // if(val.data.type == 'success')
    setIsicon(true);
    setIconfiltereddata(val.data.record);
  };

  const filterbutton = (val) => {
    // console.log("props" , val.data);
    if (val.data.type == "success") setIsfilterbutton(true);
    setButtonfiltereddata(val.data.record);
  };

  const wishlistarray = async (id, mail) => {
    if (mail) {
      let payload = {
        roomid: id,
        emailid: mail,
      };
      await addwishlist(payload)
        .then(async (data) => {
          console.log("wishlist r eturn: ", data, hostedDatas, userDatas);
          cookies.set(
            "User-Datas",
            data.data.record,
            { path: "/" },
            { expires: new Date(Date.now() + 600000) }
          );

          setUserDatas({});
          setHostedDatas([]);
          // let wishlist = await getwishlist(mail);
          // console.log("wishlist return data", wishlist.data.record);

          setUserDatas(getUserDatas());
          getHostedDatas(start, end, selectedcurrency).then((data) => {
            showLoader();
            setHostedDatas(data);
            hideLoader();
          });
        })
        .catch((e) => console.log("wishlist return error", e));
    } else {
    }
  };

  const showLoader = () => {
    // document.getElementsByTagName("body")[0].style.height = "100vh";
    // document.getElementsByTagName("body")[0].classList.add("overflow_hidden");
    // document.getElementById("logo_overlay").style.opacity = 0.2;
    // document.getElementById("loader_div").classList.remove("d-none");
    // document.getElementById("loader_div").classList.add("d-flex");
  };
  const hideLoader = () => {
    // document
    //   .getElementsByTagName("body")[0]
    //   .classList.remove("overflow_hidden");
    // document.getElementById("logo_overlay").style.opacity = 1;
    // document.getElementById("loader_div").classList.remove("d-flex");
    // document.getElementById("loader_div").classList.add("d-none");
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LottieImg,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const refresh = (setHostedDatas) => {};

  const fetchData = () => {
    getHostedDatas(hostedDatas.length, end, selectedcurrency).then((data) => {
      console.log("THE NEXT 10 DATAS :", data);
      if (data != "") {
        setHostedDatas(hostedDatas.concat(data));
        setMapfiltereddata(hostedDatas.concat(data));
      } else {
        setHasMore(false);
      }
    });
  };

  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 11,
  };

  const filterhostingdata = async (datas) => {
    datas.skip = 0;
    datas.limit = 10;
    datas.currency = selectedcurrency;
    console.log("filter data", datas);
    setIsfilter(true);
    // setTopsec(false);
    // console.log("date", startDate, endDate);
    // let payload = {
    //   country: data?.country,
    //   checkInDate: data?.startDate,
    //   checkOutDate: data?.endDate,
    //   adult: data?.adult,
    //   children: data?.children,
    //   infants: data?.infants,
    //   pets: data?.pets,
    //   guest: data?.adult + data?.children,
    //   //  startdate : startDate,
    //   //  enddate : endDate
    //   //  count : counter
    // };
    console.log("payload", datas);
    setFilterpayload(datas);
    await filter(datas)
      .then((data) => {
        console.log("filter return : ", data?.data?.record);
        // props.onSetFilteredData(data);
        setFilterdata(data?.data?.record);
        setMapfiltereddata(data?.data?.record);
      })
      .catch((e) => console.log("filter return error", e));
  };

  const morefilterhostingdata = async (datas) => {
    datas.skip = filterdata?.length;
    datas.limit = 10;
    datas.currency = selectedcurrency;
    await filter(datas)
      .then((data) => {
        console.log("filter return : ", data?.data?.record);
        // props.onSetFilteredData(data);
        if (data != "") {
          setFilterdata(filterdata.concat(data?.data?.record));
          setMapfiltereddata(filterdata.concat(data?.data?.record));
          setIsfilter(true);
        } else {
          setHasMore(false);
        }
      })
      .catch((e) => console.log("filter return error", e));
  };

  const loadMap = (map, maps) => {
    let marker = new maps.Marker({
      position: props.position,
      map,
      //   draggable: true,
    });
  };

  const filterhostmap = async (data) => {
    let filt = await hostmapfilter(data);
    console.log("filter host map", filt);
    setMapfiltereddata(filt?.data?.record);
  };

  const clearfilter = async () => {
    setIsfilter(false);
  };

  return (
    <div className="filter-map">
      {console.log("filterdata", filterdata)}
      <CustomFilterHeader />
      <div className="container-fluid filter-copy-sec">
        <div className="row">
          <div className="col-lg-12 col-xl-7 pe-0">
            <div
              className={mapdiv ? "map_div_web d-none" : "map_div_web d-block"}
            >
              <div className="row mx-0">
                <div className="col-lg-12">
                  <FiltersCopy
                    filter={async (data) => await filterhostingdata(data)}
                    selection={(data) => setSelection(data)}
                    expfilter={(data) => {
                      filterdataexperience(data);
                    }}
                    selected={selection}
                    clearfil={() => clearfilter()}
                  />
                </div>
                {selection == "Stay" && (
                  <InfiniteScroll
                    dataLength={
                      isfilter ? filterdata?.length : hostedDatas.length
                    } //This is important field to render the next data
                    next={() => {
                      if (isfilter) {
                        morefilterhostingdata(filterpayload);
                      } else {
                        fetchData();
                      }
                    }}
                    hasMore={hasMore}
                    loader={
                      <Loader
                        type="bubble-loop"
                        bgColor={"#000000"}
                        title={"Loading"}
                        color={"#000000"}
                        size={50}
                      />
                    }
                    className="infi_custom_loader"
                  >
                    {/* {console.log("filterdataaa", filterdata.length > 0)} */}
                    {filterdata.length > 0 && (
                      <div className="col-lg-12 ab">
                        <div
                          className="card-filter-sec-custom px-3"
                          style={{ height: "800px", overflowX: "auto" }}
                        >
                          <div className="row">
                            {isfilter
                              ? filterdata &&
                                filterdata.map((i) => {
                                  return (
                                    <>
                                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                        <Card
                                          data={i}
                                          symbol={selectedsymbol}
                                          userData={userDatas}
                                        />
                                      </div>
                                    </>
                                  );
                                })
                              : isicon
                              ? iconfiltereddata &&
                                iconfiltereddata.map((i) => {
                                  return (
                                    <>
                                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                        <Card
                                          data={i}
                                          symbol={selectedsymbol}
                                          userData={userDatas}
                                        />
                                      </div>
                                    </>
                                  );
                                })
                              : hostedDatas &&
                                hostedDatas.map((i) => {
                                  return (
                                    <>
                                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                        <Card
                                          data={i}
                                          symbol={selectedsymbol}
                                          userData={userDatas}
                                          wishlistarray={wishlistarray}
                                        />
                                      </div>
                                    </>
                                  );
                                })}
                          </div>
                        </div>
                      </div>
                    )}
                  </InfiniteScroll>
                )}

                {selection == "Experience" && (
                  <InfiniteScroll
                    dataLength={
                      isfilter ? exfiltereddata?.length : experienceDatas.length
                    } //This is important field to render the next data
                    next={() => {
                      if (isfilter) {
                        moreexfilter(exfiterpayload);
                      } else {
                        exfetchData();
                      }
                    }}
                    hasMore={exhasMore}
                    loader={
                      <Loader
                        type="bubble-loop"
                        bgColor={"#000000"}
                        title={"Loading"}
                        color={"#000000"}
                        size={50}
                      />
                    }
                  >
                    {filterdata.length > 0 && (
                      <div className="col-lg-12 b">
                        <div
                          className="card-filter-sec-custom px-3"
                          style={{ height: "800px", overflowX: "auto" }}
                        >
                          <div className="row">
                            {isfilter
                              ? filterdata &&
                                filterdata.map((i) => {
                                  return (
                                    <>
                                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                        <Card
                                          data={i}
                                          symbol={selectedsymbol}
                                          userData={userDatas}
                                        />
                                      </div>
                                    </>
                                  );
                                })
                              : experienceDatas &&
                                experienceDatas.map((i) => {
                                  return (
                                    <>
                                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                        <ExpListCard
                                          data={i}
                                          symbol={selectedsymbol}
                                          userData={userDatas}
                                          wishlistarray={wishlistarray}
                                        />
                                      </div>
                                    </>
                                  );
                                })}
                          </div>
                        </div>
                      </div>
                    )}
                  </InfiniteScroll>
                )}
              </div>
            </div>
          </div>

          <div className="col-lg-12 col-xl-5 ps-0">
            {selection == "Stay" && (
              <div
                className={
                  mapdiv ? "map_div_web d-block" : "map_div_web d-none"
                }
              >
                <div className="filtermap-map">
                  {/* <GoogleMapReact
                bootstrapURLKeys={{ key: "" }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
              >
                <AnyReactComponent
                  lat={59.955413}
                  lng={30.337844}
                  text="My Marker"
                />
              </GoogleMapReact> */}
                  {/* <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyDwQOCtNC-XQRRYsZIg5lXexgr-CrOEXrw' }}
                defaultCenter={props.position}
                defaultZoom={10}
                // onClick = {(e)=>{console.log("New Exact lat and long : ",e.lat,e.lng);props.onGetAddress({latitude:e.lat,longitude:e.lng}) }}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => loadMap(map, maps)}
                onDragEnd={(map) => console.log("data : ",map)}
              /> */}
                  <Searchmap
                    place={mapfiltereddata}
                    keys={"stay"}
                    position={{ lat: 9.88128, lng: 78.047546 }}
                    filter={(data) => filterhostmap(data)}
                  />
                </div>
              </div>
            )}

            {selection == "Experience" && (
              <div
                className={
                  mapdiv ? "map_div_web d-block" : "map_div_web d-none"
                }
              >
                <div className="filtermap-map">
                  <Searchmap
                    keys={"experience"}
                    place={exmapfiltereddata}
                    position={{ lat: 9.88128, lng: 78.047546 }}
                    filter={(data) => filterexperiencemap(data)}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="button_div_map">
          <button
            className="btn btn-theme"
            onClick={() => {
              setmapdiv(!mapdiv);
            }}
          >
            {mapdiv ? "List" : "Map"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default FilterMap;
