import React,{useState} from 'react';
import { useEffect } from 'react';
import {Modal,Button} from 'react-bootstrap'
import Scrollbars from 'react-custom-scrollbars';

const DryerDetailsModal = (props) =>{
    const[dryerdetails , setDryerDetails] = useState(true)
    
    return(
    <>
      <Modal show={dryerdetails} centered size="md" className='listingmodals modal_style scenic'>
          <Modal.Header className='border-0 pb-0'>
            <button type="button" className="btn close close_modal" onClick={() =>props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
          </Modal.Header>
          <Modal.Body>
            <h4 className='mb-4'>Dryer details</h4>
            <Scrollbars style={{  height: 250  }}>
                <div>
                   <h6 className='mb-3'>Price</h6>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Free</p>
                        </label>
                        <input class="form-check-input" type="radio" value="Air conditioning details" id="flexCheckChecked" />
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Paid</p>
                        </label>
                        <input class="form-check-input" type="radio" value="Air conditioning details" id="flexCheckChecked" />
                    </div>
                    <hr className="themehr" />
                   <h6 className='mb-3'>Location</h6>
                   <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>In unit</p>
                        </label>
                        <input class="form-check-input" type="radio" value="Air conditioning details" id="flexCheckChecked" />
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>In building</p>
                        </label>
                        <input class="form-check-input" type="radio" value="Air conditioning details" id="flexCheckChecked" />
                    </div>
                </div>
            </Scrollbars>
          </Modal.Body>
          <Modal.Footer className='jc-between'>
            <button className='btn btn-theme' >Save</button>
            <Button className='text-dark' variant="link" >Cancel</Button>
          </Modal.Footer>
      </Modal>
    </>
    )
}

export default DryerDetailsModal