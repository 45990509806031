import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { getExperienceFromLocal } from '../../../../Hooks/useUserExperienceHook';

const MentionBe = (props) =>{
    const [whereYouWillBe, setWhereYouWillBe] = useState("");
    const [localData, setLocalData] = useState({});
    const putData = (e) =>{
        console.log("putData : ", e.target.value);
        props.expbe(e.target.value)
    }
    useEffect(()=>{
        const localdata = getExperienceFromLocal();
        setLocalData(localdata)
        setWhereYouWillBe(localdata.whereYouWillBe);
        props.expbe(localdata.whereYouWillBe)
    },[]);
    console.log("STATE : ", whereYouWillBe);

    return(
        <>
                <div>
                    <h2 className='conthead'>Mention where you will be</h2>
                    <p className='text-muted'>Choose the category that best describe your experience. Add a second,
                        if you think it fits into another category.
                    </p>
                    <div className=' mt-4'>

                     <div className='h-55'> 
                     <input type="text" className='form-control mb-3' 
                     placeholder={"Enter where you will be"} 
                     value={whereYouWillBe}
                     onChange={(e) => {putData(e);setWhereYouWillBe(e.target.value)}}/>
                    
                 
                 </div> 
                
                   <div className='mt-4'><button className='btn btn-theme' disabled={!whereYouWillBe} onClick={() => {props.count();props.onSetLocal(whereYouWillBe,"whereYouWillBe")}}>Save & Continue</button></div> 
                    </div>
                    
              </div>  
        </>

    )
}

export default MentionBe