import React,{useState} from 'react';
import {Modal,Button} from 'react-bootstrap'

const TvDetails = (props) =>{
    const[tvdetails , setTv] = useState(true)
    
    return(
    <>
      <Modal show={tvdetails} scrollable centered size="md" className='listingmodals modal_style scenic'>
          <Modal.Header className='border-0 pb-0'>
            <button type="button" className="btn close close_modal" onClick={() =>props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
          </Modal.Header>
          <Modal.Body>
            <h4 className='mb-4'>TV details</h4>
            
                <div>
                <div className='h-55'>
                    <h6 className='my-4'>Size</h6>
                    <input type="text" className='form-control' placeholder="Inches"/>
                </div> 
                <hr className='themehr'/>
               
                <h6 className='mb-3'>Type</h6>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>HD</p>
                        </label>
                        <input class="form-check-input" type="radio" value="Air conditioning details" id="flexCheckChecked" />
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Standard</p>
                        </label>
                        <input class="form-check-input" type="radio" value="Air conditioning details" id="flexCheckChecked" />
                    </div>
                    

                    <hr className='themehr' />
                    <h6 className='my-4'>Streaming services and devices</h6>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Amazon Prime Video</p>
                        </label>
                        <input class="form-check-input" type="checkbox" value="Portable air conditioning" id="flexCheckChecked"/>
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Apple TV</p>
                        </label>
                        <input class="form-check-input" type="checkbox" value="Portable air conditioning" id="flexCheckChecked"/>
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Chromecast</p>
                        </label>
                        <input class="form-check-input" type="checkbox" value="Portable air conditioning" id="flexCheckChecked"/>
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Disney+</p>
                        </label>
                        <input class="form-check-input" type="checkbox" value="Portable air conditioning" id="flexCheckChecked"/>
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Fire TV</p>
                        </label>
                        <input class="form-check-input" type="checkbox" value="Portable air conditioning" id="flexCheckChecked"/>
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>HBO Max</p>
                        </label>
                        <input class="form-check-input" type="checkbox" value="Portable air conditioning" id="flexCheckChecked"/>
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Hulu</p>
                        </label>
                        <input class="form-check-input" type="checkbox" value="Portable air conditioning" id="flexCheckChecked"/>
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Netflix</p>
                        </label>
                        <input class="form-check-input" type="checkbox" value="Portable air conditioning" id="flexCheckChecked"/>
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Cable/satellite TV</p>
                        </label>
                        <input class="form-check-input" type="checkbox" value="Portable air conditioning" id="flexCheckChecked"/>
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Roku</p>
                        </label>
                        <input class="form-check-input" type="checkbox" value="Portable air conditioning" id="flexCheckChecked"/>
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Standard cable/satellite</p>
                        </label>
                        <input class="form-check-input" type="checkbox" value="Portable air conditioning" id="flexCheckChecked"/>
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>DVD player</p>
                        </label>
                        <input class="form-check-input" type="checkbox" value="Portable air conditioning" id="flexCheckChecked"/>
                    </div>
                </div>
          </Modal.Body>
          <Modal.Footer className='jc-between'>
            <Button variant="link" className="text-dark">Cancel</Button>
            <button className='btn btn-theme'>Save</button>
          </Modal.Footer>
      </Modal>
    </>
    )
}

export default TvDetails