import React, { Component, useState, useEffect } from "react";
import Scrollbars from "react-custom-scrollbars";

import { Button, Dropdown, Form } from "react-bootstrap";
// import Dropzone from 'react-dropzone-uploader'
// import { getDroppedOrSelectedFiles } from 'html5-file-selector'

import img1 from "../../../Assets/images/where/img1.png";
import img2 from "../../../Assets/images/where/img2.png";
import img3 from "../../../Assets/images/where/img3.png";
import img4 from "../../../Assets/images/where/img4.png";
import img5 from "../../../Assets/images/where/img5.png";

import { uploadHostImages } from "../../../Hooks/useUserHostingHook";
import { toast, Toaster } from "react-hot-toast";
import { toastOptions } from "../../../config/env";

const PlacePhotos = (props) => {
  console.log("placephotos propes", props, props.Images);
  const [imageFiles, setImageFiles] = useState([]);
  console.log("imageFiles hihi", imageFiles);
  const [previewUrl, setPreviewUrl] = useState([]);
  console.log("preciewUrl hihi", previewUrl);
  const [base64, setBase64] = useState([]);
  const [base64data, setBase64data] = useState([]);
  const [imgp1 , setImagep1] = useState("");
  const [imgp2 , setImagep2] = useState("");
  const [imgp3 , setImagep3] = useState("");
  const [imgp4 , setImagep4] = useState("");
  const [imgp5 , setImagep5] = useState("");

  //video
  const [videoname, setVideoname] = useState("");
  const [video, setVideo] = useState({});

  console.log("base64arrrrrrrrrrrrrrrrr", base64data);
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  useEffect(() => {
    let Image = JSON.parse(localStorage.getItem("imageUrl"));
    // setPreviewUrl(Image)
    console.log("Imagesssssssgal", Image);
    props.onstatus();
    console.log("propps.Imagessss", props.Images, props.Images.length);
    if (props.Images.length >= 5) {
      console.log("enter this now");
      let arr = [];
      Array.from(props.Images).forEach(async (val) => {
        setPreviewUrl([...previewUrl, URL.createObjectURL(val)]);
        arr.push(URL.createObjectURL(val));
      });
      setImagep1(URL.createObjectURL(props.Images[0]))
      setImagep2(URL.createObjectURL(props.Images[1]))
      setImagep3(URL.createObjectURL(props.Images[2]))
      setImagep4(URL.createObjectURL(props.Images[3]))
      setImagep5(URL.createObjectURL(props.Images[4]))
      setPreviewUrl(arr);
    }
    let data = JSON.parse(localStorage.getItem("test"));
    setBase64(data);
    let arrdata = localStorage.getItem("arr1");
    console.log("arrrdatatatata", arrdata);
    // +setPreviewUrl(Image)
  }, [props.Image]);
  const HandleFile = (data) => {
    console.log("HandleFileHandleFile", data.target.files);
    var file = data.target.files;
    var reader = new FileReader();
    if (file.length >= 5) {
      setImageFiles(file);
      let arr = [];
      let base64Arr = [];
      console.log("base^$arr", base64Arr);
      let arr1 = [];
      Array.from(file).forEach(async (val) => {
        console.log("datasarrayfrom", val);
        var fileName = val.name;
        var idxDot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();

        if (
          extFile == "jpg" ||
          extFile == "jpeg" ||
          extFile == "png" ||
          extFile == "webp"
        ) {
          arr1.push(val);
          setPreviewUrl([...previewUrl, URL.createObjectURL(val)]);
          arr.push(URL.createObjectURL(val));
          const baseImg = await toBase64(val);
          console.log("baseImg>>>>>>>>>>>>.", baseImg);

          // console.log("URL.createObjectURL(val) :",URL.createObjectURL(val),"baseImg",baseImg);
          base64Arr.push(baseImg);

          arr1.push(val);
          console.log("base^$Arr", base64Arr);
          console.log("inside foreach :", arr1);
          localStorage.setItem("test", JSON.stringify(base64Arr));
          // localStorage.setItem("photos",JSON.stringify())
          setBase64data(base64Arr);
        } else {
          toast.error("Invalid file format !", toastOptions);
        }
      });
      console.log("ar1111111111111111111111111111", arr1);
      localStorage.setItem("imageUrl", JSON.stringify(arr));
      localStorage.setItem("arr1", JSON.stringify(arr1));
      console.log("ar1111111111111111111111111111 2 ", localStorage.arr1);
      setPreviewUrl(arr);
      console.log("previewUrl", previewUrl);
    } else {
      toast.error("Must select atleast 5 photos !", toastOptions);
    }
  };
  const uploadImages = async () => {
    // await uploadHostImages(imageFiles,localStorage.getItem("email"));
    props.onGetPhotos(imageFiles);
    console.log("imageFiles jijiji", imageFiles, imageFiles.length);
    console.log("prospssssss.Imagesssssss", props.Images);
    if (imageFiles.length <= 4) {
      toast.error("Please Upload 5 or More photos !", toastOptions);
    }
  };

  const fileParams = ({ meta }) => {
    return { url: "https://httpbin.org/post" };
  };
  const onFileChange = ({ meta, file }, status) => {
    console.log(status, meta, file);
  };
  const onSubmit = (files, allFiles) => {
    allFiles.forEach((f) => f.remove());
  };
  // const getFilesFromEvent = e => {
  //     return new Promise(resolve => {
  //         getDroppedOrSelectedFiles(e).then(chosenFiles => {
  //             resolve(chosenFiles.map(f => f.fileObject))
  //         })
  //     })
  // }
  const selectFileInput = ({ accept, onFiles, files, getFilesFromEvent }) => {
    const textMsg = files.length > 0 ? "Upload Again" : "Select Files";
    return (
      <label className="btn btn-danger mt-4">
        {textMsg}
        <input
          style={{ display: "none" }}
          type="file"
          accept={accept}
          multiple
          onChange={(e) => {
            getFilesFromEvent(e).then((chosenFiles) => {
              onFiles(chosenFiles);
            });
          }}
        />
      </label>
    );
  };

  return (
    <div className="col-md-12">
      <div className="placetype rightcont mb-5 describeplace ">
        <Scrollbars
          style={{ height: "calc(100vh - 320px)" }}
          className="scroll"
        >
          <div className="row  px-3 align-items-center height-100 mb-90 dragphotos">
            <div className="text-center dashedbord d-none">
              <span className="fa fa-picture-o" />
              <h5>Drag your photos here</h5>
              <p>Add atleast 5 photos</p>

              <Button variant="link" className="text-dark">
                Upload from your Device
              </Button>
            </div>
            <h4>Click to Upload</h4>

            <div className="upphoto my-3 upphoto_big">
              <Button variant="link" className="nounder text-dark me-2 mb-2">
                <span className="fa fa-video-camera" />

                <p>{videoname ? videoname : "Upload video"}</p>

                <input
                  type="file"
                  onChange={(e) => {
                    var image = e?.target?.files[0];
                    var fileName = image.name;
                    var idxDot = fileName.lastIndexOf(".") + 1;
                    var extFile = fileName
                      .substr(idxDot, fileName.length)
                      .toLowerCase();
                    if (
                      extFile == "mp4" ||
                      extFile == "mov" ||
                      extFile == "m4v" ||
                      extFile == "mkv" ||
                      extFile == "avi"
                    ) {
                      setVideoname(e?.target?.files[0]?.name);
                      setVideo(e?.target?.files[0]);
                      props?.onGetVideo(e?.target?.files[0]);
                    } else {
                      toast.error("Invalid Video Format!..");
                    }
                  }}
                />
              </Button>
            </div>

            <div className="uploadedpics">
              {/* <input type = "file" onChange={(e) =>{
                            setVideoname(e?.target?.files[0]?.name);
                            setVideo(e?.target?.files[0]);
                            props?.onGetVideo(e?.target?.files[0]);
                        }}/> */}

              <div className="d-flex justify-content-between  mb-4">
                <div>
                  <h4>Add  photos</h4>
                  <Button
                    className="btn-theme"
                    onClick={uploadImages}
                    disabled={imageFiles.length < 5}
                  >
                    Upload
                  </Button>
                  {/* <p className='text-muted'>Drag to reorder</p> */}
                </div>
                {/* <div>
                  <Button
                    className="btn-theme"
                    onClick={uploadImages}
                    disabled={previewUrl.length < 5}
                  >
                    Upload
                  </Button>
                </div> */}
              </div>
              <div className="images">
                {/* <div className="row">
                  <div className="col-md-12 position-relative">
                    <Button className="fullbutton">
                      <div className="d-flex justify-content-between position-absolute imagedrop px-3 pt-3">
                        <Button className="btn white-btn">Cover Photo</Button>
                      </div>
                      {console.log("previewursl", previewUrl[0])}
                      <img
                        src={
                          previewUrl != ""
                            ? previewUrl[0]
                            : base64
                            ? base64[0]
                            : img1
                        }
                      />
                      <Form className="hideinput">
                        <Form.Group className="mb-3">
                          <Form.Control
                            type="file"
                            multiple
                            onChange={HandleFile}
                          />
                        </Form.Group>
                      </Form>
                    </Button>
                  </div>
                  <div className="col-md-6 position-relative">
                    <Button className="fullbutton">
                      <img
                        src={
                          previewUrl != ""
                            ? previewUrl[1]
                            : base64
                            ? base64[1]
                            : img2
                        }
                      />

                      <div className="uploadimage d-flex dashedbord align-items-center justify-content-center">
                        <span className="fa fa-picture-o" />
                      </div>
                    </Button>
                  </div>
                  <div className="col-md-6">
                    <Button className="fullbutton">
                      <img
                        src={
                          previewUrl != ""
                            ? previewUrl[2]
                            : base64
                            ? base64[2]
                            : img3
                        }
                      />

                      <div className="uploadimage d-flex dashedbord align-items-center justify-content-center">
                        <span className="fa fa-picture-o" />
                      </div>
                    </Button>
                  </div>
                  <div className="col-md-6">
                    <Button className="fullbutton">
                      <img
                        src={
                          previewUrl != ""
                            ? previewUrl[3]
                            : base64
                            ? base64[3]
                            : img4
                        }
                      />

                      <div className="uploadimage d-flex dashedbord align-items-center justify-content-center">
                        <span className="fa fa-picture-o" />
                      </div>
                    </Button>
                  </div>
                  <div className="col-md-6">
                    <Button className="fullbutton">
                      <img
                        src={
                          previewUrl != ""
                            ? previewUrl[4]
                            : base64
                            ? base64[4]
                            : img5
                        }
                      />

                      <div className="uploadimage d-flex dashedbord align-items-center justify-content-center">
                        <span className="fa fa-picture-o" />
                      </div>
                    </Button>
                  </div>
                </div> */}

                <div className="row">
                  <div className="col-md-12 position-relative">
                    <Button className="fullbutton">
                      { imgp1 && <img src={imgp1} />}
                      {/* <img src={previewUrl[0]? previewUrl[0] : ""} /> */}
                     {!imgp1 && <div className="custom_img_upload">
                        <span className="fa fa-picture-o mb-1" />
                        <p className="mb-0">Upload Image</p>
                      </div>}
                      <Form className="hideinput">
                        <Form.Group className="mb-3">
                          <Form.Control
                            type="file"

                            onChange={(e) => {
                              var fileName = e.target.files[0].name;
                              var idxDot = fileName.lastIndexOf(".") + 1;
                              var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
                              if (
                                extFile == "jpg" ||
                                extFile == "jpeg" ||
                                extFile == "png" ||
                                extFile == "webp"
                              ){
                                let prev = previewUrl
                                imageFiles[0] = e?.target?.files[0];
                                // prev[0] = URL.createObjectURL(e?.target?.files[0])
                                // setPreviewUrl(prev)
                                setImagep1(URL.createObjectURL(e?.target?.files[0]))
                              }else{
                                toast.error("Invalid file format!")
                              }
                              
                            }}
                            // multiple
                            // onChange={HandleFile}
                          />
                        </Form.Group>
                      </Form>
                    </Button>
                  </div>
                  <div className="col-md-6 position-relative">
                    <Button className="fullbutton">
                    { imgp2 && <img src={imgp2} />}

                      {!imgp2 && <div className="custom_img_upload">
                        <span className="fa fa-picture-o mb-1" />
                        <p className="mb-0">Upload Image</p>
                      </div>}
                      <Form className="hideinput">
                        <Form.Group className="mb-3">
                          <Form.Control
                            type="file"
                            onChange={(e) => {
                              var fileName = e.target.files[0].name;
                              var idxDot = fileName.lastIndexOf(".") + 1;
                              var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
                              if (
                                extFile == "jpg" ||
                                extFile == "jpeg" ||
                                extFile == "png" ||
                                extFile == "webp"
                              ){
                                imageFiles[1] = e?.target?.files[0];
                                // prev[0] = URL.createObjectURL(e?.target?.files[0])
                                // setPreviewUrl(prev)
                                setImagep2(URL.createObjectURL(e?.target?.files[0]))
                              }else{
                                toast.error("Invalid file format!")
                              }
                              
                            }}
                            // multiple
                            // onChange={HandleFile}
                          />
                        </Form.Group>
                      </Form>
                    </Button>
                  </div>
                  <div className="col-md-6">
                    <Button className="fullbutton">
                    { imgp3 && <img src={imgp3} />}

                      {!imgp3 && <div className="custom_img_upload">
                        <span className="fa fa-picture-o mb-1" />
                        <p className="mb-0">Upload Image</p>
                      </div>}
                      <Form className="hideinput">
                        <Form.Group className="mb-3">
                          <Form.Control
                            type="file"
                            onChange={(e) => {
                              var fileName = e.target.files[0].name;
                              var idxDot = fileName.lastIndexOf(".") + 1;
                              var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
                              if (
                                extFile == "jpg" ||
                                extFile == "jpeg" ||
                                extFile == "png" ||
                                extFile == "webp"
                              ){
                                imageFiles[2] = e?.target?.files[0];
                                // prev[0] = URL.createObjectURL(e?.target?.files[0])
                                // setPreviewUrl(prev)
                                setImagep3(URL.createObjectURL(e?.target?.files[0]))
                              }else{
                                toast.error("Invalid file format!")
                              }
                              
                            }}
                            // multiple
                            // onChange={HandleFile}
                          />
                        </Form.Group>
                      </Form>
                    </Button>
                  </div>
                  <div className="col-md-6">
                    <Button className="fullbutton">
                    { imgp4 && <img src={imgp4} />}

                      {!imgp4 &&<div className="custom_img_upload">
                        <span className="fa fa-picture-o mb-1" />
                        <p className="mb-0">Upload Image</p>
                      </div>}
                      <Form className="hideinput">
                        <Form.Group className="mb-3">
                          <Form.Control
                            type="file"
                            onChange={(e) => {
                              var fileName = e.target.files[0].name;
                              var idxDot = fileName.lastIndexOf(".") + 1;
                              var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
                              if (
                                extFile == "jpg" ||
                                extFile == "jpeg" ||
                                extFile == "png" ||
                                extFile == "webp"
                              ){
                                imageFiles[3] = e?.target?.files[0];
                                // prev[0] = URL.createObjectURL(e?.target?.files[0])
                                // setPreviewUrl(prev)
                                setImagep4(URL.createObjectURL(e?.target?.files[0]))
                              }else{
                                toast.error("Invalid file format!")
                              }
                              
                            }}
                            // multiple
                            // onChange={HandleFile}
                          />
                        </Form.Group>
                      </Form>
                    </Button>
                  </div>
                  <div className="col-md-6 mb-5">
                    <Button className="fullbutton">
                    { imgp5 && <img src={imgp5} />}
                      {!imgp5 && <div className="custom_img_upload">
                        <span className="fa fa-picture-o mb-1" />
                        <p className="mb-0">Upload Image</p>
                      </div>}
                      <Form className="hideinput">
                        <Form.Group className="mb-3">
                          <Form.Control
                            type="file"
                            onChange={(e) => {
                              var fileName = e.target.files[0].name;
                              var idxDot = fileName.lastIndexOf(".") + 1;
                              var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
                              if (
                                extFile == "jpg" ||
                                extFile == "jpeg" ||
                                extFile == "png" ||
                                extFile == "webp"
                              ){
                                imageFiles[4] = e?.target?.files[0];
                                // prev[0] = URL.createObjectURL(e?.target?.files[0])
                                // setPreviewUrl(prev)
                                setImagep5(URL.createObjectURL(e?.target?.files[0]))
                              }else{
                                toast.error("Invalid file format!")
                              }
                              
                            }}
                            // multiple
                            // onChange={HandleFile}
                          />
                        </Form.Group>
                      </Form>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Toaster position="top-center" reverseOrder={false} />
        </Scrollbars>
      </div>
    </div>
  );
};

export default PlacePhotos;
