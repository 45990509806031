import React,{useState} from 'react';
import {Modal,Button} from 'react-bootstrap'

const OvenDetails = (props) =>{
    const[oven , setOven] = useState(true)
    
    return(
    <>
      <Modal show={oven} scrollable centered size="md" className='modal_style scenic listingmodals'>
          <Modal.Header className='border-0 pb-0'>
            <button type="button" className="btn close close_modal" onClick={() =>props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
          </Modal.Header>
          <Modal.Body>
            <h4 className='mb-4'>Oven Details</h4>
            <h6 className='mb-3'>Material</h6>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Stainless steel</p>
                        </label>
                        <input class="form-check-input" type="checkbox" value="Air conditioning details" id="flexCheckChecked" />
                    </div>
                 
             <hr className='themehr my-5'/>
                <div className='mb-4'>  
                <div className='h-55'>
                    <h6 className='my-2'>Brand</h6>
                    <input type="text" className='form-control'/>
                </div> 
             
               
               
                    

                   
                </div>
          </Modal.Body>
          <Modal.Footer className='jc-between'>
            <Button variant="link" className="text-dark">Cancel</Button>
            <button className='btn btn-theme'>Save</button>
          </Modal.Footer>
      </Modal>
    </>
    )
}

export default OvenDetails