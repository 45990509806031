import React, { useEffect, useState } from 'react';
import {Modal} from 'react-bootstrap'
import { Elements, LinkAuthenticationElement, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
// import { CreditCard, PaymentsForm } from 'react-square-web-payments-sdk';
import {
    SquarePaymentsForm,
    CreditCardInput,
  } from 'react-square-web-payments-sdk';
import { toast } from 'react-hot-toast';
import paypal from "../../Assets/images/paypal1.png"
import squareLogo from "../../Assets/images/Square.png"
import strip from "../../Assets/images/stripe2.png"
import { getUserDatas, getusersdatahooks, hostsubscriptionhooks } from '../../Hooks/useUserHook';
import { FRONT_URL } from '../../config/env';
import Confirmhostsubscribepay from './hostsubscribepay';

const HostSubscribeModal = (props) =>{
    const[hostsubscribe , setHostsubscribe] = useState(true);
    const [userdata , setUserdata] = useState({});
    const [secret , setSecret] = useState("");
    const [initiatedata , setInitiatedata] = useState({});
    const [pay , setPay] = useState(false);
    const [square , setSquare] = useState(true);
    const [squaredata , setSquaredata] = useState({});
    // const stripe = useStripe();
    // const elements = useElements();


    useEffect(() => {
        setUserdata(getUserDatas())
        console.log("stripe" , props.stripe);
    } , [])

    const handlepaid = async(data) => {
        let payload = {
            id : props?.sub?._id,
            userid : userdata?._id,
            type : data,
            // createddata :
        }
        let result =  hostsubscriptionhooks(payload).then((res) => {
            if(data == "Stripe"){
                console.log("result" , res?.data?.paymentid?.client_secret);
                setSecret(res?.data?.paymentid?.client_secret);
                setInitiatedata(res?.data?.paymentid);
                 getusersdatahooks(userdata?.email).then(() => {
                    // toast.success("Paid Successfully!");
                    // props.onDismiss();
                    // window.location.reload();
                    setPay(true);
                 });
            }
            if(data == "Square"){
                setSquaredata(res?.data?.paymentid)
                window.open(res?.data?.paymentid?.url)
                getusersdatahooks(userdata?.email).then(() => {
                    // toast.success("Paid Successfully!");
                    // props.onDismiss();
                    // window.location.reload();
                    // setPay(true);
                 });
            }
        });
        
    }

    // const handlepay = async() => {
    //     const {error} = await stripe.confirmPayment({
    //         elements,
            
    //         confirmParams: {
    //           // Make sure to change this to your payment completion page
    //           return_url: `${FRONT_URL}/orderconfirm`,
    //         },
    //       });
    // }
   
    return(
        <>
          <Modal show={hostsubscribe} centered scrollable className='modal_style'>
            <Modal.Header className='border-0 pb-0'>
                 <button type="button" className="btn close close_modal" onClick={() =>props.onDismiss()}><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
            </Modal.Header>
            <Modal.Body>
            {!pay && <div className='bg-white listingmodals'>
                <h5>Subscription</h5>
                
                <div className='mt-4'>

                <div className="graybox br-0 mt-0 py-3 mobi_fle_ger">
                    <div className="d-flex jc-between">
                        <h6 className="mb-3 f-14">
                            <p className="themeclr">Plan</p>
                            <p className="text-muted">{props?.sub?.name}</p>
                            </h6>
                            </div>
                            <div className="d-flex jc-between">
                                <h6 className="mb-3 f-14">
                                    <p className="themeclr">Price</p>
                                    <p className="text-muted">${props?.sub?.price}</p>
                                    </h6>
                                    </div>
                                    <div className="d-flex jc-between">
                                        <h6 className="mb-0 f-14">
                                            <p className="themeclr">Days</p>
                                            <p className="text-muted">{props?.sub?.days} days</p>
                                            </h6>
                                            </div>
                                          
                                                    </div>
<hr />
<h5>Pay Via</h5>

<button className='btn btn_payemnt' onClick={() => handlepaid("Square")}>
    <img src={squareLogo} className='img-fluid' />
    Square
</button>

<button className='btn btn_payemnt' onClick={() => handlepaid("Stripe")}>
    <img src={strip} className='img-fluid' />
    Stripe
</button>

                  
                </div>
            </div>}

                    {secret && <div className='test_pay'>
                        <Elements
                            stripe={props.stripe}
                            options={{
                                clientSecret: secret,
                                appearance: {
                                    theme: "flat",
                                    variables: { colorPrimaryText: "#262626" },
                                },
                            }}
                        >
                           <Confirmhostsubscribepay />
                        </Elements>
                    </div> } 

                    {/* {square && <div className='test_pay'>
                        <SquarePaymentsForm
                        applicationId={"sandbox-sq0idb-UUBLNvUaVwmWIZAA_JPv7g"} 
                        locationId={"L7YY2YB8RM5M7"}
                        >
                            <CreditCardInput />
                        </SquarePaymentsForm>
                        </div>}       */}
          </Modal.Body>
          <Modal.Footer className='border-0'>
            {/* <button onClick={() =>props.onDismiss()} className='btn btn-theme'>Pay Now</button> */}
          </Modal.Footer>
      </Modal>
        </>
    )
}

export default HostSubscribeModal