import React,{useState} from 'react';
import {Modal,Button} from 'react-bootstrap'
import Scrollbars from 'react-custom-scrollbars';

const GardenDetails = (props) =>{
    const[garden , setGarden] = useState(true)
    
    return(
    <>
      <Modal show={garden} centered size="md" className='modal_style scenic listingmodals'>
          <Modal.Header className='border-0 pb-0'>
            <button type="button" className="btn close close_modal" onClick={() =>props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
          </Modal.Header>
          <Modal.Body>
            <h4 className='mb-0'>Garden details</h4>
            <p className='text-muted mb-4'>An open space on the property usually covered in grass</p>
            <Scrollbars style={{  height: 360  }}>
                <div>
                   <h6 className='mb-3'>Privacy</h6>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Private</p>
                        </label>
                        <input class="form-check-input" type="radio" value="Air conditioning details" id="flexCheckChecked" />
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Public or shared</p>
                        </label>
                        <input class="form-check-input" type="radio" value="Air conditioning details" id="flexCheckChecked" />
                    </div>
                    <hr className="themehr" />
                    <h6 className='mb-3'>Type</h6>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Private</p>
                        </label>
                        <input class="form-check-input" type="radio" value="Air conditioning details" id="flexCheckChecked" />
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Public or shared</p>
                        </label>
                        <input class="form-check-input" type="radio" value="Air conditioning details" id="flexCheckChecked" />
                    </div>
                   
                    
                </div>
            </Scrollbars>
          </Modal.Body>
          <Modal.Footer className='jc-between'>
            <button className='btn btn-theme' >Save</button>
            <Button className='text-dark' variant="link" >Cancel</Button>
          </Modal.Footer>
      </Modal>
    </>
    )
}

export default GardenDetails