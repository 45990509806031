import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap';
import SeeMoreDates from './SeeMoreDates';
import Datepicker from 'react-datepicker';
import {ExperienceCounter} from '../../Common/experienceCounter';
import { useNavigate } from 'react-router-dom';
import { getUserEmail } from '../../../Hooks/useUserHook';


const BookingCard = (props) =>{
    const push = useNavigate();
    console.log('Booking CArd Props',props)
    const[moredates, setMoreDates] = useState(false)
    const getDates = (data) => {
        console.log("-------",data);
        const newdata = {date : data , datas :props.data }
        push(`/book-experience/${props.data._id}`,{state: newdata});
    }

    return(
        <div className='shadowbox p-4 stikybox'>
                    {moredates && <SeeMoreDates data = {props.data} onDismiss={() => setMoreDates(false)}/>}
<div className="d-flex pricepernight jc-between gap-3">
    <p><h3 className='mb-0 d-inline-block'>From {props.data.pricePerGuest}</h3> / person</p>
</div>
{/* <button className='btn btn-link text-dark ps-0'>See all price</button> */}

{/* <div className='d-flex flex-1 mt-3'>
    <Accordion>
        <Accordion.Item eventKey="0">
            <Accordion.Header>
                <div className="text-dark">
                    <p className="mb-0 f-12 ">Dates</p>
                    <p>Add Date</p>
                </div>
            </Accordion.Header>
            <Accordion.Body>
            <Datepicker
                                    // onChange={(date) => handleDateChange(date)}
                                    selectsStart={true}
                                    monthsShown={2}
                                    minDate={new Date()}
                                    shouldCloseOnSelect={false}
                                    // excludeDates={roomDetails.bookedDates}
                                    // selected={startDate}
                                    // startDate={startDate}
                                    // endDate={endDate}
                                    inline={true}
                                />
            </Accordion.Body>
        </Accordion.Item>
    </Accordion>
    <Accordion>
        <Accordion.Item eventKey="0">
            <Accordion.Header>
                <div className="text-dark">
                    <p className="mb-0 f-12 ">Dates</p>
                    <p>Add Date</p>
                </div>
            </Accordion.Header>
            <Accordion.Body>
                <ExperienceCounter />
            </Accordion.Body>
        </Accordion.Item>
    </Accordion>
</div> */}

<div className='d-flex jc-between mt-4  align-items-center'>
    <div>
        {console.log('props.data.bookedDates[0][0]', props?.data?.bookedDates)}
        <h6 className='themeclr'>{props.data&&props.data.bookedDates&&props.data.bookedDates[0]&&new Date(props.data.bookedDates[0][0]).toLocaleDateString()}</h6>
        {/* <p className='text-muted f-14 mb-0'>11AM to 4PM</p> */}
    </div>
    <div>
        <p>{props.data.pricePerGuest} / person</p>
        {getUserEmail() != props?.data?.email && <button className='btn btn-theme' 
        disabled = {new Date(props.data.bookedDates && props.data.bookedDates[0] && props.data.bookedDates[0][1]) < new Date()} 
        onClick={() => getDates(props.data&&props.data.bookedDates&&props.data.bookedDates[0]&& props.data.bookedDates[0][0])}>Choose</button>}
    </div>
</div>

<hr className='themehr' />

 
 <div className='text-center my-4'>
    <button className='btn btn-theme f-14' onClick={() => setMoreDates(true)}>See more dates</button>
</div>
</div>
    )
}


export default BookingCard
