
import React, { useEffect, useState } from 'react';

import HostCard from './Cards/HostCard';
import HostHeader from './HostHeader';
import HostUpdateCard from './Cards/HostUpdateCard'
import {Row, Col, Tab ,Nav,Button} from 'react-bootstrap'
import Footer from '../../Common/Footer'
import { Link } from 'react-router-dom';
import {checkExperienceBookStatus} from '../../../Hooks/useUserExperienceHook'
import YourReservationTab from './YourReservationTab';
import { getArrivingSoon, getPendingBookings, getCheckoutToday, getUpcomingBookings, getcancelletbooking  } from '../../../Hooks/useUserHostingHook';
import { getUserDatas, getUserEmail } from '../../../Hooks/useUserHook';
import reservation from '../../../Assets/images/reservation.png'
import { toast } from 'react-hot-toast';
const StartHosting = () => {
    const [arrivingSoon, setArrivingSoon] = useState([]);
    const [pendingBookings, setPendingBookings] = useState([]);
    const [checkOutToday, setCheckOutToday] = useState([]);
    const [upcomingBookings, setUpcomingBookings] = useState([]);
    const [email, setEmail] = useState("");
    const [cancelbooking , setCancelbooking] = useState([]);

    const getData = (hostEmail) => {
        const data =  getUserEmail()
            setEmail(data);
        

        getArrivingSoon(hostEmail).then((data)=>{
            console.log("getArrivingSoon",data);
            setArrivingSoon(data);
        })
        getPendingBookings(hostEmail).then((data)=>{
            console.log("getPendingBookings",data);
            setPendingBookings(data);
        })
        getCheckoutToday(hostEmail).then((data)=>{
            console.log("getCheckoutToday",data);
            setCheckOutToday(data);
        })
        getUpcomingBookings(hostEmail).then((data)=>{
            console.log("getUpcomingBookings",data);
            setUpcomingBookings(data);
        })
        getcancelletbooking(hostEmail).then((data) => {
            setCancelbooking(data);
        })
    }

    const refreshPage = () => {
        const mail = window.location.pathname.split("/")[2];
        getData(mail);
        checkExperienceBookStatus(mail);
    }

    // const checksubscription = () =>{
    //     let user = getUserDatas();
    //     if(parseFloat(user?.hostsubscribedaycount) <= 0){
    //         toast.error("Need Host subscription for use all host option!")
    //     }
    // }

    useEffect(()=>{
        const mail = window.location.pathname.split("/")[2];
        console.log('maildata',mail)
        getData(mail);
    },[]);


    return(
        <div>
            <HostHeader email={email}/>
            <div className='themebg dashboard-today-sec'>
               <div className='container py-5'> 
                <h2 className='today'>Today</h2>
                <p className='descrip'>Welcome! You’re on your way to being an incredible Host – here’s how to start.</p>
              
                <div className='cardsec pt-4 pb-5'>
                      <div className='row'>
                    <HostCard class="large"/>
                    <div className='todaysrequest'>
                        <div className='row'>
                            <div className='col-xl-4 col-lg-4 col-sm-6'>
                                <Link className='nav-link text-dark nounder p-0 mt-5' to="/hosting/inbox">
                                <div className='bordbox start_jps_box d-flex jc-between align-items-center br-10'>
                                    <div className='f-20'>
                                          <p className='text-left'><span className='fw-600'>John Doe</span> has a questin</p>
                                            <p className='text-left text-muted'>28-29 <span className='text-danger'><b>2h to respond</b></span></p>
                                            <p className='text-left hostname text-muted'>Just for try</p>
                                    </div>
                                    <span className='fa fa-info-circle text-danger f-20'></span>
                                  
                                </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                </div> 
            </div>


                <div className='container'> 
                <div className='reserve my-5'>
                   <div className='d-flex justify-content-between'>
                        <h4>Your Reservations</h4>
                        {/* <a href="#" className='text-dark'>All Reservation <span>(0)</span></a> */}

                    </div> 
                    <div className='reseve-tabs mb-5'>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="checkout">
                            <Row className= 'd-flex align-items-center justify-content-between flex-column'>
                                <Col className=  "mx-auto bg-white mt-4 mb-3">
                                    <Nav variant="pills" className="">
                                    <Nav.Item>
                                        <Nav.Link eventKey="checkout">Checking out <span>({checkOutToday.length})</span></Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="currenthosting">Currently Hosting <span>({pendingBookings.length})</span></Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="arriving">Arriving soon <span>({arrivingSoon.length})</span></Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="upcoming">Upcoming <span>({upcomingBookings.length})</span></Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="cancelled">Cancelled <span>({cancelbooking.length})</span></Nav.Link>
                                    </Nav.Item>
                                    </Nav>
                                </Col>
                                <Col className="mx-auto mt-3">
                                    <Tab.Content>
                                    <Tab.Pane eventKey="checkout">

                                        {(checkOutToday&&checkOutToday.length!==0)?
                                        <YourReservationTab onRefresh={refreshPage} email={email} isPending={false} datas={checkOutToday}/>:
                                        <div className='no-stat'>
                                            <div className='no-stat-cont'>
                                                {/* <span className='fa fa-user'></span> */}
                                                <img src={reservation} alt='reservation-img' className='reservation' />
                                                <p className='my-4'>You don’t have any guests checking out today or tomorrow.</p>
                                            </div>
                                            
                                        </div>}
                                        

                                      
                                   
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="currenthosting">

                                    {(pendingBookings&&pendingBookings.length!==0)?
                                        <YourReservationTab onRefresh={refreshPage} email={email} isPending={true} datas={pendingBookings}/>:
                                        <div className='no-stat'>

<div className='no-stat-cont'>
                                                {/* <span className='fa fa-user'></span> */}
                                                <img src={reservation} alt='reservation-img' className='reservation' />
                                                <p className='my-4'>You don’t have any hosting bookings.</p>
                                            </div>
                                            

                                        </div>}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="arriving">
                                    {(arrivingSoon&&arrivingSoon.length!==0)?
                                        <YourReservationTab onRefresh={refreshPage} email={email} isPending={false} datas={arrivingSoon}/>:
                                        <div className='no-stat'>
                                            <div className='no-stat-cont'>
                                                {/* <span className='fa fa-user'></span> */}
                                                <img src={reservation} alt='reservation-img' className='reservation' />
                                                <p className='my-4'>You don’t have any arriving bookings.</p>
                                            </div>
                                            
                                        </div>}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="upcoming">
                                    {(upcomingBookings&&upcomingBookings.length!==0)?
                                        <YourReservationTab onRefresh={refreshPage} email={email} isPending={false} datas={upcomingBookings}/>:
                                        <div className='no-stat'>
                                            <div className='no-stat-cont'>
                                                {/* <span className='fa fa-user'></span> */}
                                                <img src={reservation} alt='reservation-img' className='reservation' />
                                                <p className='my-4'>You don’t have any upcoming bookings.</p>
                                            </div>
                                            
                                        </div>}

                                    </Tab.Pane>
                                    <Tab.Pane eventKey="cancelled">
                                    {(cancelbooking&&cancelbooking.length!==0)?
                                        <YourReservationTab onRefresh={refreshPage} email={email} isPending={false} datas={cancelbooking}/>:
                                        <div className='no-stat'>
                                            <div className='no-stat-cont'>
                                                {/* <span className='fa fa-user'></span> */}
                                                <img src={reservation} alt='reservation-img' className='reservation' />
                                                <p className='my-4'>You don’t have any cancelled bookings.</p>
                                            </div>
                                            
                                        </div>}

                                    </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
         
                    </div>
                  
            </div>
        </div>

        {/* <div className='host-tips'>
            <div className='container'>
                <h4 className='mb-4'>Hosting tips & Updates</h4>
                    <div className='row'>
                        <HostUpdateCard class="small"/>
                    </div>
            </div>
        </div> */}
        
        <Footer />
        </div>
    )
}

export default StartHosting