import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

const CreateHost = (props) =>{
    return(
        <>
         
                <div>
                    <h2 className='conthead'>Create the page guests will see</h2>
                   
                    <div className='h-55 mt-4'>
                        <ul className='liststyle'>
                            <li className='text-muted'>Choose the category that best describe your experience. Add a second,
                        if you think it fits into another category</li>
                        {/* <li className='text-muted'>Choose the category that best describe your experience. Add a second,
                        if you think it fits into another category</li> */}
                        </ul>
                        <button className='btn btn-theme' onClick={() => props.count()}>Next</button>
                    </div>
                    
              </div>            
          
        </>

    )
}

export default CreateHost