import React, { useState } from 'react';
import {Modal,Button} from 'react-bootstrap'

const ReviewHost = (props) =>{
    const [reviewhost, setReviewHost] = useState(true)
    const [check, setCheck] = useState(false);
    return(
        <>
         <Modal show={reviewhost} centered scrollable className='modal_style ' size="md">
            <Modal.Header className='border-0 pb-0 d-flex justify-content-end'>
              <button type="button" className="btn close close_modal" onClick={() =>props.onDismiss()}><span aria-hidden="true" className=''>×</span><span class="sr-only">Close</span></button>
            </Modal.Header>
          <Modal.Body className=''>
             <div>
                <div className=''>
                    <h4 className=' fw-500 text-heading'>Review our hosting standards</h4>
                    <p className='f-12 line-height-1 text-sub-heading fw-300 '>Dawn takes you backstage at an aquarium where you learn about research programs with marine
                         conservationists.</p>
                    <div>
                        <h6 className='fw-500 f-14 mt-3'>1. Credible</h6>
                        <p className='f-12 fw-300'>Dawn takes you backstage at an aquarium where you learn about research programs with marine
                         conservationists.</p>
                    </div>
                    <div>
                        <h6 className='fw-500 f-14 mt-3'>2. Genuine</h6>
                        <p className='f-12 fw-300'>Dawn takes you backstage at an aquarium where you learn about research programs with marine
                         conservationists.</p>
                    </div>
                    <div>
                        <h6 className='fw-500 f-14 mt-3'>3. Emoethic</h6>
                        <p className='f-12 fw-300'>Dawn takes you backstage at an aquarium where you learn about research programs with marine
                         conservationists.</p>
                    </div>
                    <div class="form-check my-3 align-items-start justify-content-end gap-2">
                        <label class="form-check-label f-14" for="flexCheckChecked">
                            That's me
                        </label>
                        <input class="form-check-input"  type="checkbox" value="Split-type ductless system" id="flexCheckChecked" onChange = {e =>{e.target.checked ?setCheck(true) : setCheck(false)}}/>
                    </div>
                  <Button variant="" disabled={!check} className='f-12 px-3 my-3 py-2 btn-theme' onClick={() => props.step3()}>Next</Button>
                </div>
               
             </div>
                          
          </Modal.Body>
      </Modal></>
    )
}

export default ReviewHost