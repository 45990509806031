import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { API_URL, toastOptions } from '../../config/env';
import { Link } from 'react-router-dom';
import { cancelRoom } from '../../Hooks/useUserHostingHook';
import toast, { Toaster } from 'react-hot-toast';


const PendingOrdersCard = (props) =>{
  
    const cancel = () => {
        const data = {
            bookingId:props.data._id,
            roomId:props.data.roomId,
            checkInDate:props.data.checkInDate,
            checkOutDate:props.data.checkOutDate,
            hostEmail:props.data.hostEmail,
            email:props.data.email,
            cancellationPolicy:props.data.cancellationPolicy,
            cancelledAmount: parseFloat(props.data.totalAmount) - parseFloat(props.data.serviceFeeAmount)
        }
        console.log('datasguuu',data)
        cancelRoom(data).then((data)=>{
            console.log('datascancel',data,data.message )
            toast.success(data.message,toastOptions);
            props.onReload();
        })
    }

    return(
        <>
        {/* {(props.data.isVerified)? */}
            <div className='cols col-xl-3 col-lg-4  mb-xl-0 mb-3'>
            <div className='where-card yellownav'>
                
                {/* <Link to={"/confirm-and-pay/"+props.data._id}  className='nounder'> */}
                <div className='where-cont mt-3 bordbox br-10'>
                    <div className='d-flex justify-content-between'>
                        <h6 className='name themeclr fw-600 mb-3'>{props.data.roomTitle}</h6> 
                        {/* <p className='fav txtgray f-12'>New<span className='fa fa-star ms-1'></span></p> */}
                    </div>
                    {/* <p className='review'><i className='fa fa-star me-1'><span className='fa fa-star'></span></i>4.94(147)</p> */}
                    <p className='txtgray f-14 d-flex jc-between mb-2'><span className='themeclr'><b>Hosted by</b> </span>{props.data.hostEmail}</p>
                    <p className='date themeclr fw-600 f-14 d-flex jc-between mb-2'><span>Check-In:</span><span className='txtgray f-14'>{new Date(props.data.checkInDate).toLocaleDateString()}</span></p>
                    <p className='date themeclr fw-600 f-14 d-flex jc-between mb-2'><span>Checkout:</span><span className='txtgray f-14'>{new Date(props.data.checkOutDate).toLocaleDateString()}</span></p>
                    <p className='date themeclr fw-600 f-14 mb-2'>{props.data.nights}&nbsp;<span className='txtgray f-14'>Night(s)</span></p>
                    <p className='rent themeclr f-14 fw-600 d-flex jc-between mb-2'><span>Amount:</span>&#8377;{props.data.totalAmount}</p>
                    {(props.type=="pendingTrips")?
                    <button 
                    onClick={()=>{window.location.href = window.location.origin+"/payment-success/"+props.data._id}} 
                    disabled={props.type == "cancelledTrips"}
                    className='btn btn-theme my-3'>{(props.type=="cancelledTrips")?"Cancelled":"Status"}</button>
                    :<button onClick={()=>{window.location.href = window.location.origin+"/confirm-and-pay/"+props.data._id}} 
                    disabled={props.type=="pendingApprovals" || props.data.iscancelled || props.data.expired} 
                    className='btn btn-theme my-3'>
                        {(props.type=="pendingApprovals")?
                        (props.data.expired?"Expired":
                        (props.data.iscancelled?"cancelled":"Wait for Approval")):
                        (props.data.expired?"Expired":(props.data.iscancelled?"cancelled":"Pay"))}
                        </button>}
                    {(props.data.iscancelled || props.data.expired)?
                    <></>:
                    <button onClick={()=>{cancel()}} className='btn btn-theme my-3'>cancel</button>}
                </div>
                {/* </Link> */}
            </div>
            <Toaster/>
        </div>  
        {/* :<></>} */}
        
        </>
    )
}


export default PendingOrdersCard 