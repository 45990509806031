import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {Dropdown,Button} from 'react-bootstrap'
import { propTypes } from 'react-bootstrap/esm/Image';
import logo from '../../../../Assets/images/favicon.png'
import {getExperienceFromLocal} from "../../../../Hooks/useUserExperienceHook"
const ExperianceSide = (props) =>{
    const navigate = useNavigate();

    const[mobileside, setMobileSide] = useState(true)
    const[experienceDatas, setExperienceDatas] = useState({});
    const [reload, setReload] = useState(0);
    let expriencedata = getExperienceFromLocal()

    useEffect(()=>{
        if(!experienceDatas)
            setExperienceDatas(getExperienceFromLocal());
    },[reload,expriencedata]);

    return(
        <>
        <div className='manage-experience'>
            <div onClick={()=>{navigate("/")}} className='flu'>
                <img src={logo} className="mb-3 ps-3 pt-3 d-none d-md-block logo-img"/>
                </div>
            <Button variant="link" className='d-md-none text-white' onClick={() => setMobileSide(!mobileside)}><span className='fa fa-bars'></span></Button>
            <div className= {mobileside ? 'sidebar-lists mobildesidebar themescroll' : "sidebar-lists themescroll"}>
                <div className='li-head'>
                    <Button variant="success" id="dropdown-basic" onClick={() => props.getpage(1)}>
                        <span className='fa fa-user me-2'></span>  Category
                    </Button>
                </div>
                <div className='li-head'>
                    <div>
                        <button variant="success" className='btn-success' onClick={() => props.getpage(2)} id="dropdown-basic">
                        <span className='fa fa-user me-2'></span> Create page
                        </button>

                        <div className='dropdown-menu '>
                            <Dropdown.Item disabled={!(getExperienceFromLocal() && getExperienceFromLocal().categories)} href="#/action-2"  onClick={() => {props.getpage(3);setReload(3)}}>Create Title<span className={(experienceDatas&&experienceDatas.title)?'fa fa-check':''}></span></Dropdown.Item>
                            <Dropdown.Item disabled={!(getExperienceFromLocal() && getExperienceFromLocal().title)} href="#/action-3" onClick={() => {props.getpage(4);setReload(4)}}>Set time <span className={(experienceDatas&&experienceDatas.date)?'fa fa-check':''}></span></Dropdown.Item>
                            <Dropdown.Item disabled={!(getExperienceFromLocal() && getExperienceFromLocal().date)} href="#/action-4" onClick={() => {props.getpage(5);setReload(5)}}>Tagline  <span className={(experienceDatas&&experienceDatas.tagLine)?'fa fa-check':''}></span></Dropdown.Item>
                            <Dropdown.Item disabled={!(getExperienceFromLocal() && getExperienceFromLocal().tagLine)} href="#/action-5" onClick={() => {props.getpage(6);setReload(5)}}>Photos  <span className={(experienceDatas&&experienceDatas.photos&&experienceDatas.photos.length==5)?'fa fa-check':''}></span></Dropdown.Item>
                            <Dropdown.Item disabled={!(getExperienceFromLocal() && getExperienceFromLocal().photos)} href="#/action-6" onClick={() => {props.getpage(7);setReload(5)}}>What we'll do?  <span className={(experienceDatas&&experienceDatas.whatYouWillDo)?'fa fa-check':''}></span></Dropdown.Item>
                            <Dropdown.Item disabled={!(getExperienceFromLocal() && getExperienceFromLocal().whatYouWillDo)} href="#/action-7" onClick={() => {props.getpage(8);setReload(5)}}>Where we'll be?  <span className={(experienceDatas&&experienceDatas.whereYouWillBe)?'fa fa-check':''}></span></Dropdown.Item>
                            <Dropdown.Item disabled={!(getExperienceFromLocal() && getExperienceFromLocal().whereYouWillBe)} href="#/action-8" onClick={() => {props.getpage(9);setReload(5)}}>Where we'll meet?  <span className={(experienceDatas&&experienceDatas.meetingLocation)?'fa fa-check':''}></span></Dropdown.Item>
                            <Dropdown.Item disabled={!(getExperienceFromLocal() && getExperienceFromLocal().meetingLocation)} href="#/action-9" onClick={() => {props.getpage(10);setReload(5)}}>What I'll provide  <span className={(experienceDatas&&experienceDatas.whatYouWillProvide)?'fa fa-check':''}></span></Dropdown.Item>
                            <Dropdown.Item disabled={!(getExperienceFromLocal() && getExperienceFromLocal().whatYouWillProvide)} href="#/action-10" onClick={() => {props.getpage(11);setReload(5)}}>Notes  <span className={(experienceDatas&&experienceDatas.whatGuestShouldKnow)?'fa fa-check':''}></span></Dropdown.Item>
                        </div>
                    </div>
                </div>
                <div className='li-head'>
                    <div>
                        <Button disabled={!(getExperienceFromLocal()&&getExperienceFromLocal().whatYouWillProvide)} variant="success" id="dropdown-basic"  onClick={() => props.getpage(12)}>
                        <span className='fa fa-user me-2'></span> Finishing touch
                        </Button>

                        <div className='dropdown-menu '>
                            <Dropdown.Item disabled={!(getExperienceFromLocal()&&getExperienceFromLocal().whatGuestShouldKnow)} href="#/action-11"  onClick={() => {props.getpage(13);setReload(5)}}>About you <span className={(experienceDatas&&experienceDatas.bio)?'fa fa-check':''}></span></Dropdown.Item>
                            <Dropdown.Item disabled={!(getExperienceFromLocal()&&getExperienceFromLocal().bio)} href="#/action-12" onClick={() => {props.getpage(14);setReload(5)}}>Guest Requirement<span className={(experienceDatas&&experienceDatas.whoCanAttend)?'fa fa-check':''}></span></Dropdown.Item>
                            <Dropdown.Item disabled={!(getExperienceFromLocal()&&getExperienceFromLocal().whoCanAttend)} href="#/action-13" onClick={() => {props.getpage(15);setReload(5)}}>Group size <span className={(experienceDatas&&experienceDatas.maxGuest)?'fa fa-check':''}></span></Dropdown.Item>
                            <Dropdown.Item disabled={!(getExperienceFromLocal()&&getExperienceFromLocal().maxGuest)} href="#/action-14" onClick={() => {props.getpage(16);setReload(5)}}>Prize <span className={(experienceDatas&&experienceDatas.pricePerGuest)?'fa fa-check':''}></span></Dropdown.Item>
                            <Dropdown.Item disabled={!(getExperienceFromLocal()&&getExperienceFromLocal().pricePerGuest)} href="#/action-15" onClick={() => {props.getpage(17);setReload(5)}}>Preparation time <span className={(experienceDatas&&experienceDatas.preparationTime)?'fa fa-check':''}></span></Dropdown.Item>
                            <Dropdown.Item disabled={!(getExperienceFromLocal()&&getExperienceFromLocal().preparationTime)} href="#/action-16" onClick={() => {props.getpage(18);setReload(5)}}> Packing list <span className={(experienceDatas&&experienceDatas.packingList)?'fa fa-check':''}></span></Dropdown.Item>
                            <Dropdown.Item disabled={!(getExperienceFromLocal()&&getExperienceFromLocal().packingList)} href="#/action-17" onClick={() => {props.getpage(19);setReload(5)}}>Review experience </Dropdown.Item>
                        </div>
                    </div>
                </div>
        
                
            </div>
               
        </div>
    </>
    )
}

export default ExperianceSide