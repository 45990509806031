import React from 'react';
import {useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const CleaningFee = () =>{
    const history = useNavigate();
  

    return(
       <div className='container container_custom'>
        <button className='backbtn my-5' onClick={() => {history('/hosting', {state:"clean"}) }}><span className='fa fa-chevron-left me-2'></span> Back</button>
        <div className='row justify-content-center'>
           <div className='col-xl-6 col-md-10'>
           <div className='bg-white'>
                <div className='loca'>
                    <h2>Add a cleaning fee</h2>
                    <p className=' text-muted'>You can charge this fee to guests to cover the cost of cleaning services and supplies.</p>  
                     <div className='mt-4 dollinput h-55'>
                        <span> $</span>
                         <input type="text" className='form-control '/>
                     </div>
                   
                </div>
          </div>
           </div>
        </div>
      
        <div className='fixedbtn'> 
            <Button variant="link" className='btn btn-theme'>Save</Button>
        </div>
       </div>
    )
}

export default CleaningFee