
import React, { useState } from 'react';
import piggy from '../../../../Assets/images/piggy.png'
import AddVat from './Modals/AddVat';


const AccGuestContrib = () =>{
    const[addvat , setAddVat] = useState(false)

return(<>
{addvat && <AddVat onDismiss={() => setAddVat(false)} />}
      <div className='row mt-5 justify-content-between'>
        <div className='col-md-7'>
            <h4>Guest contributions</h4>
            <p>To show their appreciation for great hospitality, guests can send an optional financial contribution to a host after completing a stay or an FlutterPads Experience. You can choose to automatically allow or decline future contributions from guests.</p>
                <div className='d-flex justify-content-between align-items-center mt-5'>
                    <p>Allow contributions</p> 
                    <div class="form-switch">
                        <input type="checkbox" id="custom-switch" class="form-check-input"/>
                    </div>
                </div>              
              

                                    <hr className='themehr' />
                                   
                                    <p className='text-muted f-14'>100% of all contributions will be deposited into your
                                     payout account, unless you have an account balance. Your payout is subject to the 
                                     <a href="#" className='text-dark f-14'> Payment Terms of Service</a>. Please note that your contribution 
                                    may not be tax-deductible or eligible for tax credits.</p>

                                    <p className='text-muted '>To learn more about guest contributions, visit the 
                                     <a href="#" className='text-dark f-14'> Help Centre</a>.
                                     </p>
                                   
                            </div>
                            <div className='col-lg-4 col-md-5 d-md-block d-none '>
                                <div className='right-cont bord p-4 br-10'>
                                    <img src={piggy} className="mb-3"/>
                                    <h6>Don't want to keep a contribution?</h6>
                                    <p className='text-muted f-14'>If you’ve already received a contribution that you don’t want to keep, consider making a donation of the same amount to your charity of choice.</p>
                                    <p className='text-muted f-14'>Need some ideas? Here are some non-profit organisations working to help with the COVID-19 crisis:</p>
                                    <ul className='li-style-disc mt-3'>
                                        <li>
                                            <a href="#" className='themeclr'>International Medical Corps</a>
                                        </li>
                                        <li>
                                            <a href="#" className='themeclr'>International Rescue Committee</a>
                                        </li>
                                        <li>
                                            <a href="#" className='themeclr'>The International Federation of Red Cross and Red Crescent Societies</a>
                                        </li>
                                    </ul>
                                    
                                    
                                </div>
                            </div>
                        </div>
                    
    </>
)
}
export default AccGuestContrib