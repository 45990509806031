import React from 'react';


const AccPayout = () =>{
return(<>
      <div className='row mt-5 justify-content-between'>
                            <div className='col-md-7'>
                               <h4>How you’ll get paid</h4>
                               <p>Add at least one payout method so we know where to send your money.</p>
                               <button className='btn btn-theme mt-4'>Set up payouts</button>
                             
                            </div>
                            <div className='col-lg-4 col-md-5 d-md-block d-none '>
                                <div className='right-cont bord p-4 br-10'>
                                    <h6 className='mb-4'>Need Help ?</h6>
                                    <p className='d-flex jc-between align-center f-14'>
                                        When you'll get your payout
                                        <span className='fa fa-chevron-right'></span>
                                    </p>

                                    <p className='d-flex jc-between align-center f-14 my-3'>
                                    How payouts work
                                        <span className='fa fa-chevron-right'></span>
                                    </p>

                                    <p className='d-flex jc-between align-center f-14'>
                                       Go to your transaction history
                                        <span className='fa fa-chevron-right'></span>
                                    </p>
                                    
                                    
                                </div>
                            </div>
                        </div>
                    
    </>
)
}
export default AccPayout