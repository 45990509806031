import React, { Component, useState } from 'react'
// import { Marker } from 'react-google-maps'
import { Marker } from 'react-google-maps'


import PlaceInfoWindow from './Placeinfowindow'

const PlaceMarker = (props) =>{
    const {lat, lng , name, price, description} = props
    const [showTooltip , setShowTooltip] = useState(false)
    const clickTooltip = ()=>{console.log("close");
        setShowTooltip(!showTooltip)
      }
    
      const closeWindow = ()=> {
        setShowTooltip(false)
      }

    return(
        <Marker
          position={{
            lat: parseFloat(lat),
            lng: parseFloat(lng)
          }}
          onClick={() => clickTooltip()}
        >
  
  {showTooltip && (
            <PlaceInfoWindow description={description}
                             name={name}
                             price={price}
                             closeWindow={() => closeWindow()}/>
          )}
        </Marker>
      );
}

export default PlaceMarker;