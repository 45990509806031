import React, { Component, useState, useEffect } from "react";
import Scrollbars from "react-custom-scrollbars";
import { FloatingLabel, Form } from "react-bootstrap";

const CreateDescriptionContent = (props) => {
  const [charcount, setCharCount] = useState(0);
  const [title, setTitle] = useState("");
  const [rulecount , setRulecount] = useState(0);
  const [rules , setRules] = useState("");
  const handleChange = (val) => {
    if (val != "") {
      setCharCount(val.length);
      setTitle(val);
      props.onGetDescriptionContent(val);
    } else {
      setTitle("");
      setCharCount(val.length);
      props.onGetDescriptionContent(val);
    }
  };

  const handleChangerules = (val) => {
    if (val != "") {
      setRulecount(val.length);
      setRules(val);
      props.onGetRules(val);
    } else {
      setRules("");
      setRulecount(val.length);
      props.onGetRules(val);
    }
  };


  useEffect(() => {
    setTitle(
      localStorage.getItem("descriptionContent")
        ? localStorage.getItem("descriptionContent")
        : title
    );
    setCharCount(
      localStorage.getItem("descriptionContent")
        ? localStorage.getItem("descriptionContent").length
        : title.length
    );
    localStorage.getItem("descriptionContent")
      ? props.onGetDescriptionContent(
          localStorage.getItem("descriptionContent")
        )
      : localStorage.removeItem("descriptionContent");

      // setRules(
      //   localStorage.getItem("rules")
      //     ? localStorage.getItem("rules")
      //     : rules
      // );
      // setRulecount(
      //   localStorage.getItem("rules")
      //     ? localStorage.getItem("rules").length
      //     : rules.length
      // );
      // localStorage.getItem("rules")
      //   ? props.onGetRules(
      //       localStorage.getItem("rules")
      //     )
      //   : localStorage.removeItem("rules");
  }, []);

  return (
    <div className="col-lg-12 col-xl-12 col-md-12">
      <div className="rightcont placetype mb-5 placetype_heoght_fix">
        <Scrollbars style={{ height: "calc(100vh - 320px)" }} className="scroll">
          <div
            className="row  px-3 align-items-center pb-5"
            data-aos="fade-up"
          >
            <div>
              <h3 className="mb-4">Create your Description</h3>

              <Form className="p-0">
                <FloatingLabel controlId="floatingTextarea2">
                  <Form.Control
                    maxlength="2000"
                    as="textarea"
                    placeholder="Leave a comment here"
                    style={{ height: "100px" }}
                    value={title}
                    onChange={(e) => {
                      handleChange(e.target.value);
                    }}
                  />
                </FloatingLabel>
                <div className="char mt-2">
                  {" "}
                  <span>{charcount && charcount}</span>/2000
                </div>
                <h3 className="mb-4 mt-4">Create your Rules</h3>
                <FloatingLabel controlId="floatingTextarea2">
                  <Form.Control
                    maxlength="2000"
                    as="textarea"
                    placeholder="Leave a comment here"
                    style={{ height: "100px" }}
                    value={rules}
                    onChange={(e) => {
                      handleChangerules(e.target.value);
                    }}
                  />
                </FloatingLabel>
                <div className="char mt-2">
                  {" "}
                  <span>{rulecount && rulecount}</span>/2000
                </div>
              </Form>
            </div>
          </div>
        </Scrollbars>
      </div>
    </div>
  );
};

export default CreateDescriptionContent;
