import React, { Component, useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { Modal, Button, Form } from 'react-bootstrap'




const FeeModal = (props) => {

    const [infoNightModal, setInfoNightModal] = useState(true);


    return (


        <Modal show={infoNightModal} centered scrollable className='modal_style'>
            <Modal.Header className='border-0 pb-0'>
                <button type="button" className="btn close close_modal" onClick={() => props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
            </Modal.Header>
            <Modal.Body>


                {/* <div className='mt-4'>

                </div> */}
                <h6>The helps us run our platform and offer services like 24/7 support on your trip.</h6>
                <div className='text-end'>

                </div>

            </Modal.Body>
        </Modal>

    )
}

export default FeeModal