import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { getExperienceFromLocal } from '../../../../Hooks/useUserExperienceHook';

const CreatePack = (props) => {

    const [count, setCount] = useState(1);
    const [length, setLength] = useState([])
    console.log('lengthiiiiii', length)
    console.log('countdatassssssss', count)
    const [packageList, setPackageList] = useState({
        items: [],
        nothing: false
    });
    console.log('packageList', packageList)
    const [current, setCurrent] = useState("");
    const [itemData, setItemData] = useState([]);
    console.log('itemdatasssss', itemData)
    const [datas, setDatas] = useState([1])
    const [disable, setDisable] = useState(false);
    const [currentCheck, setCurrentCheck] = useState(false);
    const [errors, setErrors] = useState({})
    console.log('datasssssssssssssssssssss', datas, errors,datas.length)
    const setData = (index, type, val) => {

        if (type == "remove") {
            itemData.splice(index, 1);
        } else if (type == "items") {
            console.log('e.target.values', val, index, type)
            if (index == 0) {
                console.log('itemdata>>>>>>>>>>>>>>>..', itemData)
                if (itemData?.length > 0) {
                    var arr = itemData.map((value, ind) => {
                        console.log('valuesgal', ind, index)
                        if (ind == index) {
                            return val;
                        }
                        else {
                            return value;
                        }
                    })
                    console.log('sfgukhikdflghkvdfuikgb', arr)
                    setItemData(arr);
                    setDisable(true)
                }
                else {
                    console.log('enter this one', val)
                    itemData[0] = val;
                    setCount(1)
                }
            }

            if (index > 0) {


                if (itemData?.length > 0) {
                    var arr = length.map((value, ind) => {
                        if (ind == index) {
                            return val;
                        }
                        else {
                            console.log('itemDatasssssssss')
                            return itemData[ind];
                        }
                    })
                }
                else {
                    itemData[2] = val;
                }
                console.log('111 sfgukhikdflghkvdfuikgb', arr)
                setItemData(arr)
            }

        } else if (type == "empty") {
            let arr = [];
            console.log('222 sfgukhikdflghkvdfuikgb', arr)
            itemData.push("")
            // setItemData([""])
        }
       Updatedcountdata()
        setValue();
        console.log("inside setData count : ", count, "itemData", itemData, "index:", index);
    }
 
    const Updatedcountdata = ()=>
    {
        if(count > 0)
        {
            console.log('enter this')
            var arr2 = [];
            console.log('enter this count11', count)
            for (var i = 0; i < count; i++) {
                console.log('enter this count')
                let length = arr2.length + 1;
                console.log('lengthdetails', length)
                arr2.push(length);
            }
            setLength(arr2)
        }
    }
    
   

    const setValue = () => {
        console.log("currentCheck:", currentCheck);
        if (!currentCheck) {
            packageList.items = itemData;
            packageList.nothing = currentCheck;
        } else {
            packageList.items = [];
            packageList.nothing = currentCheck;
        }
        setPackageList(packageList);
        console.log("packageList", packageList)
        props.onSetLocal(packageList, "packingList");
    }

    useEffect(() => {
        const localdata = getExperienceFromLocal();
        console.log('localdatas>>>>', localdata.packingList)
        if (localdata && localdata.packingList) {
            setPackageList(localdata.packingList);
            setItemData(localdata.packingList.items ? localdata.packingList.items : "")
            setCount(localdata.packingList.items.length ? localdata.packingList.items.length : 1)
        }
    }, []);


    const nextpage = async () => {
        try {
            let obj = {};
            let filterdata = await itemData.map((el, i) => {
                console.log("el_cond", el, (el != "" && el != undefined && el != ""));
                if (!((el != "" && el != undefined && el != ""))) {
                    obj[`${i}`] = "Please fill the item"
                }
            });
            console.log("filterdata", obj, filterdata)
            if (Object.keys(obj)?.length == 0) {
                props?.count()
            }
            setErrors(obj)
        } catch (e) {
            console.log("nextpage_err", e)
        }
    }


    return (
        <>
            <div>
                <h2 className='conthead'>Create a Packing List</h2>

                <p className='text-muted'>Let your guests know what to bring so they're prepared.
                </p>
                {/* <p className='subhead_dark mt-3'>Mad man cottage</p> */}
                <p className='subhead_light mt-3 mb-3'>What should your guests bring?</p>


                <div className='item_div'>
                    
                    {console.log("countssssssssssssssssssssssssssssssssss", count)}
                    {[...Array(count)].map((val, index) => {
                        // {console.log('datasssss',packageList[index].items)}
                        return <div><div className='h-55 mt-2'>
                            <div class="input-group mb-3 input_grp_height_55">
                                <input type="text" id="items" value={itemData && itemData[index]} onChange={(e) => { (e.target.value || e.target.value == "") ? setData(index, "items", e.target.value) : setDisable(false) }} class="form-control" placeholder="Item" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                <div class="input-group-append">
                                    <span class="input-group-text">
                                        <a href="javascript:void(0)" className='fa fa-trash text-muted text_dec_none' disabled={count == 1} onClick={() => { setCount(count - 1); setData(index, "remove") }}></a>
                                    </span>
                                </div>
                            </div>
                            <span className='text-danger'>{errors[`${index}`]}</span>
                        </div>

                        </div>
                    })}
                </div>


                <a href="javascript:void(0)" className="add_link" onClick={() => { setCount(count + 1); setData("nothing", "empty") }}>+ Add an item</a>


                <p className='subhead_dark mt-3'>Do guests not have to bring anything at all?</p>
                <p className='subhead_light mt-3'>If so, make sure you're providing everything they might need</p>

                <div className='mt-4'>

                    <div class="form-check my-3 align-items-start justify-content-end gap-2">
                        <label class="form-check-label f-14" for="flexCheckChecked">
                            My guests don't need to bring anything.
                        </label>
                        <input class="form-check-input" type="checkbox" value="Split-type ductless system" onChange={(e) => { setCurrentCheck(e.target.checked); setData() }} id="flexCheckChecked" />
                    </div>

                    {console.log('itemdatsgal[0]', itemData[0])}
                    <div className='mt-4'><button className='btn btn-theme' disabled={(itemData[0] || currentCheck) ? false : true} onClick={() => { nextpage();console.log("packageList", packageList); }}>Save & Continue</button></div>
                </div>

            </div>

        </>

    )
}

export default CreatePack