import React,{useState} from 'react';
import { Link } from 'react-router-dom';
import {Button, Accordion} from 'react-bootstrap'
import Footer from '../../../../Common/Footer';
import HostHeader from '../../HostHeader';
import GuestArea from './Modals/GuestArea';





const RoomsandSpace = () =>{
    const[guestarea , setGuestArea] = useState(false)
    const[spaces, setSpaces] = useState([])
    const[lists, setLists] = useState([])
    const getSpace = (data) =>{
        data.map((i)=>
        {
            if (spaces.indexOf(i) !== -1) {                
                return true; 
            }
            spaces.push(i);
        }); 
        setSpaces(spaces);
    }
    const createlist = () => {
      let a = React.createElement("div", {className : "accordion-item"}, "Welcome to GeeksforGeeks")
       setLists(a)
    }
    return(
        <>
            {guestarea && <GuestArea propspace = {getSpace} onDismiss={() => setGuestArea(false)}/>}
            <div className="roomsandspace">
                <HostHeader />
                <button onClick={() => createlist()}>jk</button>
                <div className='container container_theme pt-5'>
                    <div className='row jc-center  mh-100vh-header'>
                        <div className='col-md-10'>
                            <Link to="/hosting/managespace" className='f-14 text-dark nounder'><span className='fa fa-chevron-left me-2'></span> Back to editing <b> Just for Testing1</b></Link>
                            <div className='mt-4'>
                                <h4 className=''><b>Rooms and spaces</b></h4>
                                <p>Add or edit areas guests can use and mark any spaces they'll share</p>
                            </div>
                            <div className='editspace bordbox mt-4'>
                                <div className='d-flex jc-between'>
                                    <div>
                                        {spaces.map((index,val)=>{
                                            return <span key={index}>{index}</span>
                                        })}
                                    </div>
                                    <Button variant="link" className="themeclr nounder p-0" onClick={() => setGuestArea(true)}>Edit rooms and spaces</Button>
                                </div>

                            </div>
                            <div id="demo">
                            {lists}
                            </div>

                    <Accordion defaultActiveKey="0" className='spacelists'>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Accordion Item #1</Accordion.Header>
                            <Accordion.Body>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                            minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                            aliquip ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                            culpa qui officia deserunt mollit anim id est laborum.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Accordion Item #2</Accordion.Header>
                            <Accordion.Body>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                            minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                            aliquip ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                            culpa qui officia deserunt mollit anim id est laborum.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>Accordion Item #2</Accordion.Header>
                            <Accordion.Body>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                            minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                            aliquip ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                            culpa qui officia deserunt mollit anim id est laborum.
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default RoomsandSpace