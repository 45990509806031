import React, { useEffect, useState } from 'react';
import Footer from '../../../../Common/Footer';
import HostHeader from '../../HostHeader';
import prof from  '../../../../../Assets/images/prof.jpg'
import DataTable from 'react-data-table-component';
import CancelBook from './Modal/CancelBook'
import {Tabs, Tab} from 'react-bootstrap'

import { checkBookingStatus, getUserDatas, getUserEmail, getwithdrawhistoryhooks } from '../../../../../Hooks/useUserHook';
import { getUserBookings, getUserCancelledTrips, getUserTrips } from '../../../../../Hooks/useUserHostingHook';
import { API_URL } from '../../../../../config/env';
// import { getUserDatas } from '../../../../../Hooks/useUserHook';
// import { getUserBookings } from '../../../../../Hooks/useUserHostingHook';
import GiveReview from '../../../../Common/Modals/GiveReivew';
import { useNavigate } from 'react-router-dom';



const WithdrawHistory = () => {
   
  

    const navigate = useNavigate();
    const [withdraw , setWithdraw] = useState([]);



    useEffect(() => {
        async function fetchdata(){
            if(getUserEmail()){
                let data = {email : await getUserEmail()}
                console.log("result in withdraw history payload" , data);
                let result = await getwithdrawhistoryhooks(data)
                console.log("result in withdraw history" , result?.data?.record);
                setWithdraw(result?.data?.record?.reverse());
            }
        }
        fetchdata();
    })




    const data = [
        {
            id: "#101",
            date: "12/04/2022",
            email:"lorem@gmail.com",
            amount:"$200",
            status:"Completed",
         
        },
        {
            id: "#102",
            date: "12/04/2022",
            email:"lorem@gmail.com",
            amount:"$200",
            status:"Completed",
         
        },
    ]
    
    const columns = [
        {
            name:"Id",
            selector: row => row._id,
        },
        {
            name:"Date",
            selector: row => new Date(row.createdAt).toLocaleDateString(),
        },
        {
            name:"Email",
            selector: row => row.hostemail,
        },

        {
            name:"Amount",
            selector: row => row.requestamount,
        },
        {
            name:"Status",
            selector: row => row.paymentstatus,
        },
        {
            name:"Status",
            selector: row => row.status,
        }
      
      
       
     
    ]




    return(
        <div className='trips'>
            <HostHeader />
              <div className='container container_custom mh-100vh-header'>
                     <div className='py-3 px-0 my-4'>
                            <div className='d-sm-flex jc-between align-items-center'>
                                <h4 className='mb-sm-0 mb-3 themeclr'>Withdraw History</h4>
                              
                            </div>
                        </div>
      
                        <div className='disputetab shadowbox'>
                           <DataTable className='tripstay_tab1' pagination columns={columns} data={withdraw} />
                        </div>
                     
                      
              </div>
              <Footer/>
        </div>
    )
}

export default WithdrawHistory;