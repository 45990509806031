import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

const FinishTouch = (props) =>{
    return(
        <>
                <div>
                    <h2 className='conthead'>Finishing Touches</h2>
                    <p className='text-muted f-14'>Choose the category that best describe your experience. Add a second,
                        if you think it fits into another category.
                    </p>
                    <div className=' mt-4'>

                    
                    
                   <div className='mt-4'><button className='btn btn-theme'  onClick={() => props.count()}>Next</button></div> 
                    </div>
                    
              </div>  
        </>

    )
}

export default FinishTouch