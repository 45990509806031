import React, { useState, useRef } from 'react';
import {Modal, Button, Tooltip,Overlay} from 'react-bootstrap'
const Datepicker = React.lazy(() => import('../../../Common/Datepicker/Datepicker.js'));

const MonthlyDisc = (props) =>{
    const[monthlydisc, setMothlyDisc] = useState(true)
    const[val, setVal] = useState("")
    const [show, setShow] = useState(false);
    const target = useRef(null);
    

    return(
        <Modal show={monthlydisc} centered scrollable className='modal_style' size="lg">
        <Modal.Header className='border-0 pb-0'>
          <button type="button" className="btn close close_modal" onClick={() =>props.onDismiss()}><span aria-hidden="true">×</span></button>
      </Modal.Header>
          <Modal.Body>
          <div className='bg-white'>
                <div className='loca'>
                    <h4>Monthly Discount</h4>
                    <p className='f-14'>This discount will apply to reservations that are 28 days or longer.</p>
                    <div className='mt-4'>
                        <div className='spaninput'>
                            {/* add "err"  for show error */}
                            <input type="text" className='form-control' maxlength="2" onChange={(e) => setVal(e.target.value)} value={val} />
                            <div className=''>
                                <span>{val}</span>
                                <span className='ms-2'>%</span>
                            </div>
                        </div>
                        <Button variant="link" className='ps-0 f-14 text-dark' onClick={() =>setVal("49")}>
                            <b>Average discount in your area: 49%</b></Button>
                        <p><span  className='f-14'>Your average price with a {val == "" ? "0" : val}% discount is $4953 per week. </span>
                        <span className='fa fa-question-circle-o f-14'  ref={target}  onClick={() => setShow(!show)}></span>
                        <Overlay target={target.current} show={show} placement="right">
                                <Tooltip id="overlay-example">
                                    The average discount in your area is based on the type and location of your listing, nearby listings like yours, demand, and other factors. The average price per month at any given time is calculated using the mean of your nightly prices
                                    for the next 90 days and the discount entered above.
                                </Tooltip>
                        </Overlay>
                        </p>
                      
                    </div>
                    
                    
                </div>
          </div>
          </Modal.Body>
          <Modal.Footer className='d-flex justify-content-between'>
                   
                        <Button variant="Link" >Cancel</Button>
                        <Button className='btn-theme'>Save</Button>
                   
          </Modal.Footer>
      </Modal>
    )
}

export default MonthlyDisc