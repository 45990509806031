import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { getExperienceFromLocal } from '../../../../Hooks/useUserExperienceHook';

const TimePrepare = (props) =>{
    const [preparationTime, setPreparationTime] = useState({});
    const [preparationDays, setPreparationDays] = useState("");
    const [lastMinute, setLastMinute] = useState(false);
    const [cutoff, setCutoff] = useState("");
  
    const setData = (e) => {
        let data = {};
        data = preparationTime;
        if(e.target.id == "days"){
            data.preparationDays = e.target.value;
            setPreparationDays(e.target.value);
        }
        else if(e.target.id == "no"){
            data.lastMinute = false;
            setLastMinute(false);
        }
        else if(e.target.id == "yes"){
            data.lastMinute = true;
            setLastMinute(true);
        }
        else if(e.target.id == "cutoff"){
            data.cutoff = e.target.value;
            setCutoff(e.target.value);
        }
        setPreparationTime(data);
    }

    useEffect(()=>{
        const localdata = getExperienceFromLocal();
        if(localdata && localdata.preparationTime){
            setPreparationDays(localdata.preparationTime.preparationDays);
            setLastMinute(localdata.preparationTime.lastMinute);
            setCutoff(localdata.preparationTime.cutoff);
        }
    },[]);

    return(
        <>
  
                <div>
                    <h2 className='conthead'>How much time do you need to prepare?</h2>
                    <p className='text-muted'>You can adjust this time later depending on the dates you're scheduled to host. Each experience must be at least 1 hour.
                    </p>
                    <div className='h-55 mt-4'>
                        <select value={preparationDays} onChange={(e)=>{setData(e)}} id="days" className='form-control'>
                        <option value= ''>Choose number of days</option>
                        <option>1 day</option>
                        <option>2 days</option>
                        <option>3 days</option>
                        <option>4 days</option>
                        <option>5 days</option>
                        <option>6 days</option>
                        <option>7 days</option>
                        <option>1 week</option>
                        <option>2 weeks</option>
                        <option>3 weeks</option>
                        <option>4 weeks</option>
                    </select>
                   
                   <div className='mt-4'><button className='btn btn-theme'
                   disabled={(lastMinute)? !(preparationDays&&cutoff) : !preparationDays }
                   onClick={() => {props.count();props.onSetLocal(preparationTime,"preparationTime")}}>Save & Continue</button></div> 

                 


                    </div>
                    
                    <h2 className='conthead mt-3'>Can you accommodate last minute guests?</h2>
                    <p className='text-muted mb-4 mt-3'>We want you to get as many guests as possible. For an experience that already has one guest, you can set a cutoff time for when remaining guests can book.
                    </p>
                    <div className='flex_input_rad_div'>
                        <div className='d-flex align-items-center mb-3'>
                    <input type="radio" onChange={(e)=>{setData(e)}} id="no" name='accept' checked={!lastMinute}/>
                    <label for="no">No thanks</label>
</div>
<div className='d-flex align-items-center mb-0'><input type="radio" onChange={(e)=>{setData(e)}} id="yes" name='accept' checked={lastMinute}/>
                    <label for="yes">Yes, I’m flexible</label>
</div>

</div><br/>
                    <div className={lastMinute?"":"d-none"}>
                    <h2 className='conthead mt-4'>Cutoff time</h2>
                    <select value={cutoff} onChange={(e)=>{if(e?.target?.value == "Select Anyone"){setCutoff("")}setData(e)}} id="cutoff" className='form-control'>
                        <option >Select Anyone</option>
                        <option>1 hour</option>
                        <option>2 hours</option>
                        <option>3 hours</option>
                        <option>4 hours</option>
                        <option>8 hours</option>
                        <option>12 hours</option>
                        <option>24 hours</option>
                        <option>48 hours</option>
                    </select>
                    </div>
              </div>            
        
        </>

    )
}

export default TimePrepare