import React, {useState} from 'react';
import {Modal} from "react-bootstrap";

import serviceimg from "../../../Assets/images/service.png"

import { Scrollbars } from 'react-custom-scrollbars';
const Servicemodal = (props) =>{
    console.log('propdateeeeee',props)

    const [showservice, setShowservice] = useState(true);
   
   return(
    <div>
        
    <Modal show={showservice} centered scrollable className='modal_style'>
    <Modal.Header className='border-0 pb-0'>

    <button type="button" className="btn close close_modal" onClick={() =>props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
    </Modal.Header>
    <Modal.Body className='pb-4'>
<Scrollbars style={{ height: 150  }} className="modal_scroll">

        <div className='img_div mb-3'>
        <img src={serviceimg} className="img-fluid" />
        </div>
        <p className='service_title mb-2'>Service Animals</p>
        <p className='service_desc mb-0'>Service animals aren’t pets, so there’s no need to add them here.</p>

        <p className='service_desc'>Travelling with an emotional support animal? <a href="#" target="_blank">Check out our accessibility policy.</a></p>
   </Scrollbars>
    </Modal.Body>
    </Modal>
    </div>
   

    )
}

export default Servicemodal;