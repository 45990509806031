import React, {useState} from 'react';
import {useNavigate } from 'react-router-dom';
import UpdateCalendar from '../Modals/UpdateCalendar';
import MinStayModal from '../Modals/MinStayModal';
import MaxStayModal from '../Modals/MaxStayModal';
import AdNoticeModal from '../Modals/AdNoticeModal';

const ConfirmAvail = () =>{
    const history = useNavigate();
    const[customprice, setCustomPrize] =  useState(false)
    const[updatecal, setUpdatecal] = useState(false)
    const[minstay, setMinStay] = useState(false)
    const[maxstay, setMaxStay] = useState(false)
    const[adnotice, setAdNotice] = useState(false)
    


    return(
       <div className='container container_custom'>
        {updatecal && <UpdateCalendar onDismiss = {() => setUpdatecal(false)} />}
        
        {minstay && <MinStayModal onDismiss = {() => setMinStay(false)} />}
        {maxstay && <MaxStayModal onDismiss = {() => setMaxStay(false)} />}
        {adnotice && <AdNoticeModal onDismiss = {() => setAdNotice(false)} />}
        <button className='backbtn my-5' onClick={() => {history('/hosting', {state:"custom"}) }}><span className='fa fa-chevron-left me-2'></span> Back</button>
        <div className='row justify-content-center'>
            <div className='col-xl-5 col-md-8'>
                <h2>Confirm your availabity</h2>
                <p className='mb-5 text-muted f-16'>Use your calendar and availability settings to customise how and when you want to host. 
                You can always fine-tune these later.</p>
                    <ul className='ps-0 setrule'>
                       
                        <li>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <p className='mb-0'>When can you host?</p>
                                    <p className='text-muted f-14 mb-0'>Any day in the next 12 months</p>
                                    </div>
                                    <div className='d-flex'>
                                    <a href="#" className='text-dark' onClick={() => setUpdatecal(true)}>Edit</a>
                                </div>
                              
                            </div>
                            <hr />
                        </li>
                        <li>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <p className='mb-0'>What’s the shortest a guest can stay?</p>
                                    <p className='text-muted f-14 mb-0'>1 night</p>
                                    </div>
                                    <div className='d-flex'>
                                    <a href="#" className='text-dark' onClick={() => setMinStay(true)}>Edit</a>
                                </div>
                              
                            </div>
                            <hr />
                        </li>
                        <li>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <p className='mb-0'>What’s the longest a guest can stay?</p>
                                    <p className='text-muted f-14 mb-0'>365 nights</p>
                                    </div>
                                    <div className='d-flex'>
                                    <a href="#" className='text-dark' onClick={() => setMaxStay(true)}>Edit</a>
                                </div>
                              
                            </div>
                            <hr />
                        </li>
                        <li>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <p className='mb-0'>How much notice do you want?</p>
                                    <p className='text-muted f-14 mb-0'>Same day, until 11:59 pm</p>
                                    </div>
                                    <div className='d-flex'>
                                    <a href="#" className='text-dark' onClick={() => setAdNotice(true)}>Edit</a>
                                </div>
                              
                            </div>
                            <hr />
                        </li>
                    </ul>
            </div>
        </div>
       </div>
    )
}

export default ConfirmAvail