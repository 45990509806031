
import React, { Component, useState, useEffect, useRef } from 'react';
import {Modal , Button,Form} from 'react-bootstrap'
import Countdown from 'react-countdown';
import FinishSign from './FinishSign';

import OtpInput from 'react-otp-input';


import { checksignupotp, LoginUser, resendOTP, userSignUp} from '../../../Hooks/useUserHook';
import {toast, Toaster} from "react-hot-toast";
import { toastOptions } from '../../../config/env';

const ConfirmLog = (props) =>{
    const [showconfirmlog, setShowConfirmLog] = useState(true)

    const [showfinishsign , setShowFinishSign] = useState(false)
    const [otp1, setOtp1] = useState("");
    const [otp2, setOtp2] = useState("");
    const [otp3, setOtp3] = useState("");
    const [otp4, setOtp4] = useState("");
    // const [otp, setOtp] = useState([]);
    const [otp, setOtp] = useState('');

    const [otpErr, setOtpErr] = useState(false);
    const [enableBtn, setEnablebtn] = useState(false);
    const [counter, setCounter] = useState(300)
    const [Seconds, setseconds] = useState(0)
    const [Minutes, setminutes] = useState(0)
    const [retry, setreTries] = useState(1)


    const [verifybtn , setVerifybtn] = useState(false);
    const [timestamp , setTimestamp] = useState(Date.now());
    const [pherr , setPherr] = useState("");

  //   useEffect(() => {
  //     if( counter == 0){
  //         setEnablebtn(true);
  //     }
  //     const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
  //     return () => clearInterval(timer);
  // }, [counter]);  

//   useEffect(() => {
//     if (counter == 0) {
//         setEnablebtn(true);
//     }
//     const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
//     const seconds = String(counter % 60).padStart(2, 0);
//     setseconds(seconds)
//     const minutes = String(Math.floor(counter / 60)).padStart(2, 0);
//     setminutes(minutes)
//     return () =>
//         clearInterval(timer);
// }, [counter]);

useEffect(()=>{
    console.log("~~~~~~~ RETRY I SHA : ",retry);
},[retry])


      let tabChange = (val) => {
          let ele = document.querySelectorAll('input.otp');
          console.log(ele)
          if(ele[val-1].value != ''){
            ele[val].focus()
          }else if(ele[val-1].value == ''){
            ele[val-2].focus()
          }   
       }


       const onTextOtp = (text,index)=>{
        console.log("Index and data ",index,text)
        let data = otp; 
        data[index] = text ; 
        setOtp(data);
        console.log("otp array",otp,"length",otp.length);
    }


    const modals = () => {
      setShowConfirmLog(false);
      window.location.reload();
        document.getElementById("fade").classList.remove("d-none")
        props.onDismiss()
    }


    const checkOTPFunction = async () => {
      try {
        console.log("otp length:",otp.length);
        if(otp.length == 4 || otp.length == 6){
            // const otpFinal = otp.join("");
            // console.log("otpfinal:",otpFinal);
            const data = {
              email:props.email,
              otp: parseInt(otp),
              type:props.type,
              password : props?.password,
              otptype : props?.otptype
            }
  
           
            // LoginUser
            await checksignupotp(data).then((res)=>{
              console.log("data.status",res);
              if(res.status){
                console.log('shdakfdasdfajsdfgkj' , data)
                setShowConfirmLog(false);
                window.location.reload();
              }else if(res.status == false && res.message == "Invalid OTP!"){
                setOtpErr(true);
              }else if (res.status == false && res.message == "Signup"){
                setShowConfirmLog(false);
                setShowFinishSign(true);
              }else{
              //   toast.error(data.message,toastOptions)
                setShowConfirmLog(false);
                setShowFinishSign(true);
              }
            });
        }else{
          setOtpErr(true);
        }
      } catch (e) {
        console.log('checkOTPFunction_err', e)
      }
  
    }

    const resendOTPFunction = async () => {
      
      const data = {
        email : props.email,
        otptype : props?.otptype
      }
      await resendOTP(data).then((data)=>{
        console.log("data after resend otp:",data);
        toast.success(data.message,toastOptions);
        setEnablebtn(false);
        setVerifybtn(false);
        setreTries((retries)=> retries + 1)
      // setCounter(300);
      // let time = Date.now()
      // setTimestamp(time)
      // setTimeout(setTimestamp(time) , 300);

      });
    }

  const formatTime = (time) => {
    return String(time).padStart(2, '0')
  }

  const renderer = ({ hours, minutes, seconds, completed }) => {
    console.log("render", minutes, seconds, completed);
    if (completed) {
      // Render a completed state
      console.log("render", !enableBtn);
      if (!enableBtn) {
        setVerifybtn(true);
        setEnablebtn(true);
      }
      return <span>00:00</span>;
    } else {
      // Render a countdown
      return <span>{formatTime(minutes)}:{formatTime(seconds)}</span>;
    }
  };

    return (
        <>  
         {showfinishsign && <FinishSign email={props.email} type={props.type} onDismiss={() => setShowFinishSign(false)} />}
      
        <Modal show={showconfirmlog} centered scrollable className='modal_style'>
        {/* <button onClick={() =>  setShowFinishSign(true)}>finishmodal</button> */}

        <Modal.Header className='border-0 pb-0'>
      
            <button type="button" className="btn close close_modal" onClick={() =>modals()}>
              {/* <span aria-hidden="true" className='fa fa-chevron-left f-14'></span> */}
              <span aria-hidden="true">×</span>
            </button>
        
        </Modal.Header>
            <Modal.Body>
            <h5 className='modalhead text-center mb-4'>Confirm your Email id</h5>
                <div className='otp-log'>

                    {/* <p className='f-12'>Enter the code we've sent via SMS to <span>+91 6381294616</span>:</p> */}
                    <p className='f-12'>Enter the code we've sent 
                    {/* via Email to <span>{props.email}</span>: */}
                    </p>
                
         
            {/* <form action="" className="my-4 confirm">
              <input className="otp" type="text" onKeyUp={(e) => {tabChange(1);setOtp1(e.target.value);}} maxlength="1" />
              <input className="otp" type="text" onKeyUp={(e) => {tabChange(2);setOtp2(e.target.value);}} maxlength="1" />
              <input className="otp" type="text" onKeyUp={(e) => {tabChange(3);setOtp3(e.target.value);}} maxlength="1" />
              <input className="otp" type="text" onKeyUp={(e) => {setOtp4(e.target.value)}} maxlength="1" />
            </form> */}
             <form action="" className="my-4 confirm">
              {/* <input className="otp" type="text" onKeyUp={(e) => {onTextOtp(e.target.value,0);if (e.target.value.length === 1){tabChange(1);}}} maxlength="1" />
              <input className="otp" type="text" onKeyUp={(e) => {onTextOtp(e.target.value,1);if (e.target.value.length === 1){tabChange(2);}}} maxlength="1" />
              <input className="otp" type="text" onKeyUp={(e) => {onTextOtp(e.target.value,2);if (e.target.value.length === 1){tabChange(3);}}} maxlength="1" />
              <input className="otp" type="text" onKeyUp={(e) => {onTextOtp(e.target.value,3)}} maxlength="1" /> */}
           <div className='otp_input_box'>
           <OtpInput
      value={otp}
      onChange={setOtp}
      numInputs={props?.otptype == "email" ? 4 : 6} 
      renderSeparator={<span>-</span>}
      renderInput={(props) => <input {...props} />}
    />
           </div>
         
            </form>
            {(otpErr)?
              <p className='text-danger'>
                Invalid OTP!
              </p>:<></>}
            <hr class="mt-4" />
            <button className='btn btn-theme btn-block mt-4 mb-4 customBtn me-2' 
            // disabled = {verifybtn} 
            onClick={checkOTPFunction}>Verify</button>
            <button className='btn btn-theme btn-block mt-4 mb-4 customBtn' disabled={!enableBtn} onClick={resendOTPFunction}>Resend OTP</button>
            <p>You can click resend in <span className='text-danger'>
              {/* {counter} */}
        <Countdown  key={retry} date={(Date.now() + 300000)} 
              renderer={renderer}
              />
              </span> seconds</p>
                    {/* <p className='f-12'>Haven't received a code? <a href="#">More options</a></p> */}
                </div>
            </Modal.Body>
        </Modal>
        <Toaster/>
                </>
    )
}


export default ConfirmLog