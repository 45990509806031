import React from 'react';
import {Accordion,Button,Dropdown} from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import Footer from '../../../../Common/Footer';
import HostHeader from '../../HostHeader';
import img17 from '../../../../../Assets/images/where/img17.png'


const Photos = () =>{
    const navigate = useNavigate();
    const navigatehosthome = () =>{
        navigate('/hosting/managespace')
    }

    return(
        <div className='managespace'>
        <HostHeader />
        <div className='container container_theme mh-100vh-header'>
            <div className='row mt-5'>
                <div className='col-md-3 stikyavail'>
                <Accordion defaultActiveKey="0" className='mngspace'>
                    <Accordion.Item eventKey="0">
                        <div className='gobackbtn '>
                            <Button variant='link' onClick={() => navigatehosthome()} className='nounder w-full py-3 ps-0 text-start text-dark'>
                                <span className='fa fa-chevron-left me-2'></span>Listing Details
                            </Button >
                        </div>
                        <Accordion.Body className='pt-0'>
                            <ul>
                                <li><a className='text-dark nounder active' href="#photos">Cover photo</a></li>
                                <li><a className='text-dark nounder' href="#allphotots">All photos</a></li>
                            </ul>
                        </Accordion.Body>
                    </Accordion.Item>
                   
                    </Accordion>
                </div>
                <div className='col-md-8 '>
                    
                    <div >
                        <div className='d-flex jc-between' id="photos">
                            <div>
                                <h6>Cover photo</h6>
                                <p className='text-muted f-14'>Your cover photo is a guest’s first impression of your listing.</p>
                                <button className='btn btn-theme mt-4'>Change photo</button>
                            </div>
                            <img src={img17} />
                        </div>
                        <hr className='themehr' />
                        <div className='d-flex jc-between' id="allphotots">
                            <div>
                                <h6>All photos</h6>
                                <p className='text-muted f-14'>Drag and drop your photos to change the order.</p>
                            </div>
                            <button className='btn btn-theme mt-4'>Upload photo</button>
                        </div>
                        <div className='imggrid images dragphotos '>
                          <div className='row mt-4 mb-5 phot_se'>
                            <div className='col-lg-4 col-md-6'>
                                <div className=' position-relative'>
                                    <Button className='fullbutton'>
                                    <img src={img17} />
                                    <div className='d-flex justify-content-between position-absolute imagedrop px-3 pt-3'>
                                        <div>
                                            <Dropdown className='imageaction'>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                <span className='fa fa-ellipsis-h '></span>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#/action-1">Edit</Dropdown.Item>
                                                <Dropdown.Item href="#/action-2">Move Backwards</Dropdown.Item>
                                                <Dropdown.Item href="#/action-3">Move Forwards</Dropdown.Item>
                                                <Dropdown.Item href="#/action-4">Move Cover phots</Dropdown.Item>
                                                <Dropdown.Item href="#/action-5">Delete</Dropdown.Item>
                                            </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    </Button>
                                </div>
                            </div>
                            <div className='col-lg -4 col-md-6'>
                                <div className=' position-relative'>
                                    <Button className='fullbutton'>
                                    <img src={img17} />
                                    <div className='d-flex justify-content-between position-absolute imagedrop px-3 pt-3'>
                                        <div>
                                            <Dropdown className='imageaction'>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                <span className='fa fa-ellipsis-h '></span>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#/action-1">Edit</Dropdown.Item>
                                                <Dropdown.Item href="#/action-2">Move Backwards</Dropdown.Item>
                                                <Dropdown.Item href="#/action-3">Move Forwards</Dropdown.Item>
                                                <Dropdown.Item href="#/action-4">Move Cover phots</Dropdown.Item>
                                                <Dropdown.Item href="#/action-5">Delete</Dropdown.Item>
                                            </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    </Button>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6'>
                                    <Button className='fullbutton'>
                                        

                                        <div className='uploadimage d-flex dashedbord align-items-center justify-content-center'>
                                            <span className='fa fa-picture-o'></span>
                                        </div>
                                    </Button>
                            </div>
                          </div>
                        </div>

                    </div>
                   
                 
                </div>
                
            </div>
        </div>
    </div>
    )
}

export default Photos