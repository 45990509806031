import React,{useState} from 'react';
import {Modal, Button,Form,InputGroup} from 'react-bootstrap'
import img1 from '../../../../Assets/images/where/img1.png'
import img14 from '../../../../Assets/images/where/img14.png'




  const FilterByListing = (props) =>{  
    const[selecteds , setSelecteds] = useState(0) 
    const[filterlist, setFilterList] = useState(true)

        const selectedlist = (e) =>{
            if(e.target.checked == true){
                setSelecteds(selecteds + 1)
            } else{
                setSelecteds(selecteds - 1)
            }
        }

        const uncheck = () =>{
            setSelecteds(0);
            document.querySelectorAll(".selectlist input").forEach(function(e)
            {
                e.checked = false;
            }); 
        }

        const gofirst = () =>{
            props.onDismiss()
           document.getElementById("filtermsg").style.opacity = "1"

        }
      
props.func(selecteds)
  

    return(
        <Modal show={filterlist} centered scrollable className='modal_style'>
        <Modal.Header className='border-0 pb-0'>
            {/* <button type="button" className="btn close close_modal" onClick={() =>props.onDismiss()}><span aria-hidden="true" className='fa fa-chevron-left'></span><span class="sr-only">Close</span></button> */}
            <button type="button" className="btn close close_modal" onClick={() =>gofirst()}><span aria-hidden="true" className='fa fa-chevron-left f-14'></span><span class="sr-only">Close</span></button>

        </Modal.Header>
            <Modal.Body>
                <h4 className='mb-4'>Filter by listing</h4>
                <p>{selecteds} Selected</p>
                
                        <InputGroup className=" searchbtn my-4">
                                <InputGroup.Text id="basic-addon1"><span className="fa fa-search"></span></InputGroup.Text>
                                <Form.Control
                                placeholder="Username"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                />
                            </InputGroup>
          
   <div className='selectlist my-5'>
        <div class="form-check">
            <label class="form-check-label d-flex gap-2" for="flexCheckDefault">
            <img src={img1} />
            <div>
                <p>Just for testing</p>
                <p className='text-muted f-14'>Just for testing</p>
            </div>
            </label>
            <input onChange={(e) => selectedlist(e)} class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
        </div>
        <div class="form-check">
            <label class="form-check-label d-flex gap-2" for="flexCheckDefault">
            <img src={img14} />
            <div>
                <p>Just for testing</p>
                <p className='text-muted f-14'>Just for testing</p>
            </div>
        </label>
        <input onChange={(e) => selectedlist(e)} class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
        </div>
</div>
                 
           
           
          </Modal.Body>
          <Modal.Footer className='d-flex jc-between'>
              <Button variant='link' className='text-dark' onClick={() => uncheck()}>Clear</Button>
              <button className='btn btn-theme' onClick={() => gofirst()}>Select</button>
           </Modal.Footer>
      </Modal>
    )
}


export default FilterByListing