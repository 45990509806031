
import React, {useEffect, useState} from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {Button,Form,InputGroup} from 'react-bootstrap'
import AvailabilityModal from './AvailabilityModal';

import PriceDetails from "../Common/PriceDetails";
import Cancellation from "../Common/Modals/CancellationModal";
import card from '../../Assets/images/visacard.png'
import img1 from '../../Assets/images/where/img13.png'
import prof from '../../Assets/images/prof.jpg'
import Confirmandpaymodal from "../Common/Modals/confirmandpaymodal";

import PaymentIntegrationModal from "../Common/Modals/PaymentIntegrationModal";

import { getRoomUsingId, getBookingDetails, getCancellationtypehooks, getSplitBookingDetails } from "../../Hooks/useUserHostingHook"; 
import { getUserDatas } from "../../Hooks/useUserHook";



const Header = React.lazy(() => import('../Common/Header'));
const Footer = React.lazy(() => import('../Common/Footer'));
const ConfirmandPay = (props) =>{
    console.log('propsdatasgal', props)
const[avail, setAvail] = useState(false)
const[pay, setPay] = useState(false)
const[msg , setMsg] = useState(false)
const[bookingDetails, setBookingDetails] = useState({});
const[roomDetails, setRoomDetails] = useState({});
const[paymentGateway, setPaymentGateway] = useState(false);
const [user , setUser] = useState({});
const [splitbookingdetails, setSplitBookingDetails] = useState({})

const navigate = useNavigate();
let location = useLocation()

const checkMsg = () =>{
    document.getElementById("message").value == "" ?  setMsg(true) :  setMsg(false)  
    console.log(document.getElementById("message").innerText)
}
const[cancel, setCancel] =useState(false)
const [canceltype , setCanceltype] = useState({});
const [locdata, setLocData] = useState("")


    const getHotelDetails = (id) => {
        if(id){
        getRoomUsingId(id).then((data)=>{
            console.log("hotel datas from id:",data);
            setRoomDetails(data);
            getCancellationtypehooks(data?.cancellationPolicy).then((res) => {
                var cont = res?.record?.content;
                var cancel = cont.find(e => e?.type == data?.cancellationPolicy);
                console.log("Cancellation type data" , res , res?.record?.content , cancel);
                setCanceltype(cancel);
            })
        })
        }
    }

    const getbookingdetails = (id) => {
        getBookingDetails(id).then((data)=>{
            if(data.isVerified == true){
            setBookingDetails(data);
            getHotelDetails(data.roomId);
            }else{
                window.location.href = window.location.origin+"/pending-orders"
            }
        })
    }

    const getsplitbookingdetails = (id) => {
        getSplitBookingDetails(id).then((data) => {
            console.log('getsplitbookingdetails', data[0]);
            if(data[0]?.isVerified == true) {
                setSplitBookingDetails(data[0])
                getHotelDetails(data[0].roomId);
            } else {
                // window.location.href = window.location.origin+"/pending-orders"
            }
        })
    }

    useEffect(() => {
        let bookingid = window.location.pathname.split("/")[2];
        let locdata = location?.state;
        console.log('locationdata', location, locdata)
        if (locdata == "splitreservebooking") {
            setLocData(locdata)
            if (bookingid) {
                getsplitbookingdetails(bookingid);
                const user = getUserDatas();
                setUser(user);
            }
        } else if (locdata == "reservebooking") {
            if (bookingid) {
                setLocData(locdata)
                getbookingdetails(bookingid);
                const user = getUserDatas();
                setUser(user);
            }
        }
    }, [location]);

    useEffect(() => {
        let checkpay = false;
        let error = true
        if (splitbookingdetails?.splitPaymentDetails?.length > 0) {
            let splitdata = splitbookingdetails?.splitPaymentDetails;
            splitdata && splitdata?.length > 0 && splitdata?.map((val, ind) => {
                console.log('splitvalsgal', val)
                if (ind == 0) {
                    // Before check startDate
                    // if ((new Date(val["startDate"]) <= new Date() && new Date(val["endDate"]) >= new Date()) && val?.status == 'Pending') {
                    //     checkpay = true
                    // } else if(val['status'] != 'Completed'){
                    //     error = "Booking date will be expired" // didn't use the error
                    // }
                } 
                console.log('fasdfasdfasdfasdf', new Date(val["startDate"]) >= new Date() && val?.status == 'Pending')
                // if(ind){
                //     // !error &&
                //     if ( !checkpay && (new Date() <= new Date(splitdata[ind]['endDate']) && new Date() >= new Date(splitdata[ind + 1]['startDate']))  && splitdata[ind +1]['status'] == 'Pending') {
                //         checkpay = true
                //     } 
                // }

            });
            console.log('checkpaystatus', checkpay)
        }
    }, [splitbookingdetails])


    return(
        <div id="contacthost">
            {cancel && <Cancellation onDismiss={() => setCancel(false)} />}
            {pay && <Confirmandpaymodal bookingDetails={bookingDetails} roomDetails={roomDetails} onDismiss={() => setPay(false)}  /> }

            {paymentGateway && <PaymentIntegrationModal stripe = {props?.stripe} splitbookingdetails={splitbookingdetails} filter={locdata} bookingDetails={bookingDetails} roomDetails={roomDetails} onDismiss={() => setPaymentGateway(false)}/>}

            <Header />
            <div className="mh-100vh">
            <div className="mobileresevebtn d-flex jc-between align-items-center">
                <p>Still have a question?</p>
                <button class="btn btn-theme" onClick={() => setPay(true)}>Review</button>
                </div>

                <div className='container container_custom pagetop'>
                    <div className="goback d-flex align-items-center gap-3 mb-5">
                        <Link to={`/host-dashboard/trips/stay/${user?.email}`}><span className="text-dark fa fa-chevron-left"></span></Link>
                        <h3 className="mb-0"><b>Confirm and pay
                            {/* Request to book */}
                            
                            </b></h3>
                    </div>
               
                    <div className="row mb-5">
                        <div className='col-12 col-lg-8 leftcont'>
                            <div>
                                {/* <div className="bordbox p-3 br-10">
                                    <div className="d-flex">
                                        <div className="">
                                            <p><b>Special offer: $476 off this home.</b></p>
                                            <p className="text-muted">This host is offering a one-off deal to the first 3 guests who book a trip
                                            between now and 13 Oct.</p>
                                        </div>
                                    </div>
                                </div> */}
                               { locdata == "reservebooking" && <div className="yourtrip mt-0">
                                        <h4 className="mb-4">Your trip</h4>
                                        <div className="d-flex jc-between mt-3">
                                            <div >
                                                <p><b>Dates</b></p>

                                                
                                            </div>
                                            <p className="text-muted">{new Date(bookingDetails.checkInDate).toLocaleDateString()}&nbsp;<span>-</span>&nbsp;{new Date(bookingDetails.checkOutDate).toLocaleDateString()}</p>

                                            {/* <Button variant="link" className="text-dark">Edit</Button> */}

                                        </div>
                                        <div className="d-flex jc-between mt-3">
                                            <div>
                                                <p><b>Guests</b></p>

                                                {/* <p className="text-muted">{bookingDetails.guestCount && bookingDetails.guestCount.Children} Children</p>
                                                <p className="text-muted">{bookingDetails.guestCount && bookingDetails.guestCount.Infants} Infants</p>
                                                <p className="text-muted">{bookingDetails.guestCount && bookingDetails.guestCount.Pets} Pets</p> */}
                                            </div>
                                            <p className="text-muted">{bookingDetails.guestCount && bookingDetails.guestCount.Adults + bookingDetails.guestCount.Children} Guests</p>
                                            
                                            {/* <Button variant="link" className="text-dark">Edit</Button> */}

                                        </div>
                                    </div>}

                                   { locdata == "splitreservebooking" && 
                                   <>
                                   {console.log('bookingDasdfasdetails', splitbookingdetails, splitbookingdetails?.splitPaymentDetails, splitbookingdetails?.splitPaymentDetails?.length)}
                                   <div className="yourtrip mt-0">
                                        <h4 className="mb-4">Your trip</h4>
                                        {splitbookingdetails && splitbookingdetails?.splitPaymentDetails?.length > 0 && splitbookingdetails?.splitPaymentDetails?.map((val, ind) =><div className="d-flex jc-between mt-3">
                                             <div >
                                                {console.log('valvalvalvalval', val)}
                                                <p><b>Dates</b></p>

                                                
                                            </div>
                                            <p className="text-muted">{new Date(val?.startDate).toLocaleDateString()}&nbsp;<span>-</span>&nbsp;{new Date(val?.endDate).toLocaleDateString()}</p>

                                            {/* <Button variant="link" className="text-dark">Edit</Button> */}

                                        </div>)}
                                        <div className="d-flex jc-between mt-3">
                                            <div>
                                                <p><b>Guests</b></p>
                                            </div>
                                            <p className="text-muted">{splitbookingdetails.guestCount && splitbookingdetails.guestCount.Adults + splitbookingdetails.guestCount.Children} Guests</p>
                                        </div>
                                    </div>
                                    

                                   </>}
                                    <hr className="themehr" />
                                    <div className="h-55">
                                        <div className="d-flex">

                                            <h4 className="my-4" >Pay with</h4>
                                        </div> 
                                 
                                        <InputGroup className="mb-3 payment">
                                        <InputGroup.Text id="basic-addon1"><img src={card}/></InputGroup.Text>
                                    
                                        <Button onClick={()=>{setPaymentGateway(true)}}>select payment method</Button>
                                        </InputGroup>

                                     
                                        {/* <Button variant="link" className="text-dark px-0"><b>Enter a Coupon</b></Button> */}
                                    </div>
                                    {/* <hr className="themehr" />
                                    <div>
                                        <h4>Required for your trip</h4>
                                        <h6 className="mt-3 mb-0">Message the host</h6>
                                        <p className="text-muted mb-3">Let the host know why you're travelling and when you'll check in.</p>
                                    </div>
                                    <div className="d-flex align-items-center gap-2 mb-4">
                                    <div className="flex-shrink-0">
                                <img src={prof} width="56" height="56" alt="Sample Image" className='prof_img_round'/>
                            </div>
                            <div className="flex-grow-1 me-3">
                                <p className=''><b>Contact Dorothy</b></p>
                                <p className="f-14 text-muted mb-0">Joined 2022</p>
                            </div>
                          
                        </div>
                        <textarea className="w-full br-10 mt-1 p-3" rows="5" id="message"></textarea> */}
                        <hr className="themehr" />
                        <p>Cancellation before {canceltype?.days} days {canceltype?.percentage}% amount will be refund</p>

                                    {/* <div className="cancelation">
                                        <h4>Cancellation policy</h4>
                                        <p><b>Free cancellation before {new Date((new Date(bookingDetails.checkInDate).getTime())- 2*24*60*60*1000).toLocaleDateString()}.</b> <span className="text-muted"> Cancel before check-in on {new Date((new Date(bookingDetails.checkInDate).getTime())- 1*24*60*60*1000).toLocaleDateString()} for a partial refund</span>. 
                                            <Button variant="link" className="orsnge_y ps-0" onClick={() => setCancel(true)}><b>Learn more</b></Button></p>
                                    </div>
                                    <hr className="themehr" />

                                    <p className="f-12">By selecting the button below, I agree to the 
                                       <a href="#" className="text-dark ps-1"> Host's House Rules</a>, 
                                       <a href="#" className="text-dark ps-1"> FlutterPads's Rebooking and Refund Policy </a>
                                        and that FlutterPads can
                                        <a href="#" className="text-dark ps-1">charge my payment method</a> if I’m responsible for damage.</p> */}
                            {/* <button className="btn btn-theme mt-4">Confirm and pay</button> */}
                            </div>
                        </div>
                        <div className='col-12 col-lg-4 d-lg-block d-none mt-lg-0 mt-4 '>

                           <PriceDetails bookingDetails={bookingDetails} splitbookingdetails={splitbookingdetails} roomDetails={roomDetails} filter={locdata} img={img1}/>

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ConfirmandPay 