import axios from "axios";
import { getChatRoomMessagesApi, getChatUserApi } from "../config/chatRouteFront"; 
import { API_URL } from "../config/env";

export const getChatUsers = async (senderId) => {
    let dataToReturn = []
    await axios.get(API_URL+getChatUserApi+"/"+senderId).then((data)=>{
        dataToReturn = data.data;
    })
    return dataToReturn;
}

export const getChatRoomMessages = async (data) => {
    console.log("getChatRoomMessages Limited Data : ", data);
    let dataToReturn;
    await axios.post(API_URL+getChatRoomMessagesApi,data).then((data)=>{
        dataToReturn = data.data
    });
    return dataToReturn;
}