import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { getExperienceFromLocal } from '../../../../Hooks/useUserExperienceHook';

const MentionDo = (props) =>{
    const [whatYouWillDo, setWhatYouWillDo] = useState("");
    console.log('whatyouwilldo',whatYouWillDo)
    const [localData, setLocalData] = useState({});

    const putData = (e) =>{
        props.expdo(e.target.value)
    }

    useEffect(()=>{
        const localdata = getExperienceFromLocal();
        console.log('localdata.whatYouWillDo',localdata.whatYouWillDo)
        setLocalData(localdata)
        setWhatYouWillDo(localdata.whatYouWillDo)
        props.expdo(localdata.whatYouWillDo)
    },[]);

    return(
        <>
                <div>
                    <h2 className='conthead'>Mention what you will do</h2>
                    <p className='text-muted'>Choose the category that best describe your experience. Add a second,
                        if you think it fits into another category.
                    </p>
                    <div className=' mt-4'>

                     <div className='h-55'> 
                     <input type="text" className='form-control mb-3' 
                     value={whatYouWillDo}
                     placeholder={"Enter what you will do"}
                     onChange={(e) => {putData(e);setWhatYouWillDo(e.target.value)}}/>
                    
                 
                 </div> 
               
                   <div className='mt-4'><button className='btn btn-theme' disabled={!whatYouWillDo} onClick={() => {props.count();props.onSetLocal(whatYouWillDo,"whatYouWillDo")}}>Save & Continue</button></div> 
                    </div>
                    
              </div>  
        </>

    )
}

export default MentionDo