import React, { useEffect, useState } from "react";
import Header from "./Common/Header";
import Footer from "./Common/Footer";
import { Breadcrumb, InputGroup, Button, Form } from "react-bootstrap";

import { Link, useNavigate, useLocation } from "react-router-dom";
import { getUserDatas } from "../Hooks/useUserHook";
import userIcon from "../Assets/images/account-user-cion.png";
import { RxSlash } from "react-icons/rx";
import { FaChevronLeft } from "react-icons/fa6";

const Subscribe = () => {
  const [userDatas, setUserDatas] = useState({});
  const [isHost, setIshost] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    setUserDatas(getUserDatas());
  }, []);

  return (
    <div id="hostaccount" className="nocenterhead account-details">
      <Header />
      <div className="container container_custom mt-100 ">
        <div className="row">
          <div className="col-xl-12">
            {/* <h3 className=" mt-lg-5 account"> Account</h3> */}
            {/* <p className="account-name">
              <b>
                {userDatas && userDatas.firstName}&nbsp;
                {userDatas && userDatas.lastName}
              </b>
              , <span className="email">{userDatas && userDatas.email}</span> ·{" "}
              <b>
                <a
                  href={window.location.origin + "/account/profile"}
                  className="text-dark"
                >
                  Go to profile
                </a>
              </b>
            </p> */}
            {/* <div className="mt-lg-5 mt-4 d-flex justify-content-center align-items-center">
              <div className="acc-box d-flex flex-column p-4 align-items-center justify-content-center">
                <img
                  src={userIcon}
                  alt="user-icon"
                  className="user-icon-img "
                />
                <h6 className="pt-2 font_pers">Personal info</h6>
                <p className="pt-2 text-center">
                  Provide personal details and how we can reach you
                </p>
                <button
                  className="mt-3 mt-xl-5 mt-lg-5"
                  onClick={() => {
                    navigate("/account/personal-info", { state: userDatas });
                  }}
                >
                  Edit
                </button>
                <div className="mt-3">
                  <Link to="/account/addbank" className="text-dark">
                    Add Bank Details
                  </Link>
                </div>
              </div>
            </div> */}
            <div className="breadcrum mt-4">
              {/* <Breadcrumb className="custom_breadcrumb_ovr_rt">
                <Breadcrumb.Item>
                  <Link to="/account/personal-info">Personal Info</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to="/account/addbank">Account Details</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item className="custom_subs_link">
                  <Link to="/account/subscribe" >
                    Subscribe
                  </Link>
                </Breadcrumb.Item>
              </Breadcrumb> */}
              <Breadcrumb className="custom_breadcrumb_ovr_rt">
                {/* <Breadcrumb.Item> */}
                <Link to="/account/personal-info" className="cus_link">
                  Personal Info
                </Link>
                <FaChevronLeft className="breadcrumb_custom_slash" />

                {/* </Breadcrumb.Item> */}
                {/* <Breadcrumb.Item > */}
                <Link to="/account/addbank" className="cus_link">
                  Account Details
                </Link>
                <FaChevronLeft className="breadcrumb_custom_slash" />

                {/* </Breadcrumb.Item> */}
                {/* <Breadcrumb.Item> */}
                <Link
                  to="/account/subscribe"
                  className="cus_link custom_active_link"
                >
                  Subscribe
                </Link>
                {/* </Breadcrumb.Item> */}
              </Breadcrumb>
              <h2 className="text-center">Subscribe</h2>
            </div>
            <div className="row cus_height_vh">
              <div className="my-4 col-xl-12">
                <div className="text-center">
                  <p className="need-to-deactive mb-2">Need to subscribe?</p>
                  <div
                    className={
                      parseFloat(userDatas?.guestsubscribedaycount) <= 0 ||
                      parseFloat(userDatas?.hostsubscribedaycount) <= 0
                        ? "subscribe_block"
                        : "subscribe_block"
                    }
                  >
                    {parseFloat(userDatas?.hostsubscribedaycount) <= 0 ? (
                      <p className="take-care-of mb-3 mt-3">
                        <Link
                          to={"/hostsubscribe"}
                          className="btn btn_theme_black"
                        >
                          Subscribe for Host
                        </Link>
                      </p>
                    ) : (
                      <p className="take-care-of mb-3 mt-3">
                        Host subscribe expired in{" "}
                        {userDatas?.hostsubscribedaycount} days
                      </p>
                    )}
                    {parseFloat(userDatas?.guestsubscribedaycount) <= 0 ||
                      (parseFloat(userDatas?.hostsubscribedaycount) <= 0 && (
                        <p className="take-care-of mb-3 mb-sm-0 mt-3">or</p>
                      ))}
                    {parseFloat(userDatas?.guestsubscribedaycount) <= 0 ? (
                      <p className="take-care-of">
                        <Link
                          to={"/guestsubscribe"}
                          className="btn btn_theme_black"
                        >
                          Subscribe for Guest
                        </Link>
                      </p>
                    ) : (
                      <p className="take-care-of mb-3 mt-3">
                        Guest subscribe expired in{" "}
                        {userDatas?.guestsubscribedaycount} days
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Subscribe;
