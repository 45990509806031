import React, { useState } from 'react';
import {Modal, Button} from 'react-bootstrap'
const Datepicker = React.lazy(() => import('../../../Common/Datepicker/Datepicker.js'));

const MaxstayModal = (props) =>{
    const[minstay, setMinStay] = useState(true)
    const[val, setVal] = useState("365")
    const setCount = () =>{ 
        if(val.length > 23){
            setVal("")
        }
    }

    return(
        <Modal show={minstay} centered scrollable className='modal_style' size="lg">
        <Modal.Header className='border-0 pb-0'>
          <button type="button" className="btn close close_modal" onClick={() =>props.onDismiss()}><span aria-hidden="true">×</span></button>
      </Modal.Header>
          <Modal.Body>
          <div className='bg-white'>
                <div className='loca'>
                    <h4>Maximum Stay</h4>
                    <div className='mt-5'>
                        <div className='spaninput'>
                            {/* add "err"  for show error */}
                            <input type="text" className='form-control err'  onChange={(e) => {
                                setVal(e.target.value)
                                setCount()
                                }} value={val} />
                            <div className=''>
                                <span>{val}</span>
                                <span className='ms-3'>Nights</span>
                            </div>
                        </div>
                        <span className='f-12'>Guests can instantly book stays of between 5868 and 365 nights. Requests for longer stays are not allowed.</span>
                       <div className='err'>
                            <span className='f-12 text-danger'><span className='fa fa-info'></span> Maximum stay has to be greater than or equal to minimum stay.</span>
                        </div>
                    </div>
                    
                    
                </div>
          </div>
          </Modal.Body>
          <Modal.Footer className='d-flex justify-content-between'>
                   
                        <Button variant="Link" >Cancel</Button>
                        <Button className='btn-theme'>Save</Button>
                   
          </Modal.Footer>
      </Modal>
    )
}

export default MaxstayModal