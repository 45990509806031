import React, { useEffect, useState } from "react";
import Header from "../../Common/Header.js";
import Footer from "../../Common/Footer.js";
import img1 from "../../../Assets/images/where/img1.png";
import img11 from "../../../Assets/images/where/img11.png";
import img13 from "../../../Assets/images/where/img13.png";
import HostinCity from "./Modals/HostinCity.js";
import HostExp from "./Modals/HostExpModal.js";
import ReviewHost from "./Modals/ReviewHosting.js";
import ReviewExp from "./Modals/ReviewExp.js";
import CreateExp from "./Modals/CreateExp.js";
import ConfirmDelete from "./Modals/ConfirmDelete.js";
import {
  getExperienceFromLocal,
  getUserExperienceHostings,
} from "../../../Hooks/useUserExperienceHook.js";
import { getUserDatas } from "../../../Hooks/useUserHook.js";
import { useNavigate } from "react-router-dom";

const ExperianceLand = () => {
  const navigate = useNavigate();
  const [hostcity, setHostCity] = useState(false);
  const [hostexp, setHostExp] = useState(false);
  const [reviewhost, setReviewHost] = useState(false);
  const [reviewexp, setReviewExp] = useState(false);
  const [createexp, setCreateExp] = useState(false);
  const [userDatas, setUserDatas] = useState({});
  const [experienceDatas, setExperienceDatas] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [reload, setReload] = useState(false);

  const HostExps = () => {
    setHostExp(true);
    setHostCity(false);
  };
  const openhost = () => {
    setHostExp(false);
    setReviewHost(true);
  };
  const openreviewexp = () => {
    setReviewHost(false);
    setReviewExp(true);
  };
  const opencreateexp = () => {
    setReviewExp(false);
    setCreateExp(true);
  };

  const createHostExperience = () => {
    window.location.href =
      window.location.origin + `/manage-experience/${userDatas.email}`;
  };

  useEffect(() => {
    const userdata = getUserDatas();
    setUserDatas(userdata);
    const localdata = getExperienceFromLocal();
    getUserExperienceHostings(userdata.email).then((data) => {
      let dataFinal = data;
      if (localdata && localdata.title && localdata.country) {
        dataFinal.push({ title: localdata.title, country: localdata.country });
      }
      setExperienceDatas(dataFinal);
    });
  }, [reload]);

  return (
    <div className="hostexp">
      {hostcity && (
        <HostinCity
          onDismiss={() => setHostCity(false)}
          opennext={() => HostExps()}
        />
      )}
      {hostexp && (
        <HostExp onDismiss={() => setHostExp(false)} step2={() => openhost()} />
      )}
      {reviewhost && (
        <ReviewHost
          onDismiss={() => setReviewHost(false)}
          step3={() => openreviewexp()}
        />
      )}
      {reviewexp && (
        <ReviewExp
          onDismiss={() => setReviewExp(false)}
          step4={() => opencreateexp()}
        />
      )}
      {createexp && (
        <CreateExp
          onDismiss={() => setCreateExp(false)}
          onSetCreateHost={() => {
            createHostExperience();
          }}
        />
      )}
      {confirmDelete && (
        <ConfirmDelete
          onDismiss={() => setConfirmDelete(false)}
          title={selectedTitle}
          onReload={() => {
            setReload(true);
          }}
          id={selectedId}
        />
      )}
      <Header />
      <div className="container container-theme pagetop">
        <div className="d-flex jc-between">
          <div>
            <h5 className="welcome-back">Welcome back</h5>
            <p className="heading-des">
              Keep track of and view all your experiences. Happy hosting!
            </p>
          </div>
          <div className="flex-shrink-0">
            <button
              className="btn btn-theme btn-theme-create"
              onClick={() => setHostCity(true)}
            >
              Create an Experience
            </button>
          </div>
        </div>
        <div className="d-grid editexp gap-3 mt-5">
          {experienceDatas &&
            experienceDatas.map((value) => (
              <div className="shadowbox p-3 br-10">
                <div className="d-flex jc-between">
                  <div>
                    <h6 className="themeclr experience-title">{value.title}</h6>
                    <p className="f-14 text-muted">{value.country}</p>
                  </div>
                  {value._id ? (
                    <div>
                      {/* <button
                        onClick={() => {
                          setSelectedTitle(value.title);
                          setSelectedId(value._id);
                          setConfirmDelete(true);
                        }}
                        className="circlebtn shadowbox bg-white noborder"
                      >
                        <span className="fa fa-trash text-muted" />
                      </button> */}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <p className="f-14 text-muted">
                  Your experience was submitted. Expect to hear back within two
                  or four weeks.
                </p>
                <div className="text-end mt-3">
                  {value._id ? (
                    <button
                      className="btn btn-theme"
                      onClick={() => {
                        navigate(
                          `/host-dashboard/listings/${userDatas.email}/experiences`,
                          { state: "experience" }
                        );
                      }}
                    >
                      View Experience
                    </button>
                  ) : (
                    <button
                      className="btn btn-theme"
                      onClick={() => {
                        navigate(`/manage-experience/${userDatas.email}`);
                      }}
                    >
                      Continue listing
                    </button>
                  )}
                </div>
              </div>
            ))}
        </div>
        <div className="row mt-5">
          <div className="col-md-6 d-flex flex-column">
            <h4 className="themeclr show-what">Show what you know</h4>
            <p>
              Dawn takes you backstage at an aquarium where you learn about
              research programs with marine conservationists.
            </p>
          </div>
          {/* <div className='col-md-6'> */}
          {/* </div> */}
        </div>
        <div className="row mt-5 explists">
          <div className="col-md-4">
            <div className="bordbox text-md-center mb-3 d-md-block d-flex border_box_left_p">
              <img src={img11} className="m-auto p-4" />
              <hr className="m-0" />

              <div className="px-md-3 px-0 pb-3 mx-md-0 mx-2">
                <h4 className="story-heading mt-3">Culture and Story</h4>
                <p className="text-muted f-14">
                  Dawn takes you backstage at an aquarium where you learn...
                  {/* <span className="story-more-btn">more</span> */}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="bordbox text-md-center mb-3 d-md-block d-flex border_box_left_p">
              <img src={img1} className="m-auto p-4" />
              <hr className="m-0" />
              <div className="px-md-3 px-0 pb-3 mx-md-0 mx-2">
                <h4 className="story-heading mt-3">Culture and Story</h4>
                <p className="text-muted f-14">
                  Dawn takes you backstage at an aquarium where you learn...
                  {/* <span className="story-more-btn">more</span> */}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="bordbox text-md-center mb-3 d-md-block d-flex border_box_left_p">
              <img src={img13} className="m-auto p-4" />
              <hr className="m-0" />

              <div className="px-md-3 px-0 pb-3 mx-md-0 mx-2">
                <h4 className="story-heading mt-3">Culture and Story</h4>
                <p className="text-muted f-14">
                  Dawn takes you backstage at an aquarium where you learn...
                  {/* <span className="story-more-btn">more</span> */}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-8 d-flex flex-column">
            <h4 className="themeclr show-what">Show what you know</h4>
            <p className="show-what-des">
              Dawn takes you backstage at an aquarium where you learn about
              research programs with marine conservationists.
            </p>
          </div>
          {/* <div className='col-md-6'> */}
          {/* </div> */}
        </div>
        <div className="row expmap my-5">
          <div className="col-md-4 mb-3">
            <div className="shadowbox br-10">
              <div className="d-flex align-items-center">
                <span>1</span>
                <span className="horizontal-line" />
              </div>
              <h5 className="themeclr mt-4">Learn Our Quality</h5>
              <p className="f-14 text-muted mt-3">
                Dawn takes you backstage at an aquarium where you learn about
                research programs with marine conservationists.
              </p>
            </div>
          </div>
          <div className="col-md-4 mb-3">
            <div className="shadowbox br-10">
            <div className="d-flex align-items-center">
                <span>2</span>
                <span className="horizontal-line" />
              </div>
              <h5 className="themeclr mt-4">Learn Our Quality</h5>
              <p className="f-14 text-muted mt-3">
                Dawn takes you backstage at an aquarium where you learn about
                research programs with marine conservationists.
              </p>
            </div>
          </div>
          <div className="col-md-4 mb-3">
            <div className="shadowbox br-10">
              <span>3</span>
              <h5 className="themeclr mt-4">Learn Our Quality</h5>
              <p className="f-14 text-muted mt-3">
                Dawn takes you backstage at an aquarium where you learn about
                research programs with marine conservationists.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ExperianceLand;
