import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { getExperienceFromLocal } from '../../../../Hooks/useUserExperienceHook';

const GuestKnows = (props) =>{
    const [whatGuestShouldKnow,setWhatGuestShouldKnow] = useState({});
    console.log('whatguestshouldknow',whatGuestShouldKnow)
    const [localData, setLocalData] = useState({});
    const [disable, setDisable] = useState(false);
    const [check, setCheck] = useState(false);
    const putData = (e) =>{
        console.log("putData",e.target.value)
        props.expnotes(e.target.value)
    }

    const setData = (e) => {
        console.log("setData",e.target.value)
        // let data = {};
        // data = whatGuestShouldKnow;
        if(e.target.id == "things"){
            whatGuestShouldKnow.things = e.target.value;
            // setDisable(true)
        }else if(e.target.id == "flexCheckChecked"){
            whatGuestShouldKnow.nothing = e.target.checked;
            setCheck(true)
        }
        setWhatGuestShouldKnow(whatGuestShouldKnow);
        console.log('whatGuestShouldKnow',whatGuestShouldKnow)

    }
    useEffect(()=>{
        const localdata = getExperienceFromLocal();
        console.log('loacldata',localdata)
        setLocalData(localdata?.whatGuestShouldKnow)
        setWhatGuestShouldKnow({...whatGuestShouldKnow,...{'things':localdata?.whatGuestShouldKnow}})
    },[]);
    // console.log('vhevkkkkkk',check,  "DISABLE", disable)

    return(
        <>
                <div>
                    {console.log('1st letter',whatGuestShouldKnow?.things)}
                    <h2 className='conthead'>What else should guests know?</h2>
                    <p className='text-muted'>Choose the category that best describe your experience. Add a second,
                        if you think it fits into another category.
                    </p>
                    <div className=' mt-4'>
                     <div className='h-55'>
                         <input type="text" 
                          value={whatGuestShouldKnow?.things}
                         placeholder={"Bring your own cameras.." } 
                         id="things" className='form-control' onChange={(e) => {(e?.target?.value || e.target.value == "") ? (putData(e) || setData(e)) : setDisable(false)}}/>
                     <p className='text-muted f-14 mt-2'>Is there anything else guests to know?</p>
                 </div> 
                       <div class="form-check my-3 align-items-start justify-content-end gap-2">
                        <label class="form-check-label f-14 text-muted" for="flexCheckChecked">
                            I have no additional notes for my guests.
                        </label>
                        {/* <input class="form-check-input"  type="checkbox"  onChange={(e) => {(e.target.checked) ? setData(e) : ( whatGuestShouldKnow.things == '' || e.target.checked == false ? setDisable(false): setDisable(true)) }} id="flexCheckChecked" /> */}
                        <input class="form-check-input"  type="checkbox"  onChange={(e) => {(e.target.checked) ? setData(e) : setCheck(false) }} id="flexCheckChecked" />
                    </div>
                   <div className='mt-4'><button className='btn btn-theme' 
                   disabled={!whatGuestShouldKnow?.things && !check} onClick={() => {props.count();props.onSetLocal(whatGuestShouldKnow?.things ? whatGuestShouldKnow?.things :  "" ,"whatGuestShouldKnow")}}>Save & Continue</button></div> 
                    </div>
              </div>  
        </>

    )
}

export default GuestKnows