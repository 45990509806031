import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { getExperienceFromLocal } from '../../../../Hooks/useUserExperienceHook';

const WriteBio = (props) =>{
    const [bio,setBio] = useState("");
    const [localData, setLocalData] = useState({});
    const putData = (e) =>{
       props.expbio(e.target.value)
    }

    useEffect(()=>{
        const localdata = getExperienceFromLocal();
        setLocalData(localdata)
        if(localdata && localdata.bio){
            setBio(localdata.bio);
        }
    },[]);

    return(
        <>
                <div>
                    <h2 className='conthead'>Write your bio</h2>
                    <p className='text-muted f-14'>Choose the category that best describe your experience. Add a second,
                        if you think it fits into another category.
                    </p>
                    <div className=' mt-4'>
                        <textarea rows="5" className='w-full form-control'
                        placeholder={"Write Bio"}
                        value={bio}
                        onChange={(e) => {putData(e);setBio(e.target.value)}}></textarea>

                   <div className='mt-4'><button className='btn btn-theme'
                   disabled={!bio}
                   onClick={() => {props.count();props.onSetLocal(bio,"bio")}}>Next</button></div> 
                    </div>

                    
              </div>  
        </>

    )
}

export default WriteBio