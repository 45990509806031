import React, { useState } from 'react';
import {Breadcrumb,Button, Form} from 'react-bootstrap'
import { Link } from 'react-router-dom';
import Footer from '../../../Common/Footer';
import Header from '../../../Common/Header';
import bag from '../../../../Assets/images/bag.png'

const AccHostongTools = () => {


    return(
        <div className='personalinfo nocenterhead'>
            <Header />
            <div className='mh-100vh'>
            <div className='container container_custom mt-78'>
                <div className='row justify-content-center'>
                
                    <div className='col-xl-9'>
                    <div className='breadcrum mt-4'>
                        <Breadcrumb>
                        <Breadcrumb.Item><Link to="/">Account</Link></Breadcrumb.Item>
                        <Breadcrumb.Item><Link to="/">Professional hosting tools</Link></Breadcrumb.Item>
                        </Breadcrumb>
                        <h2>Professional hosting tools</h2>
                    </div>
                        <div className='row mt-5 justify-content-between'>
                            <div className='col-md-7'>
                                <div className='cont'>
                                   <div className='d-flex align-items-center jc-between'>
                                        <div>
                                            <h6>Company</h6>
                                            <p className='text-muted'>Edit your company info and manage your listings at scale</p>
                                        </div>
                                        <Button variant='link' className='text-dark'>Manage</Button>  
                                   </div>
                                   <hr className='themehr'/>
                                   <div className='d-flex align-items-center jc-between'>
                                        <div>
                                            <h6>Allow me to use an email alias to reach guests</h6>
                                            <p className='text-muted'>I attest that I will use the email alias only for the following reasons:</p>
                                            <ul className='liststyledots'>
                                                <li>I need to send document attachments required by local laws</li>
                                                <li>I need to send rental agreements as an attachment</li>
                                                <li>I need to send check-in guides as an attachment</li>
                                                <li>I use connected software with FlutterPads that is not integrated with FlutterPads messaging</li>
                                            </ul>
                                            <p>I understand that my use of the email alias is subject to FlutterPads’s <b><a href="#" className='text-dark'> Off-Platform Policy</a></b>.</p>

                                        </div>
                                        <div class="form-switch">
                                    <input type="checkbox" id="custom-switch" class="form-check-input"/>
                                </div>
                                   </div>
                                   <hr className='themehr'/>
                                  
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <Footer />
        </div>
    )
}

export default AccHostongTools