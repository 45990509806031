import React, {  useState } from 'react';
import {Modal} from 'react-bootstrap'

// import Scrollbars from 'react-custom-scrollbars';

import userprof from '../../../Assets/images/detail/userprof.webp'

import wifi from '../../../Assets/images/icons/icons/wifi.png'
// import ac from '../../../Assets/images/icons/icons/ac.png'
import tv from '../../../Assets/images/icons/icons/tv.png'
import hairdryer from '../../../Assets/images/icons/icons/hairdryer.png'
import food from '../../../Assets/images/icons/icons/food.png'
// import bed from '../../../Assets/images/icons/icons/bed.png'

import shampoo from '../../../Assets/images/icons/icons/shampoo.png'
import firstaid from '../../../Assets/images/icons/icons/firstaid.png'
import oven from '../../../Assets/images/icons/icons/oven.png'
import securitycam from '../../../Assets/images/icons/icons/securitycamera.png'
import washingmachine from '../../../Assets/images/icons/icons/washingmachine.png'
import essentials from '../../../Assets/images/icons/icons/essentials.png'
import privateentrance from '../../../Assets/images/icons/icons/privateentrance.png'
import thermometer from '../../../Assets/images/icons/icons/thermometer.png'

import longterm from '../../../Assets/images/icons/icons/longterm.png'
import cmalarm from '../../../Assets/images/icons/icons/cmalarm.png'
import smokealarm from '../../../Assets/images/icons/icons/smokealarm.png'
import fireextinguisher from '../../../Assets/images/icons/icons/fireextinguisher.png'
import hanger from '../../../Assets/images/icons/icons/hanger.png'
import pool from '../../../Assets/images/icons/icons/pool.png'
import hotandcold from '../../../Assets/images/icons/icons/hotandcold.png'

import { API_URL } from '../../../config/env';
const PlaceOffer = (props) =>{
    const[placeoffer, setPlaceOffer] = useState(true)
    return(
        <>  
            <Modal show={placeoffer} size="" centered scrollable className='modal_style'>
                <Modal.Header className='border-0 pb-0 d-flex justify-content-end'>
                    <button type="button" className="btn close close_modal" onClick={() =>props.onDismiss()}><span aria-hidden="true" className='fa fa-times f-14'></span><span class="sr-only">Close</span></button>
                </Modal.Header>
                <Modal.Body className='pt-0'>
                    <div className='placeoffers'>
                        <h5 className='text-heading-medium'>What this place offers</h5>
                        <div className='mt-4'>
                        {props.data.map((data,index)=>
                        <>{(index<props.data.length-1)?
                            <ul className='ps-0 placof_ul'>
                            <li className=''>
                            <div className='d-flex align-items-center'>
                                <img src={`${API_URL}${"/adminImages/description"}/${data.image}`} className="place_icon" alt="Sample Image" />
                                <div className='ms-2'>
                                    <span className='ms-2 detail_desc_grey f-12'>{data.type}</span>
                                </div>
                            </div>
                        </li>
                        </ul>
                            :<></>}</>
                        )}
                        

                            {/* <h6>Bathroom</h6>
                            <ul className='ps-0'>
                                <li className=''>
                                    <div className='d-flex align-items-center'>
                                        <img src={hairdryer} className="place_icon" alt="Sample Image" />
                                        <div className='ms-2'>
                                            <span className='ms-2 detail_desc_grey f-12'>Hair Drier</span>
                                        </div>
                                    </div>
                                </li> */}
                                {/* <li className=''>
                                    <div className='d-flex align-items-center'>
                                    <img src={shampoo} className="place_icon" alt="Sample Image" />
                                    <div className='ms-2'>
                                        <span className='ms-2 detail_desc_grey f-12'>Shampoo</span>
                                    </div>
                                    </div>
                                </li> */}
                            {/* </ul> */}
                        </div>
                        {/* <div className='mt-4'>
                            <h6>Bedroom and Laundry</h6>
                            <ul className='ps-0'>
                                <li className=''>
                                    <div className='d-flex align-items-center'>
                                        <img src={essentials} className="place_icon" alt="Sample Image" />
                                        <div className='ms-2'>
                                        <span className='detail_desc_grey f-12'>Essentials</span>
                                        <p className='f-12 txtgray'> Towels, bed sheets, soap and toilet paper</p>
                                        </div>
                                    </div>
                                </li>
                                <li className=''>
                                    <div className='d-flex align-items-center'>
                                    <img src={hanger} className="place_icon" alt="Sample Image" />
                                     <div className='ms-2'>
                                        <span className=' detail_desc_grey f-12'>Hanger</span>
                                       
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className='mt-4'>
                            <h6>Entertainment</h6>
                            <ul className='ps-0'>
                                <li className=''>
                                    <div className='d-flex align-items-center'>
                                        <img src={tv} className="place_icon" alt="Sample Image" />
                                        <div className='ms-2'>
                                        <span className='detail_desc_grey f-12'>Tv</span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className='mt-4'>
                            <h6>Heating and cooling</h6>
                            <ul className='ps-0'>
                                <li className=''>
                                    <div className='d-flex align-items-center'>
                                        <img src={hotandcold} className="place_icon" alt="Sample Image" />
                                        <div className='ms-2'>
                                        <span className='detail_desc_grey f-12'>Heating and cooling</span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div className='mt-4'>
                            <h6>Home safety</h6>
                            <ul className='ps-0'>
                                <li className=''>
                                    <div className='d-flex align-items-center'>
                                        <img src={smokealarm} className="place_icon" alt="Sample Image" />
                                        <div className='ms-2'>
                                        <span className='detail_desc_grey f-12'> Smoke alarm</span>
                                        </div>
                                    </div>
                                </li>
                                <li className=''>
                                    <div className='d-flex align-items-center'>
                                        <img src={cmalarm} className="place_icon" alt="Sample Image" />
                                        <div className='ms-2'>
                                        <span className='detail_desc_grey f-12'>Carbon monoxide alarm</span>
                                        </div>
                                    </div>
                                </li>
                                <li className=''>
                                    <div className='d-flex align-items-center'>
                                        <img src={fireextinguisher} className="place_icon" alt="Sample Image" />
                                        <div className='ms-2'>
                                        <span className='detail_desc_grey f-12'>Fire Extingusher</span>
                                        </div>
                                    </div>
                                </li>
                                <li className=''>
                                    <div className='d-flex align-items-center'>
                                        <img src={firstaid} className="place_icon" alt="Sample Image" />
                                        <div className='ms-2'>
                                        <span className='detail_desc_grey f-12'> First aid kit</span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div className='mt-4'>
                            <h6>Internet and office</h6>
                            <ul className='ps-0'>
                                <li className=''>
                                    <div className='d-flex align-items-center'>
                                        <img src={wifi} className="place_icon" alt="Sample Image" />
                                        <div className='ms-2'>
                                        <span className='detail_desc_grey f-12'>WiFi</span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div className='mt-4'>
                            <h6>Parking and facilities</h6>
                            <ul className='ps-0'>
                                <li className=''>
                                    <div className='d-flex align-items-center'>
                                        <img src={pool} className="place_icon" alt="Sample Image" />
                                        <div className='ms-2'>
                                            <span className='detail_desc_grey f-12'>Pool</span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div className='mt-4'>
                            <h6>Services</h6>
                            <ul className='ps-0'>
                                <li className=''>
                                    <div className='d-flex align-items-center'>
                                        <img src={food} className="place_icon" alt="Sample Image" />
                                        <div className='ms-2'>
                                            <span className='detail_desc_grey f-12'>Breakfast</span>
                                            <p className='f-12 txtgray'>Breakfast is provided</p>
                                        </div>
                                    </div>
                                </li>
                                <li className=''>
                                    <div className='d-flex align-items-center'>
                                        <img src={longterm} className="place_icon" alt="Sample Image" />
                                        <div className='ms-2'>
                                            <span className='detail_desc_grey f-12'>Long-term stays allowed</span>
                                            <p className='f-12 txtgray'>Long-term stays allowed</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div className='mt-4'>
                            <h6>Not included</h6>
                            <ul className='ps-0'>
                                <li className=''>
                                    <div className='d-flex align-items-center'>
                                        <img src={securitycam} className="place_icon" alt="Sample Image" />
                                        <div className='ms-2'>
                                            <span className='detail_desc_grey f-12'>
                                                <s> Security cameras on property</s>
                                            </span>
                                        </div>
                                    </div>
                                </li>
                                <li className=''>
                                    <div className='d-flex align-items-center'>
                                        <img src={oven} className="place_icon" alt="Sample Image" />
                                        <div className='ms-2'>
                                        <span className='detail_desc_grey f-12'><s>Kitchen</s></span>
                                        </div>
                                    </div>
                                </li>
                                <li className=''>
                                    <div className='d-flex align-items-center'>
                                        <img src={washingmachine} className="place_icon" alt="Sample Image" />
                                        <div className='ms-2'>
                                        <span className='detail_desc_grey f-12'><s> Washing machine</s></span>
                                        </div>
                                    </div>
                                </li>
                                <li className=''>
                                    <div className='d-flex align-items-center'>
                                        <img src={privateentrance} className="place_icon" alt="Sample Image" />
                                        <div className='ms-2'>
                                        <span className='detail_desc_grey f-12'><s> Private entrance</s></span>
                                        </div>
                                    </div>
                                </li>
                                <li className=''>
                                    <div className='d-flex align-items-center'>
                                        <img src={thermometer} className="place_icon" alt="Sample Image" />
                                        <div className='ms-2'>
                                        <span className='detail_desc_grey f-12'><s> Heating</s></span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div> */}
                               
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}


export default PlaceOffer