import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { getExperienceProvisions, getExperienceFromLocal } from '../../../../Hooks/useUserExperienceHook';
const ConfirmProvides = (props) => {
    const [showinput, setShowInput] = useState(false)
    const [options, setOptions] = useState([])
    console.log('optionsssssssssss',options)
    const [items, setItems] = useState([{}])
    const [selectedValues, setSelectedValues] = useState([]);
    console.log('selectedvaluesgsdgsdgsdgs',selectedValues)
    const [finalValue, setFinalValue] = useState({});
    const [spliceItems,setSpliceItem] = useState([]);
    const [disable , setDisable] = useState(false);
    const [check, setCheck] = useState(false);
    const [errors, setErrors] = useState({})
    var spliceItem = '';

    const onSelect = (e, selectedIndex) => {
        if (e.target.value != "") {
            setShowInput(true)
            setDisable(true)
        }
        //  var a =  options.filter((filters) => filters != e.target.value)
        //  setOptions(a);
        var obj = {};
        obj.item = e.target.value;
        selectedValues[selectedIndex] = obj;
        // console.log("selected values array :", selectedValues);
        setSelectedValues([...selectedValues]);
        props.expprovide(e.target.value)
    }

    const setDescription = (e, index) => {
        selectedValues[index].description = e.target.value;
        console.log("selected values in set description :", selectedValues);
    }

    const finalFunc = () => {
        finalValue.provisions = selectedValues;
        console.log("selected values in final func:", selectedValues[0]);
        if (selectedValues[0] != undefined) {
            if(!selectedValues[0]?.item) {
                finalValue.nothingToProvide = true;
            }
        } else {
            finalValue.nothingToProvide = false;
        }
        console.log("finalValue in finalFunc", finalValue);
        props.onSetLocal(finalValue, "whatYouWillProvide")
    }

    const addItems = () => {
        if (items.length < options.length) {
            // setItems(val => [...val , val+1])
            setSelectedValues([...selectedValues, {}]);
        }
        console.log("items inside add items :", items);
    }

    const removeList = (index) => {
        console.log("Index", index)
        let testarr = selectedValues;
        testarr.splice(index, 1)
        setSelectedValues([...testarr]);
        // setDisable(false)
        console.log("selected value in remove list ", selectedValues);
        // console.log("selected value spliceItem ", spliceItem);
    }

    useEffect(() => {
        let data = getExperienceFromLocal();
        console.log('datasgaluuuu',data?.whatYouWillProvide?.provisions)
        let arrdata = data?.whatYouWillProvide?.provisions ? data?.whatYouWillProvide?.provisions : []
        setSelectedValues(arrdata)
        // selectedValues[0].description = data ? data?.whatYouWillProvide?.provisions[0]?.item : '';
        getExperienceProvisions().then((data) => {
            console.log('data.dataq.contetn',data?.content)
          let datas = data?.content ? data?.content : [];
          console.log('datassssssssssssssssssssssssssssssss',datas)
            setOptions(datas);
            // const obj = {};
            // selectedValues.push(obj);
            // console.log("selectedValues :", selectedValues);
        })
    },[]);

    const nextpage = async () => {
        try {
            let obj = {};
            let filterdata = await selectedValues.map((el, i) => {
                console.log("el_cond", el,)
                if (!((el['item'] != "" && el['item']  != undefined && el['item']  != ""))) {
                    obj[`${i}`] = "Please fill the item"
                }
            });
            if (Object.keys(obj)?.length == 0) {
                props?.count()
            }
            setErrors(obj);

            console.log("filterdata", obj, filterdata)
        } catch (e) {
            console.log("nextpage_err", e)
        }
    }

    // useEffect(() => {
    //     const data = getExperienceFromLocal();
    //     // console.log('!!!!!!!!!',data.whatYouWillProvide.provisions)
    //     if(data && data.whatYouWillProvide){
    //         console.log('```````````````````',data.whatYouWillProvide.provisions);
    //         setSelectedValues(data?.whatYouWillProvide?.provisions)
    //     }
    // },[])
    return (
        <>
            <div>{console.log("selectedVal_check" ,selectedValues)}
                <h2 className='conthead'>Confirm what you'll provide</h2>
                <p className='text-muted'>Choose the category that best describe your experience. Add a second,
                    if you think it fits into another category.
                </p>
                <div className=' mt-4'>
                    <div className='h-55'>
                        {selectedValues && selectedValues.length != 0 && selectedValues.map((value, index) => {
                            return <>
                                <div className='d-flex jc-between'>
                                    <h5 className='mt-3'>Item {index + 1}</h5>
                                    <Button variant='link' onClick={() => { removeList(index) }} className={showinput ? 'nounder  adminblue  p-0' : "d-none"}  >Remove</Button>
                                </div>
                                {/* value={value?.item}  */}
                                <select className='form-control' onChange={(e) => onSelect(e, index)}>
                                    <option value="" disabled selected  >Select items</option>
                                    {options.map((vals, i) => {
                                        return <>
                                            <option>{vals.type}</option>
                                        </>
                                    })}
                                </select>
                                <input onChange={(e) => { setDescription(e, index) }} className={showinput ? 'form-control' : "d-none"} type="text" />

                            </>
                        })}
                        {check == false && <Button variant='link' className='nounder adminblue ps-0' disabled={selectedValues[selectedValues.length - 1] == {}} onClick={() => addItems()}>{selectedValues.length > 0 ? 'Add  another item' : 'Add item'}</Button>}

                        <p className='text-muted f-14 mt-2'>Not providing anything for your guests?</p>
                    </div>
                    <div class="form-check my-3 align-items-start justify-content-end gap-2">
                        <label class="form-check-label f-14 text-muted" for="flexCheckChecked">
                            I am not providing anything
                        </label>
                        <input
                            // checked={!selectedValues&&!selectedValues[0]&&!selectedValues[0].item} 
                            onChange={(e) => { (e.target.checked) ? setSelectedValues([]) || setCheck(true) : setSelectedValues(selectedValues) || setCheck(false) }} class="form-check-input" type="checkbox" value="Split-type ductless system" id="flexCheckChecked" />
                    </div>
                    <div className='mt-4'><button disabled={!disable && !check} className='btn btn-theme' onClick={() => { props.count(); finalFunc(); }}>Save & Continue</button></div>
                </div>
                {/* disabled = {selectedValues?.length <= 0 || !check} */}
                {/* disabled={!selectedValues[0]?.item &&!selectedValues[0]?.description && !check} */}
            </div>
        </>

    )
}

export default ConfirmProvides