import React, { useState } from "react";
import { Modal } from "react-bootstrap";

// import Scrollbars from 'react-custom-scrollbars';

const CoverModal = (props) => {
  const [cover, setCover] = useState(true);

  return (
    <>
      <Modal show={cover} size="" centered scrollable className="modal_style">
        <Modal.Header className="border-0 pb-0 d-flex justify-content-end">
          <button
            type="button"
            className="btn close close_modal"
            onClick={() => props.onDismiss()}
          >
            <span aria-hidden="true" className="fa fa-times f-14" />
            <span class="sr-only">Close</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          {/* <Scrollbars style={{height:420}}> */}
          <div className="container px-0 cover_oda_txt">
            <p>
              FlutterPads is comprehensive protection included for free with
              every booking.
            </p>
            <hr className="themehr my-4" />
            <div className="">
              <div className="d-lg-flex flex-1 gap-4">
                <div className=" mb-4">
                  <div>
                    <h6 className="text-heading-small">Booking Protection Guarantee</h6>
                    <p className="graytxt">
                      In the unlikely event that a Host needs to cancel your
                      booking within 30 days of check-in, we’ll find you a
                      similar or better home or we’ll refund you.
                    </p>
                  </div>
                </div>
                <div className=" mb-4">
                  <div>
                    <h6 text-heading-small>Check-In Guarantee</h6>
                    <p className="graytxt">
                      If you can’t check into your home and the Host cannot
                      resolve the issue, we’ll find you a similar or better home
                      for the length of your original stay or we’ll refund you.
                    </p>
                  </div>
                </div>
              </div>
              <div className="d-lg-flex  flex-1 gap-4">
                <div className="mb-4">
                  <div>
                    <h6 text-heading-small>Get-What-You-Booked Guarantee</h6>
                    <p className="graytxt">
                      If at any time during your stay you find your listing
                      isn't as advertised – for example, the refrigerator stops
                      working and your Host can’t easily fix it, or it has fewer
                      bedrooms than listed – you'll have three days to report it
                      and we’ll find you a similar or better home, or we’ll
                      refund you.
                    </p>
                  </div>
                </div>
                <div className="mb-4">
                  <div>
                    <h6 text-heading-small>24-hour Safety Line</h6>
                    <p className="graytxt">
                      If you ever feel unsafe, you’ll get priority access to
                      specially trained safety agents, day or night.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <p className="mt-5 mb-4">
              Find complete details on how FlutterPads protects your booking in
              our{" "}
              <a href="#" className="link_blue_border">
                <b>Help Centre</b>
              </a>{" "}
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CoverModal;
