import React, {useEffect, useState, useRef} from "react";
import { Link } from "react-router-dom";
import {Button} from 'react-bootstrap'
import { io } from 'socket.io-client';
import prof from '../../Assets/images/prof.jpg'
import detailimg1 from '../../Assets/images/detail/detailimg1.png'
import AvailabilityModal from './AvailabilityModal';
import AvailCont from "../Common/AvailCont";
import { API_URL } from "../../config/env";
import { getUserDatas, getUserEmail } from "../../Hooks/useUserHook";

const Header = React.lazy(() => import('../Common/Header'));
const Footer = React.lazy(() => import('../Common/Footer'));
const ContactHost = () =>{
    const txt = "Adaaran Club Rannalhi, Maldives, Silver All Inclu."

const[avail, setAvail] = useState(false)
const[msg , setMsg] = useState(false)
const [userData, setUserData] = useState({});
// const [isOnline, setIsOnline] = useState(false);
const [isOnline , setIsOnline] = useState('')
const [hostEmail, setHostEmail] = useState("");
const [email, setEmail] = useState('')
console.log('email>>>>>>....',email)
const [message, setMessage] = useState("");
const [type , setType] = useState(false)

const [arrivalMessage, setArrivalMessage] = useState(null); 
const [bookingDatas, setBookingDatas] = useState({});

const socket = io(API_URL);

const handleSubmit = (e, msg = message) => {
    // e.preventDefault();
    console.log("socket in handle submit:",socket);
    // socket.emit("sendMessage", {
    //     senderId: userData?.email,
    //     receiverId: hostEmail,
    //     content: `(Selected Date : ${new Date(bookingDatas?.startDate)?.toLocaleDateString()} to ${new Date(bookingDatas?.endDate)?.toLocaleDateString()}) ${msg}` ,
    // });
    setMessage("");
   
    if(email)
    {
       window.location.href = window.location.origin+`/host-dashboard/inbox/${email}`
    }


}

const handleTyping = (e) => {
    setMessage(e.target.value);
    setType(true)
    // if (!typing) {
    //     setTyping(true);
    //     socket?.current.emit("typing", {
    //         senderId: loggedInUser._id,
    //         receiverId: userId
    //     });
    // }

    // setTimeout(() => {
    //     socket?.current.emit("typing stop", {
    //         senderId: loggedInUser._id,
    //         receiverId: userId
    //     });
    //     setTyping(false);
    // }, 2000);
}

const getData = () => {
    let data = window.location.pathname.split("/")[2];
    setUserData(getUserDatas())
    // data = JSON.parse(data);
    setHostEmail(data)
    setBookingDatas(JSON.parse(localStorage.getItem("contactDatas")));
    console.log("data in contacthost:",data,"localStorage.getItem(contactDatas)",JSON.parse(localStorage.getItem("contactDatas")));
}

useEffect(()=>{
    console.log("inside getMessage useEffect");
    // socket.on("getMessage", (data) => {
    //     console.log("getMessage, data :",data);
    //     setArrivalMessage({
    //         sender: data.senderId,
    //         content: data.content,
    //         createdAt: Date.now(),
    //     });
    // });
},[arrivalMessage]);

useEffect(() => {
    if(userData.email != null){
    // socket.emit("addUser", userData?.email);
    }
   const data = getUserEmail()
   setEmail(data)
}, [userData._id, hostEmail])

useEffect(()=>{
    getData()
},[]);

const checkMsg = () =>{
    document.getElementById("message").value == "" ?  setMsg(true) :  setMsg(false)  
    // console.log(document.getElementById("message").innerText)
    if(message){
        handleSubmit();
    }
}

    return(
        <div id="contacthost">
            {avail && <AvailabilityModal onDismiss={() => setAvail(false)}  /> }
            <Header />
            <div className="mobileresevebtn d-flex jc-between align-items-center">
                <p>Still have a question?</p>
                <button class="btn btn-theme" onClick={() => setAvail(true)}>Reserve</button>
                </div>

                <div className='container container_custom pagetop'>
                    <div className="goback mb-3">
                        <Link to="/expdetail"><span className="fa fa-chevron-left"></span></Link>
                    </div>
               
                    <div className="row mb-5">
                        <div className='col-12 col-lg-8 leftcont'>
                        <div className="d-flex">
                            <div className="flex-grow-1 me-3">
                                <p className='detail_heading'>Contact {bookingDatas&&bookingDatas.firstName}&nbsp;{bookingDatas&&bookingDatas.lastName}</p>
                                <p>{bookingDatas&&bookingDatas.responseTime}</p>
                            </div>
                            <div className="flex-shrink-0">
                                <img src={prof} width="56" height="56" alt="Sample Image" className='prof_img_round'/>
                            </div>
                        </div>
                        <hr className="my-4"/>
                        <div>
                            <h4 className="mb-3">Most travellers ask about</h4>
                            <p className="mb-3"><b>Price and availability</b></p>
                            <ul className="liststyledots">
                                <li><p className="pb-0">{bookingDatas&&bookingDatas.firstName}’s home is available from {bookingDatas&&bookingDatas.startDate&&new Date(bookingDatas.startDate).getDate()} {bookingDatas&&bookingDatas.startDate&&new Date(bookingDatas.startDate).toLocaleString('default', { month: 'long' })} –{bookingDatas&&bookingDatas.endDate&&new Date(bookingDatas.endDate).getDate()} {bookingDatas&&bookingDatas.endDate&&new Date(bookingDatas.endDate).toLocaleString('default', { month: 'long' })}. Book soon.</p></li>
                                <li><p className="pb-0">Full refund within limited period</p></li>
                            </ul>
                        </div>
                        <hr className="my-4"/>
                        <h5 className="mb-3">Message the host</h5>
                        {!msg ? "" : <label className='text-danger err d-flex f-12'><span className="fa fa-info me-2"></span> Please add a message.</label>}
                        
                        <textarea onChange={(e)=>{handleTyping(e)}} className="w-full br-10 mt-1 p-3" rows="5" id="message"></textarea>
                        <Button variant="outline-dark" className="mt-3" disabled={!type} onClick={() => {handleSubmit()}}>Send Message</Button>
                        </div>


                        <div className='col-12 col-lg-4 d-lg-block mt-lg-0 mt-4 '>
                             {/* <AvailCont img={detailimg1} txt={txt}/> */}


                        </div>
                    </div>
                </div>
            <Footer />
        </div>
    )
}

export default ContactHost 