
import React, { useState } from 'react';


const AccShare = () =>{

return(<>
      <div className='row mt-5 justify-content-between'>
        <div className='col-md-7 mb-4'>
            <h4>Activity sharing</h4>
            <p className='text-muted f-14'>Decide how your profile and activity are shown to others.</p>
                <div className=' mt-5'>
                    <p>Include my profile and listing in search engines</p> 
                   
                </div>
                <p className='text-muted f-14'>Turning this on means search engines, like Google, will display your profile and listing pages in search results.</p>
                    <div class="form-switch mt-2">
                        <input type="checkbox" id="custom-switch" class="form-check-input"/>
                    </div>
                    <hr className='themehr' />
                    <h4>Data sharing</h4>
            <p className='text-muted f-14'>Decide how your data is used for FlutterPads research.</p>
                <div className=' mt-5'>
               
                    <p>Use my first name and profile photo to help fight discrimination</p> 
                   
                </div>
                <p className='text-muted f-14'>Leaving this on means that you’re helping us further studies to help identify and prevent discrimination from happening on FlutterPads.<a href="#" className='text-dark'> Learn more</a></p>
                    <div class="form-switch mt-2">
                        <input type="checkbox" id="custom-switch" class="form-check-input"/>
                    </div>
   <hr className='themehr' />
               
                </div>
             
         




                <div className='col-lg-4 col-md-5 d-md-block d-none '>
                    <div className='right-cont bord p-4 br-10'>
                      <h6>Committed to privacy</h6>
                       <p className='text-muted f-14'>FlutterPads is committed to keeping your data protected. Read details in our 
                       <a href="#" className='text-dark'> Privacy Policy</a>.</p>
          </div>
                            </div>
                        </div>
                    
                    
    </>
)
}
export default AccShare