import React, { useState } from 'react';
import {Modal, Button, ProgressBar} from 'react-bootstrap'
import Scrollbars from 'react-custom-scrollbars';

import EmailVerify from './EmailVerify';

// import AllowPets from './AddModal/AllowPets';

// import trolly from '../../../../../Assets/images/trolly.png'
// import pc from '../../../../../Assets/images/pc.png'
// import tick from '../../../../../Assets/images/tick.png'



const AccSecure = (props) =>{
    const[secure , setSecure] = useState(true)
    const[verify, setVerify] = useState(false)

 const openChild = () =>{
    setVerify(true)
    document.getElementById("firstmodal").style.visibility = "hidden"
    
 }
  const modalclose = () =>{
    props.onDismiss()
  }
   
    return(
        <>
        {verify && <EmailVerify onDismiss={() => setVerify(false)} close={() => modalclose()}/>}
        <Modal show={secure} centered size="md" className='modal_style' id="firstmodal">
           <Modal.Header className='border-0 pb-0'>
               <button type="button" className="btn close close_modal" onClick={() => modalclose()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
          </Modal.Header>
          <Modal.Body className='d-scroll '>  
            <Scrollbars style={{  height: 450  }}>
             <div className='insight-modals'>        
                <h2><b>Let’s make your account more secure</b></h2>
                <p className='mt-3'>Your account security: <span className='text-muted'>Low</span> </p>
                <p className='text-muted mt-3'>We’re always working on ways to increase safety in our community. That’s why we look at every
                     account to make sure it’s as secure as possible.</p>  


                     <div className='verification d-flex align-items-center mt-5'>
                        <span className='verified me-2'></span>
                        {/* <span className='verified fa fa-check me-2'></span> */}
                        <div>
                        <button className='btn btn-link p-0 text-dark nounder fw-600 nolink' >Phone number verified</button>
                        </div>

                     </div>
                     <hr className='themehr' />
                     
                     <div className='verification d-flex align-items-center'>
                        {/* <span className='verified me-2'></span> */}
                        <span className='verified fa fa-check me-2'></span>
                        <div>
                        <button className='btn btn-link p-0 themeclr nounder fw-600' onClick={() => openChild()}>Verify email address</button>
                        <p className='f-14'>This helps us know that you own this email account.</p>
                        </div>

                     </div>
               
              
                     
             </div>
               </Scrollbars>
          </Modal.Body>
      </Modal>
      </>
    )
}

export default AccSecure