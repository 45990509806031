import React, { useState } from 'react';
import {Breadcrumb,Button, Form} from 'react-bootstrap'
import { Link } from 'react-router-dom';
import Footer from '../../../Common/Footer';
import Header from '../../../Common/Header';
import bag from '../../../../Assets/images/bag.png'
import AccReferral from './Modals/AccReferral';

const AccGuestRefferal = () => {

    const[referral, setReferral] = useState(false)

    return(
        <div className='personalinfo nocenterhead'>
            {referral && <AccReferral onDismiss={() =>setReferral(false)} />}
            <Header />
            <div className='mh-100vh'>
            <div className='container container_custom mt-78'>
                <div className='row justify-content-center'>
                
                    <div className='col-xl-9'>
                    <div className='breadcrum mt-4'>
                        <h2>Guest refferals</h2>
                    </div>
                        <div className='row mt-5 justify-content-center'>
                            <div className='col-md-7'>
                                <div className='cont'>
                                    <div className='shadowbox br-10 p-4'>
                                        <h6 className='mb-4'><b>Track your referrals</b></h6>
                                        <div className='d-flex jc-between mb-3'>
                                            <p>Completed referrals</p>
                                            <p><b>0</b></p>
                                        </div>
                                        <div className='d-flex jc-between mb-3'>
                                            <p>Signups</p>
                                            <p><b>0</b></p>
                                        </div>
                                        <Button variant='outline-dark' className='w-full mb-10 py-3 mt-3' onClick={() => setReferral(true)}>Show more details</Button>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <Footer />
        </div>
    )
}

export default AccGuestRefferal