import React,{useState} from 'react';
import { useEffect } from 'react';
import {Modal,Button} from 'react-bootstrap'
import Scrollbars from 'react-custom-scrollbars';

const DedicatedServiceModal = (props) =>{
    const[service , setService] = useState(true)
    
    const[actype , setActype] = useState([])
    const getValue = (e) =>{
  
     if(e.target.checked){
        setActype(data => [...data,  e.target.value]);
     }
     else{
        var arr= actype.filter(
            (car) => ((car != e.target.value))
        );
        setActype(arr)
        
     }
    }
    
    // props.conts(actype)

    return(
    <>
      <Modal show={service} centered size="md" className='modal_style scenic listingmodals'>
          <Modal.Header className='border-0 pb-0'>
            <button type="button" className="btn close close_modal" onClick={() =>props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
          </Modal.Header>
          <Modal.Body>
            <h4 className='mb-0'>Dedicated workspace details</h4>
            <p className='text-muted mb-4'>Guests have a desk or table that’s used just for working, along with a comfortable chair.</p>
            <Scrollbars style={{  height: 360  }}>
                <div>
                   <h6 className='mb-3'>Location</h6>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>In a room with a door</p>
                        </label>
                        <input class="form-check-input" onChange={(e) => getValue(e)} type="radio" value="Air conditioning details" id="flexCheckChecked" />
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>In a common space</p>
                        </label>
                        <input class="form-check-input" onChange={(e) => getValue(e)} type="radio" value="Air conditioning details" id="flexCheckChecked" />
                    </div>
                    <hr className="themehr" />
                   <h6 className='mb-3'>Setup</h6>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Ergonomic chair</p>
                        </label>
                        <input class="form-check-input" onChange={(e) => getValue(e)} type="checkbox" value="Air conditioning details" id="flexCheckChecked" />
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Laptop stand</p>
                        </label>
                        <input class="form-check-input" onChange={(e) => getValue(e)} type="checkbox" value="Portable air conditioning" id="flexCheckChecked"/>
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Monitor</p>
                        </label>
                        <input class="form-check-input" onChange={(e) => getValue(e)} type="checkbox" value="Window AC unit" id="flexCheckChecked" />
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Surge protector</p>
                        </label>
                        <input class="form-check-input" onChange={(e) => getValue(e)}  type="checkbox" value="Split-type ductless system" id="flexCheckChecked" />
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Printer</p>
                        </label>
                        <input class="form-check-input" onChange={(e) => getValue(e)}  type="checkbox" value="Split-type ductless system" id="flexCheckChecked" />
                    </div>
                </div>
            </Scrollbars>
          </Modal.Body>
          <Modal.Footer className='jc-between'>
            <button className='btn btn-theme' >Save</button>
            <Button className='text-dark' variant="link" >Cancel</Button>
          </Modal.Footer>
      </Modal>
    </>
    )
}

export default DedicatedServiceModal