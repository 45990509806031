import React, { useState, useEffect, useRef } from "react";

import { Button, Dropdown, Toast } from 'react-bootstrap'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction"
import { nanoid } from "nanoid";
import img1 from '../../../Assets/images/where/img1.png'

import Footer from "../../Common/Footer";
import HostHeader from "./HostHeader";
import ReactDOM from 'react-dom';
// const Datepicker = React.lazy(() => import('../../Common/Datepicker/Datepicker.js'));
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ConfirmAvail from "./Modals/ConfirmAvail";
import ConfirmAvailcont from "./ConfirmAvailcont";
import { getUserHostings, setRoomPricePerDay } from "../../../Hooks/useUserHostingHook";
import { API_URL, toastOptions } from "../../../config/env";
import { toast, Toaster } from "react-hot-toast";
import { getsingleroompricehooks, getUserDatas } from "../../../Hooks/useUserHook";
import { getDays } from "@datepicker-react/hooks";
const Calendar = (props) => {
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [drop, setDrop] = useState(false)
    const [dropdetails, setDropDetails] = useState(false)
    const [confirmnew, setConfirmnew] = useState(false)
    const [hostedRooms, setHostedRooms] = useState([]);
    const [email, setEmail] = useState("");
    const [selectedRoom, setSelectedRoom] = useState("");
    console.log('selectedRoom', selectedRoom)
    const [selectedRoomId, setSelectedRoomId] = useState("");
    console.log('selectRoomId', selectedRoomId)
    const [isdates, setIsdates] = useState(true);

    const [selectedroomprice , setSelectedroomprice] = useState([]);
    const [selecteddateref , setSelecteddateref] = useState({});
    const [excludedate , setExcludedate] = useState([]);
    const [button , setButton] = useState(true);
    console.log("startdata" , startDate , endDate);
    const calendarRef = useRef(null);

    const [startdate, setStartdate] = useState(true);
    const [enddate, setEnddate] = useState(false);
    const [price, setPrice] = useState(false)
    const [block, setBlock] = useState(false)
    // let calendarApi = calendarRef.current.getApi();
    let todayStr = new Date().toISOString().replace(/T.*$/, "");
    const onChange = (dates) => {
        //useless
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }

    //Murugavel choose only startdate Automatically enddate add + 1 day, ex : startdate - 11 , enddate - 12
    // const handleDateChange = (date) => {
    //     // debugger
    //     let start = new Date(date?.date);
    //     let room = hostedRooms?.find(e => e._id == selectedRoomId);
    //     if(!selectedRoom){setButton(false)
    //         toast.error("Please select room!");
    //         return;
    //     }
    //     if(start < new Date()){setButton(false)
    //         setStartDate("");
    //         setEndDate("");
    //         toast.error("Please select the Upcoming Dates!");
    //         return
    //     }
    //     console.log("excludedate" , excludedate);
    //     // start.setHours()
    //     var day = 60 * 60 * 24 * 1000;
    //     var ref = excludedate?.find(e => 
    //         // new Date(new Date(e?.checkInDate).getTime())?.getDate()+new Date(new Date(e?.checkInDate).getTime())?.getMonth()+new Date(new Date(e?.checkInDate).getTime())?.getFullYear() == new Date(start)?.getDate()+new Date(start)?.getMonth()+new Date(start)?.getFullYear()
    //         // && new Date(new Date(e?.checkOutDate).getTime())?.getDate()+new Date(new Date(e?.checkOutDate).getTime())?.getMonth()+new Date(new Date(e?.checkOutDate).getTime())?.getFullYear() == new Date(start.getTime() + day)?.getDate()+new Date(start.getTime() + day)?.getMonth()+new Date(start.getTime() + day)?.getFullYear()
    //         // new Date(e?.checkInDate)?.getDate()+new Date(e?.checkInDate)?.getMonth()+new Date(e?.checkInDate)?.getFullYear() == new Date(start)?.getDate()+new Date(start)?.getMonth()+new Date(start)?.getFullYear()
    //         // && new Date(e?.checkOutDate)?.getDate()+new Date(e?.checkOutDate)?.getMonth()+new Date(e?.checkOutDate)?.getFullYear() == new Date(start.getTime() + day)?.getDate()+new Date(start.getTime() + day)?.getMonth()+new Date(start.getTime() + day)?.getFullYear()
    //         new Date(e?.checkInDate).getTime() < new Date(start).getTime() &&
    //         new Date(e?.checkOutDate).getTime() > new Date(start).getTime()
    //         && new Date(e?.checkOutDate).getTime() > new Date(start.getTime() + day).getTime()&&
    //         new Date(e?.checkInDate).getTime() < new Date(start.getTime() + day).getTime()
    //     ) 

    //     console.log("ref in exclude dates" , ref , excludedate);
    //     var priceref = selectedroomprice?.length && selectedroomprice.find(e => 
    //         new Date(new Date(e?.startDate).getTime())?.getDate()+new Date(new Date(e?.startDate).getTime())?.getMonth()+new Date(new Date(e?.startDate).getTime())?.getFullYear() == new Date(start)?.getDate()+new Date(start)?.getMonth()+new Date(start)?.getFullYear()
    //         && new Date(new Date(e?.endDate).getTime())?.getDate()+new Date(new Date(e?.endDate).getTime())?.getMonth()+new Date(new Date(e?.endDate).getTime())?.getFullYear() == new Date(start.getTime() + day)?.getDate()+new Date(start.getTime() + day)?.getMonth()+new Date(start.getTime() + day)?.getFullYear()
    //         // new Date(e?.startDate)?.getDate()+new Date(e?.startDate)?.getMonth()+new Date(e?.startDate)?.getFullYear() == new Date(start)?.getDate()+new Date(start)?.getMonth()+new Date(start)?.getFullYear()
    //         // && new Date(e?.endDate)?.getDate()+new Date(e?.endDate)?.getMonth()+new Date(e?.endDate)?.getFullYear() == new Date(start.getTime() + day)?.getDate()+new Date(start.getTime() + day)?.getMonth()+new Date(start.getTime() + day)?.getFullYear()
    //         // new Date(e?.startDate).getTime() <= new Date(start).getTime()
    //         // && new Date(e?.endDate).getTime() <= new Date(start.getTime() + day).getTime()
    //         );
    //         console.log('priceref_priceref',selectedroomprice, priceref)
    //         if(priceref){setButton(false);setSelecteddateref(priceref)
    //             if(priceref?.price){setStartDate(new Date(priceref?.startDate));
    //                 setEndDate(new Date(priceref?.endDate));
    //                 // toast.error("Selected Date price is Already set!");
    //                 return
    //             }
    //         }
    //         else{
    //             setSelecteddateref({});
    //         }

    //     if(ref){setButton(false); setSelecteddateref(ref)
            
    //         if(ref?.type == "Blocked by roomHoster"){
    //             setStartDate("");
    //         setEndDate("");
    //             // toast.error("Selected Date is Already blocked!")
    //             return
    //         }
    //         else{
    //             toast.error("Room is Already booked!")
    //         }
    //     }
    //     else{
    //         setSelecteddateref({});
    //     }
    //     console.log("handle date change :" , excludedate , room?.checkintime);
    //     // var ref = excludedate?.includes(e => (new Date(e)?.getTime()  new Date(date?.date).getTime()));
    //     // console.log("handle date change :", startDate , endDate , ref);
    //     // let fs = new Date(start.setHours(new Date(room?.checkintime).getHours() , new Date(room?.checkintime).getMinutes()))
    //     // let fe = new Date(new Date(new Date(start.getTime() + day)).setHours(new Date(room?.checkouttime).getHours() , new Date(room?.checkouttime).getMinutes()))
    //     // console.log("price ref"  , fs , fe, room, ref , priceref , new Date(selectedroomprice[0]?.startDate).getTime() , excludedate , selectedroomprice ,  start.getTime());
    //     let st = date?.date;
    //     let et = new Date(new Date(start.getTime() + day));
    //     st.setHours(new Date(parseFloat(room?.checkintime)).getHours() , new Date(parseFloat(room?.checkintime)).getMinutes() , 0);
    //     et.setHours(new Date(parseFloat(room?.checkouttime)).getHours() , new Date(parseFloat(room?.checkouttime)).getMinutes(),0)
    //     setStartDate(new Date(st).getTime());
    //     setEndDate(new Date(st).getTime()+day);
    //     // setStartDate(start.getTime());
    //     // setEndDate(start.getTime() + day)
    //     setButton(true);
    //     // initial change: start by setting the startDate
    //     // if (!startDate && !endDate) {
    //     //     setStartDate(date?.date);
    //     //     setEndDate(new Date(start.getDate() + 1))
    //     //     // startDate has been set, set the end date
    //     // }
    //     // else if (startDate && !endDate && startDate < date?.date) {
    //     //     setEndDate(date?.date);
    //     //     //  setIsdates(false);
    //     // }
    //     // if (startDate && startDate > date) {
    //     //     setStartDate(date);
    //     //     // setEndDate('');
    //     // }
    //     // user is choosing another range => set the start date
    //     // and set the endDate back to null
    //     // if (startDate && endDate) {
    //     //     setStartDate(date);
    //     //     setEndDate(null);
    //     //     console.log("stardDate:", startDate, "endDate:", endDate);
    //     // }

    // }
    const handleDateChange = (date) => {
        // debugger
        setBlock(false)
        let start = new Date(date?.date);
        setPrice(false)
        let room = hostedRooms?.find(e => e._id == selectedRoomId);
        if (!selectedRoom) {
            setButton(false)
            toast.error("Please select room!");
            return;
        }
        if (start < new Date()) {
            setButton(false)
            setStartDate(null);
            setEndDate(null);
            toast.error("Please select the Upcoming Dates!");
            return
        }
        console.log("excludedate", excludedate);
        // start.setHours()
        var day = 60 * 60 * 24 * 1000;
        // Both choose only check the map so startDate != null && endDate != null
        var ref = excludedate?.find(e =>{
            console.log('ref scenario', startDate, endDate, startDate != null && endDate != null)
            if(start > new Date()) {
                if(startdate) {
                    // start - Startdate
                        if(endDate != null && endDate > start) {
                            return(
                                new Date(e?.checkInDate).getTime() < new Date(start).getTime() &&
                                new Date(e?.checkOutDate).getTime() > new Date(start).getTime()
                                && new Date(e?.checkOutDate).getTime() > new Date(endDate).getTime() &&
                                new Date(e?.checkInDate).getTime() < new Date(endDate).getTime()
                            )
                        }

                } else if(enddate) {
                    // start - Enddate
                        if (startDate != null && start > startDate) {
                            console.log('checkthecondi startdate exclude dates', e?.checkInDate, new Date(e?.checkInDate).getTime(), 
                            new Date(startDate), new Date(startDate).getTime(),
                            new Date(e?.checkInDate).getTime() < new Date(startDate).getTime() &&
                            new Date(e?.checkOutDate).getTime() > new Date(startDate).getTime());

                            console.log('checkthecondi enddate exclude dates', e?.checkOutDate, new Date(e?.checkOutDate).getTime(), 
                            new Date(start), new Date(start).getTime(),
                            new Date(e?.checkOutDate).getTime() > new Date(start).getTime() &&
                                new Date(e?.checkInDate).getTime() < new Date(start).getTime());

                            return(
                                new Date(e?.checkInDate).getTime() < new Date(startDate).getTime() &&
                                new Date(e?.checkOutDate).getTime() > new Date(startDate).getTime()
                                && new Date(e?.checkOutDate).getTime() > new Date(start).getTime() &&
                                new Date(e?.checkInDate).getTime() < new Date(start).getTime()
                            )
                        } 
                }
            }
        })

        console.log("ref in exclude dates", ref, excludedate);
        var priceref = selectedroomprice?.length && selectedroomprice.find(e => {
            if(start > new Date()) {
                if(startdate) {
                    if(endDate != null && endDate > start) {
                        return (
                            new Date(new Date(e?.startDate).getTime())?.getDate() + new Date(new Date(e?.startDate).getTime())?.getMonth() + new Date(new Date(e?.startDate).getTime())?.getFullYear() == new Date(start)?.getDate() + new Date(start)?.getMonth() + new Date(start)?.getFullYear()
                            && new Date(new Date(e?.endDate).getTime())?.getDate() + new Date(new Date(e?.endDate).getTime())?.getMonth() + new Date(new Date(e?.endDate).getTime())?.getFullYear() == new Date(endDate)?.getDate() + new Date(endDate)?.getMonth() + new Date(endDate)?.getFullYear()
                        )
                    }
                } else if(enddate) {
                    if (startDate != null && start > startDate) {
                        return (
                            new Date(new Date(e?.startDate).getTime())?.getDate() + new Date(new Date(e?.startDate).getTime())?.getMonth() + new Date(new Date(e?.startDate).getTime())?.getFullYear() == new Date(startDate)?.getDate() + new Date(startDate)?.getMonth() + new Date(startDate)?.getFullYear()
                            && new Date(new Date(e?.endDate).getTime())?.getDate() + new Date(new Date(e?.endDate).getTime())?.getMonth() + new Date(new Date(e?.endDate).getTime())?.getFullYear() == new Date(start)?.getDate() + new Date(start)?.getMonth() + new Date(start)?.getFullYear()
                        )
                    } 
                }
            }
        }
           
            // new Date(e?.startDate)?.getDate()+new Date(e?.startDate)?.getMonth()+new Date(e?.startDate)?.getFullYear() == new Date(start)?.getDate()+new Date(start)?.getMonth()+new Date(start)?.getFullYear()
            // && new Date(e?.endDate)?.getDate()+new Date(e?.endDate)?.getMonth()+new Date(e?.endDate)?.getFullYear() == new Date(start.getTime() + day)?.getDate()+new Date(start.getTime() + day)?.getMonth()+new Date(start.getTime() + day)?.getFullYear()
            // new Date(e?.startDate).getTime() <= new Date(start).getTime()
            // && new Date(e?.endDate).getTime() <= new Date(start.getTime() + day).getTime()
        );
        console.log('priceref_priceref', selectedroomprice, priceref)
        if (priceref) {
            setButton(false); setSelecteddateref(priceref); setPrice(true)
            if (priceref?.price) {
                setStartDate(new Date(priceref?.startDate));
                setEndDate(new Date(priceref?.endDate));
                // toast.error("Selected Date price is Already set!");
                return
            }
        }
        else {
            setSelecteddateref({});
        }

        if (ref) {
            {console.log('ENTER THIS   props?.blockdasdfasdf', block)}
            setButton(false); setSelecteddateref(ref);


            if (ref?.type == "Blocked by roomHoster") {
                setBlock(true)
                setStartDate(null);
                setEndDate(null);
                setStartdate(true)
                setEnddate(false)
                // toast.error("Selected Date is Already blocked!")
                return
            }
            else {
                toast.error("Room is Already booked!")
            }
        }
        else {
            setSelecteddateref({});
        }
        console.log("handle date change :", excludedate, room?.checkintime);
        // var ref = excludedate?.includes(e => (new Date(e)?.getTime()  new Date(date?.date).getTime()));
        // console.log("handle date change :", startDate , endDate , ref);
        // let fs = new Date(start.setHours(new Date(room?.checkintime).getHours() , new Date(room?.checkintime).getMinutes()))
        // let fe = new Date(new Date(new Date(start.getTime() + day)).setHours(new Date(room?.checkouttime).getHours() , new Date(room?.checkouttime).getMinutes()))
        // console.log("price ref"  , fs , fe, room, ref , priceref , new Date(selectedroomprice[0]?.startDate).getTime() , excludedate , selectedroomprice ,  start.getTime());
        let st = date?.date;
        let et = new Date(new Date(start.getTime() + day));
        st.setHours(new Date(parseFloat(room?.checkintime)).getHours(), new Date(parseFloat(room?.checkintime)).getMinutes(), 0);
        et.setHours(new Date(parseFloat(room?.checkouttime)).getHours(), new Date(parseFloat(room?.checkouttime)).getMinutes(), 0);
        console.log('ref, priceref', ref, priceref)
        console.log('startdate, endate scenerio', startdate, enddate)
        if(start > new Date()) {
            if (startdate) {
                console.log("startDate != null && endDate != null scenario", startDate, endDate,startDate != null && endDate != null, endDate > startDate)
                if (endDate != null) {
                    if (endDate > start) {
                        console.log('1111 enter this scenario')
                        setStartDate(new Date(st).getTime());
                        setStartdate(false)
                        setEnddate(true)
                    } else {
                        console.log('2222 enter this scenario')
                        toast.error("Choose Start date should be lesser than End date")
                    }
                } else {
                    console.log('3333 enter this scenario')
                    setStartDate(new Date(st).getTime());
                    setStartdate(false)
                    setEnddate(true)
                }
            } else if (enddate) {
                if (startDate != null) {
                    console.log("111 startDate != null && endDate != null", endDate, startDate, startDate != null && endDate != null, endDate > startDate)
                    if (start > startDate) {
                        setEndDate(new Date(st).getTime());
                        setStartdate(true)
                        setEnddate(false)
                    } else {
                        toast.error("Choose End date should be greater than Start date")
                    }
                } else {
                    setEndDate(new Date(st).getTime());
                    setStartdate(true)
                    setEnddate(false)
                }
            }
        }



        // setStartDate(start.getTime());
        // setEndDate(start.getTime() + day)
        setButton(true);
        // initial change: start by setting the startDate
        // if (!startDate && !endDate) {
        //     setStartDate(date?.date);
        //     setEndDate(new Date(start.getDate() + 1))
        //     // startDate has been set, set the end date
        // }
        // else if (startDate && !endDate && startDate < date?.date) {
        //     setEndDate(date?.date);
        //     //  setIsdates(false);
        // }
        // if (startDate && startDate > date) {
        //     setStartDate(date);
        //     // setEndDate('');
        // }
        // user is choosing another range => set the start date
        // and set the endDate back to null
        // if (startDate && endDate) {
        //     setStartDate(date);
        //     setEndDate(null);
        //     console.log("stardDate:", startDate, "endDate:", endDate);
        // }

    }

    const changeenddate = (date) => {console.log("change date in calenda" , date);
        var room = hostedRooms?.find(e => e._id == selectedRoomId);
        let enddate = new Date(date).setHours(new Date(parseFloat(room?.checkouttime)).getHours() , new Date(parseFloat(room?.checkouttime)).getMinutes(),0)
        setEndDate(enddate);
    }

    const getDaysArray = (start, end, price) => {

        for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
            const obj = {}
            obj.Date = new Date(dt);
            obj.price = parseInt(price);
            arr.push(obj);
            console.log("date in getDaysArray:", new Date(dt), "obj:", obj);
        }
        return arr;
    };

    const convertDaysArray = (start, end) => {
        var room = hostedRooms?.find(e => e._id == selectedRoomId);
        var st = new Date(new Date( start).setHours(new Date(parseFloat(room?.checkintime)).getHours() , new Date(parseFloat(room?.checkintime)).getMinutes() , 0));
        var et = new Date(new Date(end).setHours(new Date(parseFloat(room?.checkouttime)).getHours() , new Date(parseFloat(room?.checkouttime)).getMinutes(),0));
        console.log("Date array , dt" ,  st , et);
        for (var arr = [], dt = new Date(st); dt <= new Date(et); dt.setDate(dt.getDate() + 1)) {
          console.log('dtasdfasdf', dt)
          arr.push(new Date(dt));
          console.log('arr', arr)
        }
        return arr;
      };

      const finalarrayofobj = async(dt , price) => {
        console.log("Date array , dt" , dt);
        var ref = Date.now();
        // var dt = Date.now()
        var finalarr = []
        var room = hostedRooms?.find(e => e._id == selectedRoomId);
        // if (price == "****"){
        //     for(var i = 0 ; i+1 < dt?.length ; i ++){
        //         let ob = {};
        //         ob.checkInDate = new Date(new Date( dt[i]).setHours(new Date(parseFloat(room?.checkintime)).getHours() , new Date(parseFloat(room?.checkintime)).getMinutes() , 0));
        //         ob.checkOutDate = new Date(new Date(dt[i+1]).setHours(new Date(parseFloat(room?.checkouttime)).getHours() , new Date(parseFloat(room?.checkouttime)).getMinutes(),0));
        //         ob.type = "Blocked by roomHoster";
        //         ob.reference = (dt+1)?.toString();
        //     }
        // }
        // else {
        if(dt?.length == 1){
            let ob = {};
            ob.startDate =new Date(new Date( dt[0]).setHours(new Date(parseFloat(room?.checkintime)).getHours() , new Date(parseFloat(room?.checkintime)).getMinutes() , 0));
            let et = new Date(new Date(dt[0]).setHours(new Date(parseFloat(room?.checkouttime)).getHours() , new Date(parseFloat(room?.checkouttime)).getMinutes(),0)).setDate(new Date(dt[0]).getDate() + 1);
            ob.endDate = new Date(et);
            ob.price = price;
            ob.reference = (ref+i)?.toString();
            finalarr.push(ob);
        }
        else{
            for(var i = 0 ; i+1 < dt?.length ; i ++){
                let ob = {};
                ob.startDate =new Date(new Date( dt[i]).setHours(new Date(parseFloat(room?.checkintime)).getHours() , new Date(parseFloat(room?.checkintime)).getMinutes() , 0));
                ob.endDate = new Date(new Date(dt[i+1]).setHours(new Date(parseFloat(room?.checkouttime)).getHours() , new Date(parseFloat(room?.checkouttime)).getMinutes(),0));
                ob.price = price;
                ob.reference = (ref+i)?.toString();
                finalarr.push(ob);
            }
        }
            
        // }
        
        return finalarr;
      }

    const setPricePerDate = async (price) => {
        // console.log('price',price)
        if (startDate && endDate) {
            console.log("price from confirmAvailCont :", price, "startDate:", startDate, "endDate:", endDate);
            const datesArray = getDaysArray(startDate, endDate, price);
            const arrayofdate = convertDaysArray(startDate , endDate);
            const fdates = await finalarrayofobj(arrayofdate , price);
            console.log('datesAarraydata',datesArray , arrayofdate, fdates)
            const data = {};
            let user = getUserDatas();
            data.id = user?._id;
            data.roomId = selectedRoomId;
            data.finaldate = fdates;
            data.start = startDate;
            data.end = endDate;
            if (price == "****") {
                data.isBlocked = true
            } else {
                data.isBlocked = false
            }
            data.pricePerDay = datesArray;
            if (selectedRoomId == '') {
                toast.error("please select a room!", toastOptions);
            } 
            else if (price == '' || price == 'undefined' || price == undefined || price == null || price < 0) {
                console.log('enter this')
                toast.error('Please fill your amount')
            }
            else if (price > 0 && selectedRoomId != '') {
                await setRoomPricePerDay(data).then((result) => {
                    console.log('detausl roomprice', result)
                    if(result?.type == "error"){
                        toast.error(result?.msg);
                    }
                    else if (result.status) {
                        toast.success("Price set Successfully!", toastOptions);
                        window?.location?.reload();
                    }
                });
            }
            else if(selectedRoomId !='' && data.isBlocked == true)
            {
                console.log('selectedroomId>>>>>>',data.isBlocked,selectedRoomId)
                await setRoomPricePerDay(data).then((result) => {
                    console.log('detausl roomprice', result)
                    if(result?.type == "error"){
                        toast.error(result?.msg);
                    }
                    else if (result.status) {
                        toast.success("Blocked successfully", toastOptions);
                        window?.location?.reload();
                    }
                });
            }
            console.log("data in calendar:", data);
        }
        else {
            toast.error("Select starting and ending date", toastOptions);
        }
        // window.location.reload();
    }

   

    //   useEffect(()=>{setPricePerDate()},[startDate,endDate]);

    useEffect(() => {
        const mail = window.location.pathname.split("/")[3];
        setEmail(mail);
        getUserHostings(mail).then((data) => {
            console.log("geUserHosting data in calendar model :", data);
            setHostedRooms(data);
        })
    }, []);

    return (
        <>
            <HostHeader />
            <div className="calendar">
                {confirmnew && <ConfirmAvail 
                butn = {button} selectedref = {selecteddateref} 
                selectedroom = {selectedRoomId} price={price} block={block} onDismiss={() => setConfirmnew(false)} from={startDate} to={endDate} onSetPriceDate={(price) => { setPricePerDate(price) }}/>}
                <div className="container-fluid">
                    <div className="row mx-0">
                        <div className="col-xl-9">
                            <div className="d-flex justify-content-end stikybtns gap-2">
                                <div className="d-flex justify-content-end stikybtns gap-2 py-4">
                                    <div className={drop ? "drop-down show" : "drop-down"}>
                                        <div className="dropbtn">
                                            {console.log('dtpodsaata',drop)}
                                            <button className="btn btn-success" onClick={() => { setDrop(!drop); setDropDetails(false) }}>{selectedRoom ? selectedRoom : "Select a Room"}</button>
                                            <div className="drop-items ">

                                                <div className="itemss">
                                                    <div className="scrolablediv">
                                                        {console.log('hostedRooms', hostedRooms)}
                                                        {hostedRooms && hostedRooms.map((val) =>
                                                            <div class="form-check align-items-center">
                                                                <label class="form-check-label d-flex gap-1" for="flexRadioDefault2">
                                                                    <img src={API_URL + "/" + val.photosPath + "/" + val.photos[0].name} />
                                                                    <div>
                                                                        <p className="hostname f-14">{val.title}</p>
                                                                        <p className="f-12">Listed</p>
                                                                    </div>
                                                                </label>
                                                                <input class="form-check-input me-2" type="radio" name={val.title} checked={selectedRoom.includes(val.title)} id="flexRadioDefault2" onClick={(e) => { 
                                                                    setSelectedRoom(e.target.name); 
                                                                    setSelectedRoomId(val._id);
                                                                     getsingleroompricehooks(val?._id).then((prc)=>{
                                                                        console.log("roomprices in selected" , prc);
                                                                        setSelectedroomprice(prc.length > 0 && prc[0].pricePerDay);
                                                                    })
                                                                    let datesArray = [];
                                                                    // val.bookingInfo.map((val) => {
                                                                    //     const dates = convertDaysArray(val.checkInDate, val.checkOutDate);
                                                                    //     datesArray = datesArray.concat(dates);
                                                                    // }) 
                                                                    setExcludedate(val?.bookingInfo);
                                                                    }} />
                                                            </div>
                                                        )}

                                                        {/* <div class="form-check align-items-center">
                                            <label class="form-check-label d-flex gap-1" for="flexRadioDefault2">
                                                <img src={img1} />
                                                <div>
                                                    <p className="hostname f-14">Just for try 1jdk ghyt frthb bgyhu jitdhbh vgrfyh fgt</p>
                                                    <p className="f-12">Listed</p>
                                                </div>
                                            </label>
                                            <input class="form-check-input me-2" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                                        </div> */}
                                                        {/* <div class="form-check align-items-center">
                                            <label class="form-check-label d-flex gap-1" for="flexRadioDefault2">
                                                <img src={img1} />
                                                <div>
                                                    <p className="hostname f-14">Just for try 1jdk ghyt frthb bgyhu jitdhbh vgrfyh fgt</p>
                                                    <p className="f-12">Listed</p>
                                                </div>
                                            </label>
                                            <input class="form-check-input me-2" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                                        </div>  */}
                                                    </div>
                                                    <hr className="themehr" />
                                                    <div className="d-flex jc-between">
                                                        <Button variant="link" className="link_blue_border" onClick={() => setDrop(false)}> Close</Button>
                                                        <button onClick={() => { setDrop(false) }} className="btn btn-theme">Apply</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={dropdetails ? "drop-down show" : "drop-down"}>
                                        <div className="dropbtn">
                                            {/* <button className="btn btn-success" onClick={() => { setDropDetails(!dropdetails); setDrop(false) }}>Details. <span>4</span></button> */}
                                            <div className="drop-items">
                                                <div className="itemss">
                                                    <div className="scrolablediv">

                                                        <div class="form-check">

                                                            <label class="form-check-label" for="flexRadioDefault2">
                                                                <div>
                                                                    <p className="hostname f-14">Nightly prices</p>
                                                                    <p className="f-12 text muted"></p>
                                                                </div>
                                                            </label>

                                                            <input class="form-check-input me-2" type="checkbox" value="" id="flexCheckDefault" />
                                                        </div>
                                                    </div>

                                                    <hr className="themehr" />
                                                    <div className="d-flex jc-between">
                                                        <Button variant="link" className="text-dark" onClick={() => setDropDetails(false)}>Close</Button>
                                                        <button className="btn btn-theme">Apply</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                New host for testing
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">
                                    <div class="form-check align-items-center">
                                        <label class="form-check-label d-flex gap-1" for="flexRadioDefault2">
                                            <img src={img1} />
                                            <div>
                                                <p className="hostname f-14">Just for try 1jdk ghyt frthb bgyhu jitdhbh vgrfyh fgt</p>
                                                <p className="f-12">Listed</p>
                                            </div>
                                        </label>
                                        <input class="form-check-input me-2" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-2">
                                    <div class="form-check align-items-center">
                                        <label class="form-check-label d-flex gap-1" for="flexRadioDefault">
                                            <img src={img1} />
                                            <div>
                                                <p className="hostname f-14">Just for try</p>
                                                <p className="f-12">Listed</p>
                                            </div>
                                        </label>
                                        <input class="form-check-input me-2" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-3">
                                    <div class="form-check align-items-center">
                                        <label class="form-check-label d-flex gap-1" for="flexRadioDefault2">
                                            <img src={img1} />
                                            <div>
                                                <p className="hostname f-14">Just for try</p>
                                                <p className="f-12">Listed</p>
                                            </div>
                                        </label>
                                        <input class="form-check-input me-2" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown className="px-3 py-3">
                                    <div className="d-flex jc-between">
                                        <Button variant="link" className="text-dark">Close</Button>
                                        <button className="btn btn-theme">Apply</button>
                                    </div>
                                </Dropdown>
                            </Dropdown.Menu>
                        </Dropdown> */}
                                {/* <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                Dropdown Button
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">
                                    <div class="form-check align-items-center">
                                        <label class="form-check-label d-flex gap-1" for="flexRadioDefault2">
                                            Mothly View
                                        </label>
                                        <input class="form-check-input me-2" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                                    </div>
                                </Dropdown.Item>
                              
                                <Dropdown.Divider />
                                <Dropdown className="px-3 py-3">
                                    <div className="d-flex jc-between">
                                        <Button variant="link" className="text-dark">Close</Button>
                                        <button className="btn btn-theme">Apply</button>
                                    </div>
                                </Dropdown>
                            </Dropdown.Menu>
                        </Dropdown> */}
                                {/* <Dropdown autoClose={false}>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                               Details.<span>4</span>price
                                                <p className="f-12 text muted">Listed</p>
                                            </div>
                                        </label>
                                        <input class="form-check-input me-2" type="checkbox" name="flexRadioDefault" id="flexRadioDefault2" checked />
                                    </div>
                                </Dropdown.Item>
                                <Dropdown href="#/action-1">
                                    <div class="form-check align-items-center">
                                        <label class="form-check-label">
                                            <div>
                                                <p className="hostname f-14">Just for try 1jdk ghyt fgt</p>
                                                <p className="f-12 text muted">Lis ry 1jdk ghyt frthb bgyhu jitd ted</p>
                                            </div>
                                        </label>
                                        <input class="form-check-input me-2" type="checkbox" id="flexRadioDefault2" checked />
                                    </div>
                                    
                                </Dropdown>
                             
                                <Dropdown.Divider />
                                <Dropdown className="px-3 py-3">
                                    <div className="d-flex jc-between">
                                        <Button variant="link" className="text-dark">Close</Button>
                                        <button className="btn btn-theme">Apply</button>
                                    </div>
                                </Dropdown>
                            </Dropdown.Menu>
                        </Dropdown> */}
                            </div>
                            <div className="mt-4 mb-5">
{console.log("exclude date" , excludedate)}
                                {/* <DatePicker    wroking already
                                    selectsStart={true}
                                    onChange={(date) => handleDateChange(date)}
                                    startDate={startDate}
                                    endDate={endDate}
                                    monthsShown={10}
                                    minDate={new Date()}
                                    shouldCloseOnSelect={false}
                                    // selectsRange
                                    // selectsDisabledDaysInRange
                                    excludeDates = {excludedate}
                                    inline
                                /> */}

                                <FullCalendar
                                    // ref={calendarRef}
                                    plugins={[dayGridPlugin , interactionPlugin]}
                                    initialView='dayGridMonth'
                                    weekends={true}
                                    timeZone= 'local'
                                    fixedWeekCount={false}
                                    // height={531}
                                    // select = {
                                    //     {start: new Date(startDate),
                                    //     end: new Date(endDate)}
                                    //   }
                                    // visibleRange=  {
                                    //     {start: '2023-10-01',
                                    //     end: '2023-10-05'}
                                    //   }
                                      selectable= {true}

                                    //   initialEvents={[
                                    //     {
                                    //       id: nanoid(),
                                    //       title: "All-day event",
                                    //       start: todayStr
                                    //       // date: "2020-07-29"
                                    //     },
                                    //     {
                                    //       id: nanoid(),
                                    //       title: "Timed event",
                                    //       start: todayStr + "T12:00:00",
                                    //       end: todayStr + "T12:30:00"
                                    //       // date: "2020-07-30"
                                    //     }
                                    //   ]} // alternatively, use the `events` setting to fetch from a feed

                                    // selectsStart={true}
                                    dateClick={(date) => {console.log("onchange" , date); ;handleDateChange(date)}}
                                    // startDate={startDate}
                                    // endDate={endDate}
                                    // monthsShown={10}
                                    // minDate={new Date()}
                                    // shouldCloseOnSelect={false}
                                    // selectsRange
                                    // selectsDisabledDaysInRange
                                    // excludeDates = {excludedate}
                                    // inline
                                />

                            </div>
                        </div>
                        <div className="col-xl-3 d-xl-block d-none stikyavail stikyavail_cal">
                            <div className="shadowbox p-4 mb-3">
                            <ConfirmAvailcont selectedroom = {selectedRoomId} selectedref = {selecteddateref} change = {(date)=>changeenddate(date)} from={startDate} to={endDate} onSetPriceDate={(price) => { setPricePerDate(price) }} excludeDates = {excludedate}/>
                            </div>
                        </div>
                        <div className='fixedbtn showin1199'>
                            <button onClick={() => setConfirmnew(true)} className='btn btn-theme'>Next</button>
                        </div>

                    </div>
                </div>
            </div>
            <Toaster />
            <Footer />
        </>
    )
}

export default Calendar