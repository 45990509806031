import React, { useState } from 'react';
import {Modal,FloatingLabel,Form, Button} from 'react-bootstrap'
import Scrollbars from 'react-custom-scrollbars';




const AddVat = (props) =>{
    const[addvat , setAddVat] = useState(true)

   
    return(
        <>
      
        <Modal show={addvat} centered size="md" className='modal_style' id="firstmodal">
           <Modal.Header className='border-0 pb-0'>
               <button type="button" className="btn close close_modal" onClick={() => props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
          </Modal.Header>
          <Modal.Body className='d-scroll grayplace'>  
            <Scrollbars style={{  height: 550  }}>
                 <h5 className='modalhead text-center mb-4'>Add VAT ID Number</h5>
                 <p className='f-14 mb-2'>
                 If you are registered with the European Commission, verification may take up to 48 hours. We’ll send you an email when it’s finished.
                  More information on VAT IDs can be found <a href="#">here</a> .
                 </p>
                 <div className='h-55 mb-3'>
                     <Form.Select aria-label="Default select example">
                    <option>Open this select menu</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                    </Form.Select>
                 </div>
                
                 <FloatingLabel
                    controlId="floatingInput"
                    label="Add VAT ID Number"
                    className="mb-3"
                >
                    <Form.Control type="email" placeholder="name@example.com" />
                </FloatingLabel>
                <FloatingLabel
                    controlId="floatingInput"
                    label="Name on registration"
                    className="mb-3"
                >
                    <Form.Control type="email" placeholder="name@example.com" />
                </FloatingLabel>
                <div className='attachinput'>
                     
                        <FloatingLabel
                    controlId="floatingInput"
                    label="Address line 1"
                >
                    <Form.Control type="email" placeholder="name@example.com" />
                </FloatingLabel>
                <FloatingLabel
                    controlId="floatingInput"
                    label="Address line 1"
                    className="mb-3"
                >
                    <Form.Control type="email" placeholder="name@example.com" />
                </FloatingLabel>
                        </div>
                        <FloatingLabel
                    controlId="floatingInput"
                    label="City"
                    className="mb-3"
                   
                >
                    <Form.Control type="email"  placeholder='Enter city'/>
                </FloatingLabel>
                <FloatingLabel
                    controlId="floatingInput"
                    label="Province or region"
                    className="mb-3"
                >
                    <Form.Control type="email" placeholder="name@example.com" />
                </FloatingLabel>
                <FloatingLabel
                    controlId="floatingInput"
                    label="Postcode"
                    className="mb-3"
                >
                    <Form.Control type="email" placeholder="name@example.com" />
                </FloatingLabel>

</Scrollbars>

          </Modal.Body>
          <Modal.Footer className='jc-between'>
    <Button variant='light '>Cancel</Button>
    <Button className='btn btn-theme'>Add</Button>
</Modal.Footer>
      </Modal>
      </>
    )
}

export default AddVat