import React, { Component, useState } from 'react';
import {Modal , Button,Form} from 'react-bootstrap'
import fav from '../../../Assets/images/favicon.png'


const TermsModal = (props) =>{
    const[showterms , setShowTerms] = useState(true)

    const modals = () => {
        props.onDismiss()
        document.getElementById("viji").classList.remove("d-none")
    }
    return(
  
         <Modal show={showterms} centered scrollable className='modal_style'>
          <Modal.Header className='border-0 pb-0'>
            <button type="button" className="btn close close_modal"onClick={() => modals()}><span aria-hidden="true" className='fa fa-chevron-left f-14'></span><span class="sr-only">Close</span></button>
        </Modal.Header>
            <Modal.Body>
            <img src={fav} className="my-3"/>
            <h4 className='mb-3'>About the Community Commitment</h4>
            <h6>Why did FlutterPads create this commitment?</h6>
            <p>This commitment is an important step towards creating a global community where everyone can truly belong. Discrimination prevents hosts, guests and their families from feeling included and welcomed, and we have no tolerance for it. Building an FlutterPads where everyone can belong hinges on knowing that everyone in our community understands this mission and agrees to help us achieve it.</p>

            <h6>What if I decline the commitment?</h6>
            <p>If you decline the commitment, you won’t be able to host or book using FlutterPads, and you have the option to cancel your account. Once your account is cancelled, future booked trips will be cancelled. You will still be able to browse FlutterPads but you won’t be able to book any reservations or host any guests.</p>

            <h6>As a host, what if I have safety concerns about accepting reservations from anyone?</h6>
            <p>If you will share your living spaces with a guest, you may make a unit available only to guests of your own gender.</p>


            <h6></h6>
            <p></p>
            
            <div className='continue-social'>
                <Button className='bnt btnbord w-full' variant="outline-dark" onClick={() => modals()}>Go back</Button>
            </div>
            </Modal.Body>
        </Modal>
    )
}

export default TermsModal