import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { getExperienceFromLocal } from '../../../../Hooks/useUserExperienceHook';

const SetPrice = (props) =>{
        const[price, sestPrice] = useState("")
        const[guestcount, setGuestCount] = useState(1)
        const [localData, setLocalData] = useState({});
const putPrice = (e) =>{
        sestPrice(e.target.value)
}
const guestcounts = (e) =>{
        setGuestCount(e.target.value)
}
useEffect(()=>{
    const localdata = getExperienceFromLocal();
    setLocalData(localdata);
    if(localdata && localdata.pricePerGuest){
        sestPrice(localdata.pricePerGuest);
    }
},[]);
  
    return(
        <>
  
                <div>
                    <h2 className='conthead'>Set a price per guest</h2>
                    <p className='text-muted f-14'>Here, you can set price per guest
                    </p>
                    <div className='h-55 mt-4'>
                    <div className="input-group mb-3 input_grp_height_55 input_trans">
                    <div className="input-group-prepend">
                        <span className="input-group-text">
                        <i className="fa fa-usd" aria-hidden="true"></i>
                        </span>
                      </div>
                      <input type="number" min="1" className="form-control"
                      placeholder={"set price per guest"}
                      value={price}
                      onChange={(e) => putPrice(e)}/>
                      
                    </div>
                    {/* <p className='text-muted f-14'>*The price must be at least $817.</p> */}
                   
                   <div className='mt-4'>
                    <button className='btn btn-theme' 
                    disabled={price <= 0}
                    onClick={() => {props.count();props.onSetLocal(price,"pricePerGuest")}}>Save & Continue</button>

                    </div> 
                    </div>
                    <h2 className='conthead mt-4'>Pricing Calculator</h2>
                    <form className='form_row_col'>
                    <div className='d-sm-flex align-items-center'>
                        <p className='me-4 mb-0 text-muted left_label f-14'>Price Per Guest</p>
                        <div className='h-55'>
                            <div className="input-group mb-3 input_grp_height_55 input_trans">
                                <div className="input-group-prepend"><span className="input-group-text"><i className="fa fa-usd" aria-hidden="true"></i></span></div>
                                <input type="text" className="form-control" value={price} />
                            </div>
                        </div>
                    </div>

                    <div className='d-sm-flex align-items-center'>
                        
                        <p className='me-4 mb-0 text-muted left_label f-14'>No of Guest</p>
                  
                        <div className='h-55'>
                            <div className="input-group mb-3 input_grp_height_55">
                                <input type="number" value={guestcount} className="form-control" onChange={(e) => guestcounts(e)} />
                           </div>
                        </div>
                  
                </div>

                <div className='d-sm-flex align-items-center'>
                        
                        <p className='me-4 mb-0 text-muted left_label f-14'>Total</p>
                  
                        <div className='h-55'>
                        <div className="input-group mb-3 input_grp_height_55">
              
                  <input type="text" className="form-control" value={guestcount * price}/>
                  
                </div>
                        </div>
                  
                </div>
                </form>

                    
              </div>            
        
        </>

    )
}

export default SetPrice