import React, { Component, useState, useMemo } from "react";
import OwlCarousel from "react-owl-carousel";

import { InfoWindow } from "react-google-maps";
import { Link, useNavigate } from "react-router-dom";

import mapOne from "../../Assets/images/where/img14.png";
import mapTwo from "../../Assets/images/where/img15.png";
import mapThree from "../../Assets/images/where/img16.png";
import mapFour from "../../Assets/images/where/img17.png";
import { API_URL } from "../../config/env";

const PlaceInfoWindow = (props) => {
  const { description, name, price, refdata, setMapZoom, mapZoom } = props;
  const navigate = useNavigate();

  const [mapSelect, setMapSelect] = useState(false);

  const handleMapChoose = () => {
    // navigate(`/room/${refdata?._id}`);

    console.log("div clicked");
    setMapSelect(true);
    setMapZoom(true);

    console.log("map zoomm", mapZoom);
  };

  const handleMapClose = () => {
    console.log("clice");
    setMapZoom(false);
    setMapSelect(false);
  };
  return (
    // <InfoWindow onCloseClick={props.closeWindow}>
    <>
      <div
        className="mapvei_card position-relative "
        // onClick={() => navigate(`/room/${refdata?._id}`)}
        // onClick={setMapSelect(true)}
        onClick={handleMapChoose}
      >
        {/* <h1>{name}</h1>
            <p>{description}</p> */}
        <span>${price}</span>
      </div>
      {mapSelect && (
        <div className="mapvei_card position-relative min_card_width ">
          {/* <div className="position-absolute">
            <div className="map_wraper">
              <div className="map_wrap_slider">
                <OwlCarousel
                  className="owl-theme"
                  dots={false}
                  margin={10}
                  nav={true}
                  items={1}
                  id="big"
                >
                  <div>1</div>
                  <div>2</div>
                  <div>3</div>
                  <div />
                </OwlCarousel>
              </div>
              <div className="map_content">
                <p>Castle</p>
                <p>Korigad Fort</p>
              </div>
            </div>
          </div>
          <div className="position-absolute">2</div> */}
          <div className="where-card yellownav border_radius_left_right">
            <div className="card-img ">
              <OwlCarousel
                className="owl-theme"
                dots={false}
                margin={10}
                nav={true}
                items={1}
                id="big"
                loop={true}
              >
                {props?.refdata?.photos?.map((data , i) => <div className="item ">
                  <img src={API_URL+props?.refdata?.photosPath+"/"+data?.name} alt="location" className="img-fluid " />
                  <button className="fav-btn fa fa-heart heart_position position-absolute" />
                  <i
                    className="fa-solid fa-xmark close_icon_x"
                    onClick={handleMapClose}
                  />
                </div>)}
                {/* <div className="item ">
                  <img src={mapTwo} alt="location" className="img-fluid " />
                  <button className="fav-btn fa fa-heart heart_position position-absolute" />
                  <i
                    className="fa-solid fa-xmark close_icon_x"
                    onClick={handleMapClose}
                  />
                </div>
                <div className="item ">
                  <img src={mapThree} alt="location" className="img-fluid " />
                  <button className="fav-btn fa fa-heart heart_position position-absolute" />
                  <i
                    className="fa-solid fa-xmark close_icon_x"
                    onClick={handleMapClose}
                  />
                </div> */}
              </OwlCarousel>
            </div>

           {props?.refdata?.location && <>
              <div className="where-cont mt-3 pad_left_rig">
                <div className="location_review_between d-flex align-items-center justify-content-between">
                  <p className="name themeclr fw-600 f-14">{`${props?.refdata?.location?.city} , ${props?.refdata?.location?.state} , ${props?.refdata?.location?.countrycode}`}</p>
                  {/* <p className="review fav txtgray f-12">
                    <i className="fa fa-star me-1">
                      <span className="fa fa-star" />
                    </i>
                    5.0(33)
                  </p> */}
                </div>

                <p className="rent themeclr f-14 fw-600 card-amount-dis text-start mt-2">
                  ${props?.refdata?.price}{" "}
                  {/* <span className="date themeclr fw-600 card-date-custom f-12">
                    12 - 15 Jul
                  </span> */}
                </p>
              </div>
              <div className="text-right">
              <Link target="_blank" to={`/room/${props?.refdata?._id}`} className="nounder view_wher_caed">View</Link>
              </div>
            </>
            }

            {props?.refdata?.meetingLocation && <Link target="_blank" to={`/experience-details/${props?.refdata?._id}`} className="nounder ">
              <div className="where-cont mt-3 pad_left_rig">
                <div className="location_review_between d-flex align-items-center justify-content-between">
                  <p className="name themeclr fw-600 f-14">{`${props?.refdata?.meetingLocation?.city} , ${props?.refdata?.meetingLocation?.state} , ${props?.refdata?.meetingLocation?.countrycode}`}</p>
                  {/* <p className="review fav txtgray f-12">
                    <i className="fa fa-star me-1">
                      <span className="fa fa-star" />
                    </i>
                    5.0(33)
                  </p> */}
                </div>

                <p className="rent themeclr f-14 fw-600 card-amount-dis text-start mt-2">
                  ${props?.refdata?.price}{" "}
                  {/* <span className="date themeclr fw-600 card-date-custom f-12">
                    12 - 15 Jul
                  </span> */}
                </p>
              </div>
            </Link>}
          </div>
        </div>
      )}
    </>

    // </InfoWindow>
  );
};
export default PlaceInfoWindow;
