import React, {useState} from 'react';
// import { useSearchParams } from 'react-router-dom';
// import Card from '../Landing/Card.js';
// import Filter from '../Landing/Filter.js';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import img1 from '../../Assets/images/where/img1.png'
import img2 from '../../Assets/images/where/img2.png'
import img3 from '../../Assets/images/where/img3.png'
import img4 from '../../Assets/images/where/img4.png'
import img5 from '../../Assets/images/where/img5.png'
import img6 from '../../Assets/images/where/img6.png'
import img7 from '../../Assets/images/where/img7.png'
import img8 from '../../Assets/images/where/img8.png'
import img9 from '../../Assets/images/where/img9.png'
import img10 from '../../Assets/images/where/img10.png'
import img11 from '../../Assets/images/where/img11.png'
import img12 from '../../Assets/images/where/img12.png'
// import img13 from '../../Assets/images/where/img13.png'
// import img14 from '../../Assets/images/where/img14.png';
import Option from '../../Assets/images/option.png';
import { Freeze } from 'react-freeze';

import MyMapComponent from '../Landing/MyMapComponent.js';
const Header = React.lazy(() => import('../Common/Header'));
const Footer = React.lazy(() => import('../Common/Footer'));
const Filtermodal = React.lazy(() => import('../Common/Filter/Filtermodal.js'));


const Search = (props,{shouldSuspendRendering}) =>{
    const [showfilter, setShowfilter] = useState(false);


      
    return(
        <>
        <Header />
        {/* <Filter /> */}
        {showfilter && <Filtermodal  onDismiss={()=> setShowfilter(false)} />}
        <div className='container container_custom mt-78 mb-85 search'>
            <div className="row">
                <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div className="d-flex stays">
                <p className="me-auto sub-title">536 Stays</p>
                <button type="button" className="btn btn-default d-flex ms-auto align-items-center"  onClick={() => {setShowfilter(true)
                }}><img src={Option} alt="Filter" className="img-fluid" />&nbsp;Filters</button>
            </div>
            <div className="row">
                <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
        <div className='mt-4'>
            <div className='where-card yellownav'>
                <div className="outer card-img">
                <OwlCarousel className='owl-theme' dots={false} margin={10} nav={true} items={1} id="big">

                                <div className='item'>
                                    <img src={img1} />
                                    <button className='fav-btn fa fa-heart'></button>
                                </div>
                                <div className='item'>
                                    <img src={img2} />
                                    <button className='fav-btn fa fa-heart'></button>
                                </div>
                                <div className='item'>
                                    <img src={img3} />
                                    <button className='fav-btn fa fa-heart'></button>
                                </div>
                    
            </OwlCarousel>
</div>
               
                <div className='where-cont mt-3'>
                    <div className='d-flex justify-content-between'>
                        <p className='name themeclr fw-600 f-14'>Thailand Cottage</p> 
                        <p className='fav txtgray f-12'>New<span className='fa fa-star ms-1'></span></p>
                    </div>
                    <p className='review'><i className='fa fa-star me-1'><span className='fa fa-star'></span></i>4.94(147)</p>
                    <p className='txtgray f-12'>Designed by Gean-Michel</p>
                    <p className='date themeclr fw-600 f-12'>July 13</p>
                    <p className='rent themeclr f-14 fw-600'>$6,000 per Day</p>
                    
                </div>
            </div>
        </div>
        </div>
        <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
        <div className='mt-4'>
            <div className='where-card yellownav'>
                <div className="outer card-img">
                    
                <OwlCarousel className='owl-theme' dots={false} margin={10} nav={true} items={1} id="big">

                                <div className='item'>
                                    <img src={img4} />
                                    <button className='fav-btn fa fa-heart'></button>
                                </div>
                                <div className='item'>
                                    <img src={img5} />
                                    <button className='fav-btn fa fa-heart'></button>
                                </div>
                                <div className='item'>
                                    <img src={img6} />
                                    <button className='fav-btn fa fa-heart'></button>
                                </div>
                    
            </OwlCarousel>
</div>
               
                <div className='where-cont mt-3'>
                    <div className='d-flex justify-content-between'>
                        <p className='name themeclr fw-600 f-14'>Thailand Cottage</p> 
                        <p className='fav txtgray f-12'>New<span className='fa fa-star ms-1'></span></p>
                    </div>
                    <p className='review'><i className='fa fa-star me-1'><span className='fa fa-star'></span></i>4.94(147)</p>
                    <p className='txtgray f-12'>Designed by Gean-Michel</p>
                    <p className='date themeclr fw-600 f-12'>July 13</p>
                    <p className='rent themeclr f-14 fw-600'>$6,000 per Day</p>
                    
                </div>
            </div>
        </div>
        </div>
        <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
        <div className='mt-4'>
            <div className='where-card yellownav'>
                <div className="outer card-img">
                    
                <OwlCarousel className='owl-theme' dots={false} margin={10} nav={true} items={1} id="big">

                                <div className='item'>
                                    <img src={img7} />
                                    <button className='fav-btn fa fa-heart'></button>
                                </div>
                                <div className='item'>
                                    <img src={img8} />
                                    <button className='fav-btn fa fa-heart'></button>
                                </div>
                                <div className='item'>
                                    <img src={img9} />
                                    <button className='fav-btn fa fa-heart'></button>
                                </div>
                    
            </OwlCarousel>
</div>
               
                <div className='where-cont mt-3'>
                    <div className='d-flex justify-content-between'>
                        <p className='name themeclr fw-600 f-14'>Thailand Cottage</p> 
                        <p className='fav txtgray f-12'>New<span className='fa fa-star ms-1'></span></p>
                    </div>
                    <p className='review'><i className='fa fa-star me-1'><span className='fa fa-star'></span></i>4.94(147)</p>
                    <p className='txtgray f-12'>Designed by Gean-Michel</p>
                    <p className='date themeclr fw-600 f-12'>July 13</p>
                    <p className='rent themeclr f-14 fw-600'>$6,000 per Day</p>
                    
                </div>
            </div>
        </div>
        </div>
        <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
        <div className='mt-4'>
            <div className='where-card yellownav'>
                <div className="outer card-img">
                    
                <OwlCarousel className='owl-theme' dots={false} margin={10} nav={true} items={1} id="big">

                                <div className='item'>
                                    <img src={img10} />
                                    <button className='fav-btn fa fa-heart'></button>
                                </div>
                                <div className='item'>
                                    <img src={img11} />
                                    <button className='fav-btn fa fa-heart'></button>
                                </div>
                                <div className='item'>
                                    <img src={img12} />
                                    <button className='fav-btn fa fa-heart'></button>
                                </div>
                    
            </OwlCarousel>
</div>
               
                <div className='where-cont mt-3'>
                    <div className='d-flex justify-content-between'>
                        <p className='name themeclr fw-600 f-14'>Thailand Cottage</p> 
                        <p className='fav txtgray f-12'>New<span className='fa fa-star ms-1'></span></p>
                    </div>
                    <p className='review'><i className='fa fa-star me-1'><span className='fa fa-star'></span></i>4.94(147)</p>
                    <p className='txtgray f-12'>Designed by Gean-Michel</p>
                    <p className='date themeclr fw-600 f-12'>July 13</p>
                    <p className='rent themeclr f-14 fw-600'>$6,000 per Day</p>
                    
                </div>
            </div>
        </div>
        </div>
        <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
        <div className='mt-4'>
            <div className='where-card yellownav'>
                <div className="outer card-img">
                <OwlCarousel className='owl-theme' dots={false} margin={10} nav={true} items={1} id="big">

                                <div className='item'>
                                    <img src={img1} />
                                    <button className='fav-btn fa fa-heart'></button>
                                </div>
                                <div className='item'>
                                    <img src={img2} />
                                    <button className='fav-btn fa fa-heart'></button>
                                </div>
                                <div className='item'>
                                    <img src={img3} />
                                    <button className='fav-btn fa fa-heart'></button>
                                </div>
                    
            </OwlCarousel>
</div>
               
                <div className='where-cont mt-3'>
                    <div className='d-flex justify-content-between'>
                        <p className='name themeclr fw-600 f-14'>Thailand Cottage</p> 
                        <p className='fav txtgray f-12'>New<span className='fa fa-star ms-1'></span></p>
                    </div>
                    <p className='review'><i className='fa fa-star me-1'><span className='fa fa-star'></span></i>4.94(147)</p>
                    <p className='txtgray f-12'>Designed by Gean-Michel</p>
                    <p className='date themeclr fw-600 f-12'>July 13</p>
                    <p className='rent themeclr f-14 fw-600'>$6,000 per Day</p>
                    
                </div>
            </div>
        </div>
        </div>
        <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
        <div className='mt-4'>
            <div className='where-card yellownav'>
                <div className="outer card-img">
                    
                <OwlCarousel className='owl-theme' dots={false} margin={10} nav={true} items={1} id="big">

                                <div className='item'>
                                    <img src={img4} />
                                    <button className='fav-btn fa fa-heart'></button>
                                </div>
                                <div className='item'>
                                    <img src={img5} />
                                    <button className='fav-btn fa fa-heart'></button>
                                </div>
                                <div className='item'>
                                    <img src={img6} />
                                    <button className='fav-btn fa fa-heart'></button>
                                </div>
                    
            </OwlCarousel>
</div>
               
                <div className='where-cont mt-3'>
                    <div className='d-flex justify-content-between'>
                        <p className='name themeclr fw-600 f-14'>Thailand Cottage</p> 
                        <p className='fav txtgray f-12'>New<span className='fa fa-star ms-1'></span></p>
                    </div>
                    <p className='review'><i className='fa fa-star me-1'><span className='fa fa-star'></span></i>4.94(147)</p>
                    <p className='txtgray f-12'>Designed by Gean-Michel</p>
                    <p className='date themeclr fw-600 f-12'>July 13</p>
                    <p className='rent themeclr f-14 fw-600'>$6,000 per Day</p>
                    
                </div>
            </div>
        </div>
        </div>
        <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
        <div className='mt-4'>
            <div className='where-card yellownav'>
                <div className="outer card-img">
                    
                <OwlCarousel className='owl-theme' dots={false} margin={10} nav={true} items={1} id="big">

                                <div className='item'>
                                    <img src={img7} />
                                    <button className='fav-btn fa fa-heart'></button>
                                </div>
                                <div className='item'>
                                    <img src={img8} />
                                    <button className='fav-btn fa fa-heart'></button>
                                </div>
                                <div className='item'>
                                    <img src={img9} />
                                    <button className='fav-btn fa fa-heart'></button>
                                </div>
                    
            </OwlCarousel>
</div>
               
                <div className='where-cont mt-3'>
                    <div className='d-flex justify-content-between'>
                        <p className='name themeclr fw-600 f-14'>Thailand Cottage</p> 
                        <p className='fav txtgray f-12'>New<span className='fa fa-star ms-1'></span></p>
                    </div>
                    <p className='review'><i className='fa fa-star me-1'><span className='fa fa-star'></span></i>4.94(147)</p>
                    <p className='txtgray f-12'>Designed by Gean-Michel</p>
                    <p className='date themeclr fw-600 f-12'>July 13</p>
                    <p className='rent themeclr f-14 fw-600'>$6,000 per Day</p>
                    
                </div>
            </div>
        </div>
        </div>
        <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
        <div className='mt-4'>
            <div className='where-card yellownav'>
                <div className="outer card-img">
                    
                <OwlCarousel className='owl-theme' dots={false} margin={10} nav={true} items={1} id="big">

                                <div className='item'>
                                    <img src={img10} />
                                    <button className='fav-btn fa fa-heart'></button>
                                </div>
                                <div className='item'>
                                    <img src={img11} />
                                    <button className='fav-btn fa fa-heart'></button>
                                </div>
                                <div className='item'>
                                    <img src={img12} />
                                    <button className='fav-btn fa fa-heart'></button>
                                </div>
                    
            </OwlCarousel>
</div>
               
                <div className='where-cont mt-3'>
                    <div className='d-flex justify-content-between'>
                        <p className='name themeclr fw-600 f-14'>Thailand Cottage</p> 
                        <p className='fav txtgray f-12'>New<span className='fa fa-star ms-1'></span></p>
                    </div>
                    <p className='review'><i className='fa fa-star me-1'><span className='fa fa-star'></span></i>4.94(147)</p>
                    <p className='txtgray f-12'>Designed by Gean-Michel</p>
                    <p className='date themeclr fw-600 f-12'>July 13</p>
                    <p className='rent themeclr f-14 fw-600'>$6,000 per Day</p>
                    
                </div>
            </div>
        </div>
        </div>
        <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
        <div className='mt-4'>
            <div className='where-card yellownav'>
                <div className="outer card-img">
                <OwlCarousel className='owl-theme' dots={false} margin={10} nav={true} items={1} id="big">

                                <div className='item'>
                                    <img src={img1} />
                                    <button className='fav-btn fa fa-heart'></button>
                                </div>
                                <div className='item'>
                                    <img src={img2} />
                                    <button className='fav-btn fa fa-heart'></button>
                                </div>
                                <div className='item'>
                                    <img src={img3} />
                                    <button className='fav-btn fa fa-heart'></button>
                                </div>
                    
            </OwlCarousel>
</div>
               
                <div className='where-cont mt-3'>
                    <div className='d-flex justify-content-between'>
                        <p className='name themeclr fw-600 f-14'>Thailand Cottage</p> 
                        <p className='fav txtgray f-12'>New<span className='fa fa-star ms-1'></span></p>
                    </div>
                    <p className='review'><i className='fa fa-star me-1'><span className='fa fa-star'></span></i>4.94(147)</p>
                    <p className='txtgray f-12'>Designed by Gean-Michel</p>
                    <p className='date themeclr fw-600 f-12'>July 13</p>
                    <p className='rent themeclr f-14 fw-600'>$6,000 per Day</p>
                    
                </div>
            </div>
        </div>
        </div>
        <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
        <div className='mt-4'>
            <div className='where-card yellownav'>
                <div className="outer card-img">
                    
                <OwlCarousel className='owl-theme' dots={false} margin={10} nav={true} items={1} id="big">

                                <div className='item'>
                                    <img src={img4} />
                                    <button className='fav-btn fa fa-heart'></button>
                                </div>
                                <div className='item'>
                                    <img src={img5} />
                                    <button className='fav-btn fa fa-heart'></button>
                                </div>
                                <div className='item'>
                                    <img src={img6} />
                                    <button className='fav-btn fa fa-heart'></button>
                                </div>
                    
            </OwlCarousel>
</div>
               
                <div className='where-cont mt-3'>
                    <div className='d-flex justify-content-between'>
                        <p className='name themeclr fw-600 f-14'>Thailand Cottage</p> 
                        <p className='fav txtgray f-12'>New<span className='fa fa-star ms-1'></span></p>
                    </div>
                    <p className='review'><i className='fa fa-star me-1'><span className='fa fa-star'></span></i>4.94(147)</p>
                    <p className='txtgray f-12'>Designed by Gean-Michel</p>
                    <p className='date themeclr fw-600 f-12'>July 13</p>
                    <p className='rent themeclr f-14 fw-600'>$6,000 per Day</p>
                    
                </div>
            </div>
        </div>
        </div>
        <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
        <div className='mt-4'>
            <div className='where-card yellownav'>
                <div className="outer card-img">
                    
                <OwlCarousel className='owl-theme' dots={false} margin={10} nav={true} items={1} id="big">

                                <div className='item'>
                                    <img src={img7} />
                                    <button className='fav-btn fa fa-heart'></button>
                                </div>
                                <div className='item'>
                                    <img src={img8} />
                                    <button className='fav-btn fa fa-heart'></button>
                                </div>
                                <div className='item'>
                                    <img src={img9} />
                                    <button className='fav-btn fa fa-heart'></button>
                                </div>
                    
            </OwlCarousel>
</div>
               
                <div className='where-cont mt-3'>
                    <div className='d-flex justify-content-between'>
                        <p className='name themeclr fw-600 f-14'>Thailand Cottage</p> 
                        <p className='fav txtgray f-12'>New<span className='fa fa-star ms-1'></span></p>
                    </div>
                    <p className='review'><i className='fa fa-star me-1'><span className='fa fa-star'></span></i>4.94(147)</p>
                    <p className='txtgray f-12'>Designed by Gean-Michel</p>
                    <p className='date themeclr fw-600 f-12'>July 13</p>
                    <p className='rent themeclr f-14 fw-600'>$6,000 per Day</p>
                    
                </div>
            </div>
        </div>
        </div>
        <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
        <div className='mt-4'>
            <div className='where-card yellownav'>
                <div className="outer card-img">
                    
                <OwlCarousel className='owl-theme' dots={false} margin={10} nav={true} items={1} id="big">

                                <div className='item'>
                                    <img src={img10} />
                                    <button className='fav-btn fa fa-heart'></button>
                                </div>
                                <div className='item'>
                                    <img src={img11} />
                                    <button className='fav-btn fa fa-heart'></button>
                                </div>
                                <div className='item'>
                                    <img src={img12} />
                                    <button className='fav-btn fa fa-heart'></button>
                                </div>
                    
            </OwlCarousel>
</div>
               
                <div className='where-cont mt-3'>
                    <div className='d-flex justify-content-between'>
                        <p className='name themeclr fw-600 f-14'>Thailand Cottage</p> 
                        <p className='fav txtgray f-12'>New<span className='fa fa-star ms-1'></span></p>
                    </div>
                    <p className='review'><i className='fa fa-star me-1'><span className='fa fa-star'></span></i>4.94(147)</p>
                    <p className='txtgray f-12'>Designed by Gean-Michel</p>
                    <p className='date themeclr fw-600 f-12'>July 13</p>
                    <p className='rent themeclr f-14 fw-600'>$6,000 per Day</p>
                    
                </div>
            </div>
        </div>
        </div>
        </div>
        </div>
        
        <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 mapSection">
            <Freeze freeze={shouldSuspendRendering}>
            <div className="mapComponent">
                <MyMapComponent />
                </div>
            </Freeze>
        </div>
        </div>
        </div>
        <Footer />
        </>
    )
}


export default Search;