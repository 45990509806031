import React, {  useState } from 'react';
import {Modal} from 'react-bootstrap'
import img1 from '../../../Assets/images/where/img13.png'
import PriceDetails from '../PriceDetails';

// import Scrollbars from 'react-custom-scrollbars';

const Confirmandpaymodal = (props) =>{
    const[pay, setPay] = useState(true)
    const txt = "Adaaran Club Rannalhi, Maldives, Silver All Inclu."

    return(
        <>  
            <Modal show={pay} size="xl" centered scrollable className='modal_style'>
                <Modal.Header className='border-0 pb-0'>
                    <button type="button" className="btn close close_modal" onClick={() =>props.onDismiss()}><span aria-hidden="true" className='fa fa-times f-14'></span><span class="sr-only">Close</span></button>
                </Modal.Header>
                <Modal.Body>
                <PriceDetails bookingDetails={props.bookingDetails} roomDetails={props.roomDetails} img={img1}/>
                   {/* <AvailCont img={detailimg1} txt={txt}/> */}
                </Modal.Body>
            </Modal>
        </>
    )
}


export default Confirmandpaymodal