import React, { useState } from 'react';
import {Breadcrumb,Button, Form} from 'react-bootstrap'
import { Link } from 'react-router-dom';
import Footer from '../../../Common/Footer';
import Header from '../../../Common/Header';
import eye from '../../../../Assets/images/eye.png'
import bag from '../../../../Assets/images/bag.png'

import AccSecure from './Modals/AccSecure';

const LoginSecurity = () => {
    const[update , setUpdate] = useState(false)
    const[secure , setSecure] = useState(false)


  
    return(
        <div className='personalinfo nocenterhead'>
            {secure && <AccSecure onDismiss= {() => setSecure(false)}/>}
            <Header />
            <div className='container container_custom mt-78'>
                <div className='row justify-content-center'>
                
                    <div className='col-xl-9'>
                    <div className='breadcrum mt-4'>
                        <Breadcrumb>
                        <Breadcrumb.Item><Link to="/">Acocount</Link></Breadcrumb.Item>
                        <Breadcrumb.Item><Link to="/">Login & Secrity</Link></Breadcrumb.Item>
                        </Breadcrumb>
                        <h2>Login & security</h2>
                    </div>
                        <div className='row mt-5 justify-content-between'>
                            <div className='col-md-7'>
                                <div className='cont'>
                                   <h4 className='mb-5 textgray'>Login</h4>
                                   <div className='d-flex justify-content-between'>
                                      
                                         <p>Password</p> 
                                          <button onClick={() => setUpdate(!update)} className=' btn btn-link themeclr p-0 fw-700'>{update ? "Cancel" : "Update"} </button> 
                                    </div>    
                                    {!update &&  <p className='text-muted f-14'>Last updated 25 days ago</p>}
                                    {update && 
                                        <>
                                         
                                         <div className='loginupdate h-55'>
                                            <label className='text-muted mb-2 mt-1'>Current Password</label>
                                            <input type="password" className='form-control width-full' />
                                            <p className='err'><button className='btn btn-link ps-0 f-14 themeclr'>Need a new password?</button> </p>


                                            <label className='text-muted mt-2 mb-2'>New Password</label>
                                            <input type="password" className='form-control width-full' />

                                            <label className='text-muted mt-2 mb-2'>Confirm Password</label>
                                            <input type="password" className='form-control width-full' />
                                            <p className='err f-14 mt-2 text-danger'>Password strength: Weak </p>
                                            <button className='btn btn-theme mt-3'>Update Password</button>

                                         </div>
                                         </>
                                         }
                                     <hr className='themehr' />

                                     <h4 className='mb-5 mt-4 textgray'>Social accounts</h4>
                                   <div className='d-flex justify-content-between'>
                                    <div>
                                        <p>Facebook</p> 
                                        <p className='text-muted'>Not Connected</p>
                                    </div>
                                         
                                          <button className=' btn btn-link themeclr p-0 fw-700'>Connect</button> 
                                    </div> 
                                    <hr className='themehr' />
                                    <div className='d-flex justify-content-between'>
                                        <div>
                                            <p>Google</p> 
                                            <p className='text-muted'>Not Connected</p>
                                        </div>
                                        <button className=' btn btn-link themeclr p-0 fw-700'>Connect</button> 
                                    </div> 
                                    <hr className='themehr' />

                                    <h4 className='mb-5 mt-4 textgray'>Device History</h4>
                                   <div className='d-flex justify-content-between'>
                                    <div>
                                        <p>Facebook</p> 
                                        <p className='text-muted'>Not Connected</p>
                                    </div>
                                         
                                          <button className=' btn btn-link themeclr p-0 fw-700'>Log out device</button> 
                                    </div> 
                                    <hr className='themehr'/>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-5 d-md-block d-none '>
                                <div className='right-cont bord p-4 br-10'>
                                    <img src={bag} className="mb-3"/>
                                    <h6>Let's make your account more secure</h6>
                                    <h6>Your account security: <span className='text-muted'>Low</span> </h6>
                                    <p className='text-muted'>We’re always working on ways to increase safety in our community. That’s why we look at every 
                                    account to make sure it’s as secure as possible.</p>
                                      
                                       <button className='btn btn-theme mt-4' onClick={() => setSecure(true)}>Improve</button>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <>
                         
                                    <h4 className='mb-5 mt-4 textgray'>Account</h4>
                                   <div className='d-flex justify-content-between'>
                                    <div>
                                        <p>Deactivate your account</p> 
                                    </div>
                                         
                                          <button className=' btn btn-link text-danger p-0'>Deactivate</button> 
                                    </div> </>
                        </div>
                        <hr className='themehr mb-5'/>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default LoginSecurity