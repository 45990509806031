import React,{useState} from 'react';
import { useEffect } from 'react';
import {Modal,Button} from 'react-bootstrap'
import Scrollbars from 'react-custom-scrollbars';

const PoolDetails = (props) =>{
    const[pool , setPool] = useState(true)
    
    return(
    <>
      <Modal show={pool} scrollable centered size="md" className='listingmodals modal_style scenic'>
          <Modal.Header className='border-0 pb-0'>
            <button type="button" className="btn close close_modal" onClick={() =>props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
          </Modal.Header>
          <Modal.Body>
            <h4 className='mb-4'>Pool details</h4>
            
                <div>
               
                <h6 className='mb-3'>Privacy</h6>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Private</p>
                        </label>
                        <input class="form-check-input" type="radio" value="Air conditioning details" id="flexCheckChecked" />
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Public or shared</p>
                        </label>
                        <input class="form-check-input" type="radio" value="Air conditioning details" id="flexCheckChecked" />
                    </div>
                    
                   
                    <hr className="themehr" />

                   <h6 className='my-4'>Availability</h6>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Available all year</p>
                        </label>
                        <input class="form-check-input" type="radio" value="Air conditioning details" id="flexCheckChecked" />
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Available seasonally</p>
                        </label>
                        <input class="form-check-input" type="radio" value="Air conditioning details" id="flexCheckChecked" />
                    </div>
                    <hr className="themehr" />
                   <h6 className='mb-3'>Hours of use</h6>
                   <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Open 24 hours</p>
                        </label>
                        <input class="form-check-input" type="radio" value="Air conditioning details" id="flexCheckChecked" />
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Open specific hours</p>
                        </label>
                        <input class="form-check-input" type="radio" value="Air conditioning details" id="flexCheckChecked" />
                    </div>
                    <hr className='themehr' />
                    <h6 className='mb-3'>Access</h6>
                   <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Indoor</p>
                        </label>
                        <input class="form-check-input" type="radio" value="Air conditioning details" id="flexCheckChecked" />
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Outdoor</p>
                        </label>
                        <input class="form-check-input" type="radio" value="Air conditioning details" id="flexCheckChecked" />
                    </div>
                    <hr className='themehr' />
                    <h6 className='my-4'>Features</h6>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Heated</p>
                        </label>
                        <input class="form-check-input" type="checkbox" value="Portable air conditioning" id="flexCheckChecked"/>
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Infinity</p>
                        </label>
                        <input class="form-check-input" type="checkbox" value="Portable air conditioning" id="flexCheckChecked"/>
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Lap pool</p>
                        </label>
                        <input class="form-check-input" type="checkbox" value="Portable air conditioning" id="flexCheckChecked"/>
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Olympic-sized</p>
                        </label>
                        <input class="form-check-input" type="checkbox" value="Portable air conditioning" id="flexCheckChecked"/>
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Rooftop</p>
                        </label>
                        <input class="form-check-input" type="checkbox" value="Portable air conditioning" id="flexCheckChecked"/>
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Saltwater</p>
                        </label>
                        <input class="form-check-input" type="checkbox" value="Portable air conditioning" id="flexCheckChecked"/>
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Pool cover</p>
                        </label>
                        <input class="form-check-input" type="checkbox" value="Portable air conditioning" id="flexCheckChecked"/>
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Pool toys</p>
                        </label>
                        <input class="form-check-input" type="checkbox" value="Portable air conditioning" id="flexCheckChecked"/>
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Sun loungers</p>
                        </label>
                        <input class="form-check-input" type="checkbox" value="Portable air conditioning" id="flexCheckChecked"/>
                    </div>
                </div>
          </Modal.Body>
          <Modal.Footer className='jc-between'>
            <button className='btn btn-theme' >Save</button>
            <Button className='text-dark' variant="link" >Cancel</Button>
          </Modal.Footer>
      </Modal>
    </>
    )
}

export default PoolDetails