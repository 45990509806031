import { isEmpty } from "lodash";

const MinuteHourFormat = (data) => {
    return ((Number(isEmpty(data) ? 0 : data ) < 10 ? '0' : '')+data)
  }

  
  export const DateTimeForm = (date,datealone,timealone,ampm) => {
    try{
      if(datealone){
        return `${MinuteHourFormat(new Date(date)?.getDate())}/${MinuteHourFormat(new Date(date)?.getMonth()+1)}/${MinuteHourFormat(new Date(date)?.getFullYear())}`
      }
      else if(timealone){
        if(ampm){
          return `${MinuteHourFormat(new Date(date)?.getHours() > 12 ? new Date(date)?.getHours() - 12 : new Date(date)?.getHours()) }:${MinuteHourFormat(new Date(date)?.getMinutes())} ${new Date(date)?.getHours() >= 12 ? 'PM' : 'AM' }`
        }
        else{
          return `${MinuteHourFormat(new Date(date)?.getHours())}:${MinuteHourFormat(new Date(date)?.getMinutes())} `
        }
      }
      else if(ampm){
        return `${MinuteHourFormat(new Date(date)?.getDate())}/${MinuteHourFormat(new Date(date)?.getMonth()+1)}/${MinuteHourFormat(new Date(date)?.getFullYear())}, ${MinuteHourFormat(new Date(date)?.getHours() > 12 ? new Date(date)?.getHours() - 12 : new Date(date)?.getHours())}:${MinuteHourFormat(new Date(date)?.getMinutes()) } ${new Date(date)?.getHours() >= 12 ? 'PM' : 'AM'} `
      }
      return `${MinuteHourFormat(new Date(date)?.getDate())}:${MinuteHourFormat(new Date(date)?.getMonth()+1)}:${new Date(date)?.getFullYear()},${MinuteHourFormat(new Date(date)?.getHours())}:${MinuteHourFormat(new Date(date)?.getMinutes())} `
    }
    catch(err){
      console.log('dskhfskfs',err)
      return "No Date"
    }
  }