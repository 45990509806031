import React, {  useState } from 'react';
import {Modal, Button} from 'react-bootstrap'
import img1 from '../../../../Assets/images/where/img1.png'
import img2 from '../../../../Assets/images/where/img2.png'
import img3 from '../../../../Assets/images/where/img3.png'
import img4 from '../../../../Assets/images/where/img4.png'
import img5 from '../../../../Assets/images/where/img5.png'
import img6 from '../../../../Assets/images/where/img6.png'
import img7 from '../../../../Assets/images/where/img7.png'
import img8 from '../../../../Assets/images/where/img8.png'


const AddPhotoModal = (props) =>{
    const[addphoto , setAddPhoto] = useState(true)
    return(
        <Modal show={addphoto} centered scrollable size="xl" className='modal_style'>
        <Modal.Header className='border-0 pb-0'>
          <button type="button" className="btn close close_modal" onClick={() =>props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
      </Modal.Header>
          <Modal.Body>
            <div className='row'>
                <div className='col-md-10 justify-content-center d-flex m-auto'>
                    <div className='w-full'>
                        <h3>Bedroom</h3>
                        <p>Assign existing photos of this area</p>
                    </div>
                 
                    
                </div>
                <div className='col-md-10 justify-content-center d-flex m-auto'>
              
                <div className='photos w-full mt-4'>  
                <h5>Photos of this area</h5>
                    <div className='row'>
                        <div className='col-md-3 cont-bg'>
                            <div class="">
                                <div class="cont-checkbox">
                                    <input type="checkbox" id="myCheckbox-1" />
                                    <label for="myCheckbox-1">
                                        <img
                                        src={img1}
                                        />
                                        <span class="cover-checkbox">
                                        <svg viewBox="0 0 12 10">
                                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                        </svg>
                                        </span>
                                    
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3 cont-bg'>
                            <div class="">
                                <div class="cont-checkbox">
                                    <input type="checkbox" id="myCheckbox-2" />
                                    <label for="myCheckbox-2">
                                        <img
                                        src={img2}
                                        />
                                        <span class="cover-checkbox">
                                        <svg viewBox="0 0 12 10">
                                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                        </svg>
                                        </span>
                                    
                                    </label>
                                </div>
                            </div>
                        </div>
                        </div>
                        <h5 className='mt-4'>Unassigned photos</h5>
                        <div className='row'>
                            <div className='col-md-3 cont-bg'>
                                <div class="">
                                    <div class="cont-checkbox">
                                        <input type="checkbox" id="myCheckbox-3" />
                                        <label for="myCheckbox-3">
                                            <img
                                            src={img3}
                                            />
                                            <span class="cover-checkbox">
                                            <svg viewBox="0 0 12 10">
                                                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                            </svg>
                                            </span>
                                        
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-3 cont-bg'>
                                <div class="">
                                    <div class="cont-checkbox">
                                        <input type="checkbox" id="myCheckbox-4" />
                                        <label for="myCheckbox-4">
                                            <img
                                            src={img4}
                                            />
                                            <span class="cover-checkbox">
                                            <svg viewBox="0 0 12 10">
                                                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                            </svg>
                                            </span>
                                        
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-3 cont-bg'>
                            <div class="">
                                <div class="cont-checkbox">
                                    <input type="checkbox" id="myCheckbox-5" />
                                    <label for="myCheckbox-5">
                                        <img
                                        src={img5}
                                        />
                                        <span class="cover-checkbox">
                                        <svg viewBox="0 0 12 10">
                                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                        </svg>
                                        </span>
                                    
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3 cont-bg'>
                            <div class="">
                                <div class="cont-checkbox">
                                    <input type="checkbox" id="myCheckbox-6" />
                                    <label for="myCheckbox-6">
                                        <img
                                        src={img6}
                                        />
                                        <span class="cover-checkbox">
                                        <svg viewBox="0 0 12 10">
                                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                        </svg>
                                        </span>
                                    
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3 cont-bg'>
                            <div class="">
                                <div class="cont-checkbox">
                                    <input type="checkbox" id="myCheckbox-7" />
                                    <label for="myCheckbox-7">
                                        <img
                                        src={img7}
                                        />
                                        <span class="cover-checkbox">
                                        <svg viewBox="0 0 12 10">
                                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                        </svg>
                                        </span>
                                    
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3 cont-bg'>
                            <div class="">
                                <div class="cont-checkbox">
                                    <input type="checkbox" id="myCheckbox-8" />
                                    <label for="myCheckbox-8">
                                        <img
                                        src={img8}
                                        />
                                        <span class="cover-checkbox">
                                        <svg viewBox="0 0 12 10">
                                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                        </svg>
                                        </span>
                                    
                                    </label>
                                </div>
                            </div>
                        </div>
                        </div>
                        <hr />
                        <div className=''>
                    <Button className='btn-theme me-3'>Save</Button>
                    <Button className='btn-theme'>Cancel</Button>
                </div>
                        
                    </div>
                 
                </div>
               
                
            </div>
               
          </Modal.Body>
      </Modal>
    )
}

export default AddPhotoModal