import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import {toast, Toaster} from "react-hot-toast";
import profimg from '../../../../Assets/images/prof1.jpg'
import { toastOptions } from '../../../../config/env';
import { getExperienceFromLocal } from '../../../../Hooks/useUserExperienceHook';

const AddPhotos = (props) =>{
    const [selectedFile, setSelectedFile] = useState();
    const[editprofimg , setEditProfimg] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState();
    const [selectedFilesArray, setSelectedFilesArray] = useState([]);
    const [localPreview, setLocalPreview] = useState([]);
    const [firstImage, setFirstImage] = useState("");
    const [secondImage, setSecondImage] = useState("");
    const [thirdImage, setThirdimage] = useState("");
    const [fourthImage, setFourthimage] = useState("");
    const [fifthImage, setFifthImage] = useState("");



    const getimage = (e,index) =>{
        const files = e.target.files[0];
        var fileName = files.name;
        var idxDot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
        
    if(extFile=="jpg" || extFile=="jpeg" || extFile=="png" || extFile=="webp"){
        console.log("index",index,"e.target.files",e.target.files,"files:",files);
        setSelectedFile(e.target.files[0]);
        setSelectedIndex(index);
    }else{
        toast.error("Invalid file format !",toastOptions);
    }
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    useEffect(()=>{
        const data = getExperienceFromLocal();
        setLocalPreview(data&& data.photos && data.photos.data ? data.photos.data : []);
        console.log("preview url from local in useEffect:",data.previewUrl);
        if(data&&data.photos){
            setSelectedFilesArray(data.photos);
            setFirstImage(data.photos[0].data);
            setSecondImage(data.photos[1].data);
            setThirdimage(data.photos[2].data);
            setFourthimage(data.photos[3].data);
            setFifthImage(data.photos[4].data)
        }
    },[])

    useEffect(()=>{
        console.log("selectedFile inside useEffect:",selectedFile,"selectedIndex",selectedIndex);
        console.log("!selectedFile inside useEffect",!selectedFile);
        if (selectedFile && selectedIndex != undefined) {
            var arr = [];
            var fileArr = [];
            var photoObj = {};
            arr = editprofimg;
            fileArr = selectedFilesArray;
          
            var objectUrl = URL.createObjectURL(selectedFile)
            console.log("before updation, arr:",arr,"selectedIndex",selectedIndex,"selectedFile",selectedFile,"objectUrl",objectUrl);
           
            toBase64(selectedFile).then((data)=>{
                photoObj.data = data;
                photoObj.name = selectedFile.name;
                photoObj.size = selectedFile.size;
                photoObj.type = selectedFile.type;
                selectedFilesArray[selectedIndex] = (photoObj);
              });
           
            //   if((!arr[0] && selectedIndex == 0)||(!arr[1] && selectedIndex == 1)||(!arr[2] && selectedIndex == 2)){
            //       arr.push(objectUrl);
            //       toBase64(selectedFile).then((data)=>{
            //         photoObj.data = data;
            //         photoObj.name = selectedFile.name;
            //         photoObj.size = selectedFile.size;
            //         photoObj.type = selectedFile.type;
            //         fileArr.push(photoObj);
            //       });

            //   }
            //   if(arr[selectedIndex]){
            //       arr[selectedIndex] = objectUrl;
            //       toBase64(selectedFile).then((data)=>{
            //         photoObj.data = data;
            //         photoObj.name = selectedFile.name;
            //         photoObj.size = selectedFile.size;
            //         photoObj.type = selectedFile.type;
            //         fileArr[selectedIndex] = photoObj;
            //       });
                  
            //   }
              if(selectedIndex == 0){
                setFirstImage(objectUrl)
              }else if(selectedIndex == 1){
                setSecondImage(objectUrl);
              }else if(selectedIndex == 2){
                setThirdimage(objectUrl);
              }else if(selectedIndex == 3){
                setFourthimage(objectUrl);
              }else{
                setFifthImage(objectUrl)
              }
              
              setEditProfimg(arr);
              setSelectedFilesArray(fileArr);
              console.log("editprofimg final:",editprofimg,"arr",arr,"selectedFilesArray",selectedFilesArray);
           
          }
        //   return () => URL.revokeObjectURL(objectUrl)
    },[selectedFile,selectedIndex]);

    return(
        <>
                <div>
                    <h2 className='conthead'>Add photos for your experience</h2>

                    <p className='text-muted'>Choose the category that best describe your experience. Add a second,
                        if you think it fits into another category.
                    </p>
                    <div className='h-55 mt-4'>
                            
                    <div className='d-sm-flex flex-1 gap-3'>
                        <div className='selectimg my-sm-0 my-3'>
                           {firstImage?<img src={firstImage} />:<></>} 
                            <input type="file" onChange={(e) => getimage(e,0)}/>
                            <p>+</p>
                        </div>
                        <div className='selectimg my-sm-0 my-3'>
                           {secondImage?<img src={secondImage} />:<></>} 

                            <input type="file" onChange={(e) => getimage(e,1)}/>
                            <p>+</p>
                        </div>
                        <div className='selectimg my-sm-0 my-3'>
                           {thirdImage?<img src={thirdImage} />:<></>} 

                            <input type="file" onChange={(e) => getimage(e,2)}/>
                            <p>+</p>
                        </div>
                        <div className='selectimg my-sm-0 my-3'>
                           {fourthImage?<img src={fourthImage} />:<></>} 

                            <input type="file" onChange={(e) => getimage(e,3)}/>
                            <p>+</p>
                        </div>
                        <div className='selectimg my-sm-0 my-3'>
                           {fifthImage?<img src={fifthImage} />:<></>} 

                            <input type="file" onChange={(e) => getimage(e,4)}/>
                            <p>+</p>
                        </div>
                    </div>
                         
                   
                        <div className='mt-4'><button disabled={!(firstImage && secondImage && thirdImage && fourthImage && fifthImage)} className='btn btn-theme'  onClick={() => {props.onSetLocal(selectedFilesArray,"photos",editprofimg);props.count()}}>Save & Continue</button></div> 
                    </div>
                    <Toaster
                        position="top-center"
                        reverseOrder={false}
                        />
              </div>    
         
        </>

    )
}

export default AddPhotos