import React, { Component , useEffect,useState} from 'react';
import GoogleMapReact from 'google-map-react';


const GoogleMaps = (props) => {
  const [coords,setCoords] = useState(props.position);
  console.log('props---------------------------',props,props.position)
  const loadMap = (map, maps) => {
    let marker = new maps.Marker({
      position: props.position,
      map,
     draggable: true, 
   });
  };
  const {position} = props

  useEffect(()=>
  {
    // if(coords != props.position){
      console.log("corr : ",props.position)
    setCoords(props.position);
  // }
  },[props.position])
  return (
    <div style={{ height: '100%', width: '100%' }}>
      {coords && <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyDwQOCtNC-XQRRYsZIg5lXexgr-CrOEXrw' }}
        defaultCenter={coords&&coords}
        defaultZoom={10}
        onClick = {(e)=>{console.log("New Exact lat and long : ",e.lat,e.lng);props.onGetAddress({latitude:e.lat,longitude:e.lng}); }}
         yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) =>{console.log('jadfhgmjhasdgmfh',map,maps); coords && loadMap(map, maps)}}
        onDragEnd={(map) => console.log("data qqqqq: -------------",map)}
      />}
    </div>
  );
};

export default GoogleMaps;


// import React, { useState, useEffect } from 'react';
// import ReactDOM from 'react-dom';
// import _ from 'lodash';
// import { compose, withProps, lifecycle } from 'recompose';
// import {
//   withScriptjs,
//   withGoogleMap,
//   GoogleMap,
//   Marker,
// } from 'react-google-maps';
// import { SearchBox } from 'react-google-maps/lib/components/places/SearchBox';

// const MapWithASearchBox = compose(
//   withProps({
//     googleMapURL:
//       'https://maps.googleapis.com/maps/api/js?key=AIzaSyB5eMv-zon1iim5Ba1TCKxHl_p3iMTWPMQ&v=3.exp&libraries=geometry,drawing,places',
//     loadingElement: <div style={{ height: `100%` }} />,
//     containerElement: <div style={{ height: `400px` }} />,
//     mapElement: <div style={{ height: `100%` }} />,
//   }),
//   lifecycle({
//     useEffect(() =>{
//         const refs = {};

//         this.setState({
//           bounds: null,
//           center: {
//             lat: 41.9,
//             lng: -87.624,
//           },
//           markers: [],
//           onMapMounted: (ref) => {
//             refs.map = ref;
//           },
//           onBoundsChanged: () => {
//             this.setState({
//               bounds: refs.map.getBounds(),
//               center: refs.map.getCenter(),
//             });
//           },
//           onSearchBoxMounted: (ref) => {
//             refs.searchBox = ref;
//           },
//           onPlacesChanged: () => {
//             const places = refs.searchBox.getPlaces();
//             const bounds = new window.google.maps.LatLngBounds();
  
//             places.forEach((place) => {
//               if (place.geometry.viewport) {
//                 bounds.union(place.geometry.viewport);
//               } else {
//                 bounds.extend(place.geometry.location);
//               }
//             });
//             const nextMarkers = places.map((place) => ({
//               position: place.geometry.location,
//             }));
//             const nextCenter = _.get(
//               nextMarkers,
//               '0.position',
//               this.state.center
//             );
  
//             this.setState({
//               center: nextCenter,
//               markers: nextMarkers,
//             });
//             // refs.map.fitBounds(bounds);
//           },
//           onMarkerDragEnd: (coord, index) => {
//             const { latLng } = coord;
//             const lat = latLng.lat();
//             const lng = latLng.lng();
//             console.log(111);
//             this.setState((prevState) => {
//               const markers = [...this.state.markers];
//               markers[index] = {
//                 ...markers[index],
//                 position: { lat, lng },
//               };
//               return { markers };
//             });
//           },
//         });
//       },[]),
    
//   }),
//   withScriptjs,
//   withGoogleMap
// )((props) => (
//   <GoogleMap
//     ref={props.onMapMounted}
//     defaultZoom={15}
//     center={props.center}
//     onBoundsChanged={props.onBoundsChanged}
//   >
//     <SearchBox
//       ref={props.onSearchBoxMounted}
//       bounds={props.bounds}
//       controlPosition={window.google.maps.ControlPosition.TOP_LEFT}
//       onPlacesChanged={props.onPlacesChanged}
//     >
//       <input
//         type="text"
//         placeholder="Customized your placeholder"
//         style={{
//           boxSizing: `border-box`,
//           border: `1px solid transparent`,
//           width: `240px`,
//           height: `32px`,
//           marginTop: `27px`,
//           padding: `0 12px`,
//           borderRadius: `3px`,
//           boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
//           fontSize: `14px`,
//           outline: `none`,
//           textOverflow: `ellipses`,
//         }}
//       />
//     </SearchBox>
//     {props.markers.map((marker, index) => (
//       <Marker
//         key={index}
//         position={marker.position}
//         draggable={true}
//         onDragEnd={props.onDragMarker}
//       />
//     ))}
//   </GoogleMap>
// ));

// const GMap = () => {
//   const [markers1, Setmarkers1] = useState([
//     {
//       name: 'Current position',
//       position: {
//         lat: 37.77,
//         lng: -122.42,
//       },
//     },
//   ]);

//   const onMarkerDragEnd = (coord, index) => {
//     const { latLng } = coord;
//     const lat = latLng.lat();
//     const lng = latLng.lng();
//     console.log(lat, lng);
//     Setmarkers1({
//       ...markers1[index],
//       position: { lat, lng },
//     });
//     // this.setState(prevState => {
//     //     const markers1 = [...this.state.markers1];
//     //     markers1[index] = { ...markers1[index], position: { lat, lng } };
//     //     //return { markers1 };
//     // });
//   };

//   return (
//     <div className="App">
//       <MapWithASearchBox onDragMarker={onMarkerDragEnd.bind(this)} />
//     </div>
//   );
// };

// export default GMap;


