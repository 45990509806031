
import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import {Button,Form,InputGroup} from 'react-bootstrap'
import AvailabilityModal from './AvailabilityModal';

import PriceDetails from "../Common/PriceDetails";
import Cancellation from "../Common/Modals/CancellationModal";
import card from '../../Assets/images/visacard.png'
import img1 from '../../Assets/images/where/img13.png'
import prof from '../../Assets/images/prof.jpg'
import { getRoomUsingId, getBookingDetails } from "../../Hooks/useUserHostingHook"; 

const Header = React.lazy(() => import('../Common/Header'));
const Footer = React.lazy(() => import('../Common/Footer'));
const PaymentSuccess = () =>{

    const[bookingDetails, setBookingDetails] = useState({});
    console.log('bookingDetails',bookingDetails)
    const[roomDetails, setRoomDetails] = useState({});

    const getHotelDetails = (id) => {
        if(id){
        getRoomUsingId(id).then((data)=>{
            console.log("hotel datas from id:",data);
            setRoomDetails(data);
        })
        }
    }

    const getbookingdetails = (id) => {
        getBookingDetails(id).then((data)=>{
            if(data.isVerified == true){
            setBookingDetails(data);
            getHotelDetails(data.roomId);
            }else{
                window.location.href = window.location.origin+"/pending-orders"
            }
        })
    }

    useEffect(()=>{
        let bookingid = window.location.pathname.split("/")[2];
        if(bookingid){
        getbookingdetails(bookingid);
        }
    },[]);

    return(
        <div id="contacthost">
            {/* {cancel && <Cancellation onDismiss={() => setCancel(false)} />}
            {pay && <Confirmandpaymodal bookingDetails={bookingDetails} roomDetails={roomDetails} onDismiss={() => setPay(false)}  /> }

            {paymentGateway && <PaymentIntegrationModal bookingDetails={bookingDetails} roomDetails={roomDetails} onDismiss={() => setPaymentGateway(false)}/>} */}

            <Header />
            <div className="mh-100vh">
          

                <div className='container container_custom pagetop'>
                    {/* <div className="goback d-flex align-items-center gap-3 mb-5">
                      
                        <h3 className="mb-0"><b>Success</b></h3>
                    </div> */}
               
                    <div className="row mb-5 jc-center">
                       
                        <div className='col-12 col-lg-6 leftcont '>
                            <div className="bordbox br-10 py-5 px-3 px-sm-5 border-0">
                                <div className="successpay mb-sm-5">
                                    <span className="fa fa-check"></span>
                                    <p className="payment_sc_text">Payment Successful!</p>
                                </div>
                                <div>
                              
                              <div className="yourtrip mt-4 youtr_tp_co">
                                      <div className="d-flex jc-between mt-3">
                                          <div >
                                              <p><b>Dates</b></p>

                                              
                                          </div>
                                          <p className="text-muted">{new Date(bookingDetails.checkInDate).toLocaleDateString()}&nbsp;<span>-</span>&nbsp;{new Date(bookingDetails.checkOutDate).toLocaleDateString()}</p>

                                      
                                      </div>
                                      <div className="d-flex jc-between mt-3">
                                          <div>
                                              <p><b>Guests</b></p>
</div>
                                          <p className="text-muted">{bookingDetails.guestCount && bookingDetails.guestCount.Adults + bookingDetails.guestCount.Children} Guests</p>
                                          
                                       
                                      </div>
                                      <div className="d-flex jc-between mt-3">
                                          <div >
                                              <p><b>Amount</b></p>

                                              
                                          </div>
                                          <p className="text-muted">&nbsp;&#8377;{bookingDetails.totalAmount}/-</p>

                                      
                                      </div>
                                      <div className="d-flex jc-between mt-3">
                                          <div >
                                              <p><b>Booked by</b></p>

                                              
                                          </div>
                                          <p className="text-muted">&nbsp;{bookingDetails.email}</p>


                                      
                                      </div>
                                      <div className="d-flex jc-between mt-3">
                                          <div >
                                              <p><b>Host email</b></p>

                                              
                                          </div>
                                          <p className="text-muted">&nbsp;{bookingDetails.hostEmail}</p>

                                      
                                      </div>
                                      <div className="d-flex jc-between mt-3">
                                          <div >
                                              <p><b>Room Id</b></p>

                                              
                                          </div>
                                          <p className="text-muted">&nbsp;{bookingDetails.roomId}</p>

                                      
                                      </div>
                                      <div className="d-flex jc-between mt-3">
                                          <div >
                                              <p><b>bookingId</b></p>

                                              
                                          </div>
                                          <p className="text-muted">&nbsp;{bookingDetails._id}</p>

                                      
                                      </div>
                                      <div className="d-flex jc-between mt-3">
                                          <div >
                                              <p><b>Payment Method</b></p>

                                              
                                          </div>
                                          <p className="text-muted">&nbsp;{bookingDetails.orderType}</p>

                                      
                                      </div>
                                      <div className="d-flex jc-between mt-3">
                                          <div >
                                              <p><b>Transaction Id</b></p>

                                              
                                          </div>
                                          <p className="text-muted">&nbsp;{bookingDetails.orderId}</p>

                                      
                                      </div>
                                  </div>

                               <div className="text-center">
                                {console.log('window.location>>>>>>',window.location)}
                                  <Link onClick={()=>{window.location = `/host-dashboard/trips/stay/${bookingDetails.email}`}} className="btn btn-theme m-auto w-full my-4"> Done</Link>
                                  </div>
                                </div>
                            </div>
   
                        </div>
                                   {/* <PriceDetails img={img1}/> */}
                           {/*  bookingDetails={bookingDetails} roomDetails={roomDetails} */}
                   
                    </div>
                </div>
            </div>
            {/* <Footer /> */}
        </div>
    )
}

export default PaymentSuccess 