import React, { useEffect, useState } from "react";
import { Button,ProgressBar } from "react-bootstrap";

import Header from "../Components/Common/Header";
import Footer from "../Components/Common/Footer";


import profimg from '../Assets/images/detail/detailimg1.png'
import profimg1 from '../Assets/images/detail/detailimg2.png'
import profimg2 from '../Assets/images/detail/detailimg3.png'
import profimg3 from '../Assets/images/detail/detailimg4.png'
import { getallnotificationhooks, getUserDatas, readallhooks, readonehooks } from "../Hooks/useUserHook";





const Notification = () =>{

    const [msg , setMsg] = useState([]);


    const handlereadall = async() => {
        let user = getUserDatas()
        let readone = await readallhooks(user?._id)
        let refresh = await getnotification();
      }

      const handlereadone = async(id) => {
        let user = getUserDatas()
        let readone = await readonehooks(id)
        let refresh = await getnotification();
      }

    const getnotification = async() => {
        let user = getUserDatas()
        let notify = await getallnotificationhooks(user?._id);
        setMsg(notify?.data?.data);
    }
    useEffect(() => {
        async function fetchdata(){
           await getnotification() 
        }
        fetchdata();
    }, [])


    return(
        <div id="hostaccount" className="nocenterhead account-details">
        <Header />


     
         <div className="nofification pagetop pb-5" >
         
            <div className="container container_custom">
                <div className="row justify-content-center">
                    
                    <div className="col-md-7 pt-5">
                        <h2 className="mb-3">Notifications</h2>
                        <div className="text-right mb-4">
                        <a onClick={() => handlereadall()} className="mark_read_lik">Mark all as read</a>
                        </div>
                        <ul className="ps-0 noti_page_ul_dir">
                            {msg?.map((data) => 
                            <li className={data?.isRead ?"readnoti" : ""}>
                               
                            <div className="d-flex align-items-top justify-content-between">
                            <a href="#" className="text-dark under">
                                <div className="d-flex">
                                     {/* <img src={profimg} /> */}
                                <div>
                                <p className="mb-0">{data?.description}</p>
                                <p className="text-muted f-14 mb-0">{new Date(data?.createdAt)?.toLocaleDateString()}</p>

                                <a onClick={() => handlereadone(data?._id)} className="mark_read_lik">Mark as read</a>
                                </div>
                                </div>
                                  </a>
                                <Button variant="link" className="under text-dark"> <span className="fa fa-times"></span> </Button>
                            </div>
                         
                        </li>
                            )}
                            {/* <li>
                          
                                <div className="d-flex align-items-top justify-content-between">
                                <a href="#" className="text-dark under">
                                    <div className="d-flex">
                                    <img src={profimg1} />
                                    <div>
                                    <p className="mb-0">
Connect with Facebook to complete your profile and make it easy to log in.</p>
                                    <p className="text-muted f-14 mb-0">13 July 2022</p>
                                    
                                    <a href="#" className="mark_read_lik">Mark as read</a>
                                    </div>
                                    </div>
                                    </a>
                                    <Button variant="link" className="under text-dark"> <span className="fa fa-times"></span> </Button>
                                </div>
                              
                            </li>
                            <li>
                          
                                <div className="d-flex align-items-top justify-content-between">
                                <a href="#" className="text-dark under">
                                    <div className="d-flex">
                                    <img src={profimg2} />
                                    <div>
                                    <p className="mb-0">Please confirm your email address by clicking on the link we've just emailed you. If you cannot find the email, you can request a new confirmation email or change your email address.</p>
                                    <p className="text-muted f-14 mb-0">18 July 2022</p>
                                   
                                    <a href="#" className="mark_read_lik">Mark as read</a>
                                    </div>
                                    </div>  
                                     </a>
                                    <Button variant="link" className="under text-dark"> <span className="fa fa-times"></span> </Button>
                                </div>
                             
                            </li>
                            <li>
                          
                          <div className="d-flex align-items-top justify-content-between">
                          <a href="#" className="text-dark under">
                              <div className="d-flex">
                              <img src={profimg3} />
                              <div>
                              <p className="mb-0">Invite a friend, earn $1,650.</p>
                              <p className="text-muted f-14 mb-0">18 June 2022</p>
                              <a href="#" className="mark_read_lik">Mark as read</a>
                              
                              </div>
                              </div>  
                               </a>
                              <Button variant="link" className="under text-dark"> <span className="fa fa-times"></span> </Button>
                          </div>
                       
                      </li> */}
                           
                        </ul>
                    </div>
                </div>
            </div>
        </div> 
        <Footer />
        </div>
    )
}

export default Notification