import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import { getUserDatas } from '../../../../Hooks/useUserHook'
const Congrats = (props) => {
  const navigate = useNavigate();
  const [congrats, setCongrats] = useState(true);
  const [userDatas, setUserDatas] = useState([]);

  useEffect(() => {
    setUserDatas(getUserDatas());
  },[])
  console.log("userDatas", userDatas);
  return (
    <>
      <Modal show={congrats} centered scrollable className='modal_style ' size="md">
        <Modal.Header className='border-0 pb-0'>
          <button type="button" className="btn close close_modal" onClick={() =>props.onDismiss()}><span aria-hidden="true" className=' text-white'>×</span><span class="sr-only">Close</span></button>
          {/* <button type="button" className="btn close close_modal" ></button> */}
        </Modal.Header>
        <Modal.Body className=''>
          <div>
            <div className='p-2'>
              <h4 className='fw-500 text-heading-large'>Congratulation</h4>
              <p className='line-height-1 fw-300 text-sub-heading-medium'>Dawn takes you backstage at an aquarium where you learn about research programs with marine conservationists.</p>
              {/* <Button variant="outline-light" className='f-12 px-3 my-3 py-2 me-2' onClick={() =>props.onDismiss()}>Okay</Button> */}
              <Button variant="" className='f-12 px-3 my-3 py-2 me-2 btn-theme' onClick={() => navigate(`/host-dashboard/listings/${userDatas.email}/experiences` ,{state: "experience"})}>Okay</Button>
              {/* <Button variant="outline-light" className='f-12 px-3 my-3 py-2'>Edit experience</Button> */}
              {/* `/host-dashboard/listings/${userDatas.email}` */}
            </div>
          </div>

        </Modal.Body>
      </Modal></>
  )
}

export default Congrats                
