import React, { Component, useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { Modal, Button, Form } from 'react-bootstrap'




const NightModal = (props) => {

    const [infoNightModal, setInfoNightModal] = useState(true);
    const [bookingdates, setBookingDate] = useState([]);
    const [amountperday, setAmountPerDay] = useState('')


    useEffect(() => {
        let alldates = getDatesInRange(props?.startDate, props?.endDate)
        setBookingDate(alldates);
        let amountperday = Number(props?.rentAmount) / Number(props?.days);
        setAmountPerDay(amountperday)
    }, [])


    function getDatesInRange(startDate, endDate) {
        const date = new Date(startDate.getTime());
        const dates = [];
        while (date <= endDate) {
            dates.push(new Date(date));
            date.setDate(date.getDate() + 1);

        }
        return dates;
    }





    return (

        //         <Modal show={infoNightModal} centered scrollable className='modal_style'>
        //           <Modal.Header className='border-0 pb-0'>
        //             <button type="button" className="btn close close_modal" onClick={() =>props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
        //         </Modal.Header>
        //             <Modal.Body>
        //                 <h6>Booking Night(s)</h6>

        // <div className='mt-4'>
        //     {props?.spl?.length > 0 ? props?.spl?.map((dat) => 
        //     <div className='shadowbox p-3 mb-3'>
        //     <h6 className='mt-2'>Check-in Date</h6>
        //     <p className='f-14 mb-2'>{new Date(dat?.startDate)?.toLocaleDateString()}
        //     </p>

        //     <h6 className='mt-2'>Check-out Date</h6>
        //     <p className='f-14 mb-2'>{new Date(dat?.endDate)?.toLocaleDateString()}
        //     </p>
        //     <h6 className='mt-2'>Price</h6>
        //     <p className='f-14 mb-2'>{dat?.price}
        //     </p>
        //     </div>) : <p className='text-danger'><b>Special price not found for selected dates!</b></p>}


        //     {/* <div className='shadowbox p-3  mb-3'>

        //                 <h6 className='mt-2'>Check-in Date</h6>
        //                 <p className='f-14 mb-2'>22-Oct-2023
        //                 </p>

        //                 <h6 className='mt-2'>Check-out Date</h6>
        //                 <p className='f-14 mb-2'>22-Oct-2023
        //                 </p>
        //                 <h6 className='mt-2'>Price</h6>
        //                 <p className='f-14 mb-2'>22-Oct-2023
        //                 </p>

        //         </div>


        //                 <div className='shadowbox p-3 mb-3'>

        //                 <h6 className='mt-2'>Check-in Date</h6>
        //                 <p className='f-14 mb-2'>22-Oct-2023
        //                 </p>

        //                 <h6 className='mt-2'>Check-out Date</h6>
        //                 <p className='f-14 mb-2'>22-Oct-2023
        //                 </p>
        //                 <h6 className='mt-2'>Price</h6>
        //                 <p className='f-14 mb-2'>22-Oct-2023
        //                 </p>

        //         </div> */}
        //                 </div>

        //                             <div className='text-end'>
        //                     <Button className='btn-theme' onClick={() =>props.onDismiss()}>Done</Button>
        //                 </div>

        //             </Modal.Body>
        //         </Modal>

        <Modal show={infoNightModal} centered scrollable className='modal_style'>
            <Modal.Header className='border-0 pb-0'>
                <button type="button" className="btn close close_modal" onClick={() => props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
            </Modal.Header>
            <Modal.Body>
                <h6>Base Price Breakdown</h6>

                <div className='mt-4'>
                    {bookingdates && bookingdates?.length > 0 && bookingdates?.map((val, ind) => {
                        console.log('bookingdatesval', val)
                        if (val) {
                            return (
                                <div className='shadowbox p-3 mb-3'>
                                    <h6 className='mt-2 custom_breakdown d-flex align-items-center justify-content-between'><span>{new Date(val).toLocaleDateString()}</span> <span>${amountperday.toFixed(2)}</span></h6> </div>
                            )


                        }

                        // if(val)
                    })}
                    {/* {props?.spl?.length > 0 ? props?.spl?.map((dat) => 
    <div className='shadowbox p-3 mb-3'>
    <h6 className='mt-2'>Check-in Date</h6>
    <p className='f-14 mb-2'>{new Date(dat?.startDate)?.toLocaleDateString()}
    </p>

    <h6 className='mt-2'>Check-out Date</h6>
    <p className='f-14 mb-2'>{new Date(dat?.endDate)?.toLocaleDateString()}
    </p>
    <h6 className='mt-2'>Price</h6>
    <p className='f-14 mb-2'>{dat?.price}
    </p>
    </div>) : <p className='text-danger'><b>Special price not found for selected dates!</b></p>} */}


                    {/* <div className='shadowbox p-3  mb-3'>
                
                <h6 className='mt-2'>Check-in Date</h6>
                <p className='f-14 mb-2'>22-Oct-2023
                </p>

                <h6 className='mt-2'>Check-out Date</h6>
                <p className='f-14 mb-2'>22-Oct-2023
                </p>
                <h6 className='mt-2'>Price</h6>
                <p className='f-14 mb-2'>22-Oct-2023
                </p>

        </div>


                <div className='shadowbox p-3 mb-3'>

                <h6 className='mt-2'>Check-in Date</h6>
                <p className='f-14 mb-2'>22-Oct-2023
                </p>

                <h6 className='mt-2'>Check-out Date</h6>
                <p className='f-14 mb-2'>22-Oct-2023
                </p>
                <h6 className='mt-2'>Price</h6>
                <p className='f-14 mb-2'>22-Oct-2023
                </p>

        </div> */}
                </div>
                <h6 className='mt-4 mb-4 d-flex aign-items-center justify-content-between'><span className='ms-3'>Total Base Price</span> <span className='me-3'>${props?.rentAmount}</span></h6>
                <div className='text-end'>
                    <Button className='btn-theme' onClick={() => props.onDismiss()}>Done</Button>
                </div>

            </Modal.Body>
        </Modal>

    )
}

export default NightModal