
import React, { useState } from 'react';


const AccService = () =>{

return(<>
      <div className='row mt-5 justify-content-between'>
        <div className='col-md-7'>
        <h4>Connected services</h4>
            <p className='text-muted f-14'>Show services that you’ve connected to your FlutterPads account</p>
            <div className='mt-4'>
            <p className='text-muted f-14'>No services connected at the moment</p>

            </div>
            
               
                            </div>
                            <div className='col-lg-4 col-md-5 d-md-block d-none '>
                    <div className='right-cont bord p-4 br-10'>
                      <h6>Committed to privacy</h6>
                       <p className='text-muted f-14'>FlutterPads is committed to keeping your data protected. Read details in our 
                       <a href="#" className='text-dark'> Privacy Policy</a>.</p>
          </div>
                            </div>
                        </div>
                    
    </>
)
}
export default AccService