import React, { useEffect, useState } from "react";
import { Form, Accordion } from "react-bootstrap";
import Counter from "./counter";
import { useNavigate } from "react-router-dom";
import { FcApproval } from "react-icons/fc";
import { API_URL } from "../../config/env";

const PriceDetails = (props) => {
  const [filter, setFilter] = useState("");
  const navigate = useNavigate();
  // console.log('props_PriceDetails', props?.filter)
  useEffect(() => {
    setFilter(props?.filter);
  }, [props]);
  const navigatehosthome = () => {
    navigate("/confirmandpay");
  };
  return (
    <>
      {filter == "reservebooking" ? (
        <div className="personal-info-right-side-sec pricepernight jc-between gap-3 mb-4 align-items-center">
          <div className="bord p-4">
            <div className="d-flex pricepernight gap-3 mb-4">
              {props.roomDetails.photos ? (
                <img
                  src={`${API_URL}${props.roomDetails.photosPath}/${
                    props.roomDetails.photos[0].name
                  }`}
                />
              ) : (
                <img src={props.img} />
              )}
              <div>
                <p>Room Title : {props?.roomDetails?.title}</p>
                <p className="mb-0">
                  {" "}
                  <span>
                    <b>{props.bookingDetails.nights} &nbsp;</b>
                  </span>
                  <span>nights</span>
                </p>
                <p className="f-12">{props.txt}</p>
              </div>
            </div>

            <hr className="themehr" />
            <h5 className="mb-4">Price Details</h5>
            <div className="">
              <p className="d-flex jc-between f-14 mb-2">
                <span>
                  ${props.roomDetails.price} X {props.bookingDetails.nights}{" "}
                  Nights
                </span>
                <span>
                  $
                  {parseInt(props.roomDetails.price) *
                    parseInt(props.bookingDetails.nights)}
                </span>
              </p>
              <p className="d-flex jc-between f-14">
                <span>Service fee</span>
                <span>${props.bookingDetails.serviceFeeAmount}</span>
              </p>
            </div>
            <hr />
            <p className="d-flex jc-between f-14 pb-4 fw-500">
              <span>Total fee</span>

              <span>${props.bookingDetails.totalAmount}</span>
            </p>
          </div>
        </div>
      ) : (
        filter == "splitreservebooking" && (
          <>
            {props?.splitbookingdetails &&
              props?.splitbookingdetails?.splitPaymentDetails?.length > 0 &&
              props?.splitbookingdetails?.splitPaymentDetails?.map(
                (val, ind) => (
                  <div className="personal-info-right-side-sec pricepernight jc-between gap-3 mb-4 align-items-center">
                    <div className="bord p-4">
                      <div className="d-flex pricepernight gap-3 mb-4">
                        {props.roomDetails.photos ? (
                          <img
                            src={`${API_URL}${props.roomDetails.photosPath}/${
                              props.roomDetails.photos[0].name
                            }`}
                          />
                        ) : (
                          <img src={props.img} />
                        )}
                        <div>
                          <p>Room Title : {props?.roomDetails?.title}</p>
                          <p>Nights : {val?.nights}</p>
                          <p className="f-12">{props.txt}</p>
                        </div>
                        <span className="room_status_icons">
                          {val?.status == "Completed" && <FcApproval size={25} />}
                        </span>
                      </div>

                      <hr className="themehr" />
                      <h5 className="mb-4">Price Details</h5>
                      <div className="">
                        <p className="d-flex jc-between f-14 mb-2">
                          <span>
                            ${props.roomDetails.price} X {val?.nights} Nights
                          </span>
                          <span>
                            $
                            {parseInt(props.roomDetails.price) *
                              parseInt(val?.nights)}
                          </span>
                        </p>
                        <p className="d-flex jc-between f-14">
                          <span>Service fee</span>
                          <span>
                            ${props.splitbookingdetails.serviceFeeAmount}
                          </span>
                        </p>
                        {/* <p className='d-flex jc-between f-14 mb-2'>
                                

                                        <span>${val?.status} </span>

                                    </p> */}
                      </div>
                      <hr />
                      <p className="d-flex jc-between f-14 pb-4 fw-500">
                        <span>Total fee</span>

                        <span>
                          $
                          {parseInt(props.roomDetails.price) *
                            parseInt(val?.nights)}
                        </span>
                      </p>
                    </div>
                  </div>
                )
              )}
          </>
        )
      )}
    </>
  );
};

export default PriceDetails;
