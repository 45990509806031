import React, {  useState } from 'react';
import {Modal,ProgressBar,InputGroup,Form} from 'react-bootstrap'

// import Scrollbars from 'react-custom-scrollbars';



import prof from '../../../Assets/images/prof.jpg'
import prof1 from '../../../Assets/images/prof1.jpg'
import prof2 from '../../../Assets/images/prof2.jpg'
import prof3 from '../../../Assets/images/prof3.jpg'


const ReviewModal = (props) =>{
    const[review, setReview] = useState(true)

    return(
        <>  
            <Modal show={review} size="xl" centered scrollable className='modal_style'>
                <Modal.Header className='border-0 pb-0'>
                   <div className='w-full searchreview'> 
                     <button type="button" className="btn close close_modal" onClick={() =>props.onDismiss()}><span aria-hidden="true" className='fa fa-times f-14'></span><span class="sr-only">Close</span></button>
                   <div className='d-xl-flex w-full jc-between align-items-center'>
                   <h3><b><span className='fa fa-star f-16'></span> {props.overAllRating&&props.overAllRating.overAllReview&&(props.overAllRating.overAllReview).toFixed(1)} ({props.overAllRating&&props.overAllRating.users} reviews)</b></h3> 
                   {/* <InputGroup className="mb-3 w-50">
        <InputGroup.Text id="basic-addon1"><span className='fa fa-search'></span></InputGroup.Text>
        <Form.Control
          placeholder="search"
          aria-label="Username"
          aria-describedby="basic-addon1"
        />
      </InputGroup> */}

                   </div></div>
                </Modal.Header>
                <Modal.Body className='px-sm-4 px-3'>
                  {/* <Scrollbars style={{height:420}}> */}
                    <div className='container px-0'> 
                       <div className='row jc-between pb-3'>
                         <div className='col-xl-5'>
                          
                            <div className='d-flex align-items-center justify-content-between mb-3'>
                                <p className='reviews_text f-14'>Cleanliness</p>
                                <div className='d-flex align-items-center'>
                                <ProgressBar now={props.ratings&&props.ratings.cleanliness&&(props.ratings.cleanliness*10)*2} className="progress_review" />
                                <span className='ms-2'>{props.ratings&&props.ratings.cleanliness?(props.ratings.cleanliness).toFixed(1):0}</span>
                                </div>

                            </div>
                            <div className='d-flex align-items-center justify-content-between mb-3'>
                                <p className='reviews_text f-14'>Communication</p>
                                <div className='d-flex align-items-center'>
                                <ProgressBar now={props.ratings&&props.ratings.communication&&(props.ratings.communication*10)*2} className="progress_review" />
                                <span className='ms-2'>{props.ratings&&props.ratings.communication?(props.ratings.communication).toFixed(1):0}</span>
                                </div>

                            </div>
                            <div className='d-flex align-items-center justify-content-between mb-3'>
                                <p className='reviews_text f-14'>Check-in</p>
                                <div className='d-flex align-items-center'>
                                <ProgressBar now={props.ratings&&props.ratings.checkIn&&(props.ratings.checkIn*10)*2} className="progress_review" />
                                <span className='ms-2'>{props.ratings&&props.ratings.checkIn?(props.ratings.checkIn).toFixed(1):0}</span>
                                </div>

                            </div>
                            <div className='d-flex align-items-center justify-content-between mb-3'>
                                <p className='reviews_text f-14'>Accuracy</p>
                                <div className='d-flex align-items-center'>
                                <ProgressBar now={props.ratings&&props.ratings.accuracy&&(props.ratings.accuracy*10)*2} className="progress_review" />
                                <span className='ms-2'>{props.ratings&&props.ratings.accuracy?(props.ratings.accuracy).toFixed(1):0}</span>
                                </div>

                            </div>
                            <div className='d-flex align-items-center justify-content-between mb-3'>
                                <p className='reviews_text f-14'>Location</p>
                                <div className='d-flex align-items-center'>
                                <ProgressBar now={props.ratings&&props.ratings.location&&(props.ratings.location*10)*2} className="progress_review" />
                                <span className='ms-2'>{props.ratings&&props.ratings.location?(props.ratings.location).toFixed(1):0}</span>
                                </div>

                            </div>
                            <div className='d-flex align-items-center justify-content-between mb-3'>
                                <p className='reviews_text f-14'>Value</p>
                                <div className='d-flex align-items-center'>
                                <ProgressBar now={props.ratings&&props.ratings.value&&(props.ratings.value*10)*2} className="progress_review" />
                                <span className='ms-2'>{props.ratings&&props.ratings.value?(props.ratings.value).toFixed(1):0}</span>
                                </div>

                            </div>
                         </div>
                         <div className='col-xl-6 mt-xl-0 mt-4'>
                            {props.reviewDatas&&props.reviewDatas.map((value)=>
                            <div className='mt-4'>
                            <div className="d-flex mb-2">
                                <div className="flex-shrink-0">
                                    <img src={prof} className="desc_icon" alt="Sample Image" />
                                </div>
                                <div className="flex-grow-1 ms-3">
                                    <p className='name themeclr fw-600 f-14'>{value.name}</p>
                                    <p className='detail_desc_grey'>{new Date(value.date).toLocaleDateString()}</p>
                                </div>
                            </div>
                            <p className='f-14'> {value.description}</p>
                        </div>
                            )}
                            

                            {/* <div className='mt-4'>
                                <div className="d-flex mb-2">
                                    <div className="flex-shrink-0">
                                        <img src={prof1} className="desc_icon" alt="Sample Image" />
                                    </div>
                                    <div className="flex-grow-1 ms-3">
                                        <p className='name themeclr fw-600 f-14'>Charlotte</p>
                                        <p className='detail_desc_grey'>June 2022</p>
                                    </div>
                                </div>
                                <p className='f-14'>Philipe was extremely helpful organising the entire trip right from booking to organising our pickup 
                                    and drop to airport. We had a person waiting with name placard right outside on arrival. Entire 
                                    booking was managed through (Hidden by Airbnb) communication and airbnb. Easy work. They have 6
                                     houses with 2 bed each. But for 2 people or 12 people its the same. So best for couples as well as
                                     family reunion. No joiners or outsiders. Private thats the best part.</p>
                            </div>
                            <div className='mt-4'>
                                <div className="d-flex mb-2">
                                    <div className="flex-shrink-0">
                                        <img src={prof2} className="desc_icon" alt="Sample Image" />
                                    </div>
                                    <div className="flex-grow-1 ms-3">
                                        <p className='name themeclr fw-600 f-14'>Charlotte</p>
                                        <p className='detail_desc_grey'>June 2022</p>
                                    </div>
                                </div>
                                <p className='f-14'>We absolutely loved this place. The place is absolutely beautiful and looks just like the pictures to a T. 
                                    They were very accommodating and nice. We loved how they adjusted to our eating schedule. The food was amazing. 
                                    Everything was amazing. We found our favorite spot and we will be back again in February! Best family vacation
                                    spot EVER! Also the snorkeling 🤿 was amazing. The water is clear as it gets.</p>
                            </div>
                            <div className='mt-4'>
                                <div className="d-flex mb-2">
                                    <div className="flex-shrink-0">
                                        <img src={prof3} className="desc_icon" alt="Sample Image" />
                                    </div>
                                    <div className="flex-grow-1 ms-3">
                                        <p className='name themeclr fw-600 f-14'>Charlotte</p>
                                        <p className='detail_desc_grey'>June 2022</p>
                                    </div>
                                </div>
                                <p className='f-14'>We absolutely loved this place. The place is absolutely beautiful and looks just like the pictures to a T. 
                                    They were very accommodating and nice. We loved how they adjusted to our eating schedule. The food was amazing. 
                                    Everything was amazing. We found our favorite spot and we will be back again in February! Best family vacation
                                    spot EVER! Also the snorkeling 🤿 was amazing. The water is clear as it gets.</p>
                            </div> */}
                        
                      
                         </div>
                       </div>
                   </div>
                </Modal.Body>
            </Modal>
        </>
    )
}


export default ReviewModal