import React, {useState} from 'react';
import {useNavigate, Link } from 'react-router-dom';
import {Button,Accordion} from 'react-bootstrap'
import AddPhotoModal from '../Modals/AddPhotoModal';

const OrganizePhotos = () =>{
    const history = useNavigate();
    const[addphoto , setAddPhoto] = useState(false)

    return(
       <div className='container container_custom doublecheck mb-85'>
        {addphoto && <AddPhotoModal onDismiss={() => setAddPhoto(false)}/>}
        <button className='backbtn my-5' onClick={() => {history('/hosting/areaforguest/doublecheck') }}><span className='fa fa-chevron-left me-2'></span> Back</button>
        <div className='row justify-content-center'>
            <div className='col-xl-5 col-lg-6 col-md-8'>
                <h3>Organise your photos</h3>
                <p className='mb-5'>Assign existing photos to each room</p>
               
               <div className='bord px-3 py-2'>
                <div className='d-md-flex '>
                    <div className='f-14'>
                     <span> Bedroom <span>(4)</span></span>  <span>·</span>
                     <span>  Full bathroom  <span>(4)</span></span>  <span>·</span>
                     <span> Toilet with sink <span>(4)</span></span>  <span>·</span>
                     <span> Living room <span>(4)</span></span>  <span>·</span>
                     <span> Exterior <span>(4)</span></span>  
                    </div>
                    <div>
                        <Button variant="link" className='text-dark f-14 pl-inherit ps-0'>
                            Edit rooms and spaces
                        </Button>
                    </div>
                </div>
               </div>
              
               <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Bedroom</Accordion.Header>
                        <Accordion.Body>
                             <div className=''>
                             
                                <div className='d-flex justify-content-between'>
                                    <div>
                                       <p>No Photos</p>
                                    </div>
                                    <Button onClick={() => setAddPhoto(true)} variant='outline-secondary' className='tedt-dark'>Add Photos</Button>
                                </div>
                               
                             </div>
                        </Accordion.Body>
                    </Accordion.Item>
                
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Bedroom 2</Accordion.Header>
                        <Accordion.Body>
                        <div className=''>
                             
                             <div className='d-flex justify-content-between'>
                                 <div>
                                    <p>No Photos</p>
                                 </div>
                                 <Button variant='outline-secondary' className='tedt-dark'>Add Photos</Button>
                             </div>
                            
                          </div>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="2">
                        <Accordion.Header>Bedroom 3</Accordion.Header>
                        <Accordion.Body>
                        <div className=''>
                             
                             <div className='d-flex justify-content-between'>
                                 <div>
                                    <p>No Photos</p>
                                 </div>
                                 <Button variant='outline-secondary' className='tedt-dark'>Add Photos</Button>
                             </div>
                            
                          </div>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="3">
                        <Accordion.Header>Bedroom 4</Accordion.Header>
                        <Accordion.Body>
                        <div className=''>
                             
                             <div className='d-flex justify-content-between'>
                                 <div>
                                    <p>No Photos</p>
                                 </div>
                                 <Button variant='outline-secondary' className='tedt-dark'>Add Photos</Button>
                             </div>
                            
                          </div>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="4">
                        <Accordion.Header>Full bathroom</Accordion.Header>
                        <Accordion.Body>
                        <div className=''>
                             
                             <div className='d-flex justify-content-between'>
                                 <div>
                                    <p>No Photos</p>
                                 </div>
                                 <Button variant='outline-secondary' className='tedt-dark'>Add Photos</Button>
                             </div>
                            
                          </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                        <Accordion.Header>Full bathroom 2</Accordion.Header>
                        <Accordion.Body>
                        <div className=''>
                             
                             <div className='d-flex justify-content-between'>
                                 <div>
                                    <p>No Photos</p>
                                 </div>
                                 <Button variant='outline-secondary' className='tedt-dark'>Add Photos</Button>
                             </div>
                            
                          </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                        <Accordion.Header>Toilet with sink</Accordion.Header>
                        <Accordion.Body>
                        <div className=''>
                             
                             <div className='d-flex justify-content-between'>
                                 <div>
                                    <p>No Photos</p>
                                 </div>
                                 <Button variant='outline-secondary' className='tedt-dark'>Add Photos</Button>
                             </div>
                            
                          </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7">
                        <Accordion.Header>Living room</Accordion.Header>
                        <Accordion.Body>
                        <div className=''>
                             
                             <div className='d-flex justify-content-between'>
                                 <div>
                                    <p>No Photos</p>
                                 </div>
                                 <Button variant='outline-secondary' className='tedt-dark'>Add Photos</Button>
                             </div>
                            
                          </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="8">
                        <Accordion.Header>Exterior</Accordion.Header>
                        <Accordion.Body>
                        <div className=''>
                             
                             <div className='d-flex justify-content-between'>
                                 <div>
                                    <p>No Photos</p>
                                 </div>
                                 <Button variant='outline-secondary' className='tedt-dark'>Add Photos</Button>
                             </div>
                            
                          </div>
                        </Accordion.Body>
                    </Accordion.Item>

                    </Accordion>
                 
            
            </div>
        </div>
      
        <div className='fixedbtn'> 
            <Link to="/hosting/areaforguest/doublecheck" className='btn btn-theme'>Next</Link>
        </div>
       </div>
    )
}

export default OrganizePhotos