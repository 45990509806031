import React, {  useState } from 'react';
import {Modal} from 'react-bootstrap'

import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

// import Scrollbars from 'react-custom-scrollbars';

import detailimg1 from '../../../Assets/images/detail/detailimg1.png'
import detailimg2 from '../../../Assets/images/detail/detailimg2.png'
import detailimg3 from '../../../Assets/images/detail/detailimg3.png'
import detailimg4 from '../../../Assets/images/detail/detailimg4.png'
import detailimg5 from '../../../Assets/images/detail/detailimg5.png'

import { API_URL } from '../../../config/env';





const ShowAllImg = (props) =>{
    const[showallimg, setShowAllImg] =useState(true)
  
    return(
        <>  
            <Modal show={showallimg} size="lg" centered scrollable className='modal_style modal_view_img'>
                <Modal.Header className='border-0 pb-0'>
                    <button type="button" className="btn close close_modal" onClick={() =>props.onDismiss()}><span aria-hidden="true" className='fa fa-times f-14'></span><span class="sr-only">Close</span></button>
                </Modal.Header>
                <Modal.Body>
                <OwlCarousel className='owl-theme showallimg showallimg_heiht' dots={false} items={1} margin={10} nav={true} id="big" >
                    {console.log("props.photosPath :",props.photosPath,"props.photos",props.photos,"API_URL:",API_URL)}
                    {props.photos.map((data,index)=>
                    {if(data?.name?.split(".")[1]!="mp4"){
                        return(<div className='item'>
                        {console.log("`${API_URL}${props.photosPath}/${data.name}`",`${API_URL}${props.photosPath}/${data.name}` , data?.name?.split(".")[1]!="mp4")}
                    <img src={`${API_URL}${props.photosPath}/${data.name}`} />
                 </div>)
                    }}
                    //     {data?.name?.split(".")[1]!="mp4" &&<div className='item'>
                    //         {console.log("`${API_URL}${props.photosPath}/${data.name}`",`${API_URL}${props.photosPath}/${data.name}` , data?.name?.split(".")[1]!="mp4")}
                    //     <img src={`${API_URL}${props.photosPath}/${data.name}`} />
                    //  </div>}
                    )}
                </OwlCarousel>
               
                   
                </Modal.Body>
            </Modal>
        </>
    )
}


export default ShowAllImg