import React, { useState } from 'react';
import {Modal, Button, ProgressBar} from 'react-bootstrap'
import Scrollbars from 'react-custom-scrollbars';

import OfferSelf from './AddModal/OfferSelf';

import trolly from '../../../../../Assets/images/trolly.png'
import pc from '../../../../../Assets/images/pc.png'
import tick from '../../../../../Assets/images/tick.png'



const FlexStayModal = (props) =>{
    const[flexstay , setFlexStay] = useState(true)
    const[second , setSecond] = useState(false)

    const modaOptions = () =>{
       setSecond(true);
       setFlexStay(false);
    //    props.onDismiss()
      
    }
    const closemodal=()=>{props.onDismiss()}
   
    return(
        <>
        { second && <OfferSelf onDismiss={()=>setSecond(false) } closemodal={closemodal} />}
        <Modal show={flexstay} centered size="md" className='modal_style' id="firstmodal">
           <Modal.Header className='border-0 pb-0'>
               <button type="button" className="btn close close_modal" onClick={() =>props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
  
          </Modal.Header>
          <Modal.Body className='d-scroll'>  
            <Scrollbars style={{  height: 500  }}>
             <div className='insight-modals'>
           
                <h2><b>Flexible stays</b></h2>
                <p>Guests are looking for easy-to-book, last-minute stays. Searches for these features have 
                    increased by 114% in your area compared to last year.</p>
                     <div className='modal-prog'>
                        <div className='d-flex align-items-center mt-3 mb-4'>
                            <ProgressBar now={0} />
                            <p className='f-12 ms-2'><span>1</span>/2</p>
                        </div>
                     </div>
                     <div className='todos'>
                        <p className='text-muted f-14 mb-3'><b>TO DO</b></p>
                        <ul>
                            <li>
                                <button  className='d-flex' onClick={() => modaOptions() }>
                                    <div className='d-flex align-items-center'>
                                        <img src={trolly} className="icon"/>
                                        <div className='text-start'>
                                            <p className='mb-0'><b>Offer self check-in</b></p>
                                            <p className='text-muted f-14'>Lockboxes for easy entry</p>
                                        </div>
                                    </div>
                                    <span className='fa fa-chevron-right'></span>
                                </button>
                            </li>
                         
                        </ul>
                     </div>

                     <div className='completed'>
                       <p className='text-muted f-14 mb-4 mt-4'><b>COMPLETED</b></p>
                       <ul>
                        <li>
                            <div className='d-flex align-items-center'>
                                <img src={tick} className="icon" />
                                <p className='mb-0 f-14'><b>Let guests book instantly</b></p>
                            </div>
                        </li>
                        <li>
                            <div className='d-flex align-items-center'>
                                <img src={tick} className="icon" />
                                <p className='mb-0 f-14'><b>Switch to a flexible cancellation policy</b></p>
                            </div>
                        </li>
                        <li>
                            <div className='d-flex align-items-center'>
                                <img src={tick} className="icon" />
                                <p className='mb-0 f-14'><b>Lower your advance notice</b></p>
                            </div>
                        </li>
                       </ul>

                     </div>
              
                     
             </div>
               </Scrollbars>
          </Modal.Body>
      </Modal>
      </>
    )
}

export default FlexStayModal