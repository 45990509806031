
import React, { Component, useCallback, useEffect, useState } from 'react';
import { propTypes } from 'react-bootstrap/esm/Image';

import Scrollbars from 'react-custom-scrollbars';

import img1 from '../../../Assets/images/filter/beaches.png'
import img2 from '../../../Assets/images/filter/bed.png'
import img3 from '../../../Assets/images/filter/camp.png'
import img4 from '../../../Assets/images/filter/cave.png'
import img5 from '../../../Assets/images/filter/design.png'
import img6 from '../../../Assets/images/filter/island.png'
import img7 from '../../../Assets/images/filter/lake.png'
import img8 from '../../../Assets/images/filter/national.png'
import img9 from '../../../Assets/images/filter/omg.png'
import img10 from '../../../Assets/images/filter/pools.png'
import img11 from '../../../Assets/images/filter/tiny.png'

import { API_URL } from '../../../config/env';
const Amenities = (props) => {
    console.log('props jije',props)
    const [amenitiesList, setAmenitiesList] = useState(localStorage.getItem("amenitiesIndex") ? 
                                JSON.parse(localStorage.getItem("amenitiesIndex")) :[]); 
    console.log('amenitiesList',amenitiesList)
    const [selectedIndex, setSelectedIndex] = useState(localStorage.getItem("amenities") ? JSON.parse(localStorage.getItem("amenities")) : []);
    const [checked ,setChecked] = useState(false)
    var category = [];
    //  const setData = useCallback(async (val,index,path) => {
      
    //     let pathData = {imagePath:path}

    //     if(!amenitiesList.includes(val)){
    //         setAmenitiesList([...amenitiesList,val])
    //         setSelectedIndex([...selectedIndex,index])
    //         console.log("[...selectedIndex,index]",[...selectedIndex,index]);
    //         localStorage.setItem("amenitiesIndex",JSON.stringify([...selectedIndex,index]));
    //     }
    //     else if(amenitiesList.includes(val)){
           

    //         const filteredArray = amenitiesList.filter((item) => item !== val);
    //          const filteredIndex = selectedIndex.filter((item) => item !== index);
    //         setAmenitiesList(filteredArray)
    //         setSelectedIndex(filteredIndex)
    //         localStorage.setItem("amenitiesIndex",JSON.stringify(filteredIndex));
    //          }
             
    //            props.onGetAmenities(amenitiesList);
    // },[selectedIndex,amenitiesList])

    const setData = async(val,index,path) => {
        category = amenitiesList;
        var index = selectedIndex;
        console.log("val in path" , val , index , path , category);
        if(!category?.includes(val?.type)){
            console.log("val in include" , val?.type);
            category?.push(val?.type);
            index?.push(val);
            setAmenitiesList(category);
            setSelectedIndex(index);
            console.log("Category :",category);
            localStorage.setItem("amenitiesIndex",JSON.stringify(category));
        }
        else if( category?.includes(val?.type)){
            console.log("val in not  include" , val?.type);
           let inx = category?.indexOf(val?.type);
           if(inx>-1){
            category?.splice(inx , 1);
            index?.splice(inx,1);
            setAmenitiesList(category);
            setSelectedIndex(index);
           }

            console.log("category :",category);
            localStorage.setItem("amenitiesIndex",JSON.stringify(category));
             }
             console.log('onGetAmenities daiii',index)
             props.onGetAmenities(index)
             setChecked(!checked)
    }
      console.log('localStorage.getItem("amenities")',localStorage.getItem("amenities"))
    useEffect(()=>{
        props?.onstatus();
        localStorage.getItem("amenitiesIndex") &&setAmenitiesList(JSON.parse(localStorage.getItem("amenitiesIndex")));
        // localStorage.getItem("amenities") &&setSelectedIndex(JSON.parse(localStorage.getItem("amenities")));

        // setSelectedIndex((localStorage.getItem("amenitiesIndex"))?JSON.parse(localStorage.getItem("amenitiesIndex")):selectedIndex);
        (localStorage.getItem("amenities"))?props.onGetAmenities(JSON.parse(localStorage.getItem("amenities"))):localStorage.removeItem("amenitiesIndex")
    },[selectedIndex , amenitiesList,localStorage.getItem("amenities"),localStorage.getItem("amenitiesIndex")]);

    // const setData = useCallback(async (val,index,path) => {
    //     console.log("set data", val , index , path);
    //     let arrayData = [];
    //     let arrayIndex = [];
    //     arrayData = amenitiesList;
    //     arrayIndex = selectedIndex;

    //     let pathData = {imagePath:path}

    //     if(!arrayData.includes(val)){
    //         alert("1")
    //         console.log("value " , val)
    //     arrayData.push(val);
    //     arrayIndex.push(index);

    //     }
    //     else if(arrayData.includes(val)){
    //         alert("2")

    //         const filteredArray = arrayData.filter((item) => item !== val);
    //         console.log("filteredArray",filteredArray)
    //         arrayData=(filteredArray);
    //         const filteredIndex = arrayIndex.filter((item) => item !== index);
    //         console.log("filteredIndex",filteredIndex)

    //         arrayIndex=(filteredIndex);

    //     }
    //     console.log("arrayIndex",arrayIndex);
    //     setAmenitiesList(arrayData);
    //     setSelectedIndex(arrayIndex);
    //     // dummyArr.push(arrayIndex)
        
    //     // props.onGetAmenities(arrayData);
    // },[selectedIndex,setAmenitiesList])
console.log("selectedIndex",selectedIndex)
    // useEffect(()=>{
        
    // },[selectedIndex]);

    return(
        <div className='col-md-112'>
        <div className='rightcont rightcont placetype mb-5'>
      <Scrollbars style={{ height: "calc(100vh - 320px)"}} className="scroll">

                <h4 className='mb-2 ms-3'>Do you have any standout aminities?</h4>
                <div className='row  px-3  align-items-center pb-5 mt-4' data-aos="fade-up">
                {console.log('props.data',props.data.content)}
                    {props.data && props.data.content.map((val,index)=>
                       <>
                       {console.log("indedx : " , val,val?.type)}
                        {val?.status != "Inactive" && <div className={(amenitiesList && amenitiesList?.includes(val?.type))?'col-md-4 amenities active':'col-md-4 amenities '}>
                            {/* {console.log("indedx : " , index ,selectedIndex.includes(index))} */}
                        <button onClick={()=>{setData(val,index,props.data.path)}} className=''>
                            <img src={API_URL+props.data.path+"/"+val.image} />
                            <p>{val.type}</p>
                        </button>
                    </div>}
                    </>
                    )}
                    

                </div> 
            </Scrollbars>
        </div>
        </div>
    )
}

export default Amenities