import React from "react";
import { Bar } from "react-chartjs-2";

const Barchart = (props) =>{
    const { data, highlight, domain } = props;

    // calculate frequency of data
    var counts = {};
    for (var i = 0; i < data.length; i++)
      counts[data[i]] = counts[data[i]] + 1 || 1;

    // generate data
    const barDataValues = [];
    for (let i = 0; i < domain[1]; i++) {
      barDataValues.push(counts[i] || 0);
    }
    const barData = {
      labels: barDataValues.map((val, i) => i),
      datasets: [
        {
          backgroundColor: barDataValues.map((val, i) =>
            i >= highlight[0] && i <= highlight[1]
              ? "#acb4bc"
              : "#dddddd"
          ),
          hoverBackgroundColor: "#dddddd",
          data: barDataValues
        }
      ]
    };

    const options = {
      responsive: true,
      legend: {
        display: false
      },
      scales: {
        xAxes: [
          {
            display: false,
            barPercentage: 1.2,
            categorySpacing: 5
          }
        ],
        yAxes: [
          {
            display: false,
            ticks: {
              min: 0
            }
          }
        ]
      }
    }
    return (
   
     <Bar data={barData} options={options} />

    )
}

export default Barchart;
