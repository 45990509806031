import React,{useState} from 'react';
import { useEffect } from 'react';
import {Modal,Button} from 'react-bootstrap'
import Scrollbars from 'react-custom-scrollbars';

const SkiDetails = (props) =>{
    const[ski , setSki] = useState(true)

    // props.conts(actype)
    return(
        
    <>
      <Modal show={ski} centered scrollable size="md" className='modal_style scenic listingmodals'>
          <Modal.Header className='border-0 pb-0'>
            <button type="button" className="btn close close_modal" onClick={() =>props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
          </Modal.Header>
          <Modal.Body>
            <h4 className='mb-0'>Ski-in/Ski-out details</h4>
            <p className='text-muted'>Guests can access ski lifts without driving or taking paid transport</p>
            {/* <Scrollbars style={{  height: 430  }}> */}
                <div>
                    <h6 className='mt-3 mb-4 '>Location</h6>
                   
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>On a free shuttle line</p>
                        </label>
                        <input class="form-check-input" type="radio" value="Air conditioning details" id="flexCheckChecked" />
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Near ski lifts</p>
                        </label>
                        <input class="form-check-input" type="radio" value="Air conditioning details" id="flexCheckChecked" />
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>On a ski run</p>
                        </label>
                        <input class="form-check-input" type="radio" value="Air conditioning details" id="flexCheckChecked" />
                    </div>
                   
                </div>
            {/* </Scrollbars> */}
          </Modal.Body>
          <Modal.Footer className='jc-between mt-4'>
            <button className='btn btn-theme' >Save</button>
            <Button className='text-dark' variant="link" >Cancel</Button>
          </Modal.Footer>
      </Modal>
    </>

    )
}

export default SkiDetails