import React, { useState } from 'react';
import {Modal, Button,Form} from 'react-bootstrap'

const SpecialOffer = (props) =>{
    const[specialoffer, setSpecialOffer] = useState(true)
    return(
        <Modal show={specialoffer} centered scrollable className='modal_style' size="md">
        <Modal.Header className='border-0 pb-0'>
          <button type="button" className="btn close close_modal" onClick={() =>props.onDismiss()}><span aria-hidden="true">×</span></button>
      </Modal.Header>
          <Modal.Body>
          <div className='bg-white h-55'>
              <h4>Send Viji a special offer</h4>
              <p>Viji will have 24 hours to book. In the meantime, your calendar will remain open.</p>
              <h6 className='mt-4 mb-3'>Listing</h6>
                <div className='select withlab'>
                    <p>Current Region</p>
                    <Form.Select aria-label="Default select example" className='br-6'>
                        <option>India(+91)</option>
                        <option value="1">India(+91)</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                    </Form.Select>
                </div> 
                <div className='selectdate mt-3'>
                    {/* <input type="date" className='form-control' />
                    <input type="date" className='form-control' /> */}
                    <div className='d-flex flex-1 dates '>
                            <Form.Group className="position-relative" controlId="exampleForm.ControlInput1">
                                <Form.Label className='text-muted'>Check-in</Form.Label>
                                <Form.Control type="date" placeholder="name@example.com" />
                            </Form.Group>
                            <Form.Group className="position-relative" controlId="exampleForm.ControlInput1">
                                <Form.Label className='text-muted'>Check-out</Form.Label>
                                <Form.Control type="date" placeholder="name@example.com" />
                            </Form.Group>
                            </div>
                </div>
                <div className='select withlab mt-3'>
                    <p>Guests</p>
                    <Form.Select aria-label="Default select example" className='br-6'>
                        <option>1</option>
                        <option value="1">2</option>
                        <option value="2">3</option>
                        <option value="3">4</option>
                    </Form.Select>
                </div> 
                <input type="text" className='form-control mt-3 br-6' placeholder='subtotal'/>


          </div>
          </Modal.Body>
          <Modal.Footer className='d-flex justify-content-between'>
            <Button className='btn-theme'>Send special Offer </Button>     
          </Modal.Footer>
      </Modal>
    )
}

export default SpecialOffer