import React, { useEffect, useState } from 'react';
import HostHeader from '../../HostHeader';
import prof from  '../../../../../Assets/images/prof.jpg'
import Footer from '../../../../Common/Footer';
import { hostavaliablebalancehook, successbookingdata } from '../../../../../Hooks/filterHook';
import { getUserDatas, getUserEmail } from '../../../../../Hooks/useUserHook';
import Withdrawmodal from "./Modal/withdrawmodal";


const TripPayment = () =>{
    const[success, setSuccess] = useState(true)
    const[pending, setPending] = useState(false)
    const [avaliablebalance , setAvaliablebalance] = useState()
    const [received , setReceived] = useState();
    const [room , setRoom] = useState();
    const [experience , setExperience] = useState();
    const [withdrawmodal , setWithdrawmodal] = useState(false);
    const userdata = getUserDatas();

    useEffect(()=>{
        // setWithdrawmodal(false);
        console.log("userdata" , userdata);
        var email = getUserEmail();
        async function fetchdata(){
            await hostavaliablebalancehook(email).then((res)=>{
                console.log("res" , res);
                setAvaliablebalance(res?.data?.balance);
                setReceived(res?.data?.withdrawn)
            });
            await successbookingdata(email).then((res1) => {
                console.log("res1" , res1);
                setRoom(res1?.data?.room);
                setExperience(res1?.data?.experience);
            })
        }
        fetchdata();
    },[])

    return(
        <>
        <div className='trips tripcancel'>
        <HostHeader />
        {withdrawmodal && <Withdrawmodal avaliablebalance = {avaliablebalance} onDismiss = {()=>setWithdrawmodal(false)}></Withdrawmodal>}
          <div className='container container_custom mh-100vh-header'>
                     <div className='shadowbox p-3 my-4'>
                            <div className='d-lg-flex jc-between align-items-center'>
                                <h6 className='mb-0'><span className='themeclr'>Total Recieved Payment</span>: <span className='text-muted'>{received}</span></h6>
                                <h6 className='my-4'><span className='themeclr'>Total Pending Payment</span>: <span className='text-muted'>{avaliablebalance}</span></h6>
                               <div className='togglebtn toggle_ho_scr'> 
                               <button className={ withdrawmodal ? "btn active me-2" : "btn  me-2" } onClick={() => {setWithdrawmodal(true)}}>Withdraw</button>
                               <button className={success ? "active btn me-2" : "btn me-2"} onClick={() => {setSuccess(true);setPending(false)}}>Rooms</button>
                                <button className={pending ? "active btn" : "btn " } onClick={() => {setSuccess(false);setPending(true)}}>Experiences</button></div>
                            </div>
                        </div>
      
                        <div className={success ? 'paymentss' : "d-none"}>
                            <div className='row'>
                                {room?.map((data)=><>
                                    <div className='col-lg-4 col-md-6 mb-3'>
                                    <div className='shadowbox p-4 br-5'> 
                                        <div className='d-flex align-items-center'>
                                            <img src={prof} className="prof" />
                                            <h6 className='mb-0'>Hi, {userdata?.firstName}</h6>
                                        </div>
                                        <div className='graybox br-0 mt-3 py-3 mobi_fle_ger'>
                                            <div className='d-flex jc-between'>
                                                <h6 className='mb-3 f-14'>
                                                    <p className='themeclr'>Room Title</p>
                                                     <p className='text-muted'>{data?.roomTitle}</p></h6>
                                            </div>
                                            <div className='d-flex jc-between'>
                                            <h6 className='mb-3 f-14'><p className='themeclr'>Room ID</p>
                                            <p className='text-muted'>{data?.roomId}</p></h6>

                                            </div>

                                            <div className='d-flex jc-between'>

                                                <h6 className='mb-3 f-14'><p className='themeclr'>Check in</p> <p className='text-muted'>{new Date(data?.checkInDate).toLocaleDateString()}</p></h6>
                                            </div>

                                            <div className='d-flex jc-between'>

                                                <h6 className='mb-0 f-14'><p className='themeclr'>Check out</p> <p className='text-muted'>{new Date(data?.checkOutDate).toLocaleDateString()}</p></h6>
                                            </div>
                                            
                                        </div>
                                        <h6 className='mb-3 f-14 mt-3 d-flex jc-between'><span className='themeclr'>Price :</span> <span className='text-muted'>{parseFloat(data?.totalAmount) - parseFloat(data?.serviceFeeAmount)}</span></h6>
                                        <hr className='themeclr' />
                                        <h6 className='mb-3 f-14 mt-3 d-flex jc-between'><span className='themeclr'>Status:</span> <span className='success-box'>Success</span></h6>
                                    </div>
                                </div>
                                </>)}
                                
                                {/* <div className='col-lg-4 col-md-6 mb-3'>
                                    <div className='shadowbox p-4 br-5'> 
                                        <div className='d-flex align-items-center'>
                                            <img src={prof} className="prof" />
                                            <h6 className='mb-0'>Hi, Jhon</h6>
                                        </div>
                                        <div className='graybox br-10 mt-3 py-3'>
                                            <div className='d-flex jc-between'>
                                                <h6 className='mb-3 f-14'><span className='themeclr'>Room Title</span>: <span className='text-muted'>Mad Room</span></h6>
                                                <h6 className='mb-3 f-14'><span className='themeclr'>Room ID</span>: <span className='text-muted'>J5421</span></h6>
                                            </div>
                                            <div className='d-flex jc-between'>
                                                <h6 className=' f-14'><span className='themeclr'>Check in:</span> <span className='text-muted'>12.11.2022</span></h6>
                                                <h6 className=' f-14'><span className='themeclr'>Check out:</span> <span className='text-muted'>14.11.2022</span></h6>
                                            </div>
                                            
                                        </div>
                                        <h6 className='mb-3 f-14 mt-3 d-flex jc-between'><span className='themeclr'>Check out:</span> <span className='text-muted'>$1000</span></h6>
                                        <hr className='themeclr' />
                                        <h6 className='mb-3 f-14 mt-3 d-flex jc-between'><span className='themeclr'>Status:</span> <span className='success-box'>Success</span></h6>
                                            
                                          
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-6 mb-3'>
                                    <div className='shadowbox p-4 br-5'> 
                                        <div className='d-flex align-items-center'>
                                            <img src={prof} className="prof" />
                                            <h6 className='mb-0'>Hi, Jhon</h6>
                                        </div>
                                        <div className='graybox br-10 mt-3 py-3'>
                                            <div className='d-flex jc-between'>
                                                <h6 className='mb-3 f-14'><span className='themeclr'>Room Title</span>: <span className='text-muted'>Mad Room</span></h6>
                                                <h6 className='mb-3 f-14'><span className='themeclr'>Room ID</span>: <span className='text-muted'>J5421</span></h6>
                                            </div>
                                            <div className='d-flex jc-between'>
                                                <h6 className=' f-14'><span className='themeclr'>Check in:</span> <span className='text-muted'>12.11.2022</span></h6>
                                                <h6 className=' f-14'><span className='themeclr'>Check out:</span> <span className='text-muted'>14.11.2022</span></h6>
                                            </div>
                                            
                                        </div>
                                        <h6 className='mb-3 f-14 mt-3 d-flex jc-between'><span className='themeclr'>Check out:</span> <span className='text-muted'>$1000</span></h6>
                                        <hr className='themeclr' />
                                        <h6 className='mb-3 f-14 mt-3 d-flex jc-between'><span className='themeclr'>Status:</span> <span className='success-box'>Success</span></h6>
                                            
                                          
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-6 mb-3'>
                                    <div className='shadowbox p-4 br-5'> 
                                        <div className='d-flex align-items-center'>
                                            <img src={prof} className="prof" />
                                            <h6 className='mb-0'>Hi, Jhon</h6>
                                        </div>
                                        <div className='graybox br-10 mt-3 py-3'>
                                            <div className='d-flex jc-between'>
                                                <h6 className='mb-3 f-14'><span className='themeclr'>Room Title</span>: <span className='text-muted'>Mad Room</span></h6>
                                                <h6 className='mb-3 f-14'><span className='themeclr'>Room ID</span>: <span className='text-muted'>J5421</span></h6>
                                            </div>
                                            <div className='d-flex jc-between'>
                                                <h6 className=' f-14'><span className='themeclr'>Check in:</span> <span className='text-muted'>12.11.2022</span></h6>
                                                <h6 className=' f-14'><span className='themeclr'>Check out:</span> <span className='text-muted'>14.11.2022</span></h6>
                                            </div>
                                            
                                        </div>
                                        <h6 className='mb-3 f-14 mt-3 d-flex jc-between'><span className='themeclr'>Check out:</span> <span className='text-muted'>$1000</span></h6>
                                        <hr className='themeclr' />
                                        <h6 className='mb-3 f-14 mt-3 d-flex jc-between'><span className='themeclr'>Status:</span> <span className='success-box'>Success</span></h6>
                                            
                                          
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-6 mb-3'>
                                    <div className='shadowbox p-4 br-5'> 
                                        <div className='d-flex align-items-center'>
                                            <img src={prof} className="prof" />
                                            <h6 className='mb-0'>Hi, Jhon</h6>
                                        </div>
                                        <div className='graybox br-10 mt-3 py-3'>
                                            <div className='d-flex jc-between'>
                                                <h6 className='mb-3 f-14'><span className='themeclr'>Room Title</span>: <span className='text-muted'>Mad Room</span></h6>
                                                <h6 className='mb-3 f-14'><span className='themeclr'>Room ID</span>: <span className='text-muted'>J5421</span></h6>
                                            </div>
                                            <div className='d-flex jc-between'>
                                                <h6 className=' f-14'><span className='themeclr'>Check in:</span> <span className='text-muted'>12.11.2022</span></h6>
                                                <h6 className=' f-14'><span className='themeclr'>Check out:</span> <span className='text-muted'>14.11.2022</span></h6>
                                            </div>
                                            
                                        </div>
                                        <h6 className='mb-3 f-14 mt-3 d-flex jc-between'><span className='themeclr'>Check out:</span> <span className='text-muted'>$1000</span></h6>
                                        <hr className='themeclr' />
                                        <h6 className='mb-3 f-14 mt-3 d-flex jc-between'><span className='themeclr'>Status:</span> <span className='success-box'>Success</span></h6>
                                            
                                          
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-6 mb-3'>
                                    <div className='shadowbox p-4 br-5'> 
                                        <div className='d-flex align-items-center'>
                                            <img src={prof} className="prof" />
                                            <h6 className='mb-0'>Hi, Jhon</h6>
                                        </div>
                                        <div className='graybox br-10 mt-3 py-3'>
                                            <div className='d-flex jc-between'>
                                                <h6 className='mb-3 f-14'><span className='themeclr'>Room Title</span>: <span className='text-muted'>Mad Room</span></h6>
                                                <h6 className='mb-3 f-14'><span className='themeclr'>Room ID</span>: <span className='text-muted'>J5421</span></h6>
                                            </div>
                                            <div className='d-flex jc-between'>
                                                <h6 className=' f-14'><span className='themeclr'>Check in:</span> <span className='text-muted'>12.11.2022</span></h6>
                                                <h6 className=' f-14'><span className='themeclr'>Check out:</span> <span className='text-muted'>14.11.2022</span></h6>
                                            </div>
                                            
                                        </div>
                                        <h6 className='mb-3 f-14 mt-3 d-flex jc-between'><span className='themeclr'>Check out:</span> <span className='text-muted'>$1000</span></h6>
                                        <hr className='themeclr' />
                                        <h6 className='mb-3 f-14 mt-3 d-flex jc-between'><span className='themeclr'>Status:</span> <span className='success-box'>Success</span></h6>
                                            
                                          
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className={pending ? ' paymentss ' : "d-none"}>
                        <div className='row'>
                            {experience?.map((data) => <>
                                <div className='col-lg-4 col-md-6 mb-3'>
                                    <div className='shadowbox p-4 br-5'> 
                                        <div className='d-flex align-items-center'>
                                            <img src={prof} className="prof" />
                                            <h6 className='mb-0'>Hi, {userdata?.firstName}</h6>
                                        </div>
                                        <div className='graybox br-0 mt-3 py-3 mobi_fle_ger'>
                                            <div className=''>
                                                <h6 className='mb-3 f-14'><p className='themeclr'>Experience Title</p><p className='text-muted'>{data?.experienceTitle}</p></h6>
                                                <h6 className='mb-3 f-14'><p className='themeclr'>Room ID</p> <p className='text-muted'>{data?.experienceId}</p></h6>
                                            </div>
                                            <div className='d-flex jc-between'>
                                                <h6 className='f-14'><p className='themeclr'>Event Date</p> <p className='text-muted'>{new Date(data?.date)?.toLocaleDateString()}</p></h6>
                                                {/* <h6 className=' f-14'><span className='themeclr'>Check out:</span> <span className='text-muted'>14.11.2022</span></h6> */}
                                            </div>
                                            
                                        </div>
                                        <h6 className='mb-3 f-14 mt-3 d-flex jc-between'><span className='themeclr'>Price:</span> <span className='text-muted'>{parseFloat(data?.totalPrice) - parseFloat(data?.serviceFee)}</span></h6>
                                        <hr className='themeclr' />
                                        <h6 className='mb-3 f-14 mt-3 d-flex jc-between'><span className='themeclr'>Status:</span> <span className='success-box'>Success</span></h6>
                                            
                                          
                                    </div>
                                </div>
                            </>)}
                                

                                {/* <div className='col-lg-4 col-md-6 mb-3'>
                                    <div className='shadowbox p-4 br-5'> 
                                        <div className='d-flex align-items-center'>
                                            <img src={prof} className="prof" />
                                            <h6 className='mb-0'>Hi, Jhon</h6>
                                        </div>
                                        <div className='graybox br-10 mt-3 py-3'>
                                            <div className='d-flex jc-between'>
                                                <h6 className='mb-3 f-14'><span className='themeclr'>Room Title</span>: <span className='text-muted'>Mad Room</span></h6>
                                                <h6 className='mb-3 f-14'><span className='themeclr'>Room ID</span>: <span className='text-muted'>J5421</span></h6>
                                            </div>
                                            <div className='d-flex jc-between'>
                                                <h6 className=' f-14'><span className='themeclr'>Check in:</span> <span className='text-muted'>12.11.2022</span></h6>
                                                <h6 className=' f-14'><span className='themeclr'>Check out:</span> <span className='text-muted'>14.11.2022</span></h6>
                                            </div>
                                            
                                        </div>
                                        <h6 className='mb-3 f-14 mt-3 d-flex jc-between'><span className='themeclr'>Check out:</span> <span className='text-muted'>$1000</span></h6>
                                        <hr className='themeclr' />
                                        <h6 className='mb-3 f-14 mt-3 d-flex jc-between'><span className='themeclr'>Status:</span> <span className='pending-box'>Pending</span></h6>
                                            
                                          
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-6 mb-3'>
                                    <div className='shadowbox p-4 br-5'> 
                                        <div className='d-flex align-items-center'>
                                            <img src={prof} className="prof" />
                                            <h6 className='mb-0'>Hi, Jhon</h6>
                                        </div>
                                        <div className='graybox br-10 mt-3 py-3'>
                                            <div className='d-flex jc-between'>
                                                <h6 className='mb-3 f-14'><span className='themeclr'>Room Title</span>: <span className='text-muted'>Mad Room</span></h6>
                                                <h6 className='mb-3 f-14'><span className='themeclr'>Room ID</span>: <span className='text-muted'>J5421</span></h6>
                                            </div>
                                            <div className='d-flex jc-between'>
                                                <h6 className=' f-14'><span className='themeclr'>Check in:</span> <span className='text-muted'>12.11.2022</span></h6>
                                                <h6 className=' f-14'><span className='themeclr'>Check out:</span> <span className='text-muted'>14.11.2022</span></h6>
                                            </div>
                                            
                                        </div>
                                        <h6 className='mb-3 f-14 mt-3 d-flex jc-between'><span className='themeclr'>Check out:</span> <span className='text-muted'>$1000</span></h6>
                                        <hr className='themeclr' />
                                        <h6 className='mb-3 f-14 mt-3 d-flex jc-between'><span className='themeclr'>Status:</span> <span className='pending-box'>Pending</span></h6>
                                            
                                          
                                    </div>
                                </div> */}
                              
                            </div>
                        </div>
              </div>
              <Footer />
            
          </div>
          </>
    )
}

export default TripPayment