import React,{useState} from 'react';
import {Modal,Button} from 'react-bootstrap'

const GuestArea = (props) =>{
    const[guestarea , setGuestArea] = useState(true)
    const[placearr, setPlaceArr] = useState([])
    const[count, setCount] = useState(0)
    const countarr = (e) =>{
       setPlaceArr(viji =>[ ...viji, e].concat(", "))
    }
    var counts = count;
    const addcount = (e) =>{
        var a = e.target.previousSibling;
        counts++;
        setCount(counts);
        a.innerHTML = counts;
    }

  

    props.propspace(placearr);
    return(
    <>
      <Modal show={guestarea} centered scrollable size="md" className='modal_style scenic listingmodals'>
          <Modal.Header className='border-0 pb-0'>
            <button type="button" className="btn close close_modal" onClick={() =>props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
          </Modal.Header>
          <Modal.Body>
            <h4 className='mb-0'>Which areas can guests use? </h4>
            <p className='f-14 text-muted'> Add all the rooms guests can use—even the shared areas</p>
           {/* <Scrollbars style={{  height: 360  }}> */}
              <div className='mt-4'>
                <div className='d-flex align-items-center jc-between mb-3'>
                    <p className='pb-0'>Bedroom</p>
                    <div className='d-flex jc-between align-items-center'>
                        <button className='circlebtn bg-white'>-</button>
                        <span className='px-2'>0</span>
                        <button className='circlebtn bg-white' onClick={(e) => {countarr("Bedroom");addcount(e);var counts = count;}}>+</button>
                    </div>
                </div>

                <div className='d-flex align-items-center jc-between mb-3'>
                    <div>
                        <p className='pb-0'>Full bathroom</p>
                       <p className='f-14 text-muted'> Toilet, sink, shower and/or bath</p>
                    </div>
                    <div className='d-flex jc-between align-items-center'>
                        <button className='circlebtn bg-white'>-</button>
                        <span className='px-2'>0</span>
                        <button className='circlebtn bg-white' onClick={(e) =>{ countarr("Full bathroom");addcount(e);var counts = 0;}}>+</button>
                    </div>
                </div>

                <div className='d-flex align-items-center jc-between mb-3'>
                    <div>
                        <p className='pb-0'>Toilet with sink</p>
                       <p className='f-14 text-muted'>Toilet and sink</p>
                    </div>
                    <div className='d-flex jc-between align-items-center'>
                        <button className='circlebtn bg-white'>-</button>
                        <span className='px-2'>3</span>
                        <button className='circlebtn bg-white' onClick={() => countarr("Toilet with sink")}>+</button>
                    </div>
                </div>

                <div className='d-flex align-items-center jc-between mb-3'>
                    <div>
                        <p className='pb-0'>Full kitchen</p>
                    </div>
                    <div className='d-flex jc-between align-items-center'>
                        <button className='circlebtn bg-white'>-</button>
                        <span className='px-2'>3</span>
                        <button className='circlebtn bg-white'onClick={() => countarr("Toilet with sink")}>+</button>
                    </div>
                </div>
                <div className='d-flex align-items-center jc-between mb-3'>
                    <div>
                        <p className='pb-0'>Kitchenette</p>
                       <p className='f-14 text-muted'>A compact space to prepare food</p>
                    </div>
                    <div className='d-flex jc-between align-items-center'>
                        <button className='circlebtn bg-white'>-</button>
                        <span className='px-2'>1</span>
                        <button className='circlebtn bg-white'>+</button>
                    </div>
                </div>

                <div className='d-flex align-items-center jc-between mb-3'>
                    <div>
                        <p className='pb-0'>Living room</p>
                    </div>
                    <div className='d-flex jc-between align-items-center'>
                        <button className='circlebtn bg-white'>-</button>
                        <span className='px-2'>1</span>
                        <button className='circlebtn bg-white'>+</button>
                    </div>
                </div>
                <div className='d-flex align-items-center jc-between mb-3'>
                    <div>
                        <p className='pb-0'>Dining area</p>
                    </div>
                    <div className='d-flex jc-between align-items-center'>
                        <button className='circlebtn bg-white'>-</button>
                        <span className='px-2'>1</span>
                        <button className='circlebtn bg-white'>+</button>
                    </div>
                </div>
                <div className='d-flex align-items-center jc-between mb-3'>
                    <div>
                        <p className='pb-0'>Office</p>
                    </div>
                    <div className='d-flex jc-between align-items-center'>
                        <button className='circlebtn bg-white'>-</button>
                        <span className='px-2'>1</span>
                        <button className='circlebtn bg-white'>+</button>
                    </div>
                </div>
                <div className='d-flex align-items-center jc-between mb-3'>
                    <div>
                        <p className='pb-0'>Back garden</p>
                    </div>
                    <div className='d-flex jc-between align-items-center'>
                        <button className='circlebtn bg-white'>-</button>
                        <span className='px-2'>1</span>
                        <button className='circlebtn bg-white'>+</button>
                    </div>
                </div>
                <div className='d-flex align-items-center jc-between mb-3'>
                    <div>
                        <p className='pb-0'>Patio</p>
                    </div>
                    <div className='d-flex jc-between align-items-center'>
                        <button className='circlebtn bg-white'>-</button>
                        <span className='px-2'>1</span>
                        <button className='circlebtn bg-white'>+</button>
                    </div>
                </div>
                <h5 className='my-4'>Additional areas</h5>
                <div className='d-flex align-items-center jc-between mb-3'>
                    <div>
                        <p className='pb-0'>Pool</p>
                    </div>
                    <div className='d-flex jc-between align-items-center'>
                        <button className='circlebtn bg-white'>-</button>
                        <span className='px-2'>1</span>
                        <button className='circlebtn bg-white'>+</button>
                    </div>
                </div>
                <div className='d-flex align-items-center jc-between mb-3'>
                    <div>
                        <p className='pb-0'>Hot tub</p>
                    </div>
                    <div className='d-flex jc-between align-items-center'>
                        <button className='circlebtn bg-white'>-</button>
                        <span className='px-2'>1</span>
                        <button className='circlebtn bg-white'>+</button>
                    </div>
                </div>
                <div className='d-flex align-items-center jc-between mb-3'>
                    <div>
                        <p className='pb-0'>Utility room</p>
                    </div>
                    <div className='d-flex jc-between align-items-center'>
                        <button className='circlebtn bg-white'>-</button>
                        <span className='px-2'>1</span>
                        <button className='circlebtn bg-white'>+</button>
                    </div>
                </div>
                <div className='d-flex align-items-center jc-between mb-3'>
                    <div>
                        <p className='pb-0'>Garage</p>
                    </div>
                    <div className='d-flex jc-between align-items-center'>
                        <button className='circlebtn bg-white'>-</button>
                        <span className='px-2'>1</span>
                        <button className='circlebtn bg-white'>+</button>
                    </div>
                </div>
                <div className='d-flex align-items-center jc-between mb-3'>
                    <div>
                        <p className='pb-0'>Gym</p>
                    </div>
                    <div className='d-flex jc-between align-items-center'>
                        <button className='circlebtn bg-white'>-</button>
                        <span className='px-2'>1</span>
                        <button className='circlebtn bg-white'>+</button>
                    </div>
                </div>
                <div className='d-flex align-items-center jc-between mb-3'>
                    <div>
                        <p className='pb-0'>Exterior</p>
                        <p className='f-14 text-muted'>This allows you to add a photo of what your place looks like from the outside</p>
                    </div>
                    <div className='d-flex jc-between align-items-center'>
                        <button className='circlebtn bg-white'>-</button>
                        <span className='px-2'>1</span>
                        <button className='circlebtn bg-white'>+</button>
                    </div>
                </div>


              </div>
            {/* </Scrollbars> */}
          </Modal.Body>
          <Modal.Footer className='jc-between'>
            <button className='btn btn-theme' >Save</button>
            <Button className='text-dark' variant="link" >Cancel</Button>
          </Modal.Footer>
      </Modal>
    </>
    )
}

export default GuestArea