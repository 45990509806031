import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { getExperienceFromLocal } from '../../../../Hooks/useUserExperienceHook';

const TagLine = (props) =>{

    const [tagLine, setTagLine] = useState("");

    useEffect(()=>{
        setTagLine( getExperienceFromLocal()&&getExperienceFromLocal().tagLine?getExperienceFromLocal().tagLine : tagLine )
    },[]);

    return(
        <>
                <div>
                    <h2 className='conthead'>Write a tagline</h2>

                    <p className='text-muted'>Choose the category that best describe your experience. Add a second,
                        if you think it fits into another category.
                    </p>
                    <div className='h-55 mt-4'>
                            
                                  <input type="text" 
                                  placeholder={"Choose Tagline"}
                                  value={tagLine} 
                                  onChange={(e)=>{setTagLine(e.target.value)}} className='form-control' />
                         

                        <div className='mt-4'><button disabled={!tagLine}  onClick={() => {props.onSetLocal(tagLine,"tagLine");props.count()}} className='btn btn-theme'>Save & Continue</button></div> 
                    </div>
                    
              </div>    
         
        </>

    )
}

export default TagLine