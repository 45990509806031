import React, { useEffect, useState } from 'react';
import img1 from '../../Assets/images/where/img11.png'
import { API_URL } from '../../config/env';
import { getUserDatas } from "../../Hooks/useUserHook";
import { getAdminDetails } from '../../Hooks/useUserHostingHook';

const BookingPayCard = (props) => {
    console.log('props---',props.data);
    console.log('Count props---',props.stateData);
    // const [totalAmount , setTotalAmount] = useState();
    // setTotalAmount(props?.data?.datas?.pricePerGuest * props?.stateData.length)
    const [admindetails , setAdmindetails] = useState({});
    const [fee , setFee] = useState(0);
    // const []

    useEffect(() => {
        getAdminDetails().then((data) => {
            setAdmindetails(data);
            var fee = 0;
            data?.map((dat) => {
                fee = fee + parseFloat(dat.percentage)
            })
            setFee(fee)
            // var fee = adminDetails.reduce(function (acc, obj) { return acc + parseFloat(obj.percentage)/100 * rent; }, 0);
        })
    })


    return(
        <div className='shadowbox p-4'>
            
            <div className='d-flex reviewcard'>
                <div>
                    <h5>{props?.data?.datas?.title}</h5>
                    {/* <span className='badge br-10'>2 hours experience</span> */}
                    <p className='adminblue f-14 mt-3 fw-700'>hosted by <span className='text-muted'>{props?.data?.datas?.firstName + ' ' +     props?.data?.datas?.lastName }</span></p>
                </div>
                <img src={ props && props?.props && props?.props?.datas && API_URL + props?.props?.datas?.photosPath + "/" + props?.props?.datas?.photos[2].name} />
            </div>
            <div className='shadowbox p-3 d-flex jc-between f-14 my-3'>
                <p className='themeclr'>{props?.data?.date ?new Date(props?.data?.date).toLocaleDateString():''}</p>
                <p className='text-muted'>{`${new Date(props?.data?.datas?.startTime).toLocaleTimeString()} - ${new Date(props?.data?.datas?.endTime).toLocaleTimeString()}`}</p>
            </div>
            <div className='d-flex f-14 jc-between'>
            <p className='themeclr'>${props?.data?.datas?.pricePerGuest} x {props?.stateData.length} guest</p>
                <p className='text-muted'>${props?.data?.datas?.pricePerGuest * props?.stateData.length }</p>
            </div>
            <hr className='themehr'/>
            <div className='d-flex f-14 jc-between'>
                <p className='themeclr'>Service fee</p>
                
                <p className='text-muted'>${((props?.data?.datas?.pricePerGuest * props?.stateData.length)/100) * (parseFloat(fee))}</p>
                
                {/* <p className='text-muted'>${props?.data?.datas?.pricePerGuest * props?.stateData.length * (21/100)}</p> */}
            </div>
            <hr className='themehr'/>

            <div className='shadowbox p-3 f-14 my-3'>
                <div className='d-flex jc-between'>
                <p className='themeclr'>Total USD</p>
                <p className='text-muted'>$ {(props?.data?.datas?.pricePerGuest * props?.stateData.length * (21/100) + (props?.data?.datas?.pricePerGuest * props?.stateData.length))}</p>
                </div>
                {/* <p className='f-14 mt-3'>The adjusted exchange rate for this purchase is $1.00 USD to 2EUR</p> */}
            </div>
            {/* <div>
                <h6 className="themeclr">Cancellation Policy</h6>
                <p className="text-muted f-14">
                    Get a full refund if you cancel within 24hours of purchase
                </p>
            </div> */}
           
            
        </div>
    )
}

export default BookingPayCard