import React, { useState } from 'react';
import {Button, Modal} from "react-bootstrap"
import img1 from "../../../../../../Assets/images/where/img1.png"
import { raisedisputehook } from '../../../../../../Hooks/adminHook';
import { getUserDatas } from '../../../../../../Hooks/useUserHook';

const CreateDispute = (props) => {
    const[createdispute, setCreateDispute] = useState(true);
    const [imageshow , setImageshow] = useState();
    const [image , setImage] = useState();
    const [reason , setReason] = useState();
    const [submit , setSubmit] = useState(false);

    const filevalidate = (image)=>{
        var fileName = image?.name ? image?.name :image;
            var idxDot = fileName?.lastIndexOf(".") + 1;
            var extFile = fileName?.substr(idxDot, fileName.length).toLowerCase();
            if(extFile=="jpg" || extFile=="jpeg" || extFile=="png" || extFile=="webp"){
                return true;
            }else{
                return false;
            }
      }

      const handlesubmit = async()=>{
        if(filevalidate(image) , reason?.length > 0){
            setSubmit(true);
            let userdata = await getUserDatas();
            let payload = {
                raiser : userdata?._id,
                image : image,
                message : reason
            }
            await raisedisputehook(payload).then((result)=>{
                console.log("raise dispute hook result" , result);
                // props.record(result)
                window.location.reload();
                setCreateDispute(false);
            })
            console.log("payload" , payload);
        }
      else{
        setSubmit(false);
      }
      }

    return(
        <>
           <Modal show={createdispute} centered scrollable size="md" className='modal_style   dispute'>
                <Modal.Header className='border-0 pb-0'>
                    <button type="button" className="btn close close_modal" onClick={() =>props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <h2 className='mb-10'>Raise Dispute</h2>
                        <p>Dispute Image</p>
                        {/* <div className='uploadfile'>
                        <span className='fa fa-image'></span>
                            <input type="file" className='form-control' 
                             onChange={(e)=>{
                                console.log("e" , e?.target?.files[0] , URL.createObjectURL(e?.target?.files[0]));
                                setImage(e?.target?.files[0]);
                                setImageshow(URL.createObjectURL(e?.target?.files[0]))
                             }}/>
                            <img src={imageshow} className="img d-none" />
                            <button className='btn btn-link'>upload</button>
                           
                        </div> */}
                        <div className='upphoto my-3 ml-6'>
                        <Button variant="link" className='nounder text-dark me-2 mb-2'>
                                    
                                    <span className='fa fa-photo'></span>
                                   
                                    <p>Upload Image</p>
                                    <img src = {imageshow}/>
                                    
                                    <input type="file"  name = "img" accept=".jpg,.jpeg,.png" onChange ={(e)=>{setImage(e?.target?.files[0]); 
                                        setImageshow(URL.createObjectURL(e?.target?.files[0]));
                                        // if(filevalidate(image) && reason?.length > 0){
                                        //     setSubmit(true);
                                        // }
                                        // else{
                                        //     setSubmit(false);
                                        // }
                                        }}/>
                                </Button>
                        </div>
                        <p className='mt-3'>Dispute Reason </p>
                       <textarea rows="5" className='form-control mb-4 mt-3'
                            onChange={(e)=>{setReason(e?.target?.value);
                                // if(filevalidate(image) && reason?.length > 0){
                                //     setSubmit(true);
                                //     console.log("filevalidate(image)" , filevalidate(image));
                                // }
                                // else{
                                //     setSubmit(false);
                                // }
                            }}
                        ></textarea>
                       <button className='btn btn-theme' disabled={!(filevalidate(image) && reason)} onClick={handlesubmit}>Submit</button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default CreateDispute