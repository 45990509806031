import React,{useState} from 'react';
import {Modal,Button} from 'react-bootstrap'

const ParkingOff = (props) =>{
    const[parkingoff , setParkingOff] = useState(true)
    
    return(
    <>
      <Modal show={parkingoff} scrollable centered size="md" className='listingmodals modal_style scenic'>
          <Modal.Header className='border-0 pb-0'>
            <button type="button" className="btn close close_modal" onClick={() =>props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
          </Modal.Header>
          <Modal.Body>
            <h4 className='mb-4'>Paid parking off premises details</h4>
            
                <div>
              
                <h6 className='mb-3'>Type</h6>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Multistorey car park</p>
                        </label>
                        <input class="form-check-input" type="radio" value="Air conditioning details" id="flexCheckChecked" />
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Car park</p>
                        </label>
                        <input class="form-check-input" type="radio" value="Air conditioning details" id="flexCheckChecked" />
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>StanOn-street parkingdard</p>
                        </label>
                        <input class="form-check-input" type="radio" value="Air conditioning details" id="flexCheckChecked" />
                    </div>
                    
                </div>
          </Modal.Body>
          <Modal.Footer className='jc-between mt-4'>
            <Button variant="link" className="text-dark">Cancel</Button>
            <button className='btn btn-theme'>Save</button>
          </Modal.Footer>
      </Modal>
    </>
    )
}

export default ParkingOff