import React, {  useState } from 'react';
import {Modal} from 'react-bootstrap'

// import Scrollbars from 'react-custom-scrollbars';


import ball from '../../../Assets/images/icons/new/ball.png'
import rain from '../../../Assets/images/icons/new/rain.png'
import dot from '../../../Assets/images/icons/new/dot.png'




const HealthSafety = (props) =>{
    const[healthsafety, setHealthSafety] = useState(true)
    return(
        <>  
            <Modal show={healthsafety} size="" centered scrollable className='modal_style'>
                <Modal.Header className='border-0 pb-0 d-flex justify-content-end'>
                    <button type="button" className="btn close close_modal" onClick={() =>props.onDismiss()}><span aria-hidden="true" className='fa fa-times f-14'></span><span class="sr-only">Close</span></button>
                </Modal.Header>
                <Modal.Body className='pt-0'>
                    <div className='thingstoknow healthandsafe'>
                        <h5 className='mb-4 text-heading'>Health and Safety</h5>
                        <p className='detail_desc_grey f-16 pb-3'><img src={ball}/>COVID-19 safety practices apply</p>
                        <p className='detail_desc_grey f-16 pb-3'><img src={rain} />Corbon monoxide alarm</p>
                        <p className='detail_desc_grey f-16 pb-3'><img src={dot} />Smoke alarm</p>
                        </div>
                </Modal.Body>
            </Modal>
        </>
    )
}


export default HealthSafety