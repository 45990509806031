
import React, {useState , useEffect} from 'react';
import {Modal, Form} from "react-bootstrap";
import { Scrollbars } from 'react-custom-scrollbars';
import calendar from "../../../Assets/images/calender_icon.png"
import {getfilteroption , getfilteroptiondata} from '../../../Hooks/filterHook';
import RangeSlider from "./Rangeslider";
import {API_URL} from '../../../config/env'
const Filtermodal = (props) =>{
  const [showfilter, setShowfilter] = useState(true);
    const prices = [0,10000];
// for (let i = 0; i < 40; i++) {
//   prices.push(Math.floor(Math.random() * 80) + 1);
// }

   
const [count , setCount] = useState(0);
const [show, setShow] = useState(2);
const [essentials, setessentials] = useState(false);
const [features, setfeatures] = useState(false);
const [language, setlanguage] = useState(false);

const [propertytype , setPropertytype] = useState([]);
console.log('propertype',propertytype)
const [placetype , setPlacetype] = useState([]);
const [floorplan , setFloorplan] = useState([]);
console.log('floorplamsssss',floorplan)
const [amenity , setAmenity] = useState([]);
console.log('amienty',amenity)
const [Average,setAverage] = useState({min:0,max:10000})
const[clearAlldata,setClearAlldata] = useState('');
console.log('clearAlldatasgal',clearAlldata)
console.log('Average',Average)
const [selectedindex , setSelectedindex] = useState([{ type: 'guests', count: 0 },
{ type: 'beds', count: 0 },
{ type: 'bedrooms', count: 0 },
{ type: 'bathrooms', count: 0 }, { type: 'Wifi', count: 0 }, {type: 'Parking',count: 0}]);
console.log('selectindex',selectedindex)
// const [FloorPlan , setFloorPlan] = useState([{ type: 'guests', count: 0 },
// { type: 'beds', count: 0 },
// { type: 'bedrooms', count: 0 },
// { type: 'bathrooms', count: 0 }]);
// const [FloorPlan , setFloorPlan] = useState(selectedindex);
const [selectedplacetype,setSelectedPlaceType] = useState([])
console.log('selected hihi',selectedplacetype)
const [selectedpropertytype , setSelectedpropertytype] = useState([]);
const [selectedamienties ,setSelectedAmienties] = useState([]);
const [testamenties,setTestAmenties] = useState(false);
console.log('testamenities',testamenties)
console.log('selectedamienties',selectedamienties,selectedamienties.length)
console.log('selectedpropertype',selectedpropertytype)
const [floorlist , setFloorlist] = useState([]);
const [path , setPath] = useState([]);
const [cleared,setCleared] = useState(false);
let FloorPlan = [{ type: 'guests', count: 0 },
                 { type: 'beds', count: 0 },
                 { type: 'bedrooms', count: 0 },
                 { type: 'bathrooms', count: 0 },
                 { type: 'Wifi', count: 0 },
                {type: 'Parking',count: 0}];

// const [place , setPlace] = useState([]);
useEffect(() => {
  async function fetchdata(){
      let filteroption = await getfilteroption();
      console.log("retrive db",filteroption);
      setPropertytype(filteroption.data.data.record.propertytype);
      setPlacetype(filteroption.data.data.record.placetype);
      setFloorplan(filteroption.data.data.record.floorplan);
      setAmenity(filteroption.data.data.record.amenity);
      setPath(filteroption.data.data.record.path);
      console.log("Selected indes",selectedindex);
      }
       fetchdata();
},[])
let PlaceType = [];
// console.log('placeType',PlaceType)
const handlePlacetype = async(data) => {
    console.log("handle place type : " , data);
    const index =  PlaceType.indexOf(data);
    if(index == -1){
      console.log("push" , index)
       PlaceType.push(data);
    }
    else{
      console.log("pop")
      PlaceType.splice(index, 1)
    }
    setSelectedPlaceType(PlaceType)
    console.log("place tyupe array:" , PlaceType)
}

console.log('placeTypesss',PlaceType)
var PropertyType = [];
const handlePropertytype = async(data) => {
  console.log("handle place type : " , data);
    const index =  PropertyType.indexOf(data);
    if(index == -1){
      console.log("push" , index)
      PropertyType.push(data);
    }
    else{
      console.log("pop")
      PropertyType.splice(index, 1)
    }
    console.log("place tyupe array:" , PropertyType)
    setSelectedpropertytype(PropertyType);
}

// let FloorPlan = [{ type: 'guests', count: 0 },
//                  { type: 'beds', count: 0 },
//                  { type: 'bedrooms', count: 0 },
//                  { type: 'bathrooms', count: 0 }];
// var FloorPlan = selectedindex;
const handlefloorplan = async(data , e , index) => {
  // setSelectedindex();
  // var FloorPlan = selectedindex;
console.log("handle floor plan" , data , e);
let floordata = {"type" :data ,  "count" : e}
let count = 0;

let arr = selectedindex.map((val,ind)=>
{
  console.log('valuesgal',val,val.type,data)
  if(data == val.type)
  {
    return { type : val.type , count : e}
  }
  else
  {
    console.log('valuessss',val)
    return val;
  }
})
console.log('arrssssss',arr)
for(let i=0 ; i<FloorPlan.length ; i++)
{
  if(FloorPlan[i].type == data)
  {
    
    console.log("rewrite");
    FloorPlan[i] = floordata;
    console.log('Floorplain[88]',FloorPlan[i])
    // selectedindex[i]= floordata;
    // setCount(count++);
    console.log('floorplan',FloorPlan)
    // console.log('floorplan',...FloorPlan,{...{...FloorPlan},floordata})
    // var arr = {...{...FloorPlan},floordata}
    setSelectedindex(arr);
    }
}

    console.log("place tyupe array:" , FloorPlan)
}


var Aminety = [];
const handleAminety = async(data) => {
  console.log("handle aminety :" , data,Aminety);
  Aminety=selectedamienties;
// console.log('arrsgal',arr)
  const index =  Aminety.indexOf(data);
  console.log('index---------',index)
    if(index == -1){
      console.log("push" , index)
      Aminety.push(data);
    }
    else{
      console.log("pop")
      Aminety.splice(index, 1)
    }
    setSelectedAmienties(Aminety)
    setTestAmenties(!testamenties)
    console.log("place tyupe array:" , Aminety)
}


let min;
let max;
const price = async(data) => {
  console.log("propspricedata" , data);
  min = data.min;
  max = data.max;
  setAverage({...Average,...{'min': min,'max': max}})
}

const onHandlefilterbutton = async(data) => {
  console.log("on handle filter button" , data);
  let payload = {
    min : Average?.min?.toString(),
    max : Average?.max?.toString(),
    Place : selectedplacetype,
    Property : selectedpropertytype,
    Floor : selectedindex,
    Aminity : selectedamienties
  };
  console.log('payload',payload)
  let FilterButtonData = await getfilteroptiondata(payload);
  console.log("Filter Button daata" , FilterButtonData);
  props.onSetFilterbuttondata(FilterButtonData)
  // props.onSetFilterbutton(FilterButtonData);
  setShowfilter(false)
}
const clear = async(data) =>{
  console.log("clear");

  setSelectedindex([{ type: 'guests', count: 0 },
{ type: 'beds', count: 0 },
{ type: 'bedrooms', count: 0 },
{ type: 'bathrooms', count: 0 }]);
console.log()
FloorPlan = [{ type: 'guests', count: 0 },
                 { type: 'beds', count: 0 },
                 { type: 'bedrooms', count: 0 },
                 { type: 'bathrooms', count: 0 }];
                //  Aminety = []; 
               //  PropertyType = [];
                 setSelectedpropertytype([])
                 setSelectedPlaceType([])
                 setSelectedAmienties([])
                //  setTestAmenties([])
                 console.log('placeTypearray',PlaceType)

                 
      min = 0;
      max = 10000;
      setClearAlldata('clear')
      console.log('min values',min,'maxvalues',max)
      setCleared(true);
  // window.location.reload();
}

const selectedbutton = (type , count) =>{
  var result = false;
  // console.log("selected index state" , selectedindex)
   selectedindex?.map((data , id)=>{
    
    // console.log("select idex function" , data?.type, type ,data?.count , count)
    if(data?.type == type && data?.count == count){
      // console.log("return res" , type , count);
      result = true;
    }
    
  })
  return result;
}

   return(
    <div>
        
    <Modal show={showfilter} centered scrollable className='modal_style modal_filter' size="md">
    <Modal.Header className='modal_head_flex'>
    <p className='modal_title_blue'>Filters</p>
    <button type="button" className="btn close close_modal" onClick={() =>props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
    </Modal.Header>
    <Modal.Body className='pb-5 px-0'>
      <div className='px-3'>
    <p className='modal_title_blue mb-2'>Price Range</p>
    <p className='text_grey font_14 pb-4'>The average nightly price is $27,589</p>

<div className='mb-4'>
      <RangeSlider   onSetClear = {setClearAlldata} clear={clearAlldata} data={prices} onSetPrices = {price}/>
</div>


    </div>
    <hr className='modal_hr' />
    <div className='px-3'>
    <p className='modal_title_blue mb-2'>Type of place</p>
    <div className='row mt-4'>

      
      {placetype.map((i) => i?.status == "Active"&& <>{console.log("place type map",i?.status,i?.type)}
      <div className='col-12 col-md-6 mb-3'>
      <div className="custom-control custom-checkbox custom_label_filter">
      <Form.Group controlId="formBasicCheckbox">
        <Form.Check type="checkbox" onChange={() => handlePlacetype(i.type)} checked={ selectedplacetype.includes(i?.type) > 0 ? true : false}/>
        <label className="custom-control-label" for="customCheck1">
        <p className='label_blue_main mb-0'>{i.type}</p>
        {/* <p className='label_grey_sub mb-0'>A Place all to yourself</p> */}
      </label>
      </Form.Group>
      </div>
      </div>
      </>)}

    
   


    </div>
    </div>
    <hr className='modal_hr' />
    <div className='px-3'>
    <p className='modal_title_blue mb-2'>Rooms and Beds</p>
    {floorplan?.map((i , index) => i?.status == "Active"&&<>
    {console.log("selected index" , selectedindex)}
    <div className='mt-4'>
      <p className='sub_titl_blue'>{i.type}</p>
      <Scrollbars style={{ width: 500, height: 50  }} className="calendar_scroll room_scroll">
      <div className="btn-group btn_blue_grp btn_width_unset" role="group" aria-label="Basic example">
      {/* <button type="button" className={selectedbutton.some(data => ) ? "btn btn-secondary active" : "btn btn-secondary"}
   onClick={() => handlefloorplan(i.type , 0)}>Any</button> */}
   {console.log('wifiwifiwifi',i?.type)}
  <button type="button" className={selectedbutton(i?.type , 0) ? "btn btn-secondary active" : "btn btn-secondary"}
   onClick={() => handlefloorplan(i.type , 0)}>Any</button>
   {console.log("classname" , selectedbutton(i?.type , 1))}
  <button type="button" className={selectedbutton(i?.type , 1) ? "btn btn-secondary active" : "btn btn-secondary"}  onClick={() => handlefloorplan(i.type , 1)}>1</button>
  <button type="button" className={selectedbutton(i?.type , 2) ? "btn btn-secondary active" : "btn btn-secondary"} onClick={() => handlefloorplan(i.type , 2)}>2</button>
  <button type="button" className={selectedbutton(i?.type , 3) ? "btn btn-secondary active" : "btn btn-secondary"} onClick={() => handlefloorplan(i.type , 3)}>3</button>
  <button type="button" className={selectedbutton(i?.type , 4) ? "btn btn-secondary active" : "btn btn-secondary"} onClick={() => handlefloorplan(i.type , 4)}>4</button>
  <button type="button" className={selectedbutton(i?.type , 5) ? "btn btn-secondary active" : "btn btn-secondary"} onClick={() => handlefloorplan(i.type , 5)}>5</button>
  <button type="button" className={selectedbutton(i?.type , 6) ? "btn btn-secondary active" : "btn btn-secondary"} onClick={() => handlefloorplan(i.type , 6)}>6</button>
  <button type="button" className={selectedbutton(i?.type , 7) ? "btn btn-secondary active" : "btn btn-secondary"} onClick={() => handlefloorplan(i.type , 7)}>7+</button>


</div>  
</Scrollbars>
    </div>

    </>)}



   
      </div>
      <hr className='modal_hr' />
    <div className='px-3'>
    <p className='modal_title_blue mb-2'>Property Type</p>
    <div className='mt-4'>
    <div className='card card_grey'>
      <div className='card-body'>
    <Scrollbars style={{ width: 500, height: 100  }} className="calendar_scroll type_scroll">
<div className='calendar_div_scroll calendar_div_scroll_filter'>

      {propertytype.map((i) =>i?.status == "Active"&& <>{console.log("property type mapdata:" , i)}
      <div className={selectedpropertytype?.includes(i.type)?'card card_calendar active mr-5' : 'card card_calendar  mr-5'}>
        <div className='card-body '  onClick = {() => handlePropertytype(i.type)}>
            <div className='text-center' >
            <img src={API_URL+path+'/'+i.image} className="img-fluid" alt="logo"/>
            <p className='month_title mb-0'>{i.type}</p>
            </div>

        </div>
    </div>

    </>)}



     
      </div>
      </Scrollbars>
      </div>
      </div>


      
    </div>
   </div>
   <hr className='modal_hr' />
    <div className='px-3'>
    <p className='modal_title_blue mb-2'>Amenities</p>
    <div className='mt-4'>

      {/* <p className='sub_titl_blue'>Essentials</p> */}
      {console.log('amennnibhibhfhef',selectedamienties,testamenties,selectedamienties.length)}
      <div className='row mt-4'>
      {amenity.map((i) =>i?.status == "Active"&& <>{console.log("Amenity type mapdata:" ,selectedamienties, i)}
      <div className='col-12 col-md-6 mb-3'>
      <div className="custom-control custom-checkbox custom_label_filter">
      <Form.Group controlId="formBasicCheckbox">
        
        <Form.Check type="checkbox"  onChange={()=> handleAminety(i?.type)} checked={selectedamienties.includes(i?.type)?true:false}/>
        <label className="custom-control-label" for="customCheck1">
        <p className='label_blue_main mb-0'>{i.type}</p>


      </label>
      </Form.Group>
      </div>
      </div>

      </>)}
   
 
      

    </div>  

      </div>
      </div>
  

    </Modal.Body>
    <Modal.Footer className='footer_flex_modal'>
    <a href="javascript:void(0)" className='blue_a_sm' onClick={clear}>Clear All</a>

    <button type="button" className="btn btn-theme filterbtn" onClick={onHandlefilterbutton}>Show  Stays</button>

    </Modal.Footer>
    </Modal>
    </div>
   

    )
}

export default Filtermodal;