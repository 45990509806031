import React, { useState } from 'react';
import {Modal, Button, ProgressBar} from 'react-bootstrap'
import Scrollbars from 'react-custom-scrollbars';

import FlexStayModal from '../FlexStayModal';

import tick from '../../../../../../Assets/images/tick.png'




const OfferSelf = (props) =>{
    const[flexstay , setFlexStay] = useState(false)
    const[second , setSecond] = useState(true)
    const modalOptions = () =>{
        setFlexStay(true);
        setSecond(false);
    }

    const modalClose = () =>
    {
        setSecond(false);
        props.closemodal()
    }
    return(
        <>
        { flexstay && <FlexStayModal/>}

        <Modal show={second} centered size="md" className='modal_style'>
           <Modal.Header className='border-0 pb-0'>
               <button type="button" className="btn close close_modal" onClick={() =>modalClose()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
  
          </Modal.Header>
          <Modal.Body className='d-scroll'>  
            <Scrollbars style={{  height: 200  }}>
                <p className='themeclr f-14'><img src={tick} /> Listing details</p>
                <h4 className='mt-3'>Offer self check-in</h4>
                <p>Lockboxes are affordable, provide easy access, and save you time.</p>
              
          
               </Scrollbars>
          </Modal.Body>
          <Modal.Footer className='justify-content-between'>
          <Button variant="link" onClick={() => modalOptions()} className="text-dark">Back</Button>
          <Button className='btn btn-theme'>Offer self check-in</Button>
          </Modal.Footer>
      </Modal>
      </>
    )
}

export default OfferSelf