import React, { useEffect, useState } from "react";
import {Row, Col, Tab ,Nav,Button} from 'react-bootstrap'

import Footer from "../Common/Footer";
import Header from "../Common/Header";
import img1 from "../../Assets/images/where/img1.png"
import img2 from "../../Assets/images/where/img2.png"
import img3 from "../../Assets/images/where/img3.png"
import img16 from "../../Assets/images/where/img16.png"
import img14 from "../../Assets/images/where/img14.png"
import { Link } from "react-router-dom";
import PendingOrdersCard from "./PendingOrdersCard";
import { checkBookingStatus, getUserEmail } from "../../Hooks/useUserHook";
import { getUserBookings, getUserTrips, getUserPendings, getUserCancelledTrips } from "../../Hooks/useUserHostingHook";

const PendingOrders = () =>{

    const [email, setEmail] = useState("");
    const [bookingDatas, setBookingDatas] = useState([]);
    const [pendingDatas, setPendingDatas] = useState([]);
    const [tripDatas, setTripDatas] = useState([]);
    const [cancelledDatas, setCancelledDatas] = useState([]);
    const [refresh, setRefresh] = useState(false);

    const getuserbookings = () => {
        if(email){
        getUserBookings(email).then((data)=>{
            console.log("get user bookings in pending order page :",data,"email",email);
            setBookingDatas(data);
        })
    }
    }

    const getuserpendings = () => {
        if(email){
            getUserPendings(email).then((data)=>{
            console.log("get user pending in pending order page :",data,"email",email);
            setPendingDatas(data);
        })
    }
    }

    const getusertrips = () => {
        if(email){
        getUserTrips(email).then((data)=>{
            console.log("get user trips in pending order page :",data,"email",email);
            setTripDatas(data);
        })
    }
    }

    const getcancelledtrips = () => {
        if(email){
        getUserCancelledTrips(email).then((data)=>{
            console.log("get user trips in pending order page :",data,"email",email);
            setCancelledDatas(data);
        })
    }
    }

    useEffect(()=>{
       const data = getUserEmail()
            setEmail(data);
        checkBookingStatus();
        getuserbookings();
        getuserpendings();
        getusertrips();
        getcancelledtrips();
    },[email,refresh]);

    return(
        <div className="reserve">
        <Header />

        <div className="wishlist detail_ful mh-100vh-header reseve-tabs">
            <div className="container container_theme mb-4">
         
            <Tab.Container id="left-tabs-example" defaultActiveKey="arriving">
                            <Row className= 'd-flex align-items-center justify-content-between flex-column'>
                                <Col className=  "mx-auto bg-white mt-4 mb-3">
                                    <Nav variant="pills" className="">
                                    <Nav.Item>
                                        <Nav.Link eventKey="arriving">Pending Approvals <span>({pendingDatas.length})</span> </Nav.Link>
                                        
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="checkout">Pending Orders <span>({bookingDatas.length})</span></Nav.Link>
                                        
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="currenthosting">Trips <span>({tripDatas.length})</span></Nav.Link>
                       
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="cancelled">Cancelled<span>({cancelledDatas.length})</span></Nav.Link>
                       
                                    </Nav.Item>
                                 
                                    </Nav>
                                </Col>
                                <Col className="mx-auto mt-3">
                                    <Tab.Content>
                    <Tab.Pane eventKey="arriving">
                                    <div className='row'>

                {(pendingDatas.length!=0) ? pendingDatas.map((val)=>
                    <PendingOrdersCard type={"pendingApprovals"} onReload={()=>{setRefresh(true)}} data={val}/>
                    // <>{console.log("booking datas in pending orders:",val)}</>
                ):<p className="text-center">Currently no pending approvals available.</p>}
               
                  
                </div>

                                    </Tab.Pane>
                                    <Tab.Pane eventKey="checkout">
                                    <div className='row'>
                                    {(bookingDatas.length!=0) ? bookingDatas.map((val)=>

                    <PendingOrdersCard type={"pendingOrders"} onReload={()=>{setRefresh(true)}} data={val}/>
                    // <>{console.log("booking datas in pending orders:",val)}</>
                ):<p className="text-center">Currently no pending orders available.</p>}
                </div>

               
                                      
                                   
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="currenthosting">
                                    <div className='row'>

                {(tripDatas.length!=0) ? tripDatas.map((val)=>
                    <PendingOrdersCard type={"pendingTrips"} onReload={()=>{setRefresh(true)}} data={val}/>
                    // <>{console.log("booking datas in pending orders:",val)}</>
                ):<p className="text-center">Currently no trip history available.</p>}
               
                  
                </div>
                                    </Tab.Pane>
                                 
                                    <Tab.Pane eventKey="cancelled">
                                    <div className='row'>

                {(cancelledDatas.length!=0) ? cancelledDatas.map((val)=>
                    <PendingOrdersCard type={"cancelledTrips"} onReload={()=>{setRefresh(true)}} data={val}/>
                    // <>{console.log("booking datas in pending orders:",val)}</>
                ):<p className="text-center">Currently no trip history available.</p>}
               
                  
                </div>
                                    </Tab.Pane>
                                 
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
            </div>
        
         
        </div>
        <Footer />
        </div>
    )

}

export default PendingOrders