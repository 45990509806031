import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const CustomizePrize = (props) => {
  const [customprice, setCustomPrize] = useState(true);
  console.log("component customize comes")

  return (
    <Modal show={customprice} centered scrollable fullscreen={true}>
      <Modal.Header className="border-0 pb-0 topclose">
        <button
          type="button"
          className="btn close close_modal"
          onClick={() => props.onDismiss()}
        >
          <span aria-hidden="true" className="fa fa-chevron-left" />
          <span class="sr-only">Close</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="host-dashboard-img-sec">
              <img src={props.img} className="img-fluid" />
            </div>
          </div>
          <div className="col-md-6 d-flex  justify-content-center">
            <div className="col-md-8">
              <div className=" modalright">
                <h3>Get ready for your first guests</h3>
                <p className="mb-4 text-muted">
                  Great hosting starts with great communication. Here’s what
                  your guests need to know first.
                </p>
                <ul className="ps-0">
                  <li>
                    <Link to="/hosting/areaforguest/confirmavail">
                      Confirm your availability{" "}
                      <span className="fa fa-chevron-right ms-2" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/hosting/areaforguest/promotlist">
                      Promote Your Listing{" "}
                      <span className="fa fa-chevron-right ms-2" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/hosting/areaforguest/cleaningfee">
                      Add a ceaning fee
                      <span className="fa fa-chevron-right ms-2" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CustomizePrize;
