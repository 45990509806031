import moment from "moment"
import {extendMoment} from "moment-range"

export const Arrayofdate = async(bookings) =>{
    try{
        // console.log("bookings" , bookings);
        // let bookedDates = [];
        // await bookings.map((booking , i) => {
        //     const range = moment.range(moment(new Date(booking.checkInDate)), moment(new Date(booking.checkOutDate)));
        //     //The Array.from() static method creates a new, shallow-copied Array instance from an array-like or iterable object.
        //     const dates = Array.from(range.by("day"));
        //     // The concat() method is used to merge two or more arrays. This method does not change the existing arrays, but instead returns a new array.
        //     bookedDates.concat(dates);
        // });

        const excludedDates = [];
        await bookings.map((date) => {
            excludedDates.push(new Date(date));
        });
        console.log("excludeDate" , excludedDates);
        return excludedDates;
    }
    catch(e){
        console.log("error on array pof date" , e);
    }
}
