import React, {useState} from 'react';
import {useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const SetRules = () =>{
    const history = useNavigate();
    const[showedit , setShowEdit] = useState(false)

    return(
       <div className='container container_custom'>
        <button className='backbtn my-5' onClick={() => {history('/hosting', {state:"rule"}) }}><span className='fa fa-chevron-left me-2'></span> Back</button>
        <div className='row justify-content-center mb-85'>
            <div className='col-md-5'>
                <h2>Set your house rules</h2>
                <p className='mb-5'>Guests must agree to your house rules before they book. 
                    <a href="#" className='text-dark'> Read tips on setting house rules</a></p>
                    <ul className='ps-0 setrule'>
                        <li>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <p className='mb-0'>Suitable for children (2-12 years)</p>
                                    <p className='text-muted f-14 mt-3'>You can let guests know that your place isn't suitable for children if there are features that are dangerous for them. Guests will still be able to contact you for more details.
                                    <a href="#" className='text-dark ps-1'>Learn more</a> </p>
                                    </div>
                                    <div className='d-flex'>
                                    <button className='fa fa-times'></button>
                                    <button className='fa fa-check'></button>
                                </div>
                              
                            </div>
                            <hr />
                          
                        </li>
                        <li>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <p className='mb-0'>Suitable for infants (under 2 years)</p>
                                    <p className='text-muted f-14 mt-3'>You can let guests know that your place isn't suitable for infants if there are features that are dangerous for them.
                                     Guests will still be able to contact you for more details.
                                     <a href="#" className='text-dark ps-1'>Learn more</a>  </p>
                                    </div>
                                    <div className='d-flex'>
                                    <button className='fa fa-times'></button>
                                    <button className='fa fa-check'></button>
                                </div>
                              
                            </div>
                            <hr />
                        </li>
                        <li>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <p className='mb-0'>Pets allowed</p>
                                    <p className='text-muted f-14 mt-3'>You can restrict guests from bringing pets, but you must 
                                    reasonably accommodate guests that might bring an assistance animal.
                                    <a href="#" className='text-dark ps-1'>Learn more</a>  </p>
                                    </div>
                                    <div className='d-flex'>
                                    <button className='fa fa-times'></button>
                                    <button className='fa fa-check'></button>
                                </div>
                              
                            </div>
                            <hr />
                        </li>
                        <li>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <p className='mb-0'>Smoking allowed</p>
                                
                                    </div>
                                    <div className='d-flex'>
                                    <button className='fa fa-times'></button>
                                    <button className='fa fa-check'></button>
                                </div>
                              
                            </div>
                            <hr />
                        </li>
                        <li>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <p className='mb-0'>Events allowed</p>
                                
                                    </div>
                                    <div className='d-flex'>
                                    <button className='fa fa-times'></button>
                                    <button className='fa fa-check'></button>
                                </div>
                              
                            </div>
                            <hr />
                        </li>
                        <li>
                           <div className={!showedit ? 'd-flex justify-content-between' : "d-none"}>
                                <div>
                                    <p className='mb-0'>Additional rules</p>
                                    <p className='text-muted f-14 mt-3 mb-0'> No set</p>
                                    </div>
                                    <div className='d-flex'>
                                    <a href="#" className='text-dark'  onClick={() => setShowEdit(true)}>Edit</a>
                                </div>
                              
                            </div>

                            <div className={!showedit ?  "d-none" : 'bord br-10 p-3'}>
                                <h6 className='d-flex justify-content-between'>Additional rules <button onClick={() => setShowEdit(false)} className='closebtn'>X</button> </h6>
                                <p className='f-12 mb-2 text-muted'>You can include any requirements around safety concerns, shared space rules, and your community
                                 regulations.</p>
                                 <textarea className='form-control'></textarea>
                                 <hr />
                                 <div className='d-flex justify-content-between'>
                                    <Button variant="Link">cancel</Button>
                                    <Button variant="" className="btn-theme">save</Button>
                                 </div>
                            </div>
                        </li>

                        

                    </ul>
            </div>
        </div>
        <div className='fixedbtn'>
            <button className='btn btn-theme'>Save</button>        </div>
       </div>
    )
}

export default SetRules