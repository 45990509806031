import React,{useState} from 'react';
import { useEffect } from 'react';
import {Modal,Button} from 'react-bootstrap'
import Scrollbars from 'react-custom-scrollbars';

const AcModal = (props) =>{
    const[aircondition , setAircondition] = useState(true)
    const[actype , setActype] = useState([])
    const getValue = (e) =>{
   
     if(e.target.checked){
        setActype(data => [...data,  e.target.value]);
     }
     else{
        var arr= actype.filter(
            (car) => ((car != e.target.value))
        );
        setActype(arr)
     }
    }
    
    props.conts(actype)

    return(
      <>
      <Modal show={aircondition} centered scrollable size="md" className='modal_style scenic listingmodals'>
          <Modal.Header className='border-0 pb-0'>
            <button type="button" className="btn close close_modal" onClick={() =>props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
          </Modal.Header>
          <Modal.Body>
            <h4 className='mb-0'>Air conditioning details</h4>
            <p className='text-muted mb-4'>Views so photo-worthy that they'll wow every guest.</p>
            <Scrollbars style={{  height: 300  }}>
                <div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Air conditioning details</p>
                        </label>
                        <input class="form-check-input" onChange={(e) => getValue(e)} type="checkbox" value="Air conditioning details" id="flexCheckChecked" />
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Portable air conditioning</p>
                        </label>
                        <input class="form-check-input" onChange={(e) => getValue(e)} type="checkbox" value="Portable air conditioning" id="flexCheckChecked"/>
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Window AC unit</p>
                        </label>
                        <input class="form-check-input" onChange={(e) => getValue(e)} type="checkbox" value="Window AC unit" id="flexCheckChecked" />
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Split-type ductless system</p>
                        </label>
                        <input class="form-check-input" onChange={(e) => getValue(e)}  type="checkbox" value="Split-type ductless system" id="flexCheckChecked" />
                    </div>
                </div>
            </Scrollbars>
          </Modal.Body>
          <Modal.Footer className='jc-between'>
            <button className='btn btn-theme' >Save</button>
            <Button className='text-dark' variant="link" >Cancel</Button>
          </Modal.Footer>
      </Modal>
      </>
    )
}

export default AcModal