import React, { useState } from 'react';
import {Modal, Button,Form} from 'react-bootstrap'
const Datepicker = React.lazy(() => import('../../../Common/Datepicker/Datepicker.js'));

const AdNoticeModal = (props) =>{
    const[adnotice, setAdNotice] = useState(true)
    const[selectday, setSelectDay] = useState(true)

const setDay = (e) =>{
    console.log(e.target.value,document.getElementById("daysel").value);
    e.target.value == "val1" ? setSelectDay(true) : setSelectDay(false);
    console.log("selectday",selectday);
}









    return(
        <Modal show={adnotice} centered scrollable className='modal_style' size="lg">
        <Modal.Header className='border-0 pb-0'>
          <button type="button" className="btn close close_modal" onClick={() =>props.onDismiss()}><span aria-hidden="true">×</span></button>
      </Modal.Header>
          <Modal.Body>
          <div className='bg-white'>
                <div className='loca'>
                    <h4>Advance notice</h4>
                    <div className='mt-4 h-55'>
                        <div className='spaninput'>
                            <Form.Select aria-label="Default select example" id="daysel" onChange={(e) =>setDay(e)}>
                                <option value="val1">Same day</option>
                                <option value="val2">At least 1 day's notice</option>
                                <option value="val3">At least 2 day's notice</option>
                                <option value="val4">At least 3 day's notice</option>
                                <option value="val5">At least 7 day's notice</option>
                            </Form.Select>
                           
                        </div>

                         <div className={!selectday ? "mt-4 d-none" : "mt-4"}>
                                <p> Same-day check-ins can be booked until:</p>
                                <Form.Select aria-label="Default select example">
                                    <option value="1">11.59 pm</option>
                                    <option value="2">10.59 pm</option>
                                </Form.Select>
                         </div>
                        <span className='f-12'>
                            This is the minimum amount of time you’ll have between when a guest books and when they check in.
                        </span>
                           
                     
                    </div>

                    
                    
                </div>
          </div>
          </Modal.Body>
          <Modal.Footer className='d-flex justify-content-between'>
                   
                        <Button variant="Link" >Cancel</Button>
                        <Button className='btn-theme'>Save</Button>
                   
          </Modal.Footer>
      </Modal>
    )
}

export default AdNoticeModal