
import React, { Component, useCallback, useEffect, useState } from 'react';
import { propTypes } from 'react-bootstrap/esm/Image';

import Scrollbars from 'react-custom-scrollbars';

import img1 from '../../../Assets/images/filter/beaches.png'
import img2 from '../../../Assets/images/filter/bed.png'
import img3 from '../../../Assets/images/filter/camp.png'
import img4 from '../../../Assets/images/filter/cave.png'
import img5 from '../../../Assets/images/filter/design.png'
import img6 from '../../../Assets/images/filter/island.png'
import img7 from '../../../Assets/images/filter/lake.png'
import img8 from '../../../Assets/images/filter/national.png'
import img9 from '../../../Assets/images/filter/omg.png'
import img10 from '../../../Assets/images/filter/pools.png'
import img11 from '../../../Assets/images/filter/tiny.png'

import { API_URL } from '../../../config/env';
const RoomCategory = (props) => {
    
    const [amenitiesList, setAmenitiesList] = useState(localStorage.getItem("roomCategoryIndex") ? 
                    JSON.parse(localStorage.getItem("roomCategoryIndex")) : []); 
    const [selectedIndex, setSelectedIndex] = useState(localStorage.getItem("category") 
    ? JSON.parse(localStorage.getItem("category")) : []);
    // var index = [];
    var category = [];
    const[checked,setCheckrender] = useState(false)
    //  const setData = useCallback(async (val,index,path) => {
    //     category = amenitiesList;
    //     console.log("val in path" , val , index , path);
    //     let pathData = {imagePath:path}

    //     if(!category.includes(val?.type)){
            // setAmenitiesList([...amenitiesList,val])
            // setSelectedIndex([...selectedIndex,index])
            // index.push(val);
            // console.log("selectedIndex in categories list :",[...selectedIndex,index]);
            // localStorage.setItem("roomCategoryIndex",JSON.stringify([...selectedIndex,index]));
        //     category.push(val?.type);
        //     setAmenitiesList(category);
        //     console.log("Category :",[...selectedIndex,index]);
        //     localStorage.setItem("roomCategoryIndex",JSON.stringify(category));
        // }
        // else if(amenitiesList.includes(val?.type)){
        //    let inx = amenitiesList?.indexOf(val?.type);
        //    if(inx>-1){
        //     category?.splice(inx , 1);
        //     setAmenitiesList(category);
        //    }

            // const filteredArray = amenitiesList.filter((item) => item !== val);
            //  const filteredIndex = selectedIndex.filter((item) => item !== index);
            // setAmenitiesList(filteredArray)
            // setSelectedIndex(filteredIndex)
            // console.log("filteredIndex in categories list :",filteredIndex);
            // localStorage.setItem("roomCategoryIndex",JSON.stringify(filteredIndex));
    //         console.log("category :",category);
    //         localStorage.setItem("roomCategoryIndex",JSON.stringify(category));
    //          }
             
    //            props.onGetPlaceCategory(amenitiesList);
    // },[amenitiesList]);

    const setData = async(val,index,path) => {
        category = amenitiesList;
        var index = selectedIndex;
        console.log("val in path eiji" ,  category);
        if(!category?.includes(val?.type)){
            console.log("val in include eiji" , val?.type);
            category?.push(val?.type);
            index?.push(val);
            setAmenitiesList(category);
            setSelectedIndex(index);
            console.log("Category :",category);
            localStorage.setItem("roomCategoryIndex",JSON.stringify(category));
        }
        else if( category?.includes(val?.type)){
            console.log("val in not  include eiji" , val?.type);
           let inx = category?.indexOf(val?.type);
           console.log('splice',inx)
           if(inx>-1){
            category?.splice(inx , 1);
            index?.splice(inx,1);
            setAmenitiesList(category);
            setSelectedIndex(index);
           }

            console.log("category :",category);
            localStorage.setItem("roomCategoryIndex",JSON.stringify(category));
             }
             console.log('getplacecategory',index)
            props.onGetPlaceCategory(index);
            setCheckrender(!checked)
    }

    useEffect(()=>{
        props?.onstatus();
        // localStorage.getItem("roomCategoryIndex") &&setAmenitiesList(JSON.parse(localStorage.getItem("roomCategoryIndex")));
        // localStorage.getItem("category") &&setSelectedIndex(JSON.parse(localStorage.getItem("category")));
        // setSelectedIndex((localStorage.getItem("roomCategoryIndex"))?JSON.parse(localStorage.getItem("roomCategoryIndex")):selectedIndex);
        (localStorage.getItem("category"))?props.onGetPlaceCategory(JSON.parse(localStorage.getItem("category"))):localStorage.removeItem("roomCategoryIndex")
    },[selectedIndex , amenitiesList,localStorage.getItem("category")]);

    // const setData = useCallback(async (val,index,path) => {
    //     console.log("set data", val , index , path);
    //     let arrayData = [];
    //     let arrayIndex = [];
    //     arrayData = amenitiesList;
    //     arrayIndex = selectedIndex;

    //     let pathData = {imagePath:path}

    //     if(!arrayData.includes(val)){
    //         alert("1")
    //         console.log("value " , val)
    //     arrayData.push(val);
    //     arrayIndex.push(index);

    //     }
    //     else if(arrayData.includes(val)){
    //         alert("2")

    //         const filteredArray = arrayData.filter((item) => item !== val);
    //         console.log("filteredArray",filteredArray)
    //         arrayData=(filteredArray);
    //         const filteredIndex = arrayIndex.filter((item) => item !== index);
    //         console.log("filteredIndex",filteredIndex)

    //         arrayIndex=(filteredIndex);

    //     }
    //     console.log("arrayIndex",arrayIndex);
    //     setAmenitiesList(arrayData);
    //     setSelectedIndex(arrayIndex);
    //     // dummyArr.push(arrayIndex)
        
    //     // props.onGetAmenities(arrayData);
    // },[selectedIndex,setAmenitiesList])
// console.log("selectedIndex",selectedIndex)
    // useEffect(()=>{
        
    // },[selectedIndex]);

    return(
        <div className='col-md-12'>
        <div className='rightcont rightcont placetype mb-5'>
            <Scrollbars style={{ height: "calc(100vh - 320px)"}} className="scroll">
                {/* <h6 className='my-2'>Tell guest what your place has to offer</h6> */}
                <div className='row  px-3  align-items-center pb-5 mt-1' data-aos="fade-up">
                    
                    {props.data && props.data.content.map((val,index)=>
                       <>
                       {console.log("indedx category: " , val,index,props.data.path)}
                        <div className={(amenitiesList && amenitiesList?.includes(val?.type))?'col-md-4 amenities active':'col-md-4 amenities '}>
                            {/* {console.log("indedx : " , index ,selectedIndex.includes(index))} */}
                        <button onClick={()=>{setData(val,index,props.data.path)}} className=''>
                            <img src={API_URL+props.data.path+"/"+val.image} />
                            <p>{val.type}</p>
                        </button>
                    </div>
                    </>
                    )}
                    

                </div> 
            </Scrollbars>
        </div>
        </div>
    )
}

export default RoomCategory