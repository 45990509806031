import React,{useState} from 'react';
import {Modal, Button,Form} from 'react-bootstrap'
import FilterByListing from './FilterByListing';




  const FilterMessage = (props) =>{  
    const[filtermsg, setFilterMsg] = useState(true)
    const[filterlist, setFilterList] = useState(false)

    const[selecteds , setSelecteds] = useState("No filter applied") 

const setShowNext = () =>{
    setFilterList(true)
    document.getElementById("filtermsg").style.opacity = "0"
}
const selectCount = (data) =>{
    setSelecteds(data + " Selected")
}
const clearall = () =>{
document.querySelectorAll("#filtermsg input").forEach((e) =>{
   e.checked = false
})
}

    return(
        <>
        {filterlist && <FilterByListing onDismiss={() =>setFilterList(false) } func={selectCount}/>}
        <Modal show={filtermsg} id="filtermsg" centered scrollable className='modal_style'>
        <Modal.Header className='border-0 pb-0'>
            <button type="button" className="btn close close_modal" onClick={() =>props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
        </Modal.Header>
            <Modal.Body>
                <div id='filtermsg'>
                <h4 className='mb-4'>Filter Message</h4>
                <Button variant="link" onClick={() => setShowNext()} className="d-flex ps-0 text-start align-items-center jc-between w-full nounder text-dark">
                    <div>
                        <h6>Listing</h6>
                        <p className='text-muted'>{selecteds} </p>
                    </div>
                    <span className='fa fa-chevron-right'></span>
                </Button>

                <hr className='themehr' />
                <h6>Message Type</h6>
                <div className='msgtype'>
                   <Form.Check label="Unread" type="checkbox"/>
                   <Form.Check label="Stared" type="checkbox" />
                </div>
                <hr className='themehr' />
                <h6>Trip Stage</h6>
               <div className='tripstage'>
                        <Form.Check label="Enquiry or request" type="checkbox"/>
                        <Form.Check label="Check-in" type="checkbox" />
                        <Form.Check label="Check-out" type="checkbox" />
                        <Form.Check label="Post-trip" type="checkbox" />
                        <Form.Check label="Pre-trip" type="checkbox" />
                        <Form.Check label="Pre-trip" type="checkbox" />
                        <Form.Check label="Review needed" type="checkbox" />
               </div>
               </div>
           
          </Modal.Body>
          <Modal.Footer className='d-flex jc-between'>
              <Button variant='link' className='text-dark' onClick={() => clearall()}>Clear</Button>
              <button className='btn btn-theme' onClick={() => props.onDismiss()}>Apply</button>
           </Modal.Footer>
      </Modal>
      </>
    )
}


export default FilterMessage