import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {Form} from 'react-bootstrap'
import WeeklyDisc from '../Modals/WeeklyDiscModal';
import MonthlyDisc from '../Modals/MonthlyDiscModal';

const PromotList = () =>{
    const history = useNavigate();
    const[weeklydisc, setWeeklyDisc] = useState(false)
    const[monthlydisc, setMonthlyDisc] = useState(false)

    return(
        <div className='container container_custom'>
            {weeklydisc && <WeeklyDisc onDismiss={() => setWeeklyDisc(false)}/>}
            {monthlydisc && <MonthlyDisc onDismiss={() => setMonthlyDisc(false)}/>}
        <button className='backbtn my-5' onClick={() => {history('/hosting', {state:"custom"}) }}><span className='fa fa-chevron-left me-2'></span> Back</button>
        <div className='row justify-content-center'>
            <div className='col-md-5'>
                <h2>Promote your listing</h2>
                <p className='mb-5'>Offering discounts could help you attract guests and get your first reviews. 
                    <a href="#" className='text-dark'> Learn more</a></p>
                    <ul className='ps-0 setrule'>
                    <li>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <p className='mb-0'>20% off for your first 3 guests</p>
                                    <p className='text-muted f-14 mb-0'>Applies to your nightly rate before taxes and fees. Discount expires after your first 3 bookings or after 30 days, 
                                    whichever comes first.</p>
                                    </div>
                                    <div className='d-flex'>
                                    <Form.Check type="switch" id="custom-switch" />
                                </div>
                            </div>
                            <hr />
                        </li>
                        <li>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <p className='mb-0'>Set a weekly discount</p>
                                    <p className='text-muted f-14 mb-0'>Not set</p>
                                    </div>
                                    <div className='d-flex'>
                                        <a href="#" className='text-dark' onClick={() => setWeeklyDisc(true)}>Edit</a>
                                    </div>
                                </div>
                            <hr />
                        </li>
                        <li>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <p className='mb-0'>Set a monthly discount</p>
                                    <p className='text-muted f-14 mb-0'>Not set</p>
                                    </div>
                                    <div className='d-flex'>
                                    <a href="#" className='text-dark' onClick={() => setMonthlyDisc(true)}>Edit</a>
                                </div>
                              
                            </div>
                        </li>

                    </ul>
            </div>
        </div>
       </div>
    )
}

export default PromotList