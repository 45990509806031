import React, { Component, useState, useRef, useEffect } from "react";
import Scrollbars from "react-custom-scrollbars";


import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import { Form, Tooltip, Overlay } from "react-bootstrap";

import { propTypes } from "react-bootstrap/esm/Image";

const SetPrice = (props) => {
  const [show, setShow] = useState(false);
  const [showinfo, setShowInfo] = useState(false);
  const target = useRef(null);
  const targetinfo = useRef(null);

  const [price, setPrice] = useState("1");

  const [startDate, setStartDate] = useState(new Date()?.setHours(12 , 0 , 0));
  const [enddate , setEnddate] = useState(new Date()?.setHours(11,0,0));
  // const [startdateref , setStartdateref] = useState("")

  const setData = (val, type) => {
    console.log("val" , type , val);
    var amount = 0;
    if (type == "add") {
      amount = parseFloat(price || 1) + 100;
      console.log("amount in add" , amount , price || 1+100 , price|| 1);
    } else if (type == "sub") {
      amount = parseFloat(price || 1) - 100;
      console.log("amount in sub" , amount);
    } else {
      amount = parseFloat(val);
      console.log("amount in o" , amount);
    }
    setPrice(amount);
    let payload = {
      price : amount ,
      checkin : new Date(startDate).getTime(),
      checkout : new Date(enddate).getTime()
    }
    props.onGetPrice(payload);
  };

  const checkin = (val) =>{
    console.log("checkin" , val);
    let payload = {
      price : price ,
      checkin : new Date(val).getTime(),
      checkout : new Date(enddate).getTime()
    }
    props.onGetPrice(payload);
  }

  const checkout = (val) =>{
    console.log("checkout" , val);
    let payload = {
      price : price ,
      checkin : new Date(startDate).getTime(),
      checkout : new Date(val).getTime()
    }
    props.onGetPrice(payload);
  }

  let handleColor = (time) => {
    return time.getHours() > 12 ? "text-success" : "text-error";
  };

  useEffect(() => {
    setPrice(
      localStorage.getItem("price")
        ? parseInt(localStorage.getItem("price"))
        : price
    );
    localStorage.getItem("price")
      ? props.onGetPrice(parseInt(localStorage.getItem("price")))
      : localStorage.removeItem("price");
  }, []);

  return (
    <div className="col-md-12">
      <div className="placetype mb-5 placetype_heoght_fix">
        <Scrollbars style={{ height: "calc(100vh - 320px)" }} className="scroll">
          <div
            className="row  px-3 text-center mt-3 align-items-center pb-5"
            data-aos="fade-up"
          >
            {console.log("startdate , enddate" , startDate , enddate)}
            <div>
              <div className="d-flex countbtns">
                <button
                  className="countbtn"
                  disabled={price < 100}
                  onClick={() => {
                    setData(0, "sub");
                  }}
                >
                  -
                </button>
                <input
                  type="Number"
                  value={price}
                  placeholder="00"
                  onChange={(e) => {
                    setData(e.target.value);
                  }}
                />
                <button
                  className="countbtn"
                  onClick={() => {
                    setData(0, "add");
                  }}
                >
                  +
                </button>
              </div>
              {/* <h5 className="fw-normal my-5 text-dark h5_lie_nmo">
                Keep in mind that places like yours usually range from $1,523 to
                $2,538. <a
                    href="#"
                    className="text-dark"
                    onClick={() => setShowInfo(!showinfo)}
                    ref={targetinfo}
                  >
                    <span className="fa fa-info-circle ms-2" />
                  </a>
              </h5> */}
              <div>

                <div className="flex_time_div">
                  <div className="mx-1">
                    <p className="pb-3">Check-in Time</p>

                    <DatePicker  
      showTimeSelect
      showTimeSelectOnly
      dateFormat="h:mm aa"
      timeFormat="h:mm aa"
      selected={new Date(startDate)}
      // showIcon
      onChange={(date) => {
        // console.log("start time" , new Date(date)?.getHours()); 
        setStartDate(date)
        checkin(date);
      }}
      timeClassName={handleColor}
      // dateFormat="LLL"
    />


                    {/* <input
                    className="form-control"
                type="time"
                placeholder="Time"
                onChange={(e) => console.log("time input" , e)}
        /> */}
                  </div>

                  <div className="mx-1">
                    <p className="pb-3">Check-out Time</p>
                    <DatePicker  
                      showTimeSelect
                      showTimeSelectOnly
                      dateFormat="h:mm aa"
                      timeFormat="h:mm aa"
                      selected={new Date(enddate)}
                      // showIcon
                      onChange={(date) => {
                        setEnddate(date);
                        checkout(date);
                      }}
                      timeClassName={handleColor}
                      // dateFormat="LLL"
    />

                    {/* <input
                    className="form-control"

                type="time"
                placeholder="Time"
                onChange={(e) => console.log("time input" , e)}
        /> */}
                  </div>
                </div>
            
              </div>
              {/* <div className='bordered d-flex text-start mt-5 p-3 align-items-center'>
                         <p className='mb-0'>
                         Offer a 20% discount for your first 3 guests to help you get booked faster. 
                         <a href="#" className='text-dark' ref={target}  onClick={() => setShow(!show)}><b>
                            Learn more</b>
                            </a>
                  
                         
                         
                         </p>
                         <Form.Check  type="checkbox" />
                      </div> */}
            </div>
          </div>
        </Scrollbars>
      </div>
      <Overlay target={target.current} show={show} placement="right">
        <Tooltip id="overlay-example">
          When you offer this promotion, your first 3 guests get 20% off your
          nightly price. It expires after your first 3 reservations are made or
          30 days after you publish your listing, whichever happens first.
          <a href="#" className="text-dark">
            Get details
          </a>
        </Tooltip>
      </Overlay>
      <Overlay target={targetinfo.current} show={showinfo} placement="left">
        <Tooltip id="overlay-example1">
          Suggested prices are based on your listing’s location and amenities,
          as well as nearby prices and demand. You choose your price, and you
          can adjust it at any time.
        </Tooltip>
      </Overlay>
    </div>
  );
};

export default SetPrice;
