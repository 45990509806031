import axios from "axios";
import Cookies from 'universal-cookie';
import * as api from '../config/adminRouteFront';
import {API_URL} from '../config/env';
const cookies = new Cookies();

export const getsingleuserdisputehook = async(data) => {
    console.log("getfilter option data" , data);
    var formdata = new FormData();
    try{
      if(data){
        formdata.append("id" , data);
      }
      try {
        let respdata = await axios({
          'method': 'POST',
          'url': API_URL + api.getsingleuserdispute,
          'credentials': true,
          'headers': {
            'content-Type':'multipart/form-data',
          },
          data: formdata,
        });
        console.log("response",respdata);
        return {
          data: respdata.data
        }
      }
      catch (err) {
        return {
          error: err
        }
      }
    }
    catch(e){
      console.log("error" , e);
    }
  }

  export const raisedisputehook = async(data) => {
    console.log("getfilter option data" , data);
    var formdata = new FormData();
    try{
      if(data){
        formdata.append("raiser" , data?.raiser);
        formdata.append("message" , data?.message);
        formdata?.append("image" , data?.image);
      }
      try {
        let respdata = await axios({
          'method': 'POST',
          'url': API_URL + api.raisedisputeApi,
          'credentials': true,
          'headers': {
            'content-Type':'multipart/form-data',
          },
          data: formdata,
        });
        console.log("response",respdata);
        return {
          data: respdata.data
        }
      }
      catch (err) {
        return {
          error: err
        }
      }
    }
    catch(e){
      console.log("error" , e);
    }
  }

  export const disputechathook = async(data) => {
    console.log("getfilter option data" , data);
    var formdata = new FormData();
    try{
      if(data){
        formdata.append("ref" , "txt");
        formdata.append("sender" , data?.sender);
        formdata.append("message" , data?.message);
        formdata?.append("id" , data?.id);
      }
      try {
        let respdata = await axios({
          'method': 'POST',
          'url': API_URL + api.disputechatApi,
          'credentials': true,
          'headers': {
            'content-Type':'multipart/form-data',
          },
          data: formdata,
        });
        console.log("response",respdata);
        return {
          data: respdata.data
        }
      }
      catch (err) {
        return {
          error: err
        }
      }
    }
    catch(e){
      console.log("error" , e);
    }
  }

  export const disputechatwithimghook = async(data) => {
    console.log("getfilter option data" , data);
    var formdata = new FormData();
    try{
      if(data){

        formdata?.append("folpath" , data?.folpath);
        formdata?.append("id" , data?.id);
        formdata.append("sender" , data?.sender);
        formdata.append("message" , data?.message);
        formdata?.append("image" , data?.image);
      }
      try {
        let respdata = await axios({
          'method': 'POST',
          'url': API_URL + api.disputechatwithimgApi,
          'credentials': true,
          'headers': {
            'content-Type':'multipart/form-data',
          },
          data: formdata,
        });
        console.log("response",respdata);
        return {
          data: respdata.data
        }
      }
      catch (err) {
        return {
          error: err
        }
      }
    }
    catch(e){
      console.log("error" , e);
    }
  }

  export const getsinglechathook = async(data) => {
    console.log("getfilter option data" , data);
    var formdata = new FormData();
    try{
      if(data){

        // formdata?.append("folpath" , data?.folpath);
        formdata?.append("id" , data);
        // formdata.append("sender" , data?.sender);
        // formdata.append("message" , data?.message);
        // formdata?.append("image" , data?.image);
      }
      try {
        let respdata = await axios({
          'method': 'POST',
          'url': API_URL + api.getsinglechatdisputeApi,
          'credentials': true,
          'headers': {
            'content-Type':'multipart/form-data',
          },
          data: formdata,
        });
        console.log("response",respdata);
        return {
          data: respdata.data
        }
      }
      catch (err) {
        return {
          error: err
        }
      }
    }
    catch(e){
      console.log("error" , e);
    }
  }

  export const closedisputehook = async(data) => {
    console.log("getfilter option data" , data);
    var formdata = new FormData();
    try{
      if(data){

        formdata?.append("ticketid" , data);
        // formdata?.append("id" , data?.id);
        // formdata.append("sender" , data?.sender);
        // formdata.append("message" , data?.message);
        // formdata?.append("image" , data?.image);
      }
      try {
        let respdata = await axios({
          'method': 'POST',
          'url': API_URL + api.closedisputeApi,
          'credentials': true,
          'headers': {
            'content-Type':'multipart/form-data',
          },
          data: formdata,
        });
        console.log("response",respdata);
        return {
          data: respdata.data
        }
      }
      catch (err) {
        return {
          error: err
        }
      }
    }
    catch(e){
      console.log("error" , e);
    }
  }