import React, { useEffect, useState } from 'react';
import {Modal} from 'react-bootstrap'
import languag from "../../../config/languages.json"
const AddLang = (props) =>{
    const[language , setLanguage] = useState(true)
    const[langlist, setLangList] = useState([])
    const [selected , setSelected] = useState([]);
    const [languages , setLanguages] = useState(languag);
    console.log("langlist----------",langlist)

    useEffect(() => {
        setSelected(props?.chosen);
    } , [props])
    const checklang = (e) =>{
        if(e.target.checked){
            console.log('e.target.checked',e.target.checked)
            let lang = e.target.previousSibling.children[0].innerHTML
            console.log("lang--------->",)
            setLangList(data => [...data, lang])
            props.choose([...langlist, lang]);
        }else{
           let  filtered = langlist.filter((val) => val != e.target.previousSibling.children[0].innerHTML)
           console.log('filtereddddddddddd',filtered)
           setLangList(filtered)
           props.choose(filtered);
        }
    }
    if(langlist.length > 0 )
    {
        
    }
    
    return(
        <>
          <Modal show={language} centered scrollable className='modal_style'>
            <Modal.Header className='border-0 pb-0'>
                 <button type="button" className="btn close close_modal" onClick={() =>props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
            </Modal.Header>
            <Modal.Body>
            <div className='bg-white listingmodals'>
                <h5>Languages I speak</h5>
                <p className='f-14'>We have many international travellers who appreciate hosts who can speak their language.</p>
                
                <div className='mt-4'>
                <input
                    type="text"
                 placeholder='Search Language'
                 onChange={async(e) => {console.log("filo" , e?.target?.value?.toLowerCase());
                    if(e?.target?.value?.length <=0){
                        setLanguages(languag);
                    }
                    else{
                        let searc= await languag?.filter(a => a.name?.toLowerCase().includes(e?.target?.value?.toLowerCase()))
                        setLanguages(searc)
                    }
                 }}
                    className="form-control mb-4 input_big_ne"
                  />


                    {languages.map((data)=>
                    <div class="form-check justify-content-end gap-2">
                    <label class="form-check-label" for="flexCheckChecked">
                        <p>{data.name}</p>
                    </label>
                    <input class="form-check-input" checked = {selected?.includes(data.name)} type="checkbox" value="Air conditioning details" onClick={(e) => checklang(e)} />
                    </div>
                    )}

                    {/* <div class="form-check justify-content-end gap-2">
                            <label class="form-check-label" for="flexCheckChecked">
                                <p>한국어</p>
                            </label>
                            <input class="form-check-input" type="checkbox" value="Air conditioning details" onClick={(e) => checklang(e)} />
                    </div>
                    <div class="form-check justify-content-end gap-2">
                            <label class="form-check-label" for="flexCheckChecked">
                                <p>日本語</p>
                            </label>
                            <input class="form-check-input" type="checkbox" value="Air conditioning details" onClick={(e) => checklang(e)}  />
                    </div>
                    <div class="form-check justify-content-end gap-2">
                            <label class="form-check-label" for="flexCheckChecked">
                                <p>ภาษาไทย</p>
                            </label>
                            <input class="form-check-input" type="checkbox" value="Air conditioning details" onClick={(e) => checklang(e)}  />
                    </div>
                    <div class="form-check justify-content-end gap-2">
                            <label class="form-check-label" for="flexCheckChecked">
                                <p>Українська</p>
                            </label>
                            <input class="form-check-input" type="checkbox" value="Air conditioning details" onClick={(e) => checklang(e)}  />
                    </div> */}
                </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button onClick={() =>props.onDismiss()} className='btn btn-theme'>Done</button>
          </Modal.Footer>
      </Modal>
        </>
    )
}

export default AddLang