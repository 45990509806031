var Europe = [
    "Albania",
    "Andorra",
    "Austria",
    "Belarus",
    "Belgium",
    "Bosnia and Herzegovina",
    "United Kingdom"
];

var Unitedkingdom = Europe;
var Northamerica = [
    "Anguilla",
    "Antigua and Barbuda",
    "Aruba",
    "Bahamas",
    "Barbados"
];
var Unitedstates = Northamerica;
var canada = Northamerica;
var Middleeast = [
    " Algeria",
    "Bahrain",
    "Egypt"
]
 var Iamflexible = [
    "United Kingdom",
    "India",
    "Albania",
    "Andorra",
    "Austria",
    "Belarus",
    "Belgium",
    "Bosnia and Herzegovina",
    "Anguilla",
    "Antigua and Barbuda",
    "Aruba",
    "Bahamas",
    "Barbados",
    "Algeria",
    "Bahrain",
    "Egypt"
]
module.exports = {
    Iamflexible , Middleeast,canada,Unitedstates,Unitedkingdom,Europe
}