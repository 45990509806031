import React, { Component, useCallback, useEffect, useState } from "react";
import axios from "axios";

import emailLogo from "../../../Assets/images/email_logo.png";
import lockLogo from "../../../Assets/images/lock_logo.png";

import FacebookLogin from "react-facebook-login";
// import TwitterLogin from "react-twitter-login";
import InputGroup from "react-bootstrap/InputGroup";
import { Modal, Button, Form, Tab, Row, Col, Nav } from "react-bootstrap";


import OtpInput from 'react-otp-input';

import ConfirmLog from "../Modals/ConfirmLog";

import facebook from "../../../Assets/images/facebook.png";

import WelcomeModal from "./WelcomeModal";
import countrycode from "../../../config/countrycode.json";
import Debounce from "lodash.debounce";
import {
  userSignUp,
  userCheck,
  LoginUser,
  loginUserFacebook,
  loginUserGoogle,
  GetEmail,
  forgotpasswordhooks,
  checkotpforgotpasswordhooks,
  changepasswordhooks,
} from "../../../Hooks/useUserHook";
import { Loginusercheck } from "../../../Hooks/useUserHook";
import FinishSign from "./FinishSign";
import { toast, Toaster } from "react-hot-toast";
import { toastOptions } from "../../../config/env";

const ForgotModal = (props) => {
  const [showforgot, setShowForgot] = useState(true);
  const [isotp, setIsotpt] = useState(false);

  const [resetpassword, setResetpassword] = useState(false);
  
  const [showPassword,setShowPassword] = useState(false);
  const [showConfirmPassword,setShowConfirmPassword] = useState(false);

  const [email , setEmail] = useState("");
  const [emailerror , setEmailerror] = useState("");
  const [otp , setOtp] = useState("");
  const [otperror , setOtperror] = useState("");
  const [newpassword , setNewpassword] = useState("");
  const [newpassworderror , setNewpassworderror] = useState("");
  const [confirmnewpassword , setConfirmnewpassword] = useState("");
  const [confirmnewpassworderror , setConfirmpassworderror] = useState("");
  const [passrejx , setPassrejx] = useState(/^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*\W).{6,18}(?!\S)/);




  const emailValidation = () => {
    let emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([A-Za-zA-Z\-0-9]+\.)+[A-Za-zA-Z]{2,}))$/;
    const validated = emailRegex.test(email);
    console.log("validated datas", validated);
    return validated;
  };





  return (
    <div>
      <Modal
        show={showforgot}
        centered
        scrollable
        className="modal_style"
        id="fade"
      >
        {/* <Button className='btn w-full' onClick={() =>setShowWelcome(true) } variant="outline-dark">Continue</Button> */}

        <Modal.Header className="border-0 pb-0 d-flex justify-content-end">
          <button
            type="button"
            className="btn close close_modal"
            onClick={() => props.onDismiss()}
          >
            <span aria-hidden="true">×</span>
            {/* <span class="sr-only">Close</span> */}
          </button>
        </Modal.Header>

        <Modal.Body>
            <p className="modal_trle_sta">{resetpassword?"Reset Password":"Forgot Password"}</p>
        <Form>
      
                          {
                            isotp?

                            resetpassword?
                            <>
                            <InputGroup className="mb-3 email-text-field">
                            <InputGroup.Text id="basic-addon1">
                              <img
                                src={lockLogo}
                                alt="emailid"
                                className="input-logos"
                              />
                            </InputGroup.Text>
                            <Form.Control
                              placeholder="Password"
                              className=""
                              aria-label="Username"
                              id="password"
                              type={showPassword?"text":"password"}
                              onChange = {(e) => {
                                setNewpassword(e?.target?.value);
                              }}
                            />
                              <InputGroup.Text id="basic-addon1" className="cursor_eye" onClick={()=>{setShowPassword(!showPassword)}}>
                            <i className={!showPassword?"fa fa-eye":"fa fa-eye-slash"} />
                            </InputGroup.Text>
                          </InputGroup>
                          <Form.Text className="text-danger">
                            {newpassworderror}
                          </Form.Text>

                          <InputGroup className="mb-3 email-text-field">
                            <InputGroup.Text id="basic-addon1">
                              <img
                                src={lockLogo}
                                alt="emailid"
                                className="input-logos"

                              />
                            </InputGroup.Text>
                            <Form.Control
                              placeholder="Confirm Password"
                              className=""
                              aria-label="Username"
                              id="confirmpassword"
                              type={showConfirmPassword?"text":"password"}
                              onChange = {(e) => {
                                setConfirmnewpassword(e?.target?.value);
                              }}
                            />
                              <InputGroup.Text id="basic-addon1" className="cursor_eye" onClick={()=>{setShowConfirmPassword(!showConfirmPassword)}}>
                            <i className={!showConfirmPassword?"fa fa-eye":"fa fa-eye-slash"} />
                            </InputGroup.Text>
                          </InputGroup>
                          <Form.Text className="text-danger">
                            {confirmnewpassworderror}
                          </Form.Text>
                          <div className="d-flex justify-content-center align-items-center">
                          <Button
                            className="btn btn-theme w-full mt-3 login-continue-btn"
                            variant="outline-dark"
                            onClick={async() => {
                              if(!passrejx.test(newpassword)){
                                setNewpassworderror("PASSWORD MUST HAVE ONE SPECIAL CHARACTER ONE NUMERIC AND ONE UPPERCASE!")
                              }
                              else{
                                setNewpassworderror("")
                              }
                              if(!passrejx.test(confirmnewpassword)){
                                setConfirmpassworderror("PASSWORD MUST HAVE ONE SPECIAL CHARACTER ONE NUMERIC AND ONE UPPERCASE!")
                              }
                              else{
                                setConfirmpassworderror("")
                              }
                              if(newpassword!=confirmnewpassword){
                                setConfirmpassworderror("Confirm password must be same as new password!")
                              }
                              else{
                                setConfirmpassworderror("")
                              }
                              if(newpassword && confirmnewpassword && passrejx.test(newpassword) && passrejx.test(confirmnewpassword)
                              && newpassword==confirmnewpassword){
                                let payload = {
                                  email : email,
                                  newpassword : newpassword
                                }
                                let res = await changepasswordhooks(payload);
                                if(res?.data?.type == "success"){
                                  toast.success(res?.data?.message); 
                                  props.onDismiss()
                                }
                              }
                            }}
                          >
                            Submit
                          </Button>
                        </div>
                        </>
                            :
                            
                            <>
<p>Enter your OTP</p>
                          <div className='otp_input_box mt-3'>
                          <OtpInput
      value={otp}
      onChange={setOtp}
      numInputs={4}
      renderSeparator={<span>-</span>}
      renderInput={(props) => <input {...props} />}
    />
 <Form.Text className="text-danger">
                            {otperror}
                          </Form.Text>
    </div>

    <div className="d-flex justify-content-center align-items-center">
                        <Button
                          className="btn btn-theme w-full mt-4 login-continue-btn"
                          onClick={async()=>{
                            let payload = {
                              otp : otp,
                              email : email
                            }
                            let res = await checkotpforgotpasswordhooks(payload);
                            console.log("result in check otp forgot password" , res);
                            if(res?.data?.status == true){
                              setResetpassword(true)
                            }
                            else{
                              setOtperror(res?.data?.message);
                            }
                          }}
                          variant="outline-dark"
                          disabled={otp && otp?.toString()?.length!=4}
                        >
                          Submit
                        </Button>
                      </div>
    </>
    :
    <>
    <InputGroup className="mb-3 email-text-field">
    <InputGroup.Text id="basic-addon1">
      <img
        src={emailLogo}
        alt="emailid"
        className="input-logos"
      />
    </InputGroup.Text>
    <Form.Control
      placeholder="Email"
      className=""
      aria-label="Username"
      // id="password"
      onChange = {(e) => setEmail(e?.target?.value)}
    />
    
  </InputGroup>
  <Form.Text className="text-danger">
                            {emailerror}
                          </Form.Text>
  <div className="d-flex justify-content-center align-items-center">
  <Button
    className="btn btn-theme w-full mt-3 login-continue-btn"
    disabled = {!emailValidation(email)}
    variant="outline-dark"
    onClick={async()=>{
      if(!emailValidation(email)){
        setEmailerror("Invalid Email!")
      }
      else{
        let payload = {
          email : email
        }
        let res = await forgotpasswordhooks(payload);
        console.log("result" , res);
        if(res?.data?.type == "success"){
          setIsotpt(true)
        }
        else if(res?.data?.type == "nots"){
          toast.error(res?.data?.message);
        }
      }
    }}
  >
    Submit
  </Button>
</div>
</>

                          }


                          
           
           
         {!isotp?
            <p className='f-12 mt-4 pb-4'>We’ll send you a password reset link  to reset your password. Standard message and data rates apply.</p>
:
<p className='f-12 mt-4 pb-4'></p>
}           
        
           


         
           </Form>
        </Modal.Body>
      </Modal>
      <Toaster />

   

    </div>
  );
};

export default ForgotModal;
