import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { getExperienceFromLocal } from '../../../../Hooks/useUserExperienceHook';

const ExpTitle = (props) =>{

    const [title, setTitle] = useState(""); 

    const putdata = (e) => {
        props.exptitle(e.target.value);
        setTitle(e.target.value);
    }

    useEffect(()=>{
        setTitle( getExperienceFromLocal()&&getExperienceFromLocal().title?getExperienceFromLocal().title : title )
        props.exptitle(getExperienceFromLocal()&&getExperienceFromLocal().title?getExperienceFromLocal().title : title );
    },[]);

    return(
        <>
                <div>
                    <h2 className='conthead'>Title your experience</h2>
                    <p className='text-muted f-14'>Choose the category that best describe your experience. Add a second,
                        if you think it fits into another category.
                    </p>
                    <div className='h-55 mt-4'>
                       <input type="text" 
                       placeholder={"Choose Title"} 
                       value={title}
                       className='form-control' onChange={(e) => putdata(e)} />
                   <div className='mt-4'><button className='btn btn-theme' disabled={!title} onClick={() => {props.onSetLocal(title,"title");props.count()}}>Save & Continue</button></div> 
                    </div>
                    
              </div>  
        </>

    )
}

export default ExpTitle