import React,{useState} from 'react';
import { useEffect } from 'react';
import {Modal,Button} from 'react-bootstrap'
import Scrollbars from 'react-custom-scrollbars';

const CoffeeMaker = (props) =>{
    const[coffee , setCoffee] = useState(true)
  
    return(
      <>
      <Modal show={coffee} centered scrollable size="md" className='listingmodals modal_style scenic'>
          <Modal.Header className='border-0 pb-0'>
            <button type="button" className="btn close close_modal" onClick={() =>props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
          </Modal.Header>
          <Modal.Body>
            <h4 className='mb-0'>Coffee maker details</h4>
            <Scrollbars style={{  height: 400  }}>
                <div>
                    <h6 className='my-4'>Type</h6>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Filter coffee maker</p>
                        </label>
                        <input class="form-check-input" type="checkbox" value="Portable air conditioning" id="flexCheckChecked"/>
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Espresso machine</p>
                        </label>
                        <input class="form-check-input" type="checkbox" value="Window AC unit" id="flexCheckChecked" />
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Cafetière</p>
                        </label>
                        <input class="form-check-input"  type="checkbox" value="Split-type ductless system" id="flexCheckChecked" />
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Keurig coffee machine</p>
                        </label>
                        <input class="form-check-input" type="checkbox" value="Air conditioning details" id="flexCheckChecked" />
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Nespresso</p>
                        </label>
                        <input class="form-check-input"  type="checkbox" value="Split-type ductless system" id="flexCheckChecked" />
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Pour-over coffee</p>
                        </label>
                        <input class="form-check-input" type="checkbox" value="Air conditioning details" id="flexCheckChecked" />
                    </div>
                </div>
            </Scrollbars>
          </Modal.Body>
          <Modal.Footer className='jc-between'>
            <button className='btn btn-theme' >Save</button>
            <Button className='text-dark' variant="link" >Cancel</Button>
          </Modal.Footer>
      </Modal>
      </>
    )
}

export default CoffeeMaker