import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import { withGoogleMap, GoogleMap } from 'react-google-maps'

const apiKey = 'AIzaSyDwQOCtNC-XQRRYsZIg5lXexgr-CrOEXrw';
const mapApiJs = 'https://maps.googleapis.com/maps/api/js';
const geocodeJson = 'https://maps.googleapis.com/maps/api/geocode/json';

const AirbnbMap = withGoogleMap(props => (
  <GoogleMap
    defaultCenter={props.center}
    defaultZoom={props.zoom} />
));

const Experiencemap = (props) => {
  console.log('props.position',props.position)


  const loadMap = (map, maps) => {
    let marker = new maps.Marker({
      position: props.position,
      map,
    //   draggable: true,
      });
  };

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyDwQOCtNC-XQRRYsZIg5lXexgr-CrOEXrw' }}
        defaultCenter={props.position}
        defaultZoom={10}
        // onClick = {(e)=>{console.log("New Exact lat and long : ",e.lat,e.lng);props.onGetAddress({latitude:e.lat,longitude:e.lng}) }}
         yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => loadMap(map, maps)}
        onDragEnd={(map) => console.log("data : ",map)}
      />
    </div>
  );
};

export default Experiencemap;

