import React, {  useState } from 'react';
import {Button, Modal} from 'react-bootstrap'
import CoverModal from './CoverModal';



const Cancellation = (props) =>{
    const[cancel, setCancel] =useState(true)
    const[cover, setCover] = useState(false)
    const initialText = "We had great time with Akash! The meals we prepared were delicious and I'm hoping to be able to recreate them at home. Akash is very knowledgeable and a super likable guy. We had great time with Akash! The meals we prepared were delicious";
    const [text, setText] = useState(initialText)  
    const [showone, setShowone] = useState(false);

    return(
        <>  {cover && <CoverModal onDismiss={() => setCover(false)}/>}
            <Modal show={cancel} size="md" centered scrollable className='modal_style'>
                <Modal.Header className='border-0 pb-0 d-flex justify-content-end'>
                    <button type="button" className="btn close close_modal" onClick={() =>props.onDismiss()}><span aria-hidden="true" className='fa fa-times f-14'></span><span class="sr-only">Close</span></button>
                </Modal.Header>
                <Modal.Body>
                    <div className='cancelpolicy'>
                        <h3 className='text-heading'>Cancellation policy</h3>
                        <p className='text-muted mt-3'>
                        Before you book, make sure you're comfortable with this Host's 
                        cancellation policy. Keep in mind that Jetolet's <b><a href="javascript:void(0)" className='text-dark' onClick={ () => {setCover(true)}}>Extenuating Circumstances policy </a></b> doesn't cover
                         cancellations due to illness or travel disruptions caused by COVID-19.
                        </p>

                        <h5 className='my-4 text-heading-medium'>Cancel by</h5>

                        <div className='d-flex gap-5'>
                            <div className='noshrink'>
                                <p className=' f-14'><b>48 hours</b></p>
                                <p className='f-12 text-muted'>48 hours</p>
                            </div>
                            <p>Full refund: Get back 100% of what you paid.</p>
                        </div>

                        <hr className='themehr' />

                        <div className='d-flex gap-5'>
                            <div className='noshrink'>
                                <p className=' f-14'><b>5 Oct</b></p>
                                <p className='f-12 text-muted'>3:00 pm</p>
                            </div>
                            <p>Partial refund: Get back 50% of every night.
                                 No refund of the service fee.</p>
                        </div>

                        <hr className='themehr' />

                        <div className='d-flex gap-5'>
                            <div className='noshrink'>
                                <p className=' f-14'><b>12 Oct</b></p>
                                <p className='f-12 text-muted'>3:00 pm <br /> (check-in)</p>
                            </div>
                            <p>No refund</p>
                        </div>

                        <hr className='themehr' />

                        {/* <Button variant='link' className='text-dark' onClick={() => setShowone(!showone)}>Learn more about cancellation policies</Button> */}
                        <p className='mb-3'><a href="javascript:void(0)" className='link_blue_border' onClick={() => setShowone(!showone)}>Learn more about cancellation policies</a></p>
                        <p className='detail_desc_grey'>{showone ? text : ""}</p>
                         {/* <p className='mb-3'><a href="javascript:void(0)" className='link_blue_border' onClick={() => setShowone(!showone)}>{showone ? "Show Less" : "Show More"}</a></p> */}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}


export default Cancellation