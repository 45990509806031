import React, { useState, useEffect } from 'react';
import Header from '../Common/Header';
import img1 from '../../Assets/images/where/img11.png'
import user1 from '../../Assets/images/prof1.jpg'
import { InputGroup, Form } from 'react-bootstrap'
import Footer from '../Common/Footer';
import BookingPayCard from './BookingPayCard';
import BookCardModal from './Modal/BookCardModal';
import BookPayModal from './Modal/BookExpModal';
import { useLocation } from 'react-router-dom';
import { getUserDatas } from "../../Hooks/useUserHook";
import { API_URL } from '../../config/env';
import {bookingExperienceDetails} from '../../Hooks/useUserExperienceHook';
import {experienceCreateRazorPayment} from '../../Hooks/useUserExperienceHook'
import { useNavigate } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import ExperiencePaymentIntegrationModal from '../Common/Modals/ExperiencePaymentModel';
 const BookExp = (props) => {
    const [steps, setSteps] = useState("1")
    const [adduser, setAddUser] = useState(0);
    const [addGuestUser, setAddGuestUser] = useState(false);
    const [paypal, setPaypal] = useState(false)
    const [bookpaycard, setBookPayCard] = useState(false);
    const [userData, setUserData] = useState([]);
    const [updateGuest, setUpdateGuest] = useState({});
    const [guestData, setguestData] = useState({ firstName: '', lastName: '', email: '' });
    const [addGuest, setAddGuest] = useState([]);
    const { firstName, lastName, email } = guestData;
    const [addAnotherGuest, setAddAnotherGuest] = useState(false)
    const [showModel, setShowModel] = useState(false);
    const [experienceBooking, setExperienceBooking] = useState({});
    const location = useLocation();
    const push = useNavigate();


    const bookExperience = async () => {
        let data = {
            bookedBy: userData[0].email,
            hostEmail: location.state.datas.email,
            members: userData,
            date: location.state.date,
            totalPrice: ((location.state.datas.pricePerGuest * userData.length * 21/100) + (location.state.datas.pricePerGuest * userData.length)),
            serviceFee: location.state.datas.pricePerGuest * userData.length * 21/100,
            experienceId: location.state.datas._id,
            experienceTitle: location.state.datas.title
        }
        await bookingExperienceDetails(data).then( (result) => {
            console.log('bookingExperienceDetails --------->',result)
            setExperienceBooking(result.record);
            console.log('PAYMENT DATA 1 : ',result);
            // setShowModel(true)
            window.location.href = `/host-dashboard/trips/experience/${userData[0].email}`
            // await experienceCreateRazorPayment(data)
            // const newdata = {date : data , datas :props.data }
            // push("/bookexp",{state: newdata}); 
        })
    }

    const setData = (e, index, type) => {
        if (type == "remove" && index > 0) {
            userData.splice(index, 1)
        } else if (type == "items") {
            // var guest = guestData
            // if(e?.target?.name == "firstName"){
            //     guest[index]?.firstName = e?.target?.value
            // }
            // else if(e?.target?.name == "lastName"){
            //     guest[index]?.lastName = e?.target?.value
            // }
            // else if(e?.target?.name == "email"){
            //     guest[index]?.email = e?.target?.value
            // }
            setguestData({ ...guestData, [e.target.name]: e.target.value })
        }
        // console.log("inside setData count : ", guestData.length, "itemData", guestData, "index:", index);
    }

    const addGuestData = (e, index) => {
        e.preventDefault();
        setUserData([...userData, guestData])
    }

        const selectoption = (e) => {
        e.target.value == "paypal" ? setPaypal(true) : setPaypal(false)
    }
    useEffect(() => {
    const userDataGet =  getUserDatas();
    { console.log('User Details --> ', userDataGet) }
    setUserData([...userData, { firstName: userDataGet?.firstName, lastName: userDataGet?.lastName, email: userDataGet?.email, profilePicturePath: userDataGet?.profilePicturePath }])
    }, [])


    console.log('userdata states', userData)
    return (
        <div className='bookingexp'>
            {bookpaycard && <BookPayModal onDismiss={() => setBookPayCard(false)} />}
            {/* <div className='mobileresevebtn'>
                <button className='btn btn-theme' onClick={() => setBookPayCard(true)}>Reserve</button>
            </div> */}
            <Header />
            <div className='container container-theme pagetop mb-4'>
                <div className='row'>
                    <div className='col-md-8 leftcont'>
                        <div className='shadowbox p-4 expsteps expsteps_repo'>
                            <div className='d-flex jc-between steps'>
                                <button className='' onClick={() => setSteps(1)}>
                                    <span>1</span>
                                    <span>Review guest requirement</span>
                                </button>
                                <button className='disabled' onClick={() => setSteps(2)}>
                                    <span>2</span>
                                    <span>Who's coming</span>
                                </button>
                                <button className='disabled' onClick={() => setSteps(3)}>
                                    <span>3</span>
                                    <span>Confirm and pay</span>
                                </button>
                            </div>

                        </div>
                        <div className={steps == "1" ? 'reviewguest' : 'd-none'}>
                            <div className='mt-4'>
                                <h4>Review Guest requirement</h4>
                                <hr className='themehr' />
                                {/* <h6 className='themeclr mb-3'>Who can come</h6>
                                <p className='my-4 f-14 text-muted'>Guests ages 10 and up can attend</p> */}
                                {/* <p className='my-4 f-14 text-muted'>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sapiente adipisci,
                                    assumenda dolor asperiores quae earum ipsum quaerat reiciendis quibusdam error qui
                                    et,  quasi illum eum! Ipsa voluptatem adipisci quo necessitatibus?</p> */}
                                <button className='btn btn-theme' onClick={() => setSteps(2)}>Next</button>
                            </div>
                        </div>
                        <div className={steps == "2" ? 'whoscoming' : 'd-none'}>
                            <div className='mt-4'>
                                <h4 className='mb-4'>Who's coming?</h4>
                                {
                                    userData && userData.length > 0 && userData.map((userData, index) =>
                                    <>
                                    {console.log('userDatsgal>>>>>',userData,index)}
                                        <div className='shadowbox p-4' onClick={index != 0 ? (() => { setUpdateGuest({ firstName: userData?.firstName, lastName: userData?.lastName, email: userData?.email, index: index }); setAddGuestUser(!addGuestUser) }) : null}>
                                            <div className='guestlists d-flex jc-between align-items-center'>
                                                <p>{userData && userData?.firstName + ' ' + userData?.lastName}</p>
                                                {
                                                    userData && index == 0 ? <> <img className='userimg' src={(userData?.profilePicturePath && userData?.profilePicturePath[0]) ? API_URL + "/" + (userData?.profilePicturePath[0] == '' ? img1 : userData?.profilePicturePath[0]) : img1} /> </> :
                                                        <><div></div></>
                                                }
                                            </div>
                                        </div>
                                        </>
                                    )
                                }

                                {addGuestUser && addGuestUser ?
                                    <div className='shadowbox p-4 h-55 mt-4'>
                                        {console.log(' GUEST ADD USER: ', updateGuest.index)}
                                        <div className='d-flex jc-between mb-3 align-items-center'>
                                            <h5>Guest</h5>
                                            <button className='btn btn-theme' onClick={(e) => { setAddGuestUser(addGuestUser - 1); setData(e, updateGuest.index, "remove") }}>Remove</button>
                                        </div>
                                        <div className='row '>
                                            <div className='col-md-6 mb-3'>
                                                <div className="select withlab">
                                                    <p>First Name</p>
                                                    <input type="text" className='form-control' placeholder='jhon' name='firstName' value={updateGuest.firstName} onChange={(e) => setData(e, "items")} />
                                                </div>
                                            </div>
                                            <div className='col-md-6 mb-3'>
                                                <div className="select withlab">
                                                    <p>Last Name</p>
                                                    <input type="text" className='form-control' placeholder='Last Name' name='lastName' value={updateGuest.lastName} onChange={(e) => setData(e, "items")} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row  gap-3'>
                                            <div className='col-md-6'>
                                                <div className="select withlab">
                                                    <p>Email (Optional) </p>
                                                    <input type="text" className='form-control' placeholder='Email' name='email' value={updateGuest.email} onChange={(e) => setData(e, "items")} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : ''}


                                {/* // Add Guest */}
                                {[...Array(adduser)].map((num, index) => {
                                    return (

                                        <div className='shadowbox p-4 h-55 mt-4'>
                                            <div className='d-flex jc-between mb-3 align-items-center'>
                                                <h5>Guest {userData.length + 1}</h5>
                                                <button className='btn btn-theme' onClick={() => { setAddUser(adduser - 1); setData(index, "remove") }}>Remove</button>
                                            </div>
                                            <div className='row '>
                                                <div className='col-md-6 mb-3'>
                                                    <div className="select withlab">
                                                        <p>First Name</p>
                                                        <input type="text" className='form-control' placeholder='jhon' name='firstName' 
                                                        value={guestData?.firstName}
                                                        onChange={(e) => setData(e, index, "items")} 
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-md-6 mb-3'>
                                                    <div className="select withlab">
                                                        <p>Last Name</p>
                                                        <input type="text" className='form-control' placeholder='Last Name' name='lastName' 
                                                        value={guestData?.lastName}
                                                        onChange={(e) => setData(e, index, "items")} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row  gap-3'>
                                                <div className='col-md-6'>
                                                    <div className="select withlab">
                                                        <p>Email (Optional) </p>
                                                        <input type="text" className='form-control' placeholder='Email' name='email' 
                                                        value={guestData?.email}onChange={(e) => setData(e, index, "items")} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='d-flex jc-between mb-3 align-items-center'>
                                                <p></p>
                                                <button className='btn btn-theme' disabled = {!guestData?.firstName || !guestData?.lastName || !guestData?.email} onClick={(e) => { setAddUser(adduser - 1); addGuestData(e, index) }}>Add</button>
                                            </div>
                                        </div>)
                                })}

                                {
                                    location && location.state && location.state.datas && location.state.datas.maxGuest >  userData.length ? 
                                    <div className='shadowbox p-4 mt-4'>
                                    <div className='d-flex jc-between'>
                                        <p className='themeclr mb-0'>Add another guest</p>
                                             <button className='fa fa-plus-circle btn-trans' onClick={() => setAddUser(adduser + 1)}></button>
                                    </div>
                                </div> : '' 
                                   
                                }
                                
                                <button className='btn btn-theme mt-4' onClick={() => setSteps(3)}>Next</button>
                            </div>
                        </div>
                        <div className={steps == "3" ? 'confirmandpay' : 'd-none'}>
                            <div className='mt-4'>
                                <h4 className='mb-4'>Confirm and pay</h4>
                                <hr className='themehr' />
                                
                                {/* <div className='shadowbox p-4 h-55 mt-4'>
                                    <div className='row '>
                                        <div className='col-md-6'>
                                            <div className="select withlab">
                                                <p>First Name</p>
                                                <input type="text" className='form-control' placeholder='jhon' />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="">
                                                <Form.Select ct aria-label="Default select example" onChange={(e) => selectoption(e)}>
                                                    <option>Open this select menu</option>
                                                    <option value="1">One</option>
                                                    <option value="paypal">Paypal</option>
                                                    <option value="3">Three</option>
                                                </Form.Select>
                                            </div>
                                        </div>
                                    </div> 
                                    <div className={paypal ? "d-none" : "row mt-4"}>
                                        <div className='col-md-6'>
                                            <div className="select withlab">
                                                <p>Last Name</p>
                                                <input type="text" className='form-control' placeholder='Last Name' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={paypal ? "row mt-4" : "d-none"}>
                                        <div>
                                            <button className='btn btn-link adminblue ps-0'>Redeem coupon</button>
                                            <p className='f-14 my-3'>By confirming this booking to the <a href="#" className='adminblue nounder'>et Additional Terms of Service, Guest Release and Waiver</a> and the <a href="#" className='adminblue nounder'> Cancellation Policy</a></p>
                                            <button className='btn btn-theme'>Pay with Paypal</button>
                                        </div>
                                    </div>
                                </div> */}

                                    <div className='shadowbox p-4 h-55 mt-4'>
                                        {userData && userData.map ((data,index) => 
                                        <div key={index}>
                                        <div className='row '>
                                            <div className='col-md-6'>
                                                <div className="select withlab">
                                                    <p>First Name</p>
                                                    <input type="text" readOnly={true} className='form-control' placeholder='jhon' value={data.firstName} />
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className="select withlab">
                                                    <p>Last Name</p>
                                                    <input type="text" readOnly={true} className='form-control' placeholder='Last Name' value={data.lastName}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row mt-4 mb-4'>
                                            <div className='col-md-6'>
                                                <div className="select withlab">
                                                    <p>Email</p>
                                                    <input type="text" className='form-control' readOnly={true} placeholder='Email' value={data.email}/>
                                                </div>
                                            </div>
                                            {/* <div className='col-md-6'>
                                                <h6 className='adminblue'>United states</h6>
                                            </div> */}
                                        </div>
                                        <hr className='themehr' />
                                        </div>
                                        )}                 
                                        {/* <button className='btn btn-link adminblue ps-0'>Redeem coupon</button> */}
                                        {/* <p className='f-14 my-3'>By confirming this booking to the <a href="#" className='adminblue nounder'>FlutterPads Additional Terms of Service, Guest Release and Waiver</a> and the <a href="#" className='adminblue nounder'> Cancellation Policy</a></p> */}
                                        <button className='btn btn-theme' onClick={() => {bookExperience();}}>Confirm</button>
                                        {/* onClick={()=> {bookExperience(); setShowModel(true)}} */}
                                    </div>
                                    
                                {/* <div className={paypal ? "d-none" : "row"}>

                                    <div className='shadowbox p-4 h-55 mt-4'>
                                        <h5 className='adminblue mb-3'>Expires on</h5>
                                        <div className='row '>
                                            <div className='col-md-4'>
                                                <div className="">
                                                    <Form.Select aria-label="Default select example">
                                                        <option>Month</option>
                                                        <option value="1">One</option>
                                                        <option value="2">Two</option>
                                                        <option value="3">Three</option>
                                                    </Form.Select>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <div className="">
                                                    <Form.Select aria-label="Default select example">
                                                        <option>Year</option>
                                                        <option value="1">One</option>
                                                        <option value="2">Two</option>
                                                        <option value="3">Three</option>
                                                    </Form.Select>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <div className="select withlab">
                                                    <p>Security code</p>
                                                    <input type="text" className='form-control' placeholder='Code' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='shadowbox p-4 h-55 mt-4'>
                                        <div className='row '>
                                            <div className='col-md-6'>
                                                <div className="select withlab">
                                                    <p>First Name</p>
                                                    <input type="text" className='form-control' placeholder='jhon' />
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className="select withlab">
                                                    <p>Last Name</p>
                                                    <input type="text" className='form-control' placeholder='Last Name' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row mt-4'>
                                            <div className='col-md-6'>
                                                <div className="select withlab">
                                                    <p>Post code</p>
                                                    <input type="text" className='form-control' placeholder='Last Name' />
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <h6 className='adminblue'>United states</h6>
                                            </div>
                                        </div>

                                        <button className='btn btn-link adminblue ps-0'>Redeem coupon</button>
                                        <p className='f-14 my-3'>By confirming this booking to the <a href="#" className='adminblue nounder'>FlutterPads Additional Terms of Service, Guest Release and Waiver</a> and the <a href="#" className='adminblue nounder'> Cancellation Policy</a></p>
                                        <button className='btn btn-theme'>Confirm</button>
                                    </div>
                                </div> */}

                                
                                {
                                    showModel &&
                                    <>
                                    <ExperiencePaymentIntegrationModal data = {experienceBooking} />
                                        {/* <Modal show={showModel} size="md" centered scrollable className='modal_style'>
                                            <Modal.Header className='border-0 pb-0'>
                                                <button type="button" className="btn close close_modal " onClick={() => setShowModel(false)}><span aria-hidden="true" className='fa fa-times f-14'></span><span class="sr-only">Close</span></button>
                                            </Modal.Header>
                                            <Modal.Body>
                                            <h5 className='modalhead text-center mb-4 mt-4'>Select Payment Gateway</h5>
                                                <div>
                                                    <button onClick={() => } className="w-full btn btn-theme mb-4">Paypal</button>
                                                    <button className="w-full btn btn-theme mb-4">Razorpay</button>
                                                </div>
                                            </Modal.Body> </Modal></> */}
                                            </> 
                                }
                            </div>
                        </div>

                    </div>
                    <div className='col-md-4 rightcont rightcont_new rightcont_margn'>
                        <BookingPayCard data={location?.state} stateData={userData} />
                    </div>
                </div>

            </div>
            <Footer />
        </div>
    )
}

export default BookExp 