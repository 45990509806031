import React, { useEffect , useState} from 'react';
import bag from '../../../../Assets/images/bag.png'
import Header from '../../../Common/Header';
import {Button} from 'react-bootstrap'
import { Navigate, useNavigate } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import {getUserDatas,uploadgovtid } from "../../../../Hooks/useUserHook";
import Cookies from 'universal-cookie';
import { toast } from 'react-hot-toast';
import { toastOptions } from '../../../../config/env';


const AccAddId = () =>{
const cookies = new Cookies();
const navigate = useNavigate();
const goto = useNavigate();
const [proofdetail , setProofdetail] = useState({});
const [user , setUser] = useState('');
const [front , setFront] = useState('');
const [back , setBack] = useState('');
const [image , setImage] = useState('');
// const [proofimg , setProofimg] = useState([]);
const [frontimg , setFrontimg] = useState('');
const [backimg , setBackimg] = useState('');
const [userimg , setUserimg] = useState('');
let  location=useLocation();
useEffect(()=>{
    (async()=>{
        console.log("location data" , location?.state);
        setProofdetail(location?.state);
        let userdata = await getUserDatas();
        setUser(userdata);
    })
    ();
},[])

const handlechange = async(e)=>{
    console.log("name" , e.target.name)
    // console.log("image state outside" , frontimg , backimg,userimg)
    // console.log("e.target.files",e.target.files,"handle change function0", e?.target?.files[0] ,"URl.createobjurl", URL.createObjectURL(e.target.files[0]));
    // let data = proofimg;
    // data.push(URL.createObjectURL(e.target.files[0]));
    // console.log("data in handlechange:",data);
    // setProofimg(data);
   
    // const files = e.target.files[0];
    // if (files) {
    //   const fileReader = new FileReader();
    //   fileReader.readAsDataURL(files);
    //   fileReader.addEventListener("load", function () {
    //     // setProofimg(prev=>[...prev,URL.createObjectURL(this.result)]);
    //     console.log("URL.createObjectURL(val) :",URL.createObjectURL(this.result));
    //     // setFrontimg(this.result);
    //   });}
    var image = e?.target?.files[0];
    var fileName = image.name;
    var idxDot = fileName.lastIndexOf(".") + 1;
    var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    if (extFile == "jpg" || extFile == "jpeg" || extFile == "png" || extFile == "webp" || extFile == "svg") {
        if(e?.target?.name == 'front')
    {console.log("image state front" , frontimg , backimg,userimg)
        setFront(e?.target?.files[0]);
        setFrontimg(URL.createObjectURL(e?.target?.files[0]))
    }
    if(e?.target?.name == 'back')
    {console.log("image state back" , frontimg , backimg,userimg)
        setBack(e?.target?.files[0]);
        setBackimg(URL.createObjectURL(e?.target?.files[0]))
    }
    if(e?.target?.name == 'image')
    {console.log("image state user" , frontimg , backimg,userimg)
        setImage(e?.target?.files[0]);
        setUserimg(URL.createObjectURL(e?.target?.files[0]))
    }
    } else {
        toast.error("Invalid file format!")
    }
    
}

const handlesubmit = async()=>{
    console.log("handle submit function");

    

    if(front.length == 0 || back.length == 0 || image.length ==0) 
    {
        alert("Upload  all the images!!")
    }
    else{
        let payload = {
            email : user.email,
            id : proofdetail?.idproof,
            country : proofdetail?.country,
            front : front ,
            back : back ,
            image : image
        }
        let updatedgovtid = await uploadgovtid(payload);
        console.log("updated govt id " , updatedgovtid);
      toast.success("Govt Id uploaded Successfully!" , toastOptions)
        // var userdata = await getUserDatas();
        // console.log("userdata in cookies" , userdata);
        // cookies.set('User-Datas' ,userdata);
        navigate("/account/personal-info")
    }
}

return(<div className='nocenterhead'>
<Header />
<div className='container container_custom mt-78 pt-5 pt-lg-0'>
      <div className='row justify-content-center'>
                            <div className='col-xl-8 col-lg-8 col-md-10 mt-lg-5 mt-3 mb-4'>
                               <h4 className='mb-3'>Upload images of your {location?.state?.idproof}</h4>
                               <p>Make sure your photos aren’t blurry and the front of your {location?.state?.idproof} clearly shows your face.</p>
                              <div className='upphoto my-3'>
                                    <Button variant="link" className='nounder text-dark me-2 mb-2'>
                                    {!frontimg?<><span className='fa fa-photo' ></span> <p>Upload Image</p></>:<></>} 
                                    
                                        {/* <span className='fa fa-photo'></span> */}
                                       
                                       
                                        
                                        <input type="file"  name = "front" onChange={(e)=>{handlechange(e)}}/>
                                        <img src = {frontimg}/>
                                    </Button>
                                    <Button variant="link" className='nounder text-dark mb-2' >
                                       {!backimg?<><span className='fa fa-photo' ></span><p>Upload Back</p></>:<></>} 
                                        
                                        <input type="file"  name = "back" onChange={(e)=>{handlechange(e)}}/>
                                        {
                                            backimg != '' &&
                                           <img src = {backimg}/>

                                        }
                                    </Button>
                              </div>
                              <h4 className='mb-2 mt-4'>Upload a photo of yourself</h4>
                              <p>We’ll match it to the one on your ID to confirm that it’s yours.</p>
                              <div className='upphoto  my-3'>
                                    <Button variant="link" className='nounder text-dark'name = "user">
                                        {!userimg ?
                                        <>   <span className='fa fa-photo'></span>
                                        <p>Upload photo</p></>
                                        :
                                        <></>
                                    }
                                     
                                        <input type="file" name = "image" onChange={(e)=>{handlechange(e)}}/>
                                        <img src = {userimg&&userimg}/>

                                    </Button>
                              </div>


                              
                               <hr className='themehr mt-5'/>
                                <div className='d-flex jc-between'>
                                    <Button variant="link" className="ps-0 text-dark" onClick={() => goto("/account/govtid")}>Back</Button>
                                    <button className='btn btn-theme ' onClick={handlesubmit}>Done</button>
                                </div>
                               
                              </div>
                          
                        </div>
                    
                       
                        </div>
    </div>
)
}
export default AccAddId