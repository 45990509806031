import React, { useEffect, useState } from 'react';
import {Modal,Button} from 'react-bootstrap'
import { getUserDatas } from '../../../../Hooks/useUserHook';

const HostExp = (props) =>{
    const[hostexp, setHostExp] = useState(true)
    useEffect(()=>{
      
    },[]);
    return(
        <>
         <Modal show={hostexp} centered scrollable className='modal_style ' size="md">
            <Modal.Header className='border-0 pb-0 d-flex justify-content-end'>
              <button type="button" className="btn close close_modal" onClick={() =>props.onDismiss()}><span aria-hidden="true" className=''>×</span><span class="sr-only">Close</span></button>
            </Modal.Header>
          <Modal.Body className=''>
             <div>
                <div className=''>
                    <h4 className='text-heading fw-500'>Hi, {getUserDatas()&&getUserDatas().firstName}</h4>
                    <p className=' f-16 text-sub-heading fw-300'>Dawn takes you backstage at an aquarium where you learn about research programs with marine conservationists.</p>
                         <Button variant="" className='f-12 px-3 my-3 py-2 btn-theme' onClick={() => props.step2()}>Next</Button>
                </div>
             </div>
                          
          </Modal.Body>
      </Modal></>
    )
}

export default HostExp