import React, {useState} from 'react';
import {Dropdown, Tabs, Tab,Row, Col, Nav} from "react-bootstrap";
import '../../Assets/css/header.css';

import logo from "../../Assets/images/landingPage/flutterpadlogo.png"
import user from "../../Assets/images/user.png"
import mobilelogo from "../../Assets/images/landingPage/flutterpadlogo.png"

import map1 from "../../Assets/images/map-1.png"
import map2 from "../../Assets/images/map-2.png"
import map3 from "../../Assets/images/map-3.png"
import map4 from "../../Assets/images/map-4.png"
import map5 from "../../Assets/images/map-5.png"
import map6 from "../../Assets/images/map-6.png"
import { Link } from 'react-router-dom';


const Header = () =>{

    const [open,setopen] = useState(false)
    const[topsec , setTopsec] = useState(false)



    function showHeader()
    {
    setopen(true);
    }
 
   return(
    <div>
    <header className= {topsec ? 'header py-1 topsec' : ' header py-1'}>
      <div id="backdrp" className={!topsec ? "d-none" : "d-block"} onClick={() => setTopsec(false)}></div>
   <div className='container container_custom'>
  
    <div className='row justify-content-center'>
        <div className={!topsec ? "col-md-8 topcontsec vanish" : "col-md-8 topcontsec "}>

        </div>
      </div>
    <div className='row headerblocks align-items-center'>
        <div className='col-lg-3 col-3'>
        <Link to="/" className='nav-link logo px-0'>
              <img src={logo} className="img-fluid d-sm-block d-none" alt="logo" />
              <img src={mobilelogo} className="img-fluid d-sm-none" alt="logo" />
            </Link>
        </div>
        {/* <div className="col-6 ">
          <div className='topcontsec'>
        <div className='tab_header_main'>
        <Tab.Container id="left-tabs-example" defaultActiveKey="stays">
  <Row className='d-flex align-items-center justify-content-between flex-column'>
    <Col sm={6} className="mx-auto bg-white">
      <Nav variant="pills" className="">
        <Nav.Item>
          <Nav.Link eventKey="stays">Stays</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="experiences">Experiences</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="onlineexperiences">Online Experiences</Nav.Link>
        </Nav.Item>
      </Nav>
    </Col>
    <Col className="mx-auto mt-3 max-850">
      <Tab.Content>
        <Tab.Pane eventKey="stays">
        <div className='inside_card_head'>
            <div className='d-grid grid-30 mx-0 justify-content-between'>
                    <div className=' col_card_in'>

                    <Dropdown className='dd_dropdown_card'>
        <Dropdown.Toggle variant="success" id="dropdown-basic" className="btn btn_trans_icon p-0">
        <p className='inside_card_heading'>Where</p>
                        <p className='mb-0 p-input'><input type="text" id="destination" name="destination" placeholder="Search destinations" className='form-control form_control_card'/></p>
        </Dropdown.Toggle>

        <Dropdown.Menu className='dd_menu_shadow_card country_card'>
           <p className='drop_header'>Search by region</p>
           <div className='row'>
            <div className='col-12 col-sm-6 col-md-4'>
                <div className='card card_country'>
                <img src={map1} className="img-fluid country_img" alt="I'm flexible" />

                </div>
           <p className='drop_header_country'>I'm flexible</p>

            </div>
            <div className='col-12 col-sm-6 col-md-4'>
                <div className='card card_country'>
                <img src={map2} className="img-fluid country_img" alt="Europe" />
                </div>
           <p className='drop_header_country'>Europe</p>

            </div>
            <div className='col-12 col-sm-6 col-md-4'>
                <div className='card card_country'>
                <img src={map3} className="img-fluid country_img" alt="United Kingdom" />


                </div>
           <p className='drop_header_country'>United Kingdom</p>

            </div>
            <div className='col-12 col-sm-6 col-md-4'>
                <div className='card card_country'>
                <img src={map4} className="img-fluid country_img" alt="United States" />


                </div>
           <p className='drop_header_country'>United States</p>

            </div>
            <div className='col-12 col-sm-6 col-md-4'>
                <div className='card card_country'>
                <img src={map5} className="img-fluid country_img" alt="Canada" />


                </div>
           <p className='drop_header_country'>Canada</p>

            </div>
            <div className='col-12 col-sm-6 col-md-4'>
                <div className='card card_country'>
                <img src={map6} className="img-fluid country_img" alt="Middle East" />


                </div>
           <p className='drop_header_country'>Middle East</p>

            </div>
           </div>

        </Dropdown.Menu>
        </Dropdown>


                  
                        </div>
                    <div className=' col_card_in'>
                    <p className='inside_card_heading'>When</p>
                    <p className='inside_card_heading_sm p-input'>Week in</p>
                    </div>
                    <div className=' col_card_in'>
                        <div className='d-flex align-items-center justify-content-between h-100'>
                            <div>
                        <p className='inside_card_heading'>Who</p>
                        <p className='inside_card_heading_sm p-input'>Add guests</p>     
                        </div>  
                        <button className='btn btn_blue'><i className="fa fa-search" aria-hidden="true"></i></button>

                        </div>

                   
                    </div>
                  

                </div>
            </div>
        </Tab.Pane>
        <Tab.Pane eventKey="experiences">
        Experiences
        </Tab.Pane>
        <Tab.Pane eventKey="onlineexperiences">
        Online Experiences
         </Tab.Pane>
      </Tab.Content>
    </Col>
  </Row>
</Tab.Container>
     </div></div>
        </div> */}
        <div className='col-lg-3 col-9 text-end'>
          <div className='right-head'>

            <button className='btn btn_grey' onClick={()=>{window.location.href = window.location.origin+"/host/property-type"}}>Become a Host</button>

           <button className='btn btn_trans_icon'>
           <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="presentation" focusable="false"><path d="m8.002.25a7.77 7.77 0 0 1 7.748 7.776 7.75 7.75 0 0 1 -7.521 7.72l-.246.004a7.75 7.75 0 0 1 -7.73-7.513l-.003-.245a7.75 7.75 0 0 1 7.752-7.742zm1.949 8.5h-3.903c.155 2.897 1.176 5.343 1.886 5.493l.068.007c.68-.002 1.72-2.365 1.932-5.23zm4.255 0h-2.752c-.091 1.96-.53 3.783-1.188 5.076a6.257 6.257 0 0 0 3.905-4.829zm-9.661 0h-2.75a6.257 6.257 0 0 0 3.934 5.075c-.615-1.208-1.036-2.875-1.162-4.686l-.022-.39zm1.188-6.576-.115.046a6.257 6.257 0 0 0 -3.823 5.03h2.75c.085-1.83.471-3.54 1.059-4.81zm2.262-.424c-.702.002-1.784 2.512-1.947 5.5h3.904c-.156-2.903-1.178-5.343-1.892-5.494l-.065-.007zm2.28.432.023.05c.643 1.288 1.069 3.084 1.157 5.018h2.748a6.275 6.275 0 0 0 -3.929-5.068z"></path></svg>

           </button>

           <Dropdown className='user_dd ps-0'>
        <Dropdown.Toggle variant="success" id="dropdown-basic" className="btn px-0 btn_trans_icon">
        <img src={user} className="img-fluid" alt="user" />
           <i className="fa fa-bars ms-2" aria-hidden="true"></i>
        </Dropdown.Toggle>

        <Dropdown.Menu className='dd_menu_shadow'>
            <Dropdown.Item href="#">Signup</Dropdown.Item>
            <Dropdown.Item href="#">Login</Dropdown.Item>
      
            <Dropdown.Divider />
            <Dropdown.Item href="#">Host Your Home</Dropdown.Item>
            <Dropdown.Item href="#">Host An Experience</Dropdown.Item>
            <Dropdown.Item href="#">Help</Dropdown.Item>

        </Dropdown.Menu>
        </Dropdown>

           
            </div>
            </div>

    </div>
    </div>
    
    </header>

    </div>
    )
}

export default Header;