import React from "react";
import bag from "../../../../Assets/images/bag.png";
import Header from "../../../Common/Header";
import { useNavigate } from "react-router-dom";
import country from "../../../../config/countrycode.json";
import { Breadcrumb, InputGroup, Button, Form } from "react-bootstrap";
import { useState, useEffect } from "react";
import { BiSolidDownArrow } from "react-icons/bi";

const AccGovtId = () => {
  const goto = useNavigate();
  const addId = useNavigate();
  const [countryname, setCountryname] = useState("Afghanistan");
  const [idproof, setIdproof] = useState("");
  console.log("idproof", idproof);

  const onchange = async (e) => {
    console.log("oncghange", e.target.value);
    if (e.target.name == "country") setCountryname(e.target.value);
    if (e.target.name == "flexRadioDefault") {
      console.log("radio button", e.target.value);
      setIdproof(e.target.value);
    }
  };

  const handlecontinue = async () => {
    let payload = {
      country: countryname,
      idproof: idproof,
    };
    console.log("payload", payload);
    addId("/account/addid", { state: payload });
  };

  return (
    <div className="nocenterhead">
      <Header />
      <div className="container container_custom mt-78">
        <div className="row justify-content-center">
          <div className="col-xl-4 col-lg-6 col-md-8 mt-5 mb-4 add_govt_id_formcheck">
            <h4 className="mb-5">Choose an Id type to add</h4>
            <div class="select withlab">
             
              <InputGroup className="mb-3 name-input-group inut_grp_id_c">
                <select
                  aria-label="Default select example"
                  class="form-select pt-2"
                  name="country"
                  onChange={(e) => {
                    onchange(e);
                  }}
                  // style={{background:'unset'}}
                >
                  {country.map((i) => (
                    <option>{i.name}</option>
                  ))}
                 
                </select>
                {/* <InputGroup.Text id="basic-addon1">
                  <BiSolidDownArrow style={{ color: "#AAAAAA" }} />
                </InputGroup.Text> */}
              </InputGroup>
            </div>

            <div class="form-check align-items-center mt-4">
              <label class="form-check-label " for="flexRadioDefault2">
                <p className="f-14">Driving Licence</p>
              </label>
              <input
                class="form-check-input me-2"
                value="Driving_Licence"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                onChange={(e) => {
                  onchange(e);
                }}
              />
            </div>
            <hr className="themehr" />
            <div class="form-check align-items-center">
              <label class="form-check-label " for="flexRadioDefault3">
                <p className="f-14">Passport</p>
              </label>
              {/* <input class="form-check-input me-2" value = "Identity card" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked={idproof?true:false} onChange={(e)=>{onchange(e)}}/> */}
              <input
                class="form-check-input me-2"
                value="Passport"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault3"
                onChange={(e) => {
                  onchange(e);
                }}
              />
            </div>
            <hr className="themehr" />
            <div class="form-check align-items-center">
              <label class="form-check-label " for="flexRadioDefault4">
                <p className="f-14">Identity card</p>
              </label>
              <input
                class="form-check-input me-2"
                value="Identity card"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault4"
                onChange={(e) => {
                  onchange(e);
                }}
              />
            </div>
            <hr className="themehr mb-5" />
            <div className="d-flex jc-between">
              <Button
                variant="link"
                className="ps-0 orsnge_y"
                onClick={() => goto("/account/personal-info")}
              >
                Back
              </Button>
              {/* <button className='btn btn-theme mt-4' onClick={() => addId("/account/addid")}>Continue</button> */}
              <button className="btn btn-theme mt-0" 
              disabled = {!idproof}
              onClick={handlecontinue}>
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AccGovtId;
