import React , {useState} from 'react';
import HostHeader from '../HostHeader';
import {Row,Col,Nav,Tab,ProgressBar,Button} from 'react-bootstrap'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import trolly from '../../../../Assets/images/trolly.png'
import calandar from '../../../../Assets/images/calandar.png'
import fav from '../../../../Assets/images/landingPage/flutterpadlogo.png'
import pc from '../../../../Assets/images/pc.png'
import notepad from '../../../../Assets/images/notepad.png'
import booking from '../../../../Assets/images/booking.png'
import tick from '../../../../Assets/images/tick.png'
import Footer from '../../../Common/Footer';
import AmenityModal from './Modals/AmenityModal';
import FlexStayModal from './Modals/FlexStayModal';

import SimilarLists from './Cards/SimilarLists';

const Insights = () => {
    const[amenity , setAmenity] = useState(false)
    const[flexstay , setFlexStay] = useState(false)


    const [responsive] = React.useState({  
        0:{
            items:1,
        },
        425:{
            items:2,
        }, 
        991:{
            item: 3,
        },
        1024:{
            items:4,
        }
    });
    const [responsive1] = React.useState({  
        0:{
            items:1,
        },
        425:{
            items:2,
        }, 
        991:{
            item: 3,
        },
        1024:{
            items:3,
        }
    });

    return(
        <>
        <HostHeader />
        {amenity && <AmenityModal onDismiss={()=>setAmenity(false)}/>}
        {flexstay && <FlexStayModal onDismiss={()=>setFlexStay(false)}/>}
        
        
        <div className='mt-5 insight-tab'>
            <Tab.Container className="" id="left-tabs-example" defaultActiveKey="opportunity">
            <div className='container custom_container'>
            <Row className='d-flex align-items-center justify-content-between flex-column'>
                <Col className="mx-auto bg-white">
                <Nav variant="pills" className="">
                    <Nav.Item><Nav.Link eventKey="opportunity">Opportunities</Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link eventKey="review">Review</Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link eventKey="earning">Earning</Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link eventKey="view">View</Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link eventKey="superhost">Superhost</Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link eventKey="cleaning">Cleaning</Nav.Link></Nav.Item>
                </Nav>
                </Col>
                </Row>
                </div>
                <div className='insight-conts'>
                   
                    <div>
                        <div className="mx-auto">
                            
                        <Tab.Content>
                            <Tab.Pane eventKey="opportunity">
                                <div className='insight-cont'>

                                
                            <div className='container custom_container'>
                                    <h2 className='themeclr py-5'>Travel is changing. We’re here to help.</h2>
                                 
                                 <div className='d-flex justify-content-between pb-3 text-muted  f-14'>
                                    <p>Dharmathupatti area, · July</p>
                                    <p className='f-14'>2/2</p>
                                </div> 
                             <OwlCarousel responsive={responsive} className='owl-theme pb-5' dots={false} margin={10} nav={true} id="big" slideBy={3}>
  
                        <div class='item' >
                            <p><b>Searches for stays in your area have increased 338% compared to this time last year.</b></p>
                        </div>
                        <div class='item' >
                           <p><b>57% of travelers to your area come from less than 300 miles away.</b></p>
                        </div>
                        <div class='item' >
                            <p><b>Most wanted amenities: child-friendly, infant-friendly, and wireless internet.</b></p>
                        </div>
                        <div class='item' >
                            <p><b>friendly, and wireless internet. The average lead time for bookings in your area is 27 days.</b></p>
                        </div>
                        <div class='item' >
                      <p><b>The average length of trips to your area is 1 night.</b></p></div>
                   </OwlCarousel>
                   </div></div>

                   <div className='container custom_container px-0'>
            <div className='adapt'>
                <h4 className='my-5'>Adapt to trends near Dharmathupatti</h4>
                <div className='row'>
                    <div className='col-lg-4 col-6 mb-5'>
                        <div className='lists'>
                            <img src={fav} />
                            <h6>Popular amenities</h6>
                            <p className='text-muted desc'>The most wanted amenities requested by travellers to your area.</p>
                             <div className='d-flex align-items-center mt-3 mb-4'>
                                <ProgressBar now={0} />
                                <p className='f-12 ms-2'><span>1</span>/2</p>
                            </div>
                            <Button variant="outline-dark" onClick={() => setAmenity(true)}>Get Started</Button>
                        </div>
                    </div>
                    <div className='col-lg-4 col-6 mb-5'>
                        <div className='lists'>
                        <img src={notepad} />

                            <h6>Flexible stays</h6>
                            <p className='text-muted desc'>Searches for easy-to-book, last-minute stays are up 155% in your area.</p>
                             <div className='d-flex align-items-center mt-3 mb-4'>
                                <ProgressBar now={60} />
                                <p className='f-12 ms-2'><span>3</span>/2</p>
                            </div>
                            <Button variant="outline-dark"  onClick={() => setFlexStay(true)}>
                                Get Started
                                {/* <span>3</span>/4 completed */}
                                </Button>
                                {/* <Button variant='link' className='text-dark f-14'>All done</Button> */}
                        </div>
                    </div>
                    <div className='col-lg-4 col-6 mb-5'>
                        <div className='lists'>
                        <img src={trolly} />

                            <h6>Family travel</h6>
                            <p className='text-muted desc'>Searches for family-friendly listings are up 484% in your area.</p>
                             <div className='d-flex align-items-center mt-3 mb-4'>
                                <ProgressBar now={60} />
                                <p className='f-12 ms-2'><span>3</span>/2</p>
                            </div>
                            <Button variant="outline-dark">
                                Get Started
                                {/* <span>3</span>/4 completed */}
                                </Button>
                                {/* <Button variant='link' className='text-dark f-14'>All done</Button> */}
                        </div>
                    </div>

                    <div className='col-lg-4 col-6 mb-5'>
                        <div className='lists'>
                        <img src={pc} />

                            <h6>Remote workspaces</h6>
                            <p className='text-muted desc'>Searches for work-friendly listings are up 241% in your area</p>
                             <div className='d-flex align-items-center mt-3 mb-4'>
                                <ProgressBar now={60} />
                                <p className='f-12 ms-2'><span>3</span>/2</p>
                            </div>
                            <Button variant="outline-dark">
                                Get Started
                                {/* <span>3</span>/4 completed */}
                                </Button>
                                {/* <Button variant='link' className='text-dark f-14'>All done</Button> */}
                        </div>
                    </div>
                    <div className='col-lg-4 col-6 mb-5'>
                        <div className='lists'>
                        <img src={calandar} />

                            <h6>Longer stay</h6>
                            <p className='text-muted desc'>Many guests are now searching for weekly and monthly stays.</p>
                             <div className='d-flex align-items-center mt-3 mb-4'>
                                <ProgressBar now={60} />
                                <p className='f-12 ms-2'><span>3</span>/2</p>
                            </div>
                            <Button variant="outline-dark">
                                Get Started
                                {/* <span>3</span>/4 completed */}
                                </Button>
                                {/* <Button variant='link' className='text-dark f-14'>All done</Button> */}
                        </div>
                    </div>
                    <div className='col-lg-4 col-6 mb-5'>
                        <div className='lists'>
                        <img src={booking} />

                            <h6>Family travel</h6>
                            <p className='text-muted desc'>Searches for family-friendly listings are up 484% in your area.</p>
                             <div className='d-flex align-items-center mt-3 mb-4'>
                                <ProgressBar now={60} />
                                <p className='f-12 ms-2'><span>3</span>/2</p>
                            </div>
                            <Button variant="outline-dark">
                                Get Started
                                {/* <span>3</span>/4 completed */}
                                </Button>
                                {/* <Button variant='link' className='text-dark f-14'>All done</Button> */}
                        </div>
                    </div>
                </div>
            </div>    <hr/>
        </div>
    
        <div className='container custom_container px-0'>
            <div className='row justify-content-between py-5'>
                <div className='col-lg-5 col-md-6'>
                    <h4>Make the most of your top dates this month</h4>
                </div>
                <div className='col-lg-5 col-md-6'>
                    <div className='shadowbox p-4 br-10'>
                        <img src={tick} className="bord-icon"/>
                        <h6><b>No popular dates to note</b></h6>
                        <p className='text-muted desc'>We’ll update this space if we notice popular dates that aren’t already unblocked on your calendar.</p>
                    </div>
                </div>
            </div>
        </div>
                   
                            </Tab.Pane>
                            <Tab.Pane eventKey="review">review</Tab.Pane>
                            <Tab.Pane eventKey="earning">Earning</Tab.Pane>
    <Tab.Pane eventKey="view">
    <div className='container custom_container'>
        <h4 className='mt-5'>Similar listings to yours</h4>
        {/* <hr className='themebord'/> */}
        <div className='row mt-5'>
            <SimilarLists />
        </div>
        </div>
        

    </Tab.Pane>
    <Tab.Pane eventKey="superhost">

        <Tab.Container className="" id="left-tabs-example" defaultActiveKey="superhost">
            <div className='container custom_container superhosttab'>
                <div className='tabwidth'>
                    <Nav variant="pills" className="my-5">
                    <Nav.Item><Nav.Link eventKey="superhost">superhost</Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link eventKey="basicrequirment">basicrequirment</Nav.Link></Nav.Item>
                </Nav>
                </div>
                
            </div>
            
            <Tab.Content>
                <Tab.Pane eventKey="superhost">
                    <div className='container custom_container pb-5'>
                        <div className='row'>
                            <div className='col-md-6'>
                                 <h3 className='themeclr'>Become a Superhost this October!</h3>
                                <p>Get recognised and rewarded for exceptional hospitality. Your next chance is on 1 October 2022.</p>
                                <p className='mt-3'>
                                    <a href="#" className='text-dark'>Learn more about the programme</a></p>
                            </div>
                        </div>
                       
                    </div>
                    <div className='bggray'>

                    
                    <div className='container custom_container py-5'>
                        <h5 className='mb-3'>Your Superhost stats</h5>
                        <div className='row'>
                            <div className='col-md-4'>
                                
                            <div class="floating-label w-full me-2 mb-1">
                                <p>Country/region</p>
                                 <select class="form-control pt-4">
                                <option>India</option><option>hgkg</option><option>hgkg</option>
                                </select></div>
                                <p className='f-12 mb-3'>Current assessment</p>
                                <p>Every 3 months, we check if you’ve met the Superhost criteria for the
                                     past year. If you do, you’ll become a Superhost.</p>
                            </div>
                            <div className='col-md-8'>
                                <div className='row'>
                                    <div className='col-lg-3 col-md-6 mb-3 mt-3'>
                                        <div className='bordbox'>
                                            <div className='d-flex'>
                                                <span className='fa fa-minus'></span>
                                                <span className='fa fa-star'></span>
                                                <span className='ms-auto'>?</span>
                                            </div>
                                            <p className='f-14'><b>Overall rating</b></p>
                                            <p className='f-12 text-muted'>Criteria: 4.8</p>

                                            <div className='d-flex f-12 mt-5'>
                                                <span className='fa fa-times faclose'></span>
                                                <span className='text-muted'>Haven't met</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-6 mb-3 mt-3'>
                                        <div className='bordbox'>
                                            <div className='d-flex'>
                                                <span className='fa fa-minus'></span>
                                                <span className='fa '>%</span>
                                                <span className='ms-auto'>?</span>
                                            </div>
                                            <p className='f-14'><b>Response rate</b></p>
                                            <p className='f-12 text-muted'>Criteria: 4.8</p>

                                            <div className='d-flex f-12 mt-5'>
                                                <span className='fa fa-times faclose'></span>
                                                <span className='text-muted'>Haven't met</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-6 mb-3 mt-3'>
                                        <div className='bordbox'>
                                            <div className='d-flex'>
                                                <span className='fa '>0</span>
                                                <span className='ms-auto'>?</span>
                                            </div>
                                            <p className='f-14'><b>Stays, 0 nights</b></p>
                                            <p className='f-12 text-muted'>Criteria: 4.8</p>

                                            <div className='d-flex f-12 mt-5'>
                                                <span className='fa fa-times faclose'></span>
                                                <span className='text-muted'>Haven't met</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-6 mb-3 mt-3'>
                                        <div className='bordbox'>
                                            <div className='d-flex'>
                                                <span className='fa'>0.00%</span>
                                                <span className='ms-auto'>?</span>
                                            </div>
                                            <p className='f-14'><b>Cancellation rate</b></p>
                                            <p className='f-12 text-muted'>Criteria: Less than 1.0%</p>

                                            <div className='d-flex f-12 mt-5'>
                                                <span className='fa fa-times faclose'></span>
                                                <span className='text-muted'>Haven't met</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
  
                    </div>
                    </div>
                    <div className=''>

                                
<div className='container custom_container'>
     
     <div className='d-flex justify-content-between pb-3 mt-5  f-14'>
        <h4>Superhost rewards</h4>
        <p className='f-14'>2/2</p>
    </div> 
 <OwlCarousel responsive={responsive1} className='owl-theme pb-5 hostreward' dots={false} margin={10} nav={true} id="big" slideBy={3}>

<div class='item' >
    <h5>FlutterPads coupon</h5>
<p className='text-muted'>Searches for stays in your area have increased 338% compared to this time last year.</p>
</div>
<div class='item' >
    <h5>Promoted to guests</h5>
<p className='text-muted'>You’ll be featured to guests in promotional emails.</p>
</div>

<div class='item' >
    <h5>Easy to find in search</h5>
<p className='text-muted'>Guests can filter their searches for Superhosts.</p>
</div>

<div class='item' >
    <h5>Early access to new features</h5>
<p className='text-muted'>You can pilot new programmes and test features before they launch to everyone.</p>
</div>
<div class='item' >
    <h5>Promoted to guests</h5>
<p className='text-muted'>You’ll be featured to guests in promotional emails.</p>
</div>

<div class='item' >
    <h5>Easy to find in search</h5>
<p className='text-muted'>Guests can filter their searches for Superhosts.</p>
</div>

</OwlCarousel>
</div></div>
                </Tab.Pane>
                <Tab.Pane eventKey="basicrequirment">basicrequirment</Tab.Pane>
            </Tab.Content>

        </Tab.Container>
    </Tab.Pane>

    <Tab.Pane eventKey="cleaning">Cleaning</Tab.Pane>
</Tab.Content>
                        </div>
                    </div>
                   
      

                </div>
            </Tab.Container>
        </div>
       
        <Footer />

        </>
    )
}

export default Insights