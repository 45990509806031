import React, { useState } from 'react';
import {Modal} from 'react-bootstrap'
import { deleteExperience } from '../../../../Hooks/useUserExperienceHook'; 
const ConfirmDelete = (props) =>{
    const[hostcity, setHostCity] = useState(true);
    const [country, setCountry] = useState("");

    const deleteexperience = () => {
        deleteExperience(props.id).then((data)=>{
            console.log("data in delete experience :",data);
            if(data.status == true){
                props.onDismiss();
                props.onReload();
            }
        })
    }

    return(
        <>
         <Modal show={hostcity} centered scrollable className='modal_style' size="md">
        <Modal.Header className='border-0 pb-0'>
          <button type="button" className="btn close close_modal" onClick={() =>props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
      </Modal.Header>
          <Modal.Body>
             <div className='text-center'>
               
                    <h4 className='txtblue fw-500 mb-4' >Delete confirmation</h4>
             
                <p className='f-14'>Are you sure you want to delete </p>
                <h5>{props.title}</h5>
                    <button className='btn btn-theme flex-shrink-0 ms-2 my-4' onClick={() => {deleteexperience()}}>confirm</button>
                {/* <div className='mb-4 graybox h-55  py-5 px-5 br-10 mt-5'> 
                <label className='text-muted'>Where</label>
                <div className='d-flex'>
                    <select className='form-control' onChange={(e)=>{setCountry(e.target.value)}}> 
                    {countrycode.map((result)=><option>{`${result.name}(${result.dial_code})`}</option>)}
                    </select>
                    </div>
                </div> */}
             </div>
                          
          </Modal.Body>
      </Modal></>
    )
}

export default ConfirmDelete