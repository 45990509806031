import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import { getUserEmail } from '../../../Hooks/useUserHook';
// import Scrollbars from 'react-custom-scrollbars';





const SeeMoreDates = (props) => {
    const push = useNavigate();
    console.log(props.data);
    const [moredates, setMoreDates] = useState(true)
    const [bookedDates, setbookedDates] = useState([])

    const getDates = (data) => {
        console.log("data" , data);
        // setbookedDates([...bookedDates, new Date(data).toLocaleDateString()]);
        const newdata = {date : data , datas :props.data }
        push(`/book-experience/${props.data._id}`,{state: newdata});
    
    }
    console.log('state',props.data.bookedDates)
    return (

        <Modal show={moredates} size="md" centered scrollable className='modal_style'>
            <Modal.Header className='border-0 pb-0'>
                <button type="button" className="btn close close_modal" onClick={() => props.onDismiss()}><span aria-hidden="true" className='fa fa-times f-14'></span><span class="sr-only">Close</span></button>
            </Modal.Header>
            <Modal.Body>
            <h4 className='themeclr mb-4'>Where do you want to go?</h4>
                {
                    props.data && props.data.bookedDates[0].map((value, index) =>
                        <div key={index}>
                            <div className='d-flex jc-between mb-3 shadowbox p-3'>
                                <div className=''>
                                    <h6 className='mb-0 f-14 themeclr'>{new Date(value).toLocaleDateString()}</h6>
                                    <div className='d-sm-flex reviewcard gap-2'>
                                        {/* <p className='themeclr f-14 mb-0'>10.00 - 12.00</p> */}
                                        <span className='badge f-14'>${props.data.pricePerGuest}/person</span>
                                    </div>
                                </div>
                               {getUserEmail() != props?.data?.email && <div>
                                    <button className='btn btn-theme' 
                                    disabled = {new Date(value) < new Date()} 
                                    onClick={ ()=>getDates(value)}>Choose</button> 
                                </div>}
                            </div>
                        </div >
                    )
                }

                {/* <div className='d-flex jc-between mb-3 shadowbox p-3'>
                <div className=''>
                    <h6 className='mb-0 f-14 themeclr'>23/10/2020</h6>
                   <div className='d-sm-flex reviewcard gap-2'> 
                        <p className='themeclr f-14 mb-0'>10.00 - 12.00</p>
                        <span className='badge f-14'>$20/person</span>
                    </div>
                </div>
                <div>
                    <button className='btn btn-theme'>Choose</button>
                </div>
            </div> */}
                {/* <div className='d-flex jc-between mb-3 shadowbox p-3'>
                <div className=''>
                    <h6 className='mb-0 f-14 themeclr'>23/10/2020</h6>
                   <div className='d-sm-flex reviewcard gap-2'> 
                        <p className='themeclr f-14 mb-0'>10.00 - 12.00</p>
                        <span className='badge f-14'>$20/person</span>
                    </div>
                </div>
                <div>
                    <button className='btn btn-theme'>Choose</button>
                </div>
            </div> */}


            </Modal.Body>
        </Modal>
    )
}


export default SeeMoreDates