import React from 'react';
import img1 from '../../../../../Assets/images/where/img1.png'
import img2 from '../../../../../Assets/images/where/img2.png'
import img3 from '../../../../../Assets/images/where/img3.png'
import img4 from '../../../../../Assets/images/where/img4.png'
import img5 from '../../../../../Assets/images/where/img5.png'
import img6 from '../../../../../Assets/images/where/img6.png'

const SimilarLists = () =>{
    return(
        <>
            <div className='col-md-4'>
                <div className='listcard'>
                    <div className='text-center imgdiv'>
                    <img src={img1} /> 
                    </div>
                    <div className='mt-2'>
                        <span className='f-14'>$949</span>
                        <span></span>
                        <span className='text-muted f-14 ms-2'>Sam's Nest/Air Conditioned private home!!</span>
                    </div>
                    <div className='d-flex align-items-center'>
                    
                        <div className='favorite'>
                            <span className='fa fa-star'></span>
                            <span className='fa fa-star'></span>
                            <span className='fa fa-star'></span>
                            <span className='fa fa-star'></span>
                            <span className='fa fa-star'></span>
                        </div>
                        <span className='f-12'>184 reviews</span>
                    </div>
                </div>
            </div>  
            <div className='col-md-4'>
            <div className='listcard'>
                <div className='text-center imgdiv'>
                   <img src={img2} /> 
                   </div>
                <div className='mt-2'>
                    <span className='f-14'>$949</span>
                    <span></span>
                    <span className='text-muted f-14 ms-2'>Sam's Nest/Air Conditioned private home!!</span>
                </div>
                <div className='d-flex align-items-center'>
                  
                    <div className='favorite'>
                        <span className='fa fa-star'></span>
                        <span className='fa fa-star'></span>
                        <span className='fa fa-star'></span>
                        <span className='fa fa-star'></span>
                        <span className='fa fa-star'></span>
                    </div>
                    <span className='f-12'>184 reviews</span>
                </div>
            </div>
        </div>  
        <div className='col-md-4'>
            <div className='listcard'>
                <div className='text-center imgdiv'>
                   <img src={img3} /> 
                   </div>
                <div className='mt-2'>
                    <span className='f-14'>$949</span>
                    <span></span>
                    <span className='text-muted f-14 ms-2'>Sam's Nest/Air Conditioned private home!!</span>
                </div>
                <div className='d-flex align-items-center'>
                  
                    <div className='favorite'>
                        <span className='fa fa-star'></span>
                        <span className='fa fa-star'></span>
                        <span className='fa fa-star'></span>
                        <span className='fa fa-star'></span>
                        <span className='fa fa-star'></span>
                    </div>
                    <span className='f-12'>184 reviews</span>
                </div>
            </div>
        </div>  
        <div className='col-md-4'>
            <div className='listcard'>
                <div className='text-center imgdiv'>
                   <img src={img4} /> 
                   </div>
                <div className='mt-2'>
                    <span className='f-14'>$949</span>
                    <span></span>
                    <span className='text-muted f-14 ms-2'>Sam's Nest/Air Conditioned private home!!</span>
                </div>
                <div className='d-flex align-items-center'>
                  
                    <div className='favorite'>
                        <span className='fa fa-star'></span>
                        <span className='fa fa-star'></span>
                        <span className='fa fa-star'></span>
                        <span className='fa fa-star'></span>
                        <span className='fa fa-star'></span>
                    </div>
        
                    <span className='f-12'>184 reviews</span>
                </div>
            </div>
        </div>  
        <div className='col-md-4'>
            <div className='listcard'>
                <div className='text-center imgdiv'>
                   <img src={img5} /> 
                   </div>
                <div className='mt-2'>
                    <span className='f-14'>$949</span>
                    <span></span>
                    <span className='text-muted f-14 ms-2'>Sam's Nest/Air Conditioned private home!!</span>
                </div>
                <div className='d-flex align-items-center'>
                  
                    <div className='favorite'>
                        <span className='fa fa-star'></span>
                        <span className='fa fa-star'></span>
                        <span className='fa fa-star'></span>
                        <span className='fa fa-star'></span>
                        <span className='fa fa-star'></span>
                    </div>
                    <span className='f-12'>184 reviews</span>
                </div>
            </div>
        </div>  
      
        </>
    )
}

export default SimilarLists