import React,{useState} from 'react';
import { useEffect } from 'react';
import {Modal,Button} from 'react-bootstrap'
import Scrollbars from 'react-custom-scrollbars';

const CotDetails = (props) =>{
    const[cot , setCot] = useState(true)
    
    return(
    <>
      <Modal show={cot} scrollable centered size="md" className='listingmodals modal_style scenic'>
          <Modal.Header className='border-0 pb-0'>
            <button type="button" className="btn close close_modal" onClick={() =>props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
          </Modal.Header>
          <Modal.Body>
            <h4 className='mb-4'>Cot details</h4>
            
                <div>
               
                <h6 className='mb-3'>Size</h6>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Standard</p>
                            <p className='f-14 text-muted'>52in (132cm) long x 28in (71cm) wide</p>
                        </label>
                        <input class="form-check-input" type="radio" value="Air conditioning details" id="flexCheckChecked" />
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Mni</p>
                            <p className='f-14 text-muted'>38in (97cm) long x 24in (61cm) wide</p>
                        </label>
                        <input class="form-check-input" type="radio" value="Air conditioning details" id="flexCheckChecked" />
                    </div>
                    
                   
                    <hr className="themehr" />

                   <h6 className='my-4'>Availability</h6>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Always at the listing</p>
                        </label>
                        <input class="form-check-input" type="radio" value="Air conditioning details" id="flexCheckChecked" />
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Guests must request it</p>
                        </label>
                        <input class="form-check-input" type="radio" value="Air conditioning details" id="flexCheckChecked" />
                    </div>

                   
                    <hr className='themehr' />
                    <h6 className='my-4'>Features</h6>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Sheets provided</p>
                        </label>
                        <input class="form-check-input" type="checkbox" value="Portable air conditioning" id="flexCheckChecked"/>
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Room-darkening shades in room</p>
                        </label>
                        <input class="form-check-input" type="checkbox" value="Portable air conditioning" id="flexCheckChecked"/>
                    </div>

                    <hr className="themehr" />
                   <h6 className='mb-3'>Price</h6>
                   <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Free</p>
                        </label>
                        <input class="form-check-input" type="radio" value="Air conditioning details" id="flexCheckChecked" />
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Paid</p>
                        </label>
                        <input class="form-check-input" type="radio" value="Air conditioning details" id="flexCheckChecked" />
                    </div>

                    <hr className='themehr' />

                    <h6 className='mb-3'>Privacy</h6>
                   <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Private</p>
                        </label>
                        <input class="form-check-input" type="radio" value="Air conditioning details" id="flexCheckChecked" />
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Public or shared</p>
                        </label>
                        <input class="form-check-input" type="radio" value="Air conditioning details" id="flexCheckChecked" />
                    </div>

                </div>
          </Modal.Body>
          <Modal.Footer className='jc-between'>
            <button className='btn btn-theme' >Save</button>
            <Button className='text-dark' variant="link" >Cancel</Button>
          </Modal.Footer>
      </Modal>
    </>
    )
}

export default CotDetails