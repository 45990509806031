import React, {useState} from 'react';
import {useNavigate ,Link} from 'react-router-dom';

const AreaForGuest = () =>{
    const history = useNavigate();
    const[firstguest, setFirstGuest] =  useState(false)

    return(
       <div className='container container_custom'>
        <button className='backbtn my-5' onClick={() => {history('/hosting', {state:"rule"}) }}><span className='fa fa-chevron-left me-2'></span> Back</button>
        <div className='row justify-content-center'>
            <div className='col-md-5'>
                <h3>Which areas can guests use?</h3>
                <p className='mb-5'>Add all the rooms guests can use – even shared areas</p>
                <div className=''>
                    <h5>Let’s start with these areas</h5>
                    <p>Based on your listing info</p>
                    <ul className='ps-0 setrule'>
                        <li className='mb-5'>
                            <div className='d-flex justify-content-between align-items-center'>
                              
                                    <div><p className='mb-0'>Bedroom</p></div>
                                  
                                    <div className='d-flex align-items-center'>
                                        <button className='fa fa-minus'></button> 
                                        <span className='me-2'>34</span>
                                        <button  className='disable fa fa-plus'></button>
                                    </div>
                              
                            </div>
                        </li>
                        <li className='mb-5'>
                            <div className='d-flex justify-content-between align-items-center'>
                              
                                   <div>
                                       <p className='mb-0'>Full bathroom</p>
                                       <p className='f-14 text-muted'>Toilet, sink, shower and/or bath</p>
                                    </div> 
                                  
                                    <div className='d-flex align-items-center'>
                                        <button className='disable fa fa-minus'></button> 
                                        <span className='me-2'>34</span>
                                        <button className="fa fa-plus"></button>
                                    </div>
                              
                            </div>
                        </li>
                        <li className='mb-5'>
                            <div className='d-flex justify-content-between align-items-center'>
                              
                                   <div>
                                       <p className='mb-0'>Toilet with sink</p>
                                       <p className='f-14 text-muted mb-0'>Toilet and sink</p>
                                    </div> 
                                  
                                    <div className='d-flex align-items-center'>
                                        <button className='disable fa fa-minus'></button> 
                                        <span className='me-2'>34</span>
                                        <button className="fa fa-plus"></button>
                                    </div>
                              
                            </div>
                        </li>
                        <li className='mb-5'>
                            <div className='d-flex justify-content-between align-items-center'>
                              
                                   
                                       <div><p className='mb-0'>Living room</p></div>
                                  
                                    <div className='d-flex align-items-center'>
                                        <button className='disable fa fa-minus'></button> 
                                        <span className='me-2'>34</span>
                                        <button className="fa fa-plus"></button>
                                    </div>
                              
                            </div>
                        </li>
                        <li className='mb-5'>
                            <div className='d-flex justify-content-between align-items-center'>
                              
                                   
                                       <div>
                                        <p className='mb-0'>Exterior</p>
                                        <p className='text-muted f-14 mb-0'>This allows you to add a photo of what your place looks like from the outside</p>
                                       </div>
                                  
                                    <div className='d-flex align-items-center'>
                                        <button className='disable fa fa-minus'></button> 
                                        <span className='me-2'>34</span>
                                        <button className="fa fa-plus"></button>
                                    </div>
                              
                            </div>
                        </li>
                        
                    </ul>

                </div>
                <div className=''>
                    <h5>What about these?</h5>
                    <ul className='ps-0 setrule mb-85'>
                        <li className='mb-5'>
                            <div className='d-flex justify-content-between align-items-center'>
                              <div><p className='mb-0'>Full kitchen</p></div>
                              <div className='d-flex align-items-center'>
                                    <button className='disable fa fa-minus'></button>
                                    <span className='me-2'>34</span>
                                    <button className="fa fa-plus"></button>
                              </div>
                            </div>
                        </li>
                        <li className='mb-5'>
                            <div className='d-flex justify-content-between align-items-center'>
                              <div>
                                <p className='mb-0'>Kitchenette</p>
                                <p className='f-14 text-muted mb-0'>A compact space to prepare food</p>
                                </div>
                              <div className='d-flex align-items-center'>
                                    <button className='disable fa fa-minus'></button>
                                    <span className='me-2'>34</span>
                                    <button className="fa fa-plus"></button>
                              </div>
                            </div>
                        </li>


                        <li className='mb-5'>
                            <div className='d-flex justify-content-between align-items-center'>
                              <div><p className='mb-0'>Dining area</p></div>
                              <div className='d-flex align-items-center'>
                                    <button className='disable fa fa-minus'></button>
                                    <span className='me-2'>34</span>
                                    <button className="fa fa-plus"></button>
                              </div>
                            </div>
                        </li>
                        <li className='mb-5'>
                            <div className='d-flex justify-content-between align-items-center'>
                              <div><p className='mb-0'>Office</p></div>
                              <div className='d-flex align-items-center'>
                                    <button className='disable fa fa-minus'></button>
                                    <span className='me-2'>34</span>
                                    <button className="fa fa-plus"></button>
                              </div>
                            </div>
                        </li>
                        <li className='mb-5'>
                            <div className='d-flex justify-content-between align-items-center'>
                              <div><p className='mb-0'>Back garden</p></div>
                              <div className='d-flex align-items-center'>
                                    <button className='disable fa fa-minus'></button>
                                    <span className='me-2'>34</span>
                                    <button className="fa fa-plus"></button>
                              </div>
                            </div>
                        </li>
                        <li className='mb-5'>
                            <div className='d-flex justify-content-between align-items-center'>
                              <div><p className='mb-0'>Patio</p></div>
                              <div className='d-flex align-items-center'>
                                    <button className='disable fa fa-minus'></button>
                                    <span className='me-2'>34</span>
                                    <button className="fa fa-plus"></button>
                              </div>
                            </div>
                        </li>
                        <li className='mb-5'>
                            <div className='d-flex justify-content-between align-items-center'>
                              <div><p className='mb-0'>Pool</p></div>
                              <div className='d-flex align-items-center'>
                                    <button className='disable fa fa-minus'></button>
                                    <span className='me-2'>34</span>
                                    <button className="fa fa-plus"></button>
                              </div>
                            </div>
                        </li>

                        <li className='mb-5'>
                            <div className='d-flex justify-content-between align-items-center'>
                              <div><p className='mb-0'>Hot tub</p></div>
                              <div className='d-flex align-items-center'>
                                    <button className='disable fa fa-minus'></button>
                                    <span className='me-2'>34</span>
                                    <button className="fa fa-plus"></button>
                              </div>
                            </div>
                        </li>
                        <li className='mb-5'>
                            <div className='d-flex justify-content-between align-items-center'>
                              <div><p className='mb-0'>Utility room</p></div>
                              <div className='d-flex align-items-center'>
                                    <button className='disable fa fa-minus'></button>
                                    <span className='me-2'>34</span>
                                    <button className="fa fa-plus"></button>
                              </div>
                            </div>
                        </li>
                        <li className='mb-5'>
                            <div className='d-flex justify-content-between align-items-center'>
                              <div><p className='mb-0'>Garage</p></div>
                              <div className='d-flex align-items-center'>
                                    <button className='disable fa fa-minus'></button>
                                    <span className='me-2'>34</span>
                                    <button className="fa fa-plus"></button>
                              </div>
                            </div>
                        </li>
                        <li className='mb-5'>
                            <div className='d-flex justify-content-between align-items-center'>
                              <div><p className='mb-0'>Gym</p></div>
                              <div className='d-flex align-items-center'>
                                    <button className='disable fa fa-minus'></button>
                                    <span className='me-2'>34</span>
                                    <button className='fa fa-plus'></button>
                              </div>
                            </div>
                        </li>
                    </ul>
                </div>
               
            </div>
        </div>
      
        <div className='fixedbtn'> 
            <Link to="/hosting/areaforguest/doublecheck" className='btn btn-theme'>Next</Link>
        </div>
       </div>
    )
}

export default AreaForGuest