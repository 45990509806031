import React, { Component } from 'react';
import bag from '../../../../Assets/images/bag.png'


const AccPayment = () =>{
return(<>
      <div className='row mt-5 justify-content-between'>
                            <div className='col-md-7'>
                               <h4>Your payments</h4>
                               <p>Keep track of all your payments and refunds.</p>
                               <button className='btn btn-theme mt-4'>Manage payments</button>
                               <div className='mt-5'>
                                        <h4>Payment methods</h4>
                                        <p className='text-muted'> Add a payment method using our secure payment system, then start planning your next trip.</p>
                                    </div>
                                    <hr className='themehr' />
                                    <h4 className='mt-5'>FlutterPads gift credit</h4>
                                    <p className='text-muted'>Redeem a gift card and look up your credit balance. 
                                    <a href="#" className='text-dark'>Terms apply</a></p>
                                    <hr className='themehr' />
                                    <div className='d-flex justify-content-between'>
                                        <p>Current credit balance</p>
                                         <p>$0.00</p>
                                    </div>
                                    <hr className='themehr' />
                                    <button className='btn btn-theme mb-5'>Add gift card</button>

                                    <h4>Coupons</h4>
                                    <p className='text-muted'>Add a coupon and save on your next trip.</p>
                                    <hr className='themehr' />
                                    <div className='d-flex justify-content-between'>
                                        <p>Your coupons</p>
                                         <p>0</p>
                                    </div>
                                    <hr className='themehr' />
                                    <button className='btn btn-theme mb-5'>Add coupon</button>
                            </div>
                            <div className='col-lg-4 col-md-5 d-md-block d-none '>
                                <div className='right-cont bord p-4 br-10'>
                                    <img src={bag} className="mb-3"/>
                                    <h6>Make all payments through FlutterPads</h6>
                                    <p className='text-muted f-14'>Always pay and communicate through FlutterPads to ensure you're protected under our 
                                   <a href="#" className='text-dark'>Terms of Service, Payments Terms of Service,</a>  cancellation, and other safeguards. 
                                   <a href="#" className='text-dark'>Learn more</a></p>
                                    
                                    
                                </div>
                            </div>
                        </div>
                    
                        <hr className='themehr mb-5'/>
    </>
)
}
export default AccPayment