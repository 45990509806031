import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { getExperienceFromLocal } from '../../../../Hooks/useUserExperienceHook';

const MinimumGuest = (props) =>{
    const [maxGuest, setMaxGuest] = useState(0);
    const [error , setError] = useState("");
    const putData = (e) => {
       props.expgroup(e.target.value)
    }

    useEffect(()=>{
        const localdata = getExperienceFromLocal();
        if(localdata&&localdata.maxGuest){
            setMaxGuest(localdata.maxGuest);
        }
    },[]);

    return(
        <>
  
                <div>
                    <h2 className='conthead'>Maximum guest experience</h2>
                    <p className='text-muted'>Choose the category that best describe your experience. Add a second,
                        if you think it fits into another category.
                    </p>
                    <div className='h-55 mt-4'>
                        {/* <select className='form-control' value={maxGuest} onChange={(e) => {putData(e);setMaxGuest(e.target.value)}}>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                        <option>6</option>
                        <option>7</option>
                        <option>8</option>
                        <option>9</option>
                        <option>10</option>
                    </select> */}
                    <input className='form-control' type = "number" value={maxGuest} min="1"
                        onChange={(e) => {setMaxGuest(e.target.value);if(parseFloat(e.target?.value) >=1){setError("");putData(e);}else{setError("Invalid Guest Count")}}}
                    ></input>
                    <p className='text-danger mt-3'>{error}</p>
                   
                   <div className='mt-4'><button disabled={!(maxGuest>1)} className='btn btn-theme'  onClick={() => {props.count();props.onSetLocal(maxGuest,"maxGuest");}}>Save & Continue</button></div> 
                    </div>
                    
              </div>            
        
        </>

    )
}

export default MinimumGuest