import React, { useEffect, useState } from "react";
import { Breadcrumb, InputGroup, Button, Form } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Footer from "../../../Common/Footer";
import Header from "../../../Common/Header";
import eye from "../../../../Assets/images/visibleNew.png";
import bag from "../../../../Assets/images/lockNew.png";
import editIcon from "../../../../Assets/images/edit-fill.png";
import closeIcon from "../../../../Assets/images/closeIcon.png";
import {
  getUserDatas,
  updatename,
  updategender,
  updatedob,
  updateaddress,
  updateemergencycontact,
  removeemergencycontact,
  usersdata,
  updatePhonenumber,
  getusersdatahooks,
  getUserEmail,
  sendotphooks,
  verifyotphooks,
} from "../../../../Hooks/useUserHook";
import { BiSolidDownArrow } from "react-icons/bi";

import Cookies from "universal-cookie";
import Countrycode from "../../../../config/countrycode.json";
import languages from "../../../../config/languages.json";
import { getEmail } from "../../../../config/userRouteFront";
import { toast } from "react-hot-toast";
import { RxSlash } from "react-icons/rx";
import { FaChevronLeft } from "react-icons/fa6";

const cookies = new Cookies();
const PersonalInfo = (props) => {
  const MAX_AGE = 100;
  const MIN_AGE = 18;

  const getMinAndMaxDateForDob = () => {
    const currenTime = new Date();
    const currentYear = currenTime.getFullYear();
    const currentMonth = currenTime.getMonth() + 1;
    const minYear = currentYear - MAX_AGE;
    const maxYear = currentYear - MIN_AGE;
    const minDate = new Date(`${minYear}-${currentMonth}-01`);
    const maxDate = new Date(`${maxYear}-${currentMonth}-01`);
    console.log("111 dobbbbbbbbbbbbbbbb", currentMonth,currentYear)
    return {minDate, maxDate};
 }

 const [dob, setDob] = useState(getMinAndMaxDateForDob().maxDate);
 console.log("222 dobbbbbbbbbbbbbbbb", dob);
  const [show, setShow] = useState(0);
  const [showadd, setShowAdd] = useState(false);
  const [addanother, setAddAnother] = useState(false);

  const [toggle, settoggle] = useState(true);
  console.log("toggle", toggle);
  const [userdata, setUserdata] = useState([]);
  const [firstname, setFirstname] = useState("");
  console.log("firstname--------------", firstname);
  const [lastname, setLastname] = useState("");
  const [gender, setGender] = useState("");
  
  const [country, setCountry] = useState("Afghanistan");
  const [street, setStreet] = useState("");
  console.log("streeeeeeeeeeeeeet", street);
  const [flat, setFlat] = useState("");
  const [city, setCity] = useState("");
  const [stateprovince, setStateprovince] = useState("");
  const [postcode, setPostcode] = useState("");
  const [epname, setEpname] = useState("");
  console.log("epnameeeeeee", epname);
  const [relationship, setRelationship] = useState("");
  const [eplanguage, setEplanguage] = useState("");
  console.log("eplanguage", eplanguage);
  const [epemail, setEpemail] = useState("");
  console.log("epemaaaaaaaaaaaaaaaaaaaaaaaaa", epemail);
  const [epcountrycode, setEpcountrycode] = useState("Afghanistan(+93)");
  const [epphonenumber, setEpphonenumber] = useState("");
  console.log("epphoneNumber >>>>>>>", epphonenumber, epphonenumber.length);
  const [language, setLanguage] = useState("India");
  const [error, setError] = useState({
    firstname: "",
    lastname: "",
    epname: "",
    gender: "",
    eprelationship: "",
    epphonenumber: "",
  });
  const [emailerr, setEmailerr] = useState(false);
  console.log("errorsssssssss", emailerr);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errState, setErrorState] = useState("");
  const [errors, setEpError] = useState({});


  const [kyc, setKyc] = useState({});
  const [phonecode, setPhonecode] = useState({});
  const [otpverify, setOtpverify] = useState(false);
  const [otp, setOtp] = useState("");
  // const MAX_AGE = 100;
  // const MIN_AGE = 18;
  console.log("erorrrrrrrrr", errors);

  const [phonenum, setPhonenum] = useState("");
  const location = useLocation();
  const removeBlur = (count) => {
    // console.log('count hihi', count, count == 7 ,!errors.email != '', !errors.epname != '', !errors?.epphonenumber != '', !errors?.eprelationship != '')
    // // if(!errors.email != '' && !errors.epname != '' && !errors?.epphonenumber != '' && !errors?.eprelationship != '')
    // // {
    // document.querySelectorAll(".blur_ul li").forEach(function (a) {
    //     console.log("a hihi", a);
    //  count == 7 ? (errors?.epname && errors?.epemail && errors?.epphonenumber && errors?.eprelationship) ? console.log('a.style.opacity = "0.5"') (a.style.opacity = "0.5") : (toggle ? (a.style.opacity = "0.5") : (a.style.opacity = "1")) :  toggle ? (a.style.opacity = "0.5") : (a.style.opacity = "1")
    //     // toggle ? (a.style.opacity = "0.5") : (a.style.opacity = "1")
    // });

    document
      .querySelectorAll(".blur_ul li button.editbtn")
      .forEach(function(a) {
        a.style.pointerEvents = "none";
      });
    var k = document.getElementById("cancel_" + count);

    console.log("toggggggg", toggle);
    toggle
      ? (k.style.pointerEvents = "unset")
      : document.querySelectorAll(".blur_ul li button").forEach(function(a) {
          a.style.pointerEvents = "unset";
        });

    // console.log(document.querySelector(".blur_ul li" + [count]), "fdsf");
    document.getElementById("blur_" + count).style.opacity = "1";
    // toggle
    //   ? (document.getElementById("cancel_" + count).innerText = "Cancel")
    //   : (document.getElementById("cancel_" + count).innerText = "Edit");
    toggle
      ? (document.getElementById("cancel_" + count).innerHTML =
          '<img src="' + closeIcon + '" width="14px" height="14px" />')
      : (document.getElementById("cancel_" + count).innerHTML =
          '<img src="' + editIcon + '" width="14px" height="14px" />');

    settoggle(!toggle);
    toggle ? setShow(count) : setShow("");
  };

  // const getMinAndMaxDateForDob = () => {
  //   const currenTime = new Date();
  //   const currentYear = currenTime.getFullYear();
  //   const currentMonth = currenTime.getMonth();
  //   const minYear = currentYear - MAX_AGE;
  //   const maxYear = currentYear - MIN_AGE;
  //   const minDate = new Date(`${minYear}-${currentMonth}-01`);
  //   const maxDate = new Date(`${maxYear}-${currentMonth}-01`);
  //   return { minDate, maxDate };
  // };

  useEffect(() => {
    console.log("userdata language data", language, userdata);
    // setLanguage(language);
    async function fetchdata() {
      var userdataaa = getUserDatas();
      var email = getUserEmail();
      var userdata = await getusersdatahooks(email);
      // console.log("userdata cookie data", userdata , email);
      // var userdata = userdatas;
      console.log("userdata cookie data",userdata?.dob,userdata, userdataaa, userdata?.govtproof[0]);
      setUserdata(userdata);
      setKyc(userdata?.govtproof[0]);
      setFirstname(userdata?.firstName);
      setLastname(userdata?.lastName);
      // setDob(new Date(userdata?.dob));
      setStreet(userdata?.address?.street);
      setFlat(userdata?.address?.flat);
      setCity(userdata?.address?.city);
      setPostcode(userdata?.address?.postcode);
      setStateprovince(userdata?.address?.stateprovince);
      setCountry(userdata?.address?.country);
      setUserdata(userdata);
      setPhoneNumber(userdata?.phoneNumber);
      setPhonecode(userdata?.phonecode ? userdata?.phonecode : "");
      // setEpname(userdata?.emergencycontact[0].name)
      // setEpemail(userdata?.emergencycontact[0].email)
      // setEplanguage(userdata?.emergencycontact[0].language)
      // setEpcountrycode(userdata?.emergencycontact[0].countrycode)
      // setEplanguage(userdata?.emergencycontact[0].relationship)
    }
    fetchdata();
  }, []);

  // let errors = {

  // };

  const onchange = async (e) => {
    var { name, value } = e?.target;
    console.log("name", name);
    console.log("value", value);
    if (name == "firstname") {
      if (value?.length == 0) {
        setError({ ...error, ...{ ["firstname"]: "! Firstname is required" } });
      } else {
        setFirstname(value);
        setError({ ...error, ...{ ["firstname"]: "" } });
      }
    }
    if (name == "lastname") {
      if (value?.length == 0) {
        setLastname("");
        setError({ ...error, ...{ ["lastname"]: "! Lastname is required" } });
      } else {
        setLastname(value);
        setError({ ...error, ...{ ["lastname"]: "" } });
      }
    }
    if (name == "gender") {
      // if(value?.length==0)
      // {
      //     setError({...error ,...{['gender'] : "! Gender is required"}})
      // }
      // {
      setGender(value);
      setError({ ...error, ...{ ["gender"]: "" } });
      // }
    }
    if (name == "dob") {
      // if(value?.length==0)
      //     error.dob = '! Date of birth is required'
      setDob(value);
    }

    if (name == "country") {
      setCountry(value);
    }
    console.log("name", name);
    if (name == "street") {
      console.log("value", value);
      setStreet(value);
    }
    if (name == "flat") {
      setFlat(value);
    }
    if (name == "city") {
      setCity(value);
    }
    if (name == "stateprovince") setStateprovince(value);
    if (name == "postcode") setPostcode(value);
    if (name == "epname") {
      if (value?.length == 0) {
        setEpname("");
        setError({ ...error, ...{ ["epname"]: "! Name is required" } });
      } else {
        setEpname(value);
        setError({ ...error, ...{ ["epname"]: "" } });
      }
    }

    if (name == "eprelationship") {
      if (value?.length == 0) {
        setRelationship("");
        setError({
          ...error,
          ...{ ["eprelationship"]: "! Relationship is required" },
        });
      } else {
        setRelationship(value);
        setError({ ...error, ...{ ["eprelationship"]: "" } });
      }
    }

    if ((name = "eplanguage")) {
      if (value?.length == 0) {
        setEplanguage("");
        setError({ ...error, ...{ ["eplanguage"]: "! Language is required" } });
      } else {
        setEplanguage(value);
        setError({ ...error, ...{ ["eplanguage"]: "" } });
      }
    }

    if (name == "epemail") {
      console.log("epnam enter this", value);
      if (value?.length == 0) {
        console.log("entet thsisiaodfjuaopsdj");
        setEpemail("");
      } else {
        setEpemail(value);
      }
    }

    if (name == "epcountrycode") setEpcountrycode(value);
    if (name == "epphonenumber") {
      console.log("epphonenumber", value?.length);
      if (value?.length == 0) {
        setEpphonenumber("");
        setError({
          ...error,
          ...{ ["epphonenumber"]: "! Phone number is required" },
        });
      } else {
        setEpphonenumber(value);
        setError({ ...error, ...{ ["epphonenumber"]: "" } });
      }
    }

    if (name == "language") setLanguage(value);
    // setError(errors);
  };

  const updateusername = async () => {
    console.log("updated name function");
    let updatenameerror = {};
    const payload = {
      email: userdata.email,
      firstname: firstname,
      lastname: lastname,
    };
    console.log("payload data", payload, (firstname?.length != 0 && firstname != undefined && firstname != 'undefined')&& (lastname?.length != 0 && lastname != undefined && lastname != 'undefined'));
    if ((firstname?.length != 0 && firstname != undefined && firstname != 'undefined')&& (lastname?.length != 0 && lastname != undefined && lastname != 'undefined')) {
      setShow(0);
      let updatedname = await updatename(payload);
      console.log("updated name", updatedname.data.record);
      // cookies.set("User-Datas", updatedname.data.record);
      setUserdata(updatedname.data.record);
    } else {
      if (firstname?.length == 0 || firstname != undefined || firstname != 'undefined') {
        updatenameerror.firstname = "! Firstname is required";
        // setError({...error ,...{['firstname'] : "! Firstname is required"}})
      }
      if (lastname?.length == 0 || lastname != undefined || lastname != 'undefined') {
        console.log("invalid lastname", error);
        updatenameerror.lastname = "! Lastname is required";
        // setError({...error ,...{['lastname'] : "! Lastname is required"}})
      }

      setError(updatenameerror);
      removeBlur(1)
    }
    console.log("errors", error);
  };

  const handlegender = async () => {
    console.log("handle gender function");
    setShow(0);
    let payload = {
      email: userdata.email,
      gender: gender,
    };
    console.log("payload data", payload);

    let updatedgender = await updategender(payload);
    console.log("updated gender", updatedgender.data.record);
    setUserdata(updatedgender.data.record);
    // cookies.set("User-Datas", updatedgender.data.record);
    removeBlur(2)
    // setError({ ...error, ...{ ["gender"]: "! Gender is required" } });
  };

  const handledob = async () => {
    console.log("handle dob function");
    setShow(0);
    let payload = {
      email: userdata.email,
      dob: dob,
    };
    console.log("handledob payload", payload);
    let updateddob = await updatedob(payload);
    console.log("updated dob ", updateddob.data.data.record);
    setUserdata(updateddob.data.data.record);
    removeBlur(3)
    // cookies.set("User-Datas", updateddob.data.data.record);
  };

  const handleaddress = async () => {
    console.log("handle address function");
    // setShow(0);
    if (street || flat || city || stateprovince || postcode || country) {
      setShow(0);
      let payload = {
        address: {
          street: street,
          flat: flat,
          city: city,
          stateprovince: stateprovince,
          postcode: postcode,
          country: country,
        },
        email: userdata.email,
      };
      console.log("address payload", payload);
      let updatedaddress = await updateaddress(payload);
      setUserdata(updatedaddress?.data?.data?.record);
      removeBlur(6)
    }
    else{
      toast.error("Fill all the fields !");
    }
    // cookies.set("User-Datas", updatedaddress?.data?.data?.record);
  };

  const emailValidation = () => {
    console.log(
      "epemail>.............",
      epemail,
      epemail != "",
      epemail == null
    );
    if (
      epemail != "" &&
      epemail != null &&
      epemail != undefined &&
      epemail != "undefined"
    ) {
      let emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([A-Za-zA-Z\-0-9]+\.)+[A-Za-zA-Z]{2,}))$/;
      var validation = emailRegex.test(epemail);
      console.log("validztauoin", validation);

      if (!validation) {
        return validation;
      } else {
        return validation;
      }
    } else {
      let eperror = {};
      console.log("eperroer", eperror);
      eperror.epemail = "! Email is required";
      return eperror;
    }
  };

  const emergencycontactValidation = async () => {
    let errors = {};
    let emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([A-Za-zA-Z\-0-9]+\.)+[A-Za-zA-Z]{2,}))$/;

    if (epname == "" || epname == "undefined") {
      errors.epname = "Name is required";
    }

    if (relationship == "" || relationship == "undefined") {
      errors.eprelationship = "Relationship is required";
    }

    if (epphonenumber == "" || epphonenumber == "undefined") {
      errors.epphonenumber = "PhoneNumber is required";
    }

    if (epemail == "" || epemail == "undefined") {
      errors.epemail = "Email is required";
    } else {
      let err = emailRegex.test(epemail);
      if (!err) {
        errors.epemail = "Invalid Email Address";
      }
    }

    setEpError(errors);
    console.log("errrrrrrrrrrrrrrrrrr", errors);
    return errors;
  };

  const handleemergencycontact = async () => {
    console.log("handle emerfgency contact function");
    let eperror = {};
    console.log("eperrorsxssdfadfasd", eperror);
    // const valid = emailValidation();
    let validation = await emergencycontactValidation();
    console.log("valid valueeeee", validation);

    console.log("eperrrrorssssssss", eperror);

    let payload = {
      email: userdata.email,
      emergencycontact: {
        name: epname,
        relationship: relationship,
        email: epemail,
        countrycode: epcountrycode,
        phonenumber: epphonenumber,
        language: language,
      },
    };

    let Objlen = Object.keys(validation).length;
    console.log("Obklength", Objlen);

    if (Objlen == 0) {
      let updatedemergencycontact = await updateemergencycontact(payload).then(
        async () => {
          let datas = await usersdata(payload.email);
          console.log("userdaas", datas.data.data[0]);
          // cookies.set("User-Datas", datas?.data?.data[0]);
          setUserdata(datas?.data?.data[0]);

        }
      );
      await removeBlur(7);
      // console.log("updated emergencycontact", updatedemergencycontact.data);
    }
    console.log("handleemergencycontact payload", payload);
    // if (epname.length != 0 && relationship.length != 0 && epphonenumber.length != 0 && valid) {
    //     setShow(0);
    //
    //     // console.log("updated emergencycontact", updatedemergencycontact.data);

    // }
    // else {
    //     console.log('enter this', epname.length, relationship.length, epphonenumber.length, valid)
    //     if (epname.length == 0) {
    //         console.log('enter this one epname')
    //         eperror.epname = '! Name is required';
    //         // setError({...error ,...{['epname'] : "! Name is required"}});
    //         // setEpError({...errors,...{'epname':'!Name is required'}})
    //     }
    //     if (relationship.length == 0) {
    //         console.log('enter this one relationship')
    //         eperror.eprelationship = '! Relationship is required';
    //         // setEpError({...errors,...{'eprelationship':'!Relationship is required'}})
    //         // setError({...error ,...{['eprelationship'] : "! Relationship is required"}});
    //     }
    //     console.log('epphonenumber.length', epphonenumber.length)
    //     if (epphonenumber.length == 0) {
    //         console.log('enter this one epname')
    //         eperror.epphonenumber = '! Phone number is required';

    //     }
    //     if (!valid) {
    //         console.log('enter this onesrasdfas')
    //         setEmailerr(true)
    //     }
    //     else if (valid) {
    //         console.log('enter this', valid?.epemail)
    //         eperror.epemail = valid?.epemail
    //         // setEpError(eperror)
    //         setEmailerr(false)
    //     }
    //     setEpError(eperror)
    // }
  };

  const handleremove = async (data) => {
    let payload = {
      email: userdata?.email,
      phonenumber: data,
    };
    let removeddata = await removeemergencycontact(payload).then(async () => {
      let datas = await usersdata(payload.email);
      console.log("userdaas", datas?.data?.data[0]);
      // cookies.set("User-Datas", datas?.data?.data[0]);
      setUserdata(datas?.data?.data[0]);
    });
  };
  // updatePhoneNumber
  //   if (e?.target?.value == "") {
  //     setErrorState("Please enter mobile number");
  //   }
  //   setPhoneNumber(e.target.value);
  // };
  const updatePhoneNumber = async () => {
    console.log("STATE : ", phoneNumber, userdata?.email);
    let payload = {
      email: userdata?.email,
      phonenumber: phoneNumber,
    };
    let result = await updatePhonenumber(payload);
    console.log("THE LIVE DATA : ", result?.data);
    setUserdata(result?.data);
    // cookies.set("User-Datas", result?.data);
  };

  const handlePhoneNumber = (value, country) => {
    console.log("phoneeeeeeeee", value, country);
    const { dialCode } = country;
    let newPhoneNo = value;
    // let formData = formValue;
    if (dialCode) {
      setPhonecode(dialCode);
      setPhoneNumber(newPhoneNo.slice(dialCode.length));
      // formData = {
      //     ...formData,
      //     phoneCode: dialCode,
      //     phoneNo: newPhoneNo.slice(dialCode.length)
      // }
    } else if (value) {
      setPhoneNumber(value);
      // formData = { ...formData, ...{ phoneNo: value } }
      // setOtpStatus(true)
    }
    if (value && country) {
      // setValidateError({})
    }
    // setFormValue(formData)
    // setValidateError(validation(formData))
  };

  const handlesend = async () => {
    let payload = {
      mobile: phoneNumber,
      phonecode: phonecode,
      email: getUserEmail(),
    };
    let result = await sendotphooks(payload);
    console.log("result in handle send otp", result);
    if (result?.success == true) {
      setOtpverify(true);
      toast.success(result?.message);
    } else {
      toast.error(result?.errors?.phoneNo);
    }
  };

  const handleverify = async () => {
    let payload = {
      mobile: phoneNumber,
      phonecode: phonecode,
      otp: otp,
      email: getUserEmail(),
    };
    let result = await verifyotphooks(payload);
    console.log("result in verify otp hooks", result);
    if (result?.type == "Success") {
      toast.success("Mobile number updated successfully!");
      setOtp("");
    } else {
      toast.error(result?.errors?.phoneNo);
    }
    // removeBlur(1);
    // setError("");
    // setOtp("")
  };
  return (
    <div className="personalinfo nocenterhead">
      <Header />
      <div className="container container_custom mt-100 person_info_edit">
        <div className="row justify-content-center">
          <div className="col-xl-12">
            <div className="breadcrum mt-4">
              {/* <Breadcrumb>
                <Breadcrumb.Item>
                  <Link
                    to="/account/personal-info"
                    className="custom_personal_link"
                  >
                    Personal Info
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to="/account/addbank">Account Details</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to="/account/subscribe">Subscribe</Link>
                </Breadcrumb.Item>
              </Breadcrumb> */}
              <Breadcrumb className="custom_breadcrumb_ovr_rt">
                {/* <Breadcrumb.Item> */}
                <Link
                  to="/account/personal-info"
                  className="cus_link custom_active_link"
                >
                  Personal Info
                </Link>
                {/* </Breadcrumb.Item> */}
                {/* <Breadcrumb.Item > */}
                {/* <span className="breadcrumb_custom_slash">/</span> */}
                {/* <RxSlash className="breadcrumb_custom_slash" /> */}
                <FaChevronLeft className="breadcrumb_custom_slash" />

                <Link to="/account/addbank" className="cus_link">
                  Account Details
                </Link>
                {/* <span className="breadcrumb_custom_slash">/</span> */}
                {/* <RxSlash className="breadcrumb_custom_slash" /> */}
                <FaChevronLeft className="breadcrumb_custom_slash" />

                {/* </Breadcrumb.Item> */}
                {/* <Breadcrumb.Item> */}
                <Link to="/account/subscribe" className="cus_link">
                  Subscribe
                </Link>
                {/* </Breadcrumb.Item> */}
              </Breadcrumb>
              <h2 className="text-center">Personal Info</h2>
            </div>
            <div className="row mt-5 justify-content-between">
              <div className="col-md-7">
                <div className="cont pb-4">
                  <ul className="ps-0 blur_ul">
                    <li id="blur_1">
                      <p className="mb-2">Legal Name</p>
                      <InputGroup className="mb-3 d-flex justify-content-between">
                        <p className="f-14 ps-2 text-muted d-flex align-items-center">
                          {userdata?.firstName &&
                            userdata?.lastName &&
                            userdata?.firstName + " " + userdata?.lastName}
                        </p>
                        <InputGroup.Text id="basic-addon1">
                          <Button
                            id="cancel_1"
                            variant="link"
                            className="p-0 editbtn"
                            onClick={() => {
                              removeBlur(1);
                              setError("");
                            }}
                          >
                            <img src={editIcon} alt="editIcon" className="" />
                          </Button>
                        </InputGroup.Text>
                      </InputGroup>

                      <div className={show == 1 ? "edited show" : "edited"}>
                        <div className="editedcont">
                          <div className="d-flex mt-4">
                            <div className="floating-label w-full me-md-2">
                              <p>First Name</p>
                              <InputGroup className="mb-3 name-input-group">
                                <input
                                  className="form-control"
                                  value={firstname}
                                  type="text"
                                  placeholder={
                                    userdata?.firstName && userdata?.firstName
                                  }
                                  name="firstname"
                                  onChange={(e) => {
                                    console.log(
                                      "EVENT TARTEGT : ",
                                      e.target.name,
                                      e.target.value
                                    );
                                    onchange({ target: e.target });
                                  }}
                                  // onChange={onchange}
                                />

                                <InputGroup.Text id="basic-addon1">
                                  <img
                                    src={editIcon}
                                    alt="editIcon"
                                    className=""
                                  />
                                </InputGroup.Text>
                              </InputGroup>

                              {error?.firstname && (
                                <span className="text-danger">
                                  {error.firstname}
                                </span>
                              )}
                            </div>

                            <div className="floating-label w-full">
                              <p>Last Name</p>
                              <InputGroup className="mb-3 name-input-group">
                                <input
                                  className="form-control"
                                  value={lastname}
                                  placeholder={userdata?.lastName}
                                  type="text"
                                  name="lastname"
                                  onChange={(e) => {
                                    onchange(e);
                                  }}
                                />
                                <InputGroup.Text id="basic-addon1">
                                  <img
                                    src={editIcon}
                                    alt="editIcon"
                                    className=""
                                  />
                                </InputGroup.Text>
                              </InputGroup>
                              {error?.lastname && (
                                <span className="text-danger">
                                  {error.lastname}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="mt-3 mb-4">
                            <button
                              className="btn btn-theme"
                              onClick={updateusername}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li id="blur_2">
                      <p className="mb-2">Gender</p>
                      <div className="d-flex justify-content-between">
                        <InputGroup className="mb-3 d-flex justify-content-between">
                          <p className="f-14 ps-2 text-muted d-flex align-items-center">
                            {userdata?.gender
                              ? userdata?.gender
                              : "Not specified"}
                          </p>
                          <InputGroup.Text id="basic-addon1">
                            <Button
                              id="cancel_2"
                              variant="link"
                              className="p-0 cancelbtn editbtn"
                              onClick={() => removeBlur(2)}
                            >
                              <img src={editIcon} alt="editIcon" className="" />
                            </Button>
                          </InputGroup.Text>
                        </InputGroup>
                      </div>
                      <div className={show == 2 ? "edited show" : "edited"}>
                        <div className="editedcont">
                          <div className="d-flex mt-2">
                            <div className="floating-label w-full">
                              {/* <p>Gender</p> */}
                              <InputGroup className="mb-3 name-input-group">
                                <select
                                  className="form-control"
                                  name="gender"
                                  onChange={(e) => {
                                    onchange(e);
                                  }}
                                >
                                  <option hidden>Gender</option>
                                  <option value="Male">Male</option>
                                  <option value="Female">Female</option>
                                  <option value="Others">others</option>
                                </select>
                                <InputGroup.Text id="basic-addon1">
                                  {/* <img
                                    src={editIcon}
                                    alt="editIcon"
                                    className=""
                                  /> */}
                                  <BiSolidDownArrow
                                    style={{ color: "#AAAAAA" }}
                                  />
                                </InputGroup.Text>
                              </InputGroup>
                              {error?.gender && (
                                <span className="text-danger">
                                  {error.gender}
                                </span>
                              )}
                              {/* <input className='form-control me-2' value="AshwinKumar" type="text"/> */}
                            </div>
                          </div>
                          <div className="mt-3 mb-4">
                            <button
                              className="btn btn-theme"
                              onClick={handlegender}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li id="blur_3">
                      <p className="mb-2">Date of birth</p>
                      <InputGroup className="mb-3 d-flex justify-content-between">
                        <p className="f-14 text-muted d-flex align-items-center ps-2">
                          {userdata?.dob
                            ? (userdata?.dob).slice(0, 10)
                            : "Not specified"}
                        </p>
                        <InputGroup.Text id="basic-addon1">
                          <Button
                            id="cancel_3"
                            variant="link"
                            className="p-0 editbtn"
                            onClick={() => removeBlur(3)}
                          >
                            <img src={editIcon} alt="editIcon" className="" />
                          </Button>
                        </InputGroup.Text>
                      </InputGroup>

                      <div className={show == 3 ? "edited show" : "edited"}>
                        <div className="editedcont">
                          <div className="d-flex mt-4">
                            <div className="floating-label w-full">
                              <p>Date of birth</p>
                              <Datepicker className='datepicker_theme_flutter'
                                    //   minDate={new Date()} 
                                    maxDate={getMinAndMaxDateForDob().maxDate}
                                    onKeyDown={(e) => {
                                        e.preventDefault()
                                    }}
                                    selected={dob}

                                    onChange={(e) => {
                                      setDob(e)
                                    }}
                                />
                              {/* <input
                                className="form-control me-2 fotmcon_big"
                                name="dob"
                                value={dob}
                                type="date"
                                max={new Date(getMinAndMaxDateForDob().maxDate)}
                                max={new Date(
                                  getMinAndMaxDateForDob().maxDate
                                ).toLocaleDateString("en-CA")}
                                onChange={(e) => {
                                  onchange(e);
                                }}
                              /> */}
                            </div>
                          </div>
                          <div className="my-4">
                            <button
                              className="btn btn-theme"
                              onClick={handledob}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* <hr className="themehr" /> */}
                    </li>
                    <li id="blur_4">
                      <div className="">
                        <p className="mb-2">Email id</p>
                        <InputGroup className="mb-3 d-flex justify-content-between">
                          <p className="f-14 ps-2 text-muted d-flex align-items-center">
                            {userdata?.email
                              ? userdata?.email
                              : "Not provided!"}
                          </p>
                          {/* <InputGroup.Text id="basic-addon1">
                            <Button
                              id="cancel_4"
                              variant="link"
                              className="p-0 editbtn"
                              onClick={() => {
                                removeBlur(4);
                                setError("");
                              }}
                            >
                              <img src={editIcon} alt="editIcon" className="" />
                            </Button>
                          </InputGroup.Text> */}
                        </InputGroup>
                      </div>
                      {console.log("show", show)}
                      <div className={show == 4 ? "edited show" : "edited"}>
                        {/* <div className="edited show"> */}

                        <div className="editedcont">
                          <div className="d-flex mt-4">
                            <div className="floating-label w-full me-0">
                              <p>Email</p>
                              <InputGroup className="mb-3 name-input-group">
                                <input
                                  className="form-control"
                                  //   value={firstname}
                                  //   type="text"
                                  //   placeholder={userdata?.firstName}
                                  //   name="firstname"
                                  //   onChange={(e) => {
                                  //     onchange(e);
                                  //   }}
                                />

                                <InputGroup.Text id="basic-addon1">
                                  <img
                                    src={editIcon}
                                    alt="editIcon"
                                    className=""
                                  />
                                </InputGroup.Text>
                              </InputGroup>

                              {/* {error?.firstname && (
                                <span className="text-danger">
                                  {error.firstname}
                                </span>
                              )} */}
                            </div>
                          </div>
                          <div className="mt-3 mb-4">
                            <button
                              className="btn btn-theme"
                              //   onClick={updateusername}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li id="blur_9">
                      <p className="mb-2">Phone Number</p>
                      <InputGroup className="mb-3 d-flex justify-content-between">
                        <p className="f-14 ps-2 text-muted d-flex align-items-center">
                          {userdata?.phoneNumber
                            ? userdata?.phonecode + userdata.phoneNumber
                            : "Not specified"}
                        </p>
                        <InputGroup.Text id="basic-addon1">
                          <Button
                            id="cancel_9"
                            variant="link"
                            className="p-0 editbtn"
                            onClick={() => {
                              removeBlur(9);
                              setAddAnother(true);
                            }}
                          >
                            <img src={editIcon} alt="editIcon" className="" />
                          </Button>
                        </InputGroup.Text>
                      </InputGroup>
                      <div className={show == 9 ? "edited show" : "edited"}>
                        <div className="d-flex">
                          <div className={toggle ? "" : "mt-0"}>
                            <p className="f-14">
                              Contact number (for confirmed guests and
                              FlutterPads to get in touch). You can add other
                              numbers and choose how they’re used.
                            </p>
                          </div>
                          {/* <Button  variant="link" className={toggle ? 'd-none' : 'p-0'} >Edit</Button> */}
                        </div>

                        {/* Use an address you’ll always have access to. */}

                        <div className="editedcont mt-2">
                          {/* <div className='floating-label w-full me-md-2 mb-3'>
                                                            <p>Street address</p>
                                                            <input className='form-control me-2' value="" type="text"/>
                                                            <label className='err'></label>
                                                        </div> */}

                          {/* <div className={showadd ?"d-none" : 'mt-4'} >
                                                        <button className='btn btn-theme' onClick={() =>setShowAdd(true)}>Add another number</button>
                                                    </div> */}
                          <div className={showadd ? "" : "d-none"}>
                            {!addanother ? (
                              <>
                                {/* <h6 className='mt-3'>OK to remove your phone as a login option?</h6>
                                                        <p className='f-14'> If you add more than one number, you’ll no longer be able to use your phone 
                                                            number to log in to FlutterPads (but there are other 
                                                            ways to log in, such as with email). This helps protect your account.</p> */}
                                <div className="mt-3">
                                  {/* <button className='btn btn-theme me-2' onClick={() => {setAddAnother(true)}}>Continue</button>
                                                        <button className='btn btn_grey' onClick={() =>setShowAdd(false)}>Cancel</button> */}
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                          {addanother ? (
                            <div className="loginmodal">
                              <div>
                                {/* <div className='select withlab'>
                            <p>Current Region</p>
                            <Form.Select aria-label="Default select example">
                            <option>India(+91)</option>
                            <option value="India(+91)">India(+91)</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                            </Form.Select>{}
                        </div>  */}
                                {console.log("phoenEnmber", phoneNumber)}
                                <div className="react_tele">
                                  <PhoneInput
                                    country={"us"}
                                    value={phonecode + phoneNumber}
                                    onChange={handlePhoneNumber}
                                  />
                                  {!otpverify && (
                                    <button
                                      className="btn btn-theme"
                                      disabled={otpverify}
                                      onClick={handlesend}
                                    >
                                      Send
                                    </button>
                                  )}
                                </div>
                                {otpverify && (
                                  <>
                                    <p className="mb-2 mt-3">OTP</p>
                                    <InputGroup className="mb-3 d-flex justify-content-between">
                                      <input
                                        className="form-control otp_iout_indie"
                                        value={otp}
                                        onChange={(e) =>
                                          setOtp(e?.target?.value)
                                        }
                                      />
                                      <InputGroup.Text id="basic-addon1">
                                        <Button
                                          id="cancel_1"
                                          variant="link"
                                          className="p-0 editbtn"
                                          onClick={() => {
                                            removeBlur(1);
                                            setError("");
                                          }}
                                        >
                                          <i className="fa fa-check" />
                                        </Button>
                                      </InputGroup.Text>
                                    </InputGroup>

                                    {/* <InputGroup className="mb-3">
                                  <Form.Control
                                    placeholder="Enter Phone Number"
                                    type="number"
                                    aria-label="Username"
                                    value={phoneNumber}
                                    onChange={(e) => {
                                      handlePhoneNumber(e);
                                    }}
                                  />
                                  <InputGroup.Text id="basic-addon1">
                                    <img
                                      src={editIcon}
                                      alt="editIcon"
                                      className=""
                                    />
                                  </InputGroup.Text>
                                </InputGroup> */}
                                    <div className="mt-4 mb-4">
                                      <button
                                        className="btn btn-theme"
                                        onClick={() => {
                                          handleverify();
                                          removeBlur(9);
                                        }}
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </>
                                )}
                                {/* <p className='f-14 mt-2'>We’ll send you a code to verify your number. Standard message and data rates apply.</p> */}
                              </div>
                              {/* <div className='mt-3'>
                        <h6 className='mt-4'>How would you like to use this number?</h6>
                        <Form.Check reverse className='ps-0 themecheck' label="Contact (for confirmed guests and FlutterPads to get in touch)"  type="checkbox"/>
                        <Form.Check reverse className='ps-0 themecheck mt-2' label="Account verification (for getting security passcodes)"  type="checkbox"/>

                        <button className='btn btn-theme mt-3' disabled>Verify</button>
        
                        </div> */}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </li>
                    <li id="blur_5">
                      <p className="mb-2 mt-3">Goverment ID</p>{" "}
                      <p className="text-danger mb-3">
                        Govt Id Status : {kyc?.verification}
                      </p>
                      <InputGroup className="mb-3 d-flex justify-content-between">
                        <p className="f-14 ps-2 d-flex align-items-center text-muted">
                          {kyc?.proof ? kyc?.proof : "Not provided"}
                        </p>
                        {/* {kyc?.verification == "rejected" || !kyc?.verification &&
                        <InputGroup.Text id="basic-addon1">
                          <Link
                            to="/account/govtid"
                            className="p-0 editbtn text-dark"
                          >
                            
                         

                          </Link>
                        </InputGroup.Text>} */}

                        <InputGroup.Text id="basic-addon1">
                          {/* userdata?.govtidverification == "" || userdata?.govtidverification == "rejected" &&  */}
                          <Link
                            to={
                              (!kyc?.verification ||
                                kyc.verification == "rejected") &&
                              "/account/govtid"
                            }
                            className="p-0 editbtn text-dark"
                          >
                            {kyc?.verification == "accepted" && (
                              <i className="fa fa-check" />
                            )}
                            {kyc?.verification == "pending" && (
                              <i className="fa fa-clock" />
                            )}
                            {(kyc?.verification == "rejected" ||
                              !kyc?.verification) && (
                              <img src={editIcon} alt="editIcon" className="" />
                            )}
                          </Link>
                        </InputGroup.Text>
                      </InputGroup>
                      <div className={show == 5 ? "edited show" : "edited"}>
                        {/* Use an address you’ll always have access to. */}

                        <div className="editedcont mt-4">
                          <div className="floating-label w-full me-md-2 mb-3" />
                          <div className="floating-label w-full me-md-2 mb-3">
                            <p>Street address</p>
                            <input
                              className="form-control me-2"
                              value=""
                              type="text"
                            />
                          </div>
                          <div className="floating-label w-full me-md-2 mb-3">
                            <p>Flat, Suite(optional)</p>
                            <input
                              className="form-control me-2"
                              value=""
                              type="text"
                            />
                          </div>
                          <div className="d-flex mb-3 custom-address-country-wrap">
                            <div className="floating-label w-full me-md-2">
                              <p>City</p>
                              <input
                                className="form-control me-2"
                                value=""
                                type="text"
                              />
                            </div>
                            <div className="floating-label w-full">
                              <p>State / Province / Country / Region </p>
                              <input
                                className="form-control me-2"
                                value="LS"
                                type="text"
                              />
                            </div>
                          </div>
                          <div className="floating-label w-full me-md-2">
                            <p>Postcode</p>
                            <input
                              className="form-control me-2"
                              value=""
                              type="text"
                            />
                          </div>
                          <div className="my-3">
                            <button className="btn btn-theme">Save</button>
                          </div>
                        </div>
                      </div>
                      {/* <hr className="themehr" /> */}
                    </li>

                    <li id="blur_6">
                      <p className="mb-2">Address</p>
                      <InputGroup className="mb-3 d-flex justify-content-between">
                        <p className="f-14 ps-2 text-muted d-flex align-items-center">
                          {console.log(
                            "Address",
                            userdata?.address,
                            userdata?.address?.street,
                            userdata?.address?.flat,
                            userdata?.address?.city,
                            userdata?.address?.stateprovince,
                            userdata?.address?.postcode
                          )}

                          {console.log(
                            "checking add",
                            userdata?.address
                              ? (userdata?.address?.street
                                  ? userdata?.address?.street
                                  : "") +
                                  " " +
                                  (userdata?.address?.flat
                                    ? userdata?.address?.flat
                                    : "") +
                                  " " +
                                  (userdata?.address?.city
                                    ? userdata?.address?.city
                                    : "") +
                                  " " +
                                  (userdata?.address?.stateprovince
                                    ? userdata?.address?.stateprovince
                                    : "") +
                                  " " +
                                  (userdata?.address?.postcode
                                    ? userdata?.address?.postcode
                                    : "") +
                                  " " +
                                  (userdata?.address?.country != "undefined"
                                    ? userdata?.address?.country
                                    : "")
                              : "Not provided"
                          )}
                          {userdata?.address
                            ? (userdata?.address?.street
                                ? userdata?.address?.street
                                : "") +
                              " " +
                              (userdata?.address?.flat
                                ? userdata?.address?.flat
                                : "") +
                              " " +
                              (userdata?.address?.city
                                ? userdata?.address?.city
                                : "") +
                              " " +
                              (userdata?.address?.stateprovince
                                ? userdata?.address?.stateprovince
                                : "") +
                              " " +
                              (userdata?.address?.postcode
                                ? userdata?.address?.postcode
                                : "") +
                              " " +
                              (userdata?.address?.country
                                ? userdata?.address?.country
                                : "")
                            : "Not provided"}
                        </p>
                        <InputGroup.Text id="basic-addon1">
                          <Button
                            id="cancel_6"
                            variant="link"
                            className="p-0 editbtn"
                            onClick={() => removeBlur(6)}
                          >
                            <img src={editIcon} alt="editIcon" className="" />
                          </Button>
                        </InputGroup.Text>
                      </InputGroup>
                      <div className={show == 6 ? "edited show" : "edited"}>
                        {console.log(
                          "userdatasgal",
                          userdata,
                          userdata?.address?.country
                        )}

                        {/* Use an address you’ll always have access to. */}

                        <div className="editedcont mt-4">
                          <div className="floating-label w-full me-md-2 mb-3">
                            <p>Country/region</p>
                            <InputGroup className="mb-3 name-input-group">
                              <select
                                className="form-control"
                                name="country"
                                value={userdata?.address?.country}
                                onChange={(e) => {
                                  onchange(e);
                                }}
                              >
                                {/* <option value = "India">India</option> */}
                                {Countrycode?.map((result) => (
                                  <option>{result?.name}</option>
                                ))}
                                {/* <option>{`${result.name}(${result.dial_code})`}</option> */}
                                {/* <option>hgkg</option> */}
                              </select>
                              <InputGroup.Text id="basic-addon1">
                                {/* <img
                                    src={editIcon}
                                    alt="editIcon"
                                    className=""
                                  /> */}
                                <BiSolidDownArrow
                                  style={{ color: "#AAAAAA" }}
                                />
                              </InputGroup.Text>
                            </InputGroup>

                            {/* <input className='form-control me-2' value="AshwinKumar" type="text"/> */}
                          </div>
                          <div className="floating-label w-full me-md-2 mb-3">
                            <p>Street address</p>
                            <InputGroup className="mb-3 name-input-group">
                              <input
                                className="form-control"
                                value={street}
                                type="text"
                                name="street"
                                onChange={(e) => {
                                  setStreet(e.target.value);
                                }}
                              />
                              <InputGroup.Text id="basic-addon1">
                                <img
                                  src={editIcon}
                                  alt="editIcon"
                                  className=""
                                />
                              </InputGroup.Text>
                            </InputGroup>
                          </div>
                          <div className="floating-label w-full me-md-2 mb-3">
                            <p>Flat, Suite(optional)</p>
                            <InputGroup className="mb-3 name-input-group">
                              <input
                                className="form-control"
                                value={flat}
                                type="text"
                                name="flat"
                                onChange={(e) => {
                                  setFlat(e.target.value);
                                }}
                              />
                              <InputGroup.Text id="basic-addon1">
                                <img
                                  src={editIcon}
                                  alt="editIcon"
                                  className=""
                                />
                              </InputGroup.Text>
                            </InputGroup>
                          </div>
                          <div className="d-flex mb-0 custom-address-country-wrap">
                            <div className="floating-label w-full me-md-2">
                              <p>City</p>
                              <InputGroup className="mb-3 name-input-group">
                                <input
                                  className="form-control"
                                  value={city}
                                  type="text"
                                  name="city"
                                  onChange={(e) => {
                                    setCity(e.target.value);
                                  }}
                                />
                                <InputGroup.Text id="basic-addon1">
                                  <img
                                    src={editIcon}
                                    alt="editIcon"
                                    className=""
                                  />
                                </InputGroup.Text>
                              </InputGroup>
                            </div>
                            <div className="floating-label w-full">
                              <p>State / Province / County / Region </p>
                              <InputGroup className="mb-3 name-input-group">
                                <input
                                  className="form-control"
                                  value={stateprovince}
                                  type="text"
                                  name="stateprovince"
                                  onChange={(e) => {
                                    setStateprovince(e.target.value);
                                  }}
                                />
                                <InputGroup.Text id="basic-addon1">
                                  <img
                                    src={editIcon}
                                    alt="editIcon"
                                    className=""
                                  />
                                </InputGroup.Text>
                              </InputGroup>
                            </div>
                          </div>
                          <div className="floating-label w-full me-md-2">
                            <p>Postcode</p>
                            <InputGroup className="mb-3 name-input-group">
                              <input
                                className="form-control"
                                value={postcode}
                                type="text"
                                name="postcode"
                                onChange={(e) => {
                                  setPostcode(e.target.value);
                                }}
                              />
                              <InputGroup.Text id="basic-addon1">
                                <img
                                  src={editIcon}
                                  alt="editIcon"
                                  className=""
                                />
                              </InputGroup.Text>
                            </InputGroup>
                          </div>
                          <div className="my-4">
                            <button
                              className="btn btn-theme"
                              onClick={handleaddress}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>

                      {/* <hr className="themehr" /> */}
                    </li>
                    <li id="blur_7">
                      <p className="mb-2">Emergency contact</p>
                      <InputGroup className="mb-3 d-flex justify-content-between">
                        <p className="f-14 ps-2 text-muted d-flex align-items-center">
                          {userdata?.emergencycontact?.length > 0
                            ? "Add another"
                            : "Not Provided"}
                        </p>
                        <InputGroup.Text id="basic-addon1">
                          <Button
                            id="cancel_7"
                            variant="link"
                            className="p-0 editbtn"
                            onClick={() => removeBlur(7)}
                          >
                            <img src={editIcon} alt="editIcon" className="" />
                          </Button>
                        </InputGroup.Text>
                      </InputGroup>

                      <div className={show == 7 ? "edited show" : "edited"}>
                        {/* <p className='f-14 text-muted'>{userdata?.emergencycontact?.email ? userdata?.emergencycontact?.email : "Not specified"}</p> */}
                        {/* Use an address you’ll always have access to. */}
                        {userdata?.emergencycontact?.map((i) => (
                          <div className="d-flex jc-between">
                            <p className="mb-0">{i.name}</p>
                            <Button
                              variant="link"
                              onClick={() => {
                                handleremove(i.phonenumber);
                              }}
                              className="text-dark p-0"
                            >
                              Remove
                            </Button>
                          </div>
                        ))}
                        <div className="editedcont">
                          <div className="floating-label w-full mt-4">
                            <p>Name</p>
                            <InputGroup className="mb-3 name-input-group">
                              <input
                                className="form-control"
                                type="text"
                                value={epname}
                                name="epname"
                                onChange={(e) => {
                                  setEpname(e.target.value);
                                }}
                              />
                              <InputGroup.Text id="basic-addon1">
                                <img
                                  src={editIcon}
                                  alt="editIcon"
                                  className=""
                                />
                              </InputGroup.Text>
                            </InputGroup>
                            {epname
                              ? ""
                              : errors?.epname && (
                                  <span className="text-danger">
                                    {errors?.epname}
                                  </span>
                                )}
                          </div>
                          <div className="floating-label w-full mt-3">
                            <p>Relationship</p>
                            <InputGroup className="mb-3 name-input-group">
                              <input
                                className="form-control"
                                type="text"
                                name="eprelationship"
                                value={relationship}
                                onChange={(e) => {
                                  setRelationship(e.target.value);
                                }}
                              />
                              <InputGroup.Text id="basic-addon1">
                                <img
                                  src={editIcon}
                                  alt="editIcon"
                                  className=""
                                />
                              </InputGroup.Text>
                            </InputGroup>
                            {relationship
                              ? ""
                              : errors?.eprelationship && (
                                  <span className="text-danger">
                                    {errors?.eprelationship}
                                  </span>
                                )}
                          </div>

                          <div className="floating-label w-full mt-3">
                            <p>Preferred language</p>
                            <InputGroup className="mb-3 name-input-group">
                              <select
                                className="form-control selct_lan"
                                name="eplanguage"
                                value={eplanguage}
                                onChange={(e) => {
                                  setEplanguage(e.target.value);
                                }}
                              >
                                {console.log("languagesssss", languages)}
                                {languages.map((result) => (
                                  <option>{result.name}</option>
                                ))}
                              </select>
                              <InputGroup.Text id="basic-addon1">
                                <BiSolidDownArrow
                                  style={{ color: "#AAAAAA" }}
                                />
                              </InputGroup.Text>
                            </InputGroup>
                          </div>

                          <div className="floating-label w-full mt-3">
                            <p>Email</p>
                            {console.log(
                              "epemailsssssssssssssssssss",
                              errors?.epemail
                            )}
                            <InputGroup className="mb-3 name-input-group">
                              <input
                                className="form-control"
                                type="text"
                                value={epemail}
                                name="epemail"
                                onChange={(e) => {
                                  setEpemail(e.target.value);
                                }}
                              />
                              <InputGroup.Text id="basic-addon1">
                                <img
                                  src={editIcon}
                                  alt="editIcon"
                                  className=""
                                />
                              </InputGroup.Text>
                            </InputGroup>
                            {/* {epemail ? (emailerr ? <Form.Text className='text-danger'>Invalid email address</Form.Text> : '') : errors?.epemail ? <Form.Text className='text-danger'>{errors?.epemail}</Form.Text> : (emailerr ? <Form.Text className='text-danger'>Invalid email address</Form.Text> : <></>)} */}
                            {
                              <Form.Text className="text-danger">
                                {errors?.epemail}
                              </Form.Text>
                            }
                          </div>

                          <div className="d-flex mt-3">
                            <div className="floating-label w-full me-md-2 mb-3">
                              <p>Country code</p>
                              <InputGroup className="mb-3 name-input-group">
                                <select
                                  className="form-control"
                                  name="epcountrycode"
                                  value={epcountrycode}
                                  onChange={(e) => {
                                    setEpcountrycode(e.target.value);
                                  }}
                                >
                                  {Countrycode?.map((result) => (
                                    <option>{`${result.name}(${
                                      result.dial_code
                                    })`}</option>
                                  ))}
                                  {/* <option>India</option> */}
                                  {/* <option>hgkg</option>
                                                                    <option>hgkg</option> */}
                                </select>
                                <InputGroup.Text id="basic-addon1">
                                  <BiSolidDownArrow
                                    style={{ color: "#AAAAAA" }}
                                  />
                                </InputGroup.Text>
                              </InputGroup>
                            </div>
                            <div className="floating-label w-full">
                              <p>Phone Number</p>
                              <InputGroup className="mb-3 name-input-group">
                                <input
                                  className="form-control"
                                  name="epphonenumber"
                                  type="number"
                                  value={epphonenumber}
                                  onChange={(e) => {
                                    console.log(
                                      "epppppppppppppp",
                                      e.target.value.length
                                    );
                                    if (e.target.value.length <= 10) {
                                      setEpphonenumber(e.target.value);
                                    }
                                  }}
                                />
                                <InputGroup.Text id="basic-addon1">
                                  <img
                                    src={editIcon}
                                    alt="editIcon"
                                    className=""
                                  />
                                </InputGroup.Text>
                              </InputGroup>
                              {errors?.epphonenumber && (
                                <span className="text-danger">
                                  {errors?.epphonenumber}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="mb-5">
                            <button
                              className="btn btn-theme"
                              onClick={() => {
                                handleemergencycontact();
                              }}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* <hr className="themehr" /> */}
                    </li>
                    {/* <li id="blur_8">
                                            <div className='d-flex justify-content-between'>
                                                <p className='mb-0'>Passport info for China travel</p>
                                                
                                                <Button id="cancel_8" variant="link" className='p-0 editbtn' onClick={() => removeBlur(8)}>Edit</Button>
                                            </div>
                                            <div className={show ==8 ? "edited show" : "edited"}>
                                                <p className='f-14 text-muted'>Not provided</p>
                                                <p className='f-14 text-muted'>This is only needed for those travelling to China. This information
                                                 will be used to complete your reservation of a China listing. By completing a reservation of a China listing, you agree that FlutterPads China will disclose your information to Chinese government agencies without further notice to you. Your name, phone number, email address, ID/passport info, booking info, and messages 
                                                with your host will be transferred to, stored and processed by FlutterPads China.Learn more</p>
                                                <p  className='f-14 text-muted mt-3'>The info you provided above won’t be shared with your host. Guest profiles
                                                     will be stored for your convenience next time.</p>
                                                 
                                                <div className='editedcont'>
                                                    <div className='d-flex'>
                                                        <div className='floating-label w-full mt-3 me-3'>
                                                            <p>First Name</p>
                                                            <input className='form-control me-2' value="AshwinKumar LS" type="text"/>
                                                        </div>
                                                        <div className='floating-label w-full mt-3'>
                                                            <p>Last Name</p>
                                                            <input className='form-control me-2' value="" type="text"/>
                                                        </div>
                                                        </div>
                                                        <div className='floating-label  mt-3'>
                                                            <p>Preffered language</p>
                                                            <select className='form-control pt-4'>
                                                                <option>India</option>
                                                                <option>hgkg</option>
                                                                <option>hgkg</option>
                                                            </select>
                                                       </div>
                                                    <div className='mt-4'>
                                                        <button className='btn btn-theme'>Save</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr className='themehr'/>
                                        </li> */}
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-md-5 mt-4">
                <div className="personal-info-right-side-sec">
                  <div className="right-cont bord p-4 br-10 d-flex align-items-center justify-content-center">
                    <img src={bag} className="mb-3 me-3" />
                    <div>
                      <h6 className="heading">Which details can be edited?</h6>
                      <p className="description">
                        Details <span className="fw-700">FlutterPads</span> uses
                        to verify your identity can’t be changed. Contact info
                        and some personal details can be edited, but we may ask
                        you to verify your identity the next time you book or
                        create a listing.
                      </p>
                    </div>
                  </div>
                  {/* <hr className="themehr" /> */}
                  <div className="right-cont bord p-4 br-10 d-flex align-items-center justify-content-center">
                    <img src={eye} className="mb-3 me-3" />
                    <div>
                      <h6 className="heading">
                        What info is shared with others?
                      </h6>
                      <p className="description">
                        FlutterPads only releases contact information for Hosts
                        and guests after a reservation is confirmed.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PersonalInfo;
