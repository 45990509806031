import React, { Component, useEffect, useMemo, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import Cookies from "universal-cookie";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { API_URL } from "../../config/env";
import { Link } from "react-router-dom";
import { getUserEmail } from "../../Hooks/useUserHook";
// import { addwishlist , getwishlistarray} from '../../Hooks/filterHook';
import Login from "../Common/Modals/LoginModal.js";
import { getOverAllRating } from "../../Hooks/useUserHostingHook";

import guest from "../../Assets/images/landingPage/card/guest.png";
import bed from "../../Assets/images/landingPage/card/bed.png";
import bathroom from "../../Assets/images/landingPage/card/bathroom.png";

const ExpListCard = (props) => {
  console.log("dsadvhnsavdhasd", props);
  // const [wishedlist , setWishedlist] = useState([]);
  const [email, setEmail] = useState("");
  const [loginModal, setLoginModal] = useState(false);
  const [aaa, setAaa] = useState("");
  const [roomRating, setRoomRating] = useState({});

  return (
    <div className="grid-5 mt-4 cus_card">
      {loginModal && (
        <Login
          onDismiss={() => {
            setLoginModal(false);
          }}
        />
      )}
      <div className="where-card yellownav">
        <div className="card-img">
          <OwlCarousel
            className="owl-theme"
            dots={false}
            margin={10}
            nav={true}
            items={1}
            id="big"
          >
            {props.data.photos?.map((data, index) => (
              <div class="item">
                <Link
                  target="_blank"
                  to={"/experience-details/" + props.data._id}
                >
                  <img
                    src={API_URL + props.data.photosPath + "/" + data?.name}
                  />
                </Link>
                <button className="fav-btn fa fa-heart-o" />
              </div>
            ))}
          </OwlCarousel>
        </div>

        <Link
          target="_blank"
          to={"/experience-details/" + props.data._id}
          className="nounder"
        >
          <div className="where-cont px-3 py-3">
            <div className="d-flex justify-content-between">
              <p className="name themeclr fw-600 f-14">{props?.data?.title}</p>
              {/* <p className="fav txtgray f-12">
                New
                <span className="fa fa-star ms-1" aria-hidden="true" />
              </p> */}
            </div>

            {/* <p className='review'><i className='fa fa-star me-1'><span className='fa fa-star'></span></i>
                    5.0(33)
                    </p> */}

            {/* <p className='txtgray f-12'>{props.data.title}</p> */}
            <p className="txtgray f-12 cus_txt_clr">
              Hosted by {props.data.firstName} {props.data.lastName}
            </p>
            <p className="rent my-2 my-md-4 themeclr f-14 fw-600 card-amount-dis">
              <span className=" fw-600">
                From {props?.symbol}
                {props.data.pricePerGuest}{" "}
              </span>
              / person
            </p>
            {/* <hr className="m-0 cus_hr " />
            <div className="card_ftr_sec mt-2">
              <div className="d-flex align-items-center ">
                <img
                  src={guest}
                  alt="guest"
                  className="me-2 img-fluid cmn_logo"
                />
                <span>6 guest</span>
              </div>
              <div className="d-flex align-items-center ">
                <img src={bed} alt="bed" className="me-2 img-fluid cmn_logo" />
                <span>2 beds</span>
              </div>
              <div className="d-flex align-items-center ">
                <img
                  src={bathroom}
                  alt="bathroom"
                  className="me-2 img-fluid cmn_logo"
                />
                <span>1 bathroom</span>
              </div>
            </div> */}
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ExpListCard;
