import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { getExperienceFromLocal } from '../../../../Hooks/useUserExperienceHook';
import ExpTitle from './ExpTitle';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-hot-toast';
import { toastOptions } from '../../../../config/env';

const SetTime = (props) =>{
    const [eventDate,setEventDate] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [buttonCondition, setButtonCondition] = useState(false);

    
    // const setExpTime = (data,index) => {
    //     if(!eventDate[0] && index == 0){
    //         eventDate.push(data)
    //     }
    //     if(!eventDate[1] && index == 1){
    //         eventDate.push(data)
    //     }
    //     if(eventDate[0] && eventDate[1]){
    //         eventDate[index] = data
    //     }
    //     setEventDate(eventDate);
    //     console.log("eventDate:",eventDate);
    // }

    useEffect(()=>{
        if(getExperienceFromLocal() && getExperienceFromLocal().date && !startDate && !endDate){
            setEventDate(getExperienceFromLocal().date);
            setStartDate(getExperienceFromLocal().date[0]);
            setEndDate(getExperienceFromLocal().date[1]);
        }  
    //     console.log("eventDate in useEffect:",eventDate);
    //    if(eventDate.length == 2){
    //     setButtonCondition(true)
    //    }
        if(startDate&&endDate){    
        eventDate[0] = startDate;
        eventDate[1] = endDate;
        }

    },[startDate,endDate]);

    return(
        <>
                <div className='set_date_time_lfex'>
                    <h2 className='conthead'>Set your default date</h2>
                    <p className='text-muted'>
                        {/* Choose the category that best describe your experience. Add a second,
                        if you think it fits into another category. */}
                        Choose the dates you’re scheduled to host. Each experience must be at least 1 day.
                    </p>
                    <div className='h-55 mt-4'>
                        <div className='d-sm-flex flex-1 align-items-center d_mbo_datete'>
                            <div>
                                <label className='f-14 text-muted'> Start date</label>
                                <div className='input_with_icon_datpicker'>
                                <DatePicker
                                minDate={new Date()}
                                selected = {startDate}

onChange={(e)=>{
  setStartDate(e);
  }}

  
  showTimeSelect
  dateFormat="Pp"
/>

</div>


                                  {/* <input type="date" 
                                   min={new Date().toISOString().split('T')[0]}
                                  value={startDate && new Date(startDate).toISOString().split('T')[0]} 
                                  onChange={(e)=>{
                                    setStartDate(e.target.value);
                                    }} className='form-control' placeholder='Start date' /> */}
                            </div>
                          
                            <p className='my-sm-0 my-3 mx-auto  max-width-100 text-center inout_picker_to_div'>to</p>
                            <div>
                            <label className='f-14 text-muted'> End date</label>
                            <div className='input_with_icon_datpicker'>
                                <DatePicker
                                minDate={new Date()}
                                selected = {endDate}
                                onChange={(e)=>{
                                    setEndDate(e);
                                }}

  
  showTimeSelect
  dateFormat="Pp"
/>

</div>
                            {/* <input type="date"
                            disabled={!startDate}
                            min={startDate && new Date(startDate).toISOString().split('T')[0]}
                            value={endDate && new Date(endDate).toISOString().split('T')[0]} 
                            onChange={(e)=>{
                                // setExpTime(e.target.value,1);
                            setEndDate(e.target.value)
                            }} className='form-control' placeholder='End date'/> */}
                            </div>
                        </div>
                        <div className='mt-4'>
                            <button disabled={!(startDate&&endDate)} className='btn btn-theme' onClick={() => {if(new Date(startDate) < new Date(endDate)){props.onSetLocal(eventDate,"date");props.count()}else{
                               toast.error("Enddate must be greater than start date", toastOptions);
                                console.log("errr");
                            }}}>Save & Continue</button>
                        </div> 
                    </div>
              </div>    
        </>

    )
}

export default SetTime