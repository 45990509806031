import React, { Component, useState, useEffect } from "react";

import { propTypes } from "react-bootstrap/esm/Image";
import Scrollbars from "react-custom-scrollbars";
import { API_URL } from "../../../config/env";

const CancellationPolicy = (props) => {
  console.log("cancal props", props);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedValue, setSelectedValue] = useState("");

  const setData = (val, index) => {
    setSelectedIndex(index);
    setSelectedValue(val);
    localStorage.setItem("cancellationPolicyIndex", index);
    props.onGetCancellationPolicy(val);
  };

  useEffect(() => {
    setSelectedIndex(
      localStorage.getItem("cancellationPolicyIndex")
        ? localStorage.getItem("cancellationPolicyIndex")
        : selectedIndex
    );
    localStorage.getItem("cancellationPolicy")
      ? props.onGetCancellationPolicy(
          localStorage.getItem("cancellationPolicy")
        )
      : localStorage.removeItem("cancellationPolicyIndex");
  }, []);

  return (
    <div className="col-md-12">
      <div className="placetype rightcont mb-5 describeplace">
        <Scrollbars
          style={{ height: "calc(100vh - 320px)" }}
          className="scroll"
        >
          <div
            className="row  px-3 align-items-center height-100 mb-5 py-2"
            data-aos="fade-up"
          >
            <div className="row">
              {props.data &&
                props.data.content.map((val, index) => {
                  return (
                    <>
                      {val?.status != "Inactive" && (
                        <div className="col-md-4">
                          <button
                            onClick={() => {
                              setData(val, index);
                            }}
                            className={
                              index == selectedIndex
                                ? "placecards active p-4"
                                : "placecards p-4"
                            }
                          >
                            <div className="d-flex flex-column align-items-center justify-content-center py-4">
                              <img
                                src={
                                  API_URL + props.data.path + "/" + val.image
                                }
                              />
                              <h6 className="mb-0">{val.type}</h6>
                            </div>
                          </button>
                        </div>
                      )}
                    </>
                  );
                })}
            </div>
          </div>
        </Scrollbars>
      </div>
    </div>
  );
};

export default CancellationPolicy;
