import React, { useEffect, useState } from 'react';
import {Modal} from "react-bootstrap"
import { toast, Toaster } from "react-hot-toast"
import { useNavigate } from 'react-router-dom';
import img1 from "../../../../../../Assets/images/where/img1.png"
import { API_URL, toastOptions } from '../../../../../../config/env';
import { getUserDatas } from '../../../../../../Hooks/useUserHook';
import { getCancellationAmount, cancelRoom } from '../../../../../../Hooks/useUserHostingHook';

const CancelModal = (props) => {
    const[cancel, setCancel] = useState(true);
    const [cancellationAmount, setCancellationAmount] = useState(0);

    const navigate = useNavigate();

    useEffect(()=>{
        if(props.data&&props.data._id){
            const data = {
                bookingId : props.data._id,
                cancellationPolicy : props.data.cancellationPolicy,
                cancelledAmount : (parseInt(props.data.totalAmount) - parseInt(props.data.serviceFeeAmount))
            }
            console.log('datacanceldetails',data)
            getCancellationAmount(data).then((result)=>{
                setCancellationAmount(result.cancellationAmount);
            })
        }
    },[]);

    const cancelBooking = () => {
        let user = getUserDatas()
        const data = {
            id : user?._id,
            bookingId:props.data._id,
            roomId:props.data.roomId,
            checkInDate:props.data.checkInDate,
            checkOutDate:props.data.checkOutDate,
            hostEmail:props.data.hostEmail,
            email:props.data.email,
            cancellationPolicy:props.data.cancellationPolicy,
            cancelledAmount: parseFloat(cancellationAmount) //parseFloat(props.data.totalAmount) - parseFloat(props.data.serviceFeeAmount)
        }
        console.log('dataqssssdddddddddd',data)
        cancelRoom(data).then((data)=>{
            if(data?.type == "error"){
                toast.error(data?.msg);
                return;
            }
            console.log('datacanclroom',data,data.message,toastOptions)
            toast.success(data.message,toastOptions);
            if(cancellationAmount!=0){
            navigate(`/host-dashboard/trips/cancellation/${data.data._id}`);
            }
            props.onReload();
            props.onDismiss();
        })
    }

    return(
        <>
           <Modal show={cancel} centered scrollable size="md" className='modal_style scenic listingmodals cancelbook'>
                <Modal.Header className='border-0 pb-0'>
                    <button type="button" className="btn close close_modal" onClick={() =>props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <h4 className='mb-4 text-center'>Cancel Booking</h4>
                        <div className='d-flex align-items-center flrx_mob_co'>
                            <img src={`${API_URL}/${props.data.roomDetails.photosPath}/${props.data.roomDetails.photos[0].name}`} className="img"/>
                            <div className='ps-3'>
                                <h5 className='themeclr mb-1'>{props.data.roomTitle}</h5>
                                <p className='text-muted f-14'>Hosted by, {props.data.firstName}&nbsp;{props.data.lastName}</p>
                                <p className='text-muted f-14'>Total Amount, {parseInt(props.data.totalAmount)}&nbsp;{props.data.lastName}</p>
                                <p className='text-muted f-14'>Refund Amount : {(parseInt(props.data.totalAmount) - parseInt(props.data.serviceFeeAmount))}</p>
                                {/* <div className='f-14'><span className='fa fa-star'></span>5.00 (1 review)</div> */}
                            </div>
                        </div>
                        <hr className='themehr' />
                        <div>
                        {props?.data?.isValidTransaction  &&<>
                            <h6 className='text-muted mb-3'>
                                Price Details
                            </h6>
                            <div className='d-flex jc-between'>
                                {/* {(props.data.roomDetails.price)} X */}
                               <h6 className='themeclr'> $ {props.data.nights} nights</h6>
                               <p >${parseFloat(props.data.totalAmount)- parseFloat(props.data.serviceFeeAmount)}</p>
                            </div>
                            <div className='d-flex jc-between'>
                               <h6 className='themeclr'> Service Fee</h6>
                               <p >${props.data.serviceFeeAmount}</p>
                            </div>
                            {/* <div className='d-flex jc-between'>
                               <h6 className='themeclr'> Occupancy taxes and fees</h6>
                               <p >$4300</p>
                            </div> */}
                            <hr className='themehr' />
                            <div className='d-flex jc-between'>
                               <h6 className='themeclr'>Return Amount(INR)</h6>
                               <p >${parseFloat(cancellationAmount).toFixed(4)}</p>
                            </div>
                            <div className='graybox br-0 border_rad_0 my-3'>
                                <p className='p-3 text-center'>{`${props.data.cancellationPolicy} - you will get only $${parseFloat(cancellationAmount).toFixed(4)} as return`}</p>
                            </div>
                            </>}
                            <div className='text-end'>
                                <button onClick={()=>{props.onDismiss()}} className='btn btn-theme-dark me-2'>cancel</button>
                                <button onClick={cancelBooking} className='btn btn-theme'>confirm</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Toaster/>
        </>
    )
}

export default CancelModal