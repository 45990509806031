import React, {  useState } from 'react';
import {Modal} from 'react-bootstrap'
import BookingPayCard from '../BookingPayCard';



const BookPayModal = (props) => {
    const[bookpaycard, setBookPayCard] = useState(true)

    return(
      
        <Modal show={bookpaycard} size="md" centered scrollable className='modal_style'>
        <Modal.Header className='border-0 pb-0'>
            <button type="button" className="btn close close_modal" onClick={() =>props.onDismiss()}><span aria-hidden="true" className='fa fa-times f-14'></span><span class="sr-only">Close</span></button>
        </Modal.Header>
        <Modal.Body>
            <BookingPayCard  />

          

        </Modal.Body>
    </Modal>
    )
}


export default BookPayModal