import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import countryLogo from "../../../../Assets/images/country-logo.png";
import countrycode from "../../../../config/countrycode.json";

const HostinCity = (props) => {
  const [hostcity, setHostCity] = useState(true);
  const [country, setCountry] = useState(`${countrycode[0]?.name}(${countrycode[0]?.dial_code})`);
  return (
    <>
      <Modal
        show={hostcity}
        centered
        scrollable
        className="modal_style"
        // size="lg"
      >
        <Modal.Header className="border-0 pb-0 d-flex justify-content-end">
          <button
            type="button"
            className="btn close close_modal"
            onClick={() => props.onDismiss()}
          >
            <span aria-hidden="true">×</span>
            <span class="sr-only">Close</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="">
              <h5 className="txtblue fw-500">
                Ready to host an experience in your country?
              </h5>
              <p className="f-14">
                Dawn takes you backstage at an aquarium where you learn about
                research programs with marine conservationists.
              </p>
            </div>

            <div className="mb-4 pb-4 br-10 mt-5">
              <label className="text3muted">Where</label>
              <div className="d-flex flex_whre_col">
                <InputGroup className="phone-no-input">
                  <InputGroup.Text id="basic-addon1">
                    <img
                      src={countryLogo}
                      alt="country"
                      className="input-logos"
                    />
                  </InputGroup.Text>
                  <select
                    className="form-control"
                    onChange={(e) => {
                      setCountry(e.target.value);
                    }}
                  >
                    {countrycode.map((result) => (
                      <option>{`${result.name}(${result.dial_code})`}</option>
                    ))}
                  </select>
                </InputGroup>
                <button
                  className="btn btn-theme flex-shrink-0 ms-2"
                  onClick={() => {
                    localStorage.setItem(
                      "experienceDetails",
                      JSON.stringify({ country: country.split("(")[0] })
                    );
                    props.opennext();
                  }}
                >
                  Create an Experience
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default HostinCity;
