import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { getExperienceFromLocal } from '../../../../Hooks/useUserExperienceHook';

const WhoCan = (props) =>{
    const [values, setValues] = useState({
        "alcohol" : false,
        "guidance" : false,
        "age" : 2,
        "specialCertificate" : "",
        "additionalRequirements" : ""
    });
    const [localData, setLocalData] = useState({});
    const [alcohol, setAlcohol] = useState(false);
    const [guidance, setGuidance] = useState(false);
    const [age, setAge] = useState(2);
    const [specialCertificate, setSpecialCertificate] = useState("");
    const [additionalRequirements, setAdditionalRequirements] = useState("");

    const putData = (e) =>{
      props.expwhocan(e.target.value)
    }

    const setData = (e) => {
        let data = {};
        data = values;
        if(e.target.id == "alcohol"){
            data.alcohol = e.target.checked;
            setAlcohol(e.target.checked)
        }
        else if(e.target.id == "age"){
            data.age = e.target.value;
            setAge(e.target.value);
        }
        else if(e.target.id == "guidance"){
            data.guidance = e.target.checked;
            setGuidance(e.target.checked);
        }
        else if(e.target.id == "specialCertificate"){
            data.specialCertificate = e.target.value;
            setSpecialCertificate(e.target.value);
        }
        else if(e.target.id == "additionalRequirements"){
            data.additionalRequirements = e.target.value;
            setAdditionalRequirements(e.target.value);
        }
        console.log("data in setData :",data);
        setValues(data);
    }

    useEffect(()=>{
        const localdata = getExperienceFromLocal();
        setLocalData(localdata)
        if(localdata&&localdata.whoCanAttend){
            setValues(localdata.whoCanAttend);
            setAlcohol(localdata.whoCanAttend.alcohol);
            setAge(localdata.whoCanAttend.age);
            setGuidance(localdata.whoCanAttend.guidance);
            setSpecialCertificate(localdata.whoCanAttend.specialCertificate);
            setAdditionalRequirements(localdata.whoCanAttend.additionalRequirements);
        }
    },[]);

    return(
        <>
                <div>
                    <h2 className='conthead'>Who can attend your experience?</h2>
                    <p className='text-muted f-14'>Choose the category that best describe your experience. Add a second,
                        if you think it fits into another category.
                    </p>
                    <div className=' mt-4'>
                        <h6 className='text-muted' >Alcohol</h6>
                    <div class="form-check my-3 align-items-start justify-content-end gap-2">
                     
                     <label class="form-check-label f-14 text-muted" for="alcohol">
                     second if you think it fits into another
                     </label>
                     <input class="form-check-input" checked={alcohol} type="checkbox" value="Split-type ductless system" onChange={(e)=>{setData(e)}} id='alcohol' />
                 </div>
                        <h6 className='text-muted'>Minimum age</h6>
                        <p className='f-14 text-muted mb-2'>
                         Add a second if you think it fits into another
                        </p>
                        <div className=' h-55'>
                            <select className='form-control' value={age} id='age' onChange={(e) => {putData(e);setData(e)}}>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                                <option>6</option>
                                <option>7</option>
                                <option>8</option>
                                <option>9</option>
                                <option>10</option>
                                <option>11</option>
                                 <option>12</option>
                                <option>13</option>
                                <option>14</option>
                                <option>15</option>
                                <option>16</option>
                                <option>17</option>
                                <option>18</option>
                            </select>
                            
                        </div>
                        <div class="form-check mb-3 mt-2 align-items-start justify-content-end gap-2">
                            <label class="form-check-label f-14 text-muted" for="guidance">
                            second if you think it fits into another
                            </label>
                            <input class="form-check-input" checked={guidance} onChange={(e)=>{setData(e)}}  type="checkbox" value="Split-type ductless system" id="guidance" />
                        </div>

                        <div className=' h-55'>
                            <h6 className='text-muted'>Special certificate</h6>
                            <input className='form-control'
                            placeholder={"Add special certificate"}
                            value={specialCertificate}
                            onChange={(e)=>{setData(e)}} id='specialCertificate' type="text" />

                            <h6 className='text-muted mt-3'>Additional requirements</h6>
                            <input className='form-control' 
                            value={additionalRequirements}
                            placeholder={"Add additional requirements"}
                            onChange={(e)=>{setData(e)}} id='additionalRequirements' type="text" />
                        </div>

                        
                        

                   <div className='mt-4'><button className='btn btn-theme' 
                   disabled={!(age&&specialCertificate&&additionalRequirements)}
                   onClick={() => {props.count();props.onSetLocal(values,"whoCanAttend")}}>Next</button></div> 
                    </div>
                    
              </div>  
        </>

    )
}

export default WhoCan