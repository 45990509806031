import React, { Component, useState } from 'react';
import {Modal, Button} from 'react-bootstrap'
import fav from '../../../Assets/images/favicon.png'
import Login from './LoginModal';

import FinishSign from './FinishSign';


const WelcomeModal = (props) =>{
    const[showwelcome , setShowWelcome] = useState(true)
    const [showlog , setShowLog] = useState(false);


    return(
        <div className='welcomemodal'>
            {showlog && <Login onDismiss={() => setShowLog(false)} logs = {"login"}/>}
              <Modal show={showwelcome} centered scrollable className='modal_style'>
        <Modal.Header className='border-0 pb-0'>
            <button type="button" className="btn close close_modal" onClick={() =>props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
        </Modal.Header>
            <Modal.Body>
            <h6 className='mb-3 text-center'>Create Your Profile</h6>
            
                <div className='welcome text-center'>  
                 <img src={fav} className="my-3"/>
                <h5 className='modalhead mb-4'>Welcome to FlutterPads</h5>
             
                <p className='f-14 text-center'>Discover Place to stay , local restaurants and unique experiances around the world</p>
                <Button className='btn btn-theme w-full mt-3' onClick={() =>{setShowLog(true);setShowWelcome(false)} } variant="outline-dark">Continue</Button>
                </div>
                {/* window.location.reload() */}
            </Modal.Body>
        </Modal>
        </div>
    )
}

export default WelcomeModal