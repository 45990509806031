import React, { useEffect, useState } from 'react';
import FilterExp from './FilterExp.js'
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from "react-js-loader";
import img1 from '../../Assets/images/where/img1.png'
import img2 from '../../Assets/images/where/img2.png'
import img3 from '../../Assets/images/where/img3.png'
import img4 from '../../Assets/images/where/img4.png'
import img5 from '../../Assets/images/where/img5.png'
import img6 from '../../Assets/images/where/img6.png'
import img7 from '../../Assets/images/where/img7.png'
import img8 from '../../Assets/images/where/img8.png'
import img9 from '../../Assets/images/where/img9.png'
import img10 from '../../Assets/images/where/img10.png'
import img11 from '../../Assets/images/where/img11.png'
import img12 from '../../Assets/images/where/img12.png'
import img13 from '../../Assets/images/where/img13.png'
import img14 from '../../Assets/images/where/img14.png'

import { getExperienceDatas } from '../../Hooks/useUserExperienceHook.js';



const Header = React.lazy(() => import('../Common/Header'));
const Footer = React.lazy(() => import('../Common/Footer'));

const ExpListCard = React.lazy(() => import('./ExpListCard'))


const Experience = () => {

    const [experienceDatas, setExperienceDatas] = useState([]);
    const [hasMore, setHasMore] = useState(true)
    const [start, setStart] = useState(0);
    const [end, setEnd] = useState(20);
    const whereImg = [
        {
            img: [{ imginner: img1 }, { imginner: img2 }, { imginner: img3 }, { imginner: img6 }, { imginner: img2 }, { imginner: img3 },
            { imginner: img5 }, { imginner: img1 }, { imginner: img6 }, { imginner: img12 }, { imginner: img12 }, { imginner: img13 }]
        },
        { img: [{ imginner: img2 }, { imginner: img4 }] },
        { img: [{ imginner: img3 }, { imginner: img1 }, { imginner: img6 }] },
        { img: [{ imginner: img4 }, { imginner: img12 }, { imginner: img13 }] },

        { img: [{ imginner: img6 }, { imginner: img2 }, { imginner: img3 }] },
        { img: [{ imginner: img7 }, { imginner: img4 }] },
        { img: [{ imginner: img5 }, { imginner: img1 }, { imginner: img6 }] },
        { img: [{ imginner: img8 }, { imginner: img12 }, { imginner: img13 }] },

        { img: [{ imginner: img9 }, { imginner: img2 }, { imginner: img3 }] },
        { img: [{ imginner: img10 }, { imginner: img4 }] },
        { img: [{ imginner: img11 }, { imginner: img1 }, { imginner: img6 }] },
        { img: [{ imginner: img12 }, { imginner: img12 }, { imginner: img13 }] },

        { img: [{ imginner: img13 }, { imginner: img2 }, { imginner: img3 }] },
        { img: [{ imginner: img14 }, { imginner: img4 }] },
        { img: [{ imginner: img5 }, { imginner: img1 }, { imginner: img6 }] },
        { img: [{ imginner: img6 }, { imginner: img12 }, { imginner: img13 }] },



    ]

    useEffect(() => {
        getExperienceDatas(start, end).then((data) => {
            console.log('dsjvfjhasdfgjhadfyu',data)
            setExperienceDatas(data);
        });
    }, []);

    const refresh = (setExperienceDatas) => { };

    const fetchData = () => {
        getExperienceDatas(experienceDatas.length, end).then((data) => {
            console.log("THE NEXT 10 DATAS :", data)
            if (data != '') {
                setExperienceDatas(experienceDatas.concat(data));
            } else {
                setHasMore(false)
            }
        })
    }


    return (
        <div>
            <Header />
            {/* <FilterExp /> */}
            {
                experienceDatas && experienceDatas.length < 10 ? <>
                    <div className='container container_custom mb-5 pagetop'>

                        <div className='d-grid card-5'>

                            {experienceDatas && experienceDatas.map((i) =>
                                <ExpListCard data={i} />

                            )}
                        </div>
                    </div>
                </> :
                    <>
                        <InfiniteScroll
                            dataLength={experienceDatas.length} //This is important field to render the next data
                            next={() => { fetchData() }}
                            hasMore={hasMore}
                            loader={<Loader type="bubble-loop" bgColor={"#000000"} title={"Loading"} color={'#000000'} size={50} />}
                            endMessage={
                                <p className='tetx-center pb-5'>
                                    <b>Yay! You have seen it all</b>
                                </p>
                            }
                            // below props only if you need pull down functionality
                            refreshFunction={refresh}
                        // pullDownToRefresh
                        // pullDownToRefreshThreshold={50}
                        // pullDownToRefreshContent={
                        //     <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
                        // }
                        // releaseToRefreshContent={
                        //     <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
                        // }
                        >
                            <div className='container container_custom mb-5 pagetop'>

                                <div className='d-grid card-5'>

                                    {experienceDatas && experienceDatas.map((i) =>
                                        <ExpListCard data={i} />

                                    )}
                                </div>
                            </div>
                        </InfiniteScroll>
                    </>

            }

            <Footer  />

        </div>
    )
}

export default Experience;