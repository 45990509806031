import React from 'react';
import FilterExp from './FilterExp.js'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import img1 from '../../Assets/images/where/img1.png'
import img2 from '../../Assets/images/where/img2.png'
import img3 from '../../Assets/images/where/img3.png'
import img4 from '../../Assets/images/where/img4.png'
import img5 from '../../Assets/images/where/img5.png'
import img6 from '../../Assets/images/where/img6.png'
import img7 from '../../Assets/images/where/img7.png'
import img8 from '../../Assets/images/where/img8.png'
import img9 from '../../Assets/images/where/img9.png'
import img10 from '../../Assets/images/where/img10.png'
import img11 from '../../Assets/images/where/img11.png'
import img12 from '../../Assets/images/where/img12.png'
import onlinebanner from '../../Assets/images/onlinebanner.webp'

import Taj from '../../Assets/images/tajmahal.jpg'
// import "../../Assets/css/online.css"
const Header = React.lazy(() => import('../Common/Header'));
const Footer = React.lazy(() => import('../Common/Footer'));

const Card_notslider = React.lazy(() => import('../Landing/Card_notslider'))


const online = () =>{
   
    const whereImg = [
        {img:[{imginner: img1}] },
        {img:[{imginner: img2}] },
        {img:[{imginner: img3}] },
        {img:[{imginner: img4}] },
        {img:[{imginner: img5}] },
        {img:[{imginner: img6}] },
        {img:[{imginner: img7}] },
        {img:[{imginner: img8}] },
        {img:[{imginner: img9}] },
        {img:[{imginner: img10}] },
        {img:[{imginner: img11}] },
        {img:[{imginner: img12}] }
    ]
    const bannerimge = [
       {
        img:onlinebanner,
        head:"Collections",
        para:"Most popular around the world"
       },
       {
        img:onlinebanner,
        head:"Collections",
        para:"Most popular around the world"
       },
       {
        img:onlinebanner,
        head:"Collections",
        para:"Most popular around the world"
       },
       {
        img:onlinebanner,
        head:"Collections",
        para:"Most popular around the world"
       },
       {
        img:onlinebanner,
        head:"Collections",
        para:"Most popular around the world"
       },
       {
        img:onlinebanner,
        head:"Collections",
        para:"Most popular around the world"
       },
       {
        img:onlinebanner,
        head:"Collections",
        para:"Most popular around the world"
       },
       {
        img:onlinebanner,
        head:"Collections",
        para:"Most popular around the world"
       }
    ]
    const topsellers = [
        {img:[{imginner: img1}] },
        {img:[{imginner: img2}] },
        {img:[{imginner: img3}] },
        {img:[{imginner: img4}] },
        {img:[{imginner: img5}] },
        {img:[{imginner: img6}] },
        {img:[{imginner: img7}] },
        {img:[{imginner: img8}] },
        {img:[{imginner: img9}] },
        {img:[{imginner: img10}] },
        {img:[{imginner: img11}] },
        {img:[{imginner: img1}] },
        {img:[{imginner: img2}] },
        {img:[{imginner: img3}] },
        {img:[{imginner: img4}] },
        {img:[{imginner: img5}] },
        {img:[{imginner: img6}] },
        {img:[{imginner: img7}] },
        {img:[{imginner: img8}] },
        {img:[{imginner: img9}] },
        {img:[{imginner: img10}] },
        {img:[{imginner: img11}] },
        {img:[{imginner: img1}] },
        {img:[{imginner: img2}] },
        {img:[{imginner: img3}] },
        {img:[{imginner: img4}] },
        {img:[{imginner: img5}] },
        {img:[{imginner: img6}] },
        {img:[{imginner: img7}] },
        {img:[{imginner: img8}] },
        {img:[{imginner: img9}] },
        {img:[{imginner: img10}] },
        {img:[{imginner: img11}] },
        {img:[{imginner: img1}] },
        {img:[{imginner: img2}] },
        {img:[{imginner: img3}] },
        {img:[{imginner: img4}] },
        {img:[{imginner: img5}] },
        {img:[{imginner: img6}] },
        {img:[{imginner: img7}] },
        {img:[{imginner: img8}] },
        {img:[{imginner: img9}] },
        {img:[{imginner: img10}] },
        {img:[{imginner: img11}] },
        {img:[{imginner: img12}] }
    ]
    const startinghours=[
        {
            image:Taj,
            content:"Virtual Tour of Taj Mahal by a professional Tour Guides" 
        },
        {
            image:Taj,
            content:"Virtual Tour of Taj Mahal by a professional Tour Guides" 
        },
        {
            image:Taj,
            content:"Virtual Tour of Taj Mahal by a professional Tour Guides" 
        },
        {
            image:Taj,
            content:"Virtual Tour of Taj Mahal by a professional Tour Guides" 
        },
        {
            image:Taj,
            content:"Virtual Tour of Taj Mahal by a professional Tour Guides" 
        },
        {
            image:Taj,
            content:"Virtual Tour of Taj Mahal by a professional Tour Guides" 
        },
        {
            image:Taj,
            content:"Virtual Tour of Taj Mahal by a professional Tour Guides" 
        }
    ]
    
    const categories = [
        {cat : "Great for groups"},
        {cat : "Family-friendly"},
        {cat : "Animals"},
        {cat : "Art & Writing"},
        {cat : "Baking"},
        {cat : "Cooking"},
        {cat : "Dance"},
        {cat : "Drinks"},
        {cat : "Entertainment"},
        {cat : "Fitness"},
        {cat : "History & Culture"},
        {cat : "Magic"},
        {cat : "Music"},
        {cat : "Socila Impact"},
        {cat : "Wellness"},
        {cat : "Olympians & Paralympians"},
        {cat : "Designed for accessibility"}
    ]
    const datas= ["Dates"]   
    const datas1= ["Group Size"]   
    const datas2= ["More Filters"]   
    const options={
        dots: false,
        infinite: true,
        smartSpeed: 400,
        
        nav:true,
        navText: ["<i class='fa fa-chevron-left'></i>","<i class='fa fa-chevron-right'></i>"],
        margin:10,
        speed: 1500,
        responsive:{
            0:{
                items:1
            },
            500:{
                items:2
            },
            768:{
                items:2
            },
            800:{
                items:2
            },
            1000:{
                items:3
            }
        }
    }
    const cardslider={
        dots: false,
        infinite: true,
       
        smartSpeed: 400,
       
        nav:true,
        navText: ["<i class='fa fa-chevron-left'></i>","<i class='fa fa-chevron-right'></i>"],
        speed: 1500,
        margin:20,
       
        responsive:{
            0:{
                items:1
            },
            576:{
                items:2
            },
            992:{
                items:4
            },
           
            1280:{
                items:4
            },
            1440:{
                items:5
            }
        }
        
    }
    const weekend={
        dots: false,
        infinite: true,
       
        smartSpeed: 400,
       
        nav:true,
        navText: ["<i class='fa fa-chevron-left'></i>","<i class='fa fa-chevron-right'></i>"],
        speed: 1500,
        margin:20,
       
        responsive:{
            0:{
                items:1
            },
            576:{
                items:2
            },
            992:{
                items:4
            },
           
            1280:{
                items:4
            },
            1440:{
                items:5
            }
        }
        
    }
    const sellers={
        dots: false,
        infinite: true,
       
        smartSpeed: 400,
       
        nav:true,
        navText:["<i class='fa fa-chevron-left'></i>","<i class='fa fa-chevron-right'></i>"],
        speed: 1500,
        margin:20,
       
        responsive:{
            0:{
                items:1
            },
            576:{
                items:2
            },
            992:{
                items:4
            },
           
            1280:{
                items:4
            },
            1440:{
                items:5
            }
        }
        
    }

    const starting={
        dots: false,
        infinite: true,
       
        smartSpeed: 400,
       
        nav:true,
        navText: ["<i class='fa fa-chevron-left'></i>","<i class='fa fa-chevron-right'></i>"],
        speed: 1500,
        margin:20,
       
        responsive:{
            0:{
                items:1
            },
            600:{
                items:1
            },
            900:{
                items:1
            },
           1000:{
                items:1
            },
            1280:{
                items:2
            }
        }
        
    }
   
   return(
    <div>
        <Header />
       <div className='container container_custom'>
       <div className='bannersec' id='filter'>
       <h1 className='newthisweek'>New this week</h1>
       <OwlCarousel className='owl-theme' {...options}>
               {bannerimge.map ((bannersec) =>  (
                               
                              <div className='item banner_head'>
                                 <div className='bannersectkd'>
                                 <div className="">
                                      <img src={bannersec.img} className="img-fluid headerclasssd" alt=""/>
                                      </div>
                                      <div className="bannercontensec">
                                        <p className='category'>{bannersec.head}</p>
                                          <h1 className="banner_name">{bannersec.para}</h1>
                                         
                                      </div>
                                 </div>
                                      <a className="banner_para" href='/'>Show All</a>
                                  </div>
                                 
                            
                          ))}
                          </OwlCarousel>
                          <a className='shownumberbanne mr-4' href='/'>Show ({bannerimge.length})</a> 
       </div>
       </div>
       <FilterExp prices={datas} prices1={datas1} prices2={datas2} cat={categories} />
       
            <div className='container container_custom mb-5'>
           <div className='localhost'>
           <h1 className='newthisweek'>Plan a trip with help from local Hosts around the world</h1>
           
            <OwlCarousel className='owl-theme' {...cardslider}>
            {whereImg.map((i)=>
               
                    <Card_notslider img={i.img}/>
                    )}
               
                </OwlCarousel>
              <a className='shownumber mr-4' href='/'>Show ({whereImg.length})</a> 

           </div>
           <div className='topsellers'>
           <h1 className='newthisweek mt-4'>Continue Browsing</h1>
           
            <OwlCarousel className='owl-theme' {...sellers}>
            {topsellers.map((i)=>
               
                    <Card_notslider img={i.img}/>
                    )}
               
                </OwlCarousel>
              <a className='shownumber' href='/'>Show ({topsellers.length})</a> 

           </div>
           <div className='tajmahalslide'>
           <h1 className='newthisweek mt-4 mb-3'>Starting in the next 6 hours</h1>
                
            <OwlCarousel className='owl-theme' {...starting}>
                {startinghours.map((hours) => (
                    <div className='tajmahal'>
                        <div>
                            <img src={hours.image} alt=""/>

                        </div>
                        <div className='tajmahalcontent'>
                            <div className='viewsindia'>
                                <p className='starlike'><i className='fa fa-star me-1'></i><span>438(539) India</span></p>
                                <button class="fav-btn fa fa-heart" aria-hidden="true"></button>
                            </div>
                            <h2 className='content my-3'>{hours.content}</h2>
                            <p className='ruppees'>$ 6,000 / <span>Person</span></p>
                            <div className='timing mt-4'>
                                <a href='/' className='sevenoclock active'>7:00 PM</a>
                                <a href='/' className='sevenoclock'>8:00 PM</a>
                                <a href='/' className='sevenoclock'>9:00 PM</a>
                            </div>
                        </div>
                    </div>
                ))}
                </OwlCarousel>
              <a className='shownumber' href='/'>Show ({startinghours.length})</a> 

           </div>
           <div className='weekend'>
           <h1 className='newthisweek mt-4'>Make plans this weekend</h1>
           
            <OwlCarousel className='owl-theme' {...weekend}>
            {whereImg.map((i)=>
               
                    <Card_notslider img={i.img}/>
                    )}
               
                </OwlCarousel>
              <a className='shownumber' href='/'>Show ({whereImg.length})</a> 

           </div>
           <div className='groups'>
           <h1 className='newthisweek mt-4'>Great for groups</h1>
           
            <OwlCarousel className='owl-theme' {...sellers}>
            {topsellers.map((i)=>
               
                    <Card_notslider img={i.img}/>
                    )}
               
                </OwlCarousel>
              <a className='shownumber' href='/'>Show ({topsellers.length})</a> 

           </div>
                <div className='showmorebtn'>
                <a href='/'>Show More</a>
                </div>
                
           
            </div>
        <Footer />

    </div>
    )
}

export default online;