
const BASE_URL = "admin";

export const getHostDetailsApi = BASE_URL + "/get-host-details";
export const getHostedDatasApi = BASE_URL + "/get-hosted-datas";
export const getSingleHostingDetailApi = BASE_URL + "/get-single-hosting-detail";
export const getAdminDetailApi = BASE_URL + "/get-admin-details";
export const getsingleuserdispute = BASE_URL + "/get-singleuser-dispute";
export const raisedisputeApi = BASE_URL + "/create-raise-dispute";
export const disputechatApi = BASE_URL + "/disputechat-user";
export const disputechatwithimgApi = BASE_URL + "/disputechat-user";
export const getsinglechatdisputeApi = BASE_URL + "/getdisputeforsinglechat";
export const closedisputeApi = BASE_URL + "/closed-dispute-mail";
export const getSingleExperienceDetailsApi = BASE_URL + "/get-single-experience-details";

