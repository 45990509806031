import React, { useEffect, useState } from "react";
import cardimg1 from "../../../../Assets/images/geust.png";
import cardimg2 from "../../../../Assets/images/calender.png";
import cardimg3 from "../../../../Assets/images/book.png";

import FirstGuestModal from "../Modals/FirstGuestModal";
import CustomizePrize from "../Modals/CustomizePrize";
import ConfirmGuestModal from "../Modals/ConfirmGuestModal";

import { useLocation } from "react-router-dom";

const HostCard = (props) => {
  const [firstguest, setFirstGuest] = useState(false);
  const [customprice, setCustomPrize] = useState(false);
  const [confirmguest, setConfirmGuest] = useState(false);
  const [cleanfee, setCleanFee] = useState(false);

  const { state } = useLocation();

  useEffect(() => {
    if (state == "rule") {
      setFirstGuest(true);
    } else if (state == "custom" || state == "clean") {
      setCustomPrize(true);
    } else if (state == "confirmguest") {
      setConfirmGuest(true);
    }
  }, [state]);

  return (
    <>
      {firstguest && (
        <FirstGuestModal
          onDismiss={() => setFirstGuest(false)}
          img={cardimg1}
        />
      )}
      {customprice && (
        <CustomizePrize
          onDismiss={() => setCustomPrize(false)}
          img={cardimg2}
        />
      )}
      {console.log('confirmguest',confirmguest)}
      {confirmguest && (
        <ConfirmGuestModal
          onDismiss={() => setConfirmGuest(false)}
          img={cardimg3}
        />
      )}

      <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-3">
        <button className="cardbtn h-100" onClick={() => setFirstGuest(true)}>
          <div className={props.class}>
            <div className="card-img-section">
              <img src={cardimg1} />
            </div>
            <hr />
            <h6 className="py-3">Get ready for your first guests</h6>
          </div>
        </button>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-3">
        <button className="cardbtn h-100" onClick={() => setCustomPrize(true)}>
          <div className={props.class}>
            <div className="card-img-section">
              <img src={cardimg2} />
            </div>
            <hr />
            <h6 className="py-3">Customise your calendar and pricing</h6>
          </div>
        </button>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-3">
        <button className="cardbtn h-100" onClick={() => setConfirmGuest(true)}>
          <div className={props.class}>
            <div className="card-img-section">
              <img src={cardimg3} />
            </div>
            <hr />
            <h6 className="py-3">Confirm how guests can book</h6>
          </div>
        </button>
      </div>
    </>
  );
};

export default HostCard;
