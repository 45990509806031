
import React, { Component, useEffect, useState } from 'react';

import {Dropdown} from 'react-bootstrap'
import Experiencedetail from '../Landing/Experiencedetail.js';
// const Servicemodal = React.lazy(() => import('../Common/Modals/Servicemodal'));
import Servicemodal  from "../Common/Modals/Servicemodal"; 


const Counter = (props) =>{
    const [showservice, setShowservice] = useState(false);
    const [adults, setAdults] = useState(1);
    const [children, setChildren] = useState(0);
    const [infants, setInfants] = useState(0);
    const [pets, setPets] = useState(0);
    const [counter, setCounter] = useState({});
    // const [guest , setGuest] = useState(0);
    // const [guests , setGuests] = useState(0);
    const setcounter = (count) => {
        console.log("count in parameter",count);
        if(count != undefined){
            props.onSetGuestCount(count);
        }
        
    }
    console.log('props.roomDetails.floorPlan[0].count',  props?.roomDetails?.floorPlan)

    const handleadultplus = () => {
        console.log("adultschildrendata", adults, children, props.roomDetails.floorPlan[0].count)
        if((adults+children) < props.roomDetails.floorPlan[0].count){
          setAdults(adults+1);
          setcounter();
        }
      };

    const handleadultminus = () => {
        if(children>0 ||infants>0 || pets>0)
        // if(children == 0 || infants == 0 || pets == 0)
        {
          // if(adult>0)
          // {
          //   setAdult(adult-1)
          // }
          if(adults>1)
        {
          setAdults(adults-1);
          setcounter();
        }
        }
        else 
        if(adults>1)
        {
          setAdults(adults-1)
          setcounter();
        }
        
       
        };

        const handlechildplus = () => {
            if((adults+children) < props.roomDetails.floorPlan[0].count)
            {
                setChildren(children+1);
            }
            if(children==0 && adults == 0)
            {
                setAdults(1);
                setcounter();
            }
            
          };
          const handlechildminus = () => {
            if(children>0)
            {
              setChildren(children-1)
              setcounter();
            }
            
          };

          const handleinfantplus = () => {
              if(infants < 5)
              {
                setInfants(infants+1);
                setcounter()
              }
            
            if(infants ==0 && adults == 0)
            {
                setAdults(1);
                setcounter();
            }
            
          };
          const handleinfantminus = () => {
            if(infants > 0)
            {
            setInfants(infants-1)};
            setcounter();
            }
          const handlepetplus = () => {
              if(pets < 5)
              {
                setPets(pets+1);
                setcounter();
              }
            
            if(pets == 0 && adults == 0)
            {
                // setAdults(1);
                setcounter();
            }
           
          };
          const handlepetminus = () => {
            if(pets > 0)
            {
                setPets(pets-1);
                setcounter()
            }
             
          };

        //   useEffect(()=>{
        //     console.log("guest count in counter :" , props.roomDetails.floorPlan[0]);
       
        //     // setGuests(props.roomDetails.floorPlan[0].count);
        //   },[])
    useEffect(()=>{
       
        const count = {
            Adults:adults,
            Children:children,
            Infants:infants,
            Pets:pets
        }
        setCounter(count);
        setcounter(count);
        console.log("useeffect counter:",counter,"useeffect count",count,"props.roomDetails.floorPlan",props.roomDetails.floorPlan);
    },[adults,children,infants,pets]);

    // const filterguest = (val)=>{
    //     console.log("guest count in counter :" , val);
    //     setGuest(val);

    // }

    return(
        <div className='counter'>
                    
        {/* <Experiencedetail onSetGuest = {filterguest}/> */}

        {/* <div className='dd_menu_shadow_card who_card'> */}
       <div className='who_main'>
        <div className='who_sub'>
            <div className='row align-items-center'>
                <div className='col-12 col-sm-6'>
                    <p className='who_title'>Adults</p>
                    <p className='who_desc mb-0'>Ages 13 or above</p>
                </div>
                <div className='col-12 col-sm-6'>
                    <div className='d-flex align-items-center justify-content-end'>

                        <div disabled={adults<=0} onClick={handleadultminus} className={(adults<=1)?'minus_div icon_div disabledbox':'minus_div icon_div'}><i  className="fa fa-minus" aria-hidden="true"></i></div>
                        <div className='number_div mx-3' value={adults} >{adults}</div>
                        {console.log("props.roomDetails",(props.roomDetails && props.roomDetails.floorPlan && props?.roomDetails?.floorPlan[0]?.count))}
                        <div onClick={handleadultplus} className={((adults+children)>=(props.roomDetails && props.roomDetails.floorPlan && props?.roomDetails?.floorPlan[0]?.count))?'plus_div icon_div disabledbox':'plus_div icon_div'}><i  className="fa fa-plus" aria-hidden="true"></i></div>


                    </div>
                </div>
            </div>
            <hr className='hr_who'></hr>
            <div className='row align-items-center'>
                <div className='col-12 col-sm-6'>
                    <p className='who_title'>Children</p>
                    <p className='who_desc mb-0'>Ages 2-12</p>
                </div>
                <div className='col-12 col-sm-6'>
                    <div className='d-flex align-items-center justify-content-end'>

                        <div disabled={children<=0} onClick={handlechildminus} className={(children<=0 )?'minus_div icon_div disabledbox':'minus_div icon_div'}><i className="fa fa-minus"  aria-hidden="true"></i></div>
                        <div className='number_div mx-3' >{children}</div>
                        <div onClick={handlechildplus} className={((adults+children)>=(props.roomDetails && props.roomDetails.floorPlan && props?.roomDetails?.floorPlan[0]?.count))?'plus_div icon_div disabledbox':'plus_div icon_div'}><i className="fa fa-plus"  aria-hidden="true"></i></div>


                    </div>
                </div>
            </div>
            <hr className='hr_who'></hr>
            <div className='row align-items-center'>
                <div className='col-12 col-sm-6'>
                    <p className='who_title'>Infants</p>
                    <p className='who_desc mb-0'>Under 2</p>
                </div>
                <div className='col-12 col-sm-6'>
                    <div className='d-flex align-items-center justify-content-end'>

                        <div  disabled={infants<=0} onClick={handleinfantminus} className={(infants<1)?'minus_div icon_div disabledbox':'minus_div icon_div'}><i className="fa fa-minus"  aria-hidden="true"></i></div>
                        <div className='number_div mx-3' >{infants}</div>
                        <div onClick={handleinfantplus} className={(infants>=5)?'plus_div icon_div disabledbox':'plus_div icon_div'}><i className="fa fa-plus"  aria-hidden="true"></i></div>


                    </div>
                </div>
            </div>
            <hr className='hr_who'></hr>
            <div className='row align-items-center'>
                <div className='col-12 col-sm-6'>
                    <p className='who_title'>Pets</p>
                    <p className='who_desc mb-0'>
                    <a className='who_service_1' onClick={() => setShowservice(true)}>
                       Bringing a service animal?</a>
                    </p>
                </div>
                <div className='col-12 col-sm-6'>
                    <div className='d-flex align-items-center justify-content-end'>

                        <div disabled={pets<=0} onClick={handlepetminus} className={(pets<1)?'minus_div icon_div disabledbox':'minus_div icon_div'}><i className="fa fa-minus"  aria-hidden="true"></i></div>
                        <div className='number_div mx-3' >{pets}</div>
                        <div onClick={handlepetplus} className={(pets>=5)?'plus_div icon_div disabledbox':'plus_div icon_div'}><i className="fa fa-plus"  aria-hidden="true"></i></div>


                    </div>
                </div>
            </div>
        </div>

       </div>

        {/* </div> */}
        {console.log('showseasdfsfrvidcedata',showservice)}
        {showservice && <Servicemodal  onDismiss={()=> setShowservice(false)} />}
        </div>
      

      
    )
}

export default Counter