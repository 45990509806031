import React, { useState } from 'react';
import {Breadcrumb,Button, Form, Tab, Row, Col,Nav} from 'react-bootstrap'
import { Link } from 'react-router-dom';
import Footer from '../../../Common/Footer';
import Header from '../../../Common/Header';
import AccPayment from './AccPayment';
import AccPayout from './AccPayout';
import AccService from './AccService';
import AccShare from './AccShare';


const AccPrivacyShar = () => {
    return(
        <div className='personalinfo nocenterhead'>
            <Header />
            <div className='container container_custom mt-78'>
                <div className='row justify-content-center'>
                
                    <div className='col-xl-9'>
                    <div className='breadcrum mt-4'>
                        <Breadcrumb>
                        <Breadcrumb.Item><Link to="/">Acocount</Link></Breadcrumb.Item>
                        <Breadcrumb.Item><Link to="/">Privacy and sharing</Link></Breadcrumb.Item>
                        </Breadcrumb>
                        <h2>Privacy and sharing</h2>
                    </div>
                    <div className='insight-tab mt-5'>
                    <Tab.Container className="" id="left-tabs-example" defaultActiveKey="share">
          
            <Row className='d-flex align-items-center justify-content-between flex-column'>
                <Col className="mx-auto bg-white">
                    <Nav variant="pills" className="">
                        <Nav.Item><Nav.Link eventKey="share">Share</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link eventKey="services">Services</Nav.Link></Nav.Item>
                       
                    </Nav>
                </Col>
                </Row>
            
    <div className='insight-conts'>
        <div className="mx-auto">
            <Tab.Content>
                <Tab.Pane eventKey="share"><AccShare /></Tab.Pane>
                <Tab.Pane eventKey="services"><AccService /> </Tab.Pane>
            </Tab.Content>
        </div>
    </div>
   </Tab.Container>
</div>
                       
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default AccPrivacyShar