import React, {  useState } from 'react';
import {Modal} from 'react-bootstrap'
import AvailCont from '../Common/AvailCont';

import detailimg1 from '../../Assets/images/detail/detailimg1.png'

// import Scrollbars from 'react-custom-scrollbars';





const AvailabilityModal = (props) =>{
    const[avail, setAvail] = useState(true)

    const cancellationPolicy = (data) => {
        props.onSetCancellation(data)
    }
    const guestcount = (val) => {
        props.onSetGuestCount(val)
    }

    const txt = "Adaaran Club Rannalhi, Maldives, Silver All Inclu."
    console.log("props data in availability room" , props);
    // console.log("get guest user subscription in avilvont" , props)
    return(
        <>  
            <Modal show={avail} size="xl" centered scrollable className='modal_style'>
                <Modal.Header className='border-0 pb-0'>
                    <button type="button" className="btn close close_modal" onClick={() =>props.onDismiss()}><span aria-hidden="true" className='fa fa-times f-14'></span><span class="sr-only">Close</span></button>
                </Modal.Header>
                <Modal.Body>
                    
                   <AvailCont 
                   id={props.id} email={props.email} roomDetails={props.roomDetails} 
                   onSetGuestCount={guestcount} onSetCancellation={cancellationPolicy} 
                   serviceFee={props.serviceFee} rentAmount={props.rentAmount} 
                   days={props.days} amountPerNight={props.amountPerNight} 
                   startDate={props.startDate} endDate={props.endDate}
                   img={detailimg1} txt={txt}
                   spl = {props?.spl}
                   suboffer = {props?.suboffer}
                   defaultprice = {props?.defaultprice}
                   subofferamount = {props?.subofferamount}
                   />
                </Modal.Body>
            </Modal>
        </>
    )
}


export default AvailabilityModal