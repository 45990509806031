import axios from 'axios';
import React, { useState } from 'react';
import { useEffect } from 'react';
import {Modal} from 'react-bootstrap'

import {getreview, getUserDatas} from '../../../Hooks/useUserHook'

const GiveReview = (props) => {
    const[givereview , setGiveReview] = useState(true);
    const [count, addCount] = useState(0)
    const [accuracy, addAccuracy] = useState(0)
    const [checkin, addCheckin] = useState(0)
    const [location, addLocation] = useState(0)
    const [value, addValue] = useState(0)
    const [communication, addCommunication] = useState(0)
    const[reviewcont, setReviewCont] = useState("")
    const [userdata , setUserdata] = useState([]);


   useEffect(() =>{
        if(count != 0 && accuracy != 0 && checkin != 0 && location != 0 && value != 0 && communication != 0 && reviewcont != "") {
       
            document.getElementById("submit").removeAttribute("disabled")
        }else{
      
            document.getElementById("submit").setAttribute("disabled", "")
        }
        async function fetchdata(){
            var userdata = await getUserDatas();
            console.log("userdata cookie data" , userdata.email);
            setUserdata(userdata);
        }
        fetchdata();
   },[reviewcont])
  
   const postreview = async () =>{
    const reviews =   {
        name : userdata.firstName,
        email : userdata.email,
       cleanliness : count,
        accuracy : accuracy,
        checkIn : checkin,
        communication :communication,
        location : location,
        value : value,
        description : reviewcont,
        profilePicPath : "",
        profilePicName : "",
        roomId: props?.roomid
        }
         await getreview(reviews).then(val =>{
           console.log(val.data) 
           props?.onDismiss()
         })
   }
    return(
        <>
           <Modal show={givereview} size="lg" centered scrollable className='modal_style'>
                <Modal.Header className='border-0 pb-0'>
                    <button type="button" className="btn close close_modal" onClick={() =>props.onDismiss()}><span aria-hidden="true" className='fa fa-times f-14'></span><span class="sr-only">Close</span></button>
                </Modal.Header>
                <Modal.Body className='px-sm-4 px-3'>
                    <h4 className='mb-0'>Review</h4>
                    <p className='text-muted mb-4'>Views so photo-worthy that they'll wow every guest.</p>
                     <div className='givereview'>
                        
                        <div id="fav"> 
                        <div className='d-flex jc-between flex-1 mb-3'>
                            <div className='d-flex justify-content-around flex-1'>
                                <p>Clenliness</p>                
                                <div>   
                                    {[...Array(5)].map((val,index)=>{
                                        return <button className={count<(index+1)?"fa fa-star":"fa fa-star active"} onClick={() => addCount(index+1)}></button>
                                    })}   
                                    </div>

                                </div>
                                <div className='d-flex justify-content-around flex-1'>
                                    <p>Accuracy</p>    
                                    <div>
                                    {[...Array(5)].map((val,index)=>{
                                        return <button className={accuracy<(index+1)?"fa fa-star":"fa fa-star active"} onClick={() => addAccuracy(index+1)}></button>
                                    })}
                                    </div>

                                </div>
                        </div>
                        <div className='d-flex jc-between flex-1 mb-3'>
                            <div className='d-flex justify-content-around flex-1'>
                                <p>Location</p>    
                                <div>
                                    {[...Array(5)].map((val,index)=>{
                                        return <button className={location<(index+1)?"fa fa-star":"fa fa-star active"} onClick={() => addLocation(index+1)}></button>
                                    })}
                                </div>
                            </div>
                            <div className='d-flex justify-content-around flex-1'>
                                <p>Value</p>    
                                <div>
                                    {[...Array(5)].map((val,index)=>{
                                        return <button className={value<(index+1)?"fa fa-star":"fa fa-star active"} onClick={() => addValue(index+1)}></button>
                                    })}
                                 </div>
                            </div>
                        </div>
                        <div className='d-flex jc-between flex-1 mb-3'>
                                <div className='d-flex justify-content-around flex-1'>
                                    <p>Check in</p>    
                                    <div>
                                    {[...Array(5)].map((val,index)=>{
                                        return <button className={checkin<(index+1)?"fa fa-star":"fa fa-star active"} onClick={() => addCheckin(index+1)}></button>
                                    })}
                                    </div>

                                </div>
                                <div className='d-flex justify-content-around flex-1'>
                                    <p>Communication</p>    
                                    <div>
                                    {[...Array(5)].map((val,index)=>{
                                        return <button className={communication<(index+1)?"fa fa-star":"fa fa-star active"} onClick={() => addCommunication(index+1)}></button>
                                    })}
                                    </div>

                                </div>
                        </div>
                        </div>
                        <textarea rows="5" className="form-control" onChange={(e) => {setReviewCont(e.target.value); }}></textarea>

                        <div className='my-3'>
                            <button className="btn btn-theme " id="submit" onClick={() => postreview()}>Submit</button>
                        </div>

                     </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default GiveReview