import React, { useEffect, useState } from 'react';
import {Tabs,Tab,Form, Table,Dropdown, Toast} from 'react-bootstrap'
import HostHeader from '../../HostHeader';
import {toast} from 'react-hot-toast'
import prof from  '../../../../../Assets/images/prof.jpg'
import img1 from  '../../../../../Assets/images/where/img1.png'
import Footer from '../../../../Common/Footer';
import DataTable from 'react-data-table-component';
import { closedisputehook, disputechathook, disputechatwithimghook, getsinglechathook, getsingleuserdisputehook } from '../../../../../Hooks/adminHook';
import { getUserDatas } from '../../../../../Hooks/useUserHook';
import { useNavigate } from 'react-router-dom';
import CreateDispute from './Modal/CreateDisputeModal';
import { API_URL } from '../../../../../config/env';
// import user from "../../Assets/images/newLogo.png"
import user from "../../../../../Assets/images/newLogo.png";
const Dispute = () => { 
    const[showdispute , setShowDispute] = useState(false);
    const [dispute , setDispute] = useState([]);
    const [singledispute , setSingledispute] = useState({});
    // const [singleRecord, setSingleRecord] = useState({})
    // console.log('singleRecordsdata',singleRecord)
    const [image , setImage] = useState();
    const [imageshow , SetImageshow] = useState();
    const [message , setMessage] = useState();
    const [userdata , setUserdata] = useState({});
    const navigate = useNavigate();
useEffect(()=>{
    async function fetchdata(){
        var userdata = await getUserDatas();
        await getsingleuserdisputehook(userdata?._id).then((result)=>{
            console.log("get single user dispute hook" , result);
            result?.data?.record?.map((data , i)=> data.sno = i+1);
            setDispute(result?.data?.record);
            setUserdata(userdata);
        })
    }
    fetchdata();
},[])

//     const [imageFiles, setImageFiles] = useState([]);
//     const [previewUrl, setPreviewUrl] = useState([]);
// const[filenames, setFileNames] = useState([]) 

//     const HandleFile = (data) => {
//         var file = data.target.files;
//     Array.from(file).forEach((val,i)=>{ setFileNames([...filenames, val.name])
//         })
//    console.log("viji", filenames)
//         var reader = new FileReader()
//         if (file) {
//             setImageFiles(file);
//             let arr = [];
//             Array.from(file).forEach((val)=>{
//                 setPreviewUrl([...previewUrl,URL.createObjectURL(val)])
//                 arr.push(URL.createObjectURL(val));
//             });
//             setPreviewUrl(arr);
//         }
//     }
const handleview = async(data)=>{
   console.log("data" , data);
   setSingledispute(data);
}
const filevalidate = (image)=>{
    var fileName = image?.name ? image?.name :image;
        var idxDot = fileName?.lastIndexOf(".") + 1;
        var extFile = fileName?.substr(idxDot, fileName.length).toLowerCase();
        if(extFile=="jpg" || extFile=="jpeg" || extFile=="png" || extFile=="webp"){
            return true;
        }else{
            return false;
        }
  }
const columns = [
    {
        name:"S.No.",
        sortable: true,
        reorder: false,
        cell: (data) => (
            <>{data.sno}
            </> ),
       
    },
    {
        name:"Ticket id",
        cell: (data) => (
         <>{data.ticketid}</> ),
            minWidth:"150px"
    },
    {
        name:"Raised Date",
        cell: (data) => (
         <>{new Date(data?.createdAt)?.toLocaleDateString()}</> ),
         minWidth:"200px"
    },
    // {
    //     name:"Location",
    //     cell: (data) => (
    //         <>
    //         <p>{data.rname} {data.rplace}</p>
    //         </>),
    //         minWidth:"200px"

    // },
    // {
    //     name:"Location",
    //     cell: (data) => (
    //         <>
    //         <p>{data.rname}</p>
    //         <p>{data.rplace}</p>
    //         </>),
    // },
    {
        name:"Status",
       
        button: true,
        cell: (data) => (
          <button
            type="button"
            class="btn btn-warning text-light nowrap m-auto"
            data-bs-toggle="modal"
            data-bs-target="#myModal" >
        {data?.status}
          </button> ),
          minWidth:"200px"
    },
    {
        name:"Option",
        button: true,
        cell: (data) => (
          <button  onClick={() => {setShowDispute(true); handleview(data)}}
            type="button"
            class="btn btn-theme nowrap"
           
           
            >
        View Details
          </button> )
    },
  
   
 
]
const[createdispute, setCreateDispute] = useState(false)

const handlesend = async()=>{
    let userdata = await getUserDatas();
    let payload = {
        message : message,
        sender : userdata?._id,
        id : singledispute?._id
    }
    if(filevalidate(image)){
        payload.image = image;
        payload.folpath = singledispute?.attachment?.path;
        await disputechatwithimghook(payload).then(async(result)=>{
            console.log("dispute chat with img hook result" , result);
            await getsinglechathook(singledispute?.ticketid).then((data)=>{
                console.log("data" , data);
                setSingledispute(data?.data?.record[0]);
                setMessage("");
                setMessage("");
                setImage();
                SetImageshow();
            })
        })
    }
    else{
        await disputechathook(payload).then(async(result)=>{
            console.log("dispute chat hook result" , result);
            await getsinglechathook(singledispute?.ticketid).then((data)=>{
                console.log("data" , data);
                setSingledispute(data?.data?.record[0]);
                setMessage("");
                setImage();
                SetImageshow();
            })
        })
    }
}

// const getRecords = (data) =>
// {
//   console.log('datsgal',data)
// }
    return(
        <div className='dispute'>
            {createdispute && <CreateDispute onDismiss={() => setCreateDispute(false)}/>}
             <div>
             <HostHeader />
                <div className='container container_custom mh-100vh-header pb-5'>
                <div className= {showdispute ? "d-none": 'shadowbox py-3 my-4 px-3' }>
                            <div className='d-flex jc-between align-items-center'>
                                <h4 className='mb-0 themeclr'>Dispute</h4>
                                <button className='btn btn-theme' onClick={() => setCreateDispute(true)}>Create</button>
                                {/* <Dropdown>
                                    <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
                                        All Dispute
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown> */}
                            </div>
                        </div>
                    <div className={showdispute ? 'd-none' : 'disputetab shadowbox'}>
                        

                    <DataTable className='dis_tab' pagination columns={columns} data={dispute} />

           
                 
                    </div>
                    <div className={showdispute ? 'row my-4' : "d-none"}>
                    
                       
                        <div className='col-lg-7'>
                            <h4 className='themeclr mb-3'><button className='btn btn-link' onClick={() => setShowDispute(false)}><span className='text-dark fa fa-chevron-left'></span></button>Ticket id : {singledispute?.ticketid}</h4>
                           {singledispute?.status != "closed" &&
                            <div className='disputechart shadowbox br-10 '>
                               <Tabs defaultActiveKey="home" id="fill-tab-example" className="mb-3" fill>
                                    <Tab eventKey="home" title="Keep talking" className='p-4'>
                                    <Form.Control
                                    as="textarea"
                                    value ={message}
                                    placeholder="Leave a message here"
                                    style={{ height: '100px' }}
                                    onChange={(e)=>{setMessage(e?.target?.value)}}
                                    />
                                    <div className='mt-3 text-end'>
                                        <button className='btn btn-theme' onClick={handlesend}>Send</button>
                                    </div>
                                    </Tab>
                                    {/* <Tab eventKey="profile" title="Admin" className='p-4'>
                                        <Form.Control as="textarea" placeholder="Leave a comment here" style={{ height: '200px' }}/>
                                        <div className='mt-3 text-end'>
                                            <button className='btn btn-theme'>Keep talking</button>
                                        </div>
                                    </Tab> */}
                                </Tabs>
                            </div>
                                }
                            <div className='disputechat-lists mb-4 mt-5 pe-4'>
                                <ul className='ps-0'>
                                   
                                {singledispute?.chat?.map((data,i)=>
                                    data?.ref == "supportteam" 
                                    ?<>
                                        <li className='mb-3 reply' >
                                        <div className='d-flex gap-4'>
                                        <div>
                                                <img src={user} className="chatprof"/>
                                                <p className='text-center f-14'>Admin</p>
                                           </div> 
                                           <div className='w-full'> 
                                           <p className="f-14">{data?.message}</p>
                                           </div>
                                           
                                        </div>
                                    </li>
                                    </>
                                    :data?.ref == "user" &&
                                    <li className='mb-3 message'>
                                    <div className='d-flex gap-4'>
                                       <div className='w-full'> 
                                       {data?.image&&
                                       <a href = {API_URL+singledispute?.attachment?.path+"/"+data?.image} target="_blank">
                                   <img className='attached attach_img_chat' src={API_URL+singledispute?.attachment?.path+"/"+data?.image} />
                                </a>}
                                       <p className="f-14">{data?.message}</p>
                                       </div>
                                       <div>
                                            <img src={API_URL + userdata?.profilePicturePath} className="chatprof"/>
                                            <p className='text-center f-14'>You</p>
                                       </div> 
                                    </div>
                                </li>
                                )}
                                    


                                    

                                    {/* <li className='mb-3'>
                                        <div className='d-flex gap-4'>
                                          
                                           <div className='w-full'> 
                                           <p className="f-14">Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                                                     Blanditiis mollitia, enim, delectus distinctio consequatur quaerat ullam reprehenderit lab Laboriosam.</p>
                                           </div>
                                           <div>
                                                <img src={prof} className="chatprof"/>
                                                <p className='text-center f-14'>Admin</p>
                                           </div> 
                                        </div>
                                    </li>

                                    <li className='mb-3'>
                                        <div className='d-flex gap-4'>
                                           <div>
                                                <img src={prof} className="chatprof"/>
                                                <p className='text-center f-14'>Admin</p>
                                           </div> 
                                           <div className='w-full'> 
                                           <p className="f-14">Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                                                     Blanditiis mollitia, enim, delectus distinctio consequatur quaerat ullam reprehenderit lab Laboriosam.</p>
                                           </div>
                                        </div>
                                    </li> */}
                                </ul>
                                
                            </div>
                            {/* <div className='chatinput d-flex'>  
                                   <textarea className='form-control' rows="2"></textarea>
                                   <button className='btn btn-link text-dark nounder'><span className='fa fa-chevron-right'></span></button>
                                </div> */}
                        </div>
                        <div className='col-lg-5'>
                            <div className='dispute-right'>
                                <h5 className='themeclr'>Dispute Reason</h5>
                                <p className='f-18'>{singledispute?.description}</p>
                                {/* <p className='f-14 mt-3 mb-2'>Reservation Information</p> */}
                                <div className='resortimg mt-4'>
                                <h5 className='themeclr'>Dispute Image</h5>
                                <a href = {API_URL+singledispute?.attachment?.path+"/"+singledispute?.attachment?.name} target="_blank">
                                   <img src={API_URL+singledispute?.attachment?.path+"/"+singledispute?.attachment?.name} />
                                </a>
                                   <img src={API_URL + userdata?.profilePicturePath} className="chatprof"/>
                                </div>
                                <div className='mt-3'>
                                {/* <h5 className='themeclr'>Dummy Resort</h5> */}
                                <p className='text-muted f-14 my-2'>{singledispute?.createdAt?.slice(0,10) }<span className='themeclr'></span></p>  
                                {/* <p className='text-muted f-14'>1 Guest . $765 . <span className='themeclr'>Canceled</span></p>  */}

                                </div>
                            </div>
                            <div className='shadowbox p-4 mt-3'>
                                <div className='attachimg'>
                                    
                                    {singledispute?.status != "closed" &&
                                    <>
                                    <div className='d-sm-flex gap-2 align-items-center mb-3'>
                                        <h6 className='themeclr mb-0'>Attachments</h6>
                                        <div className='choosefiles'> 
                                           <input type="file" multiple className='btn btn-theme' onChange={(e)=>{
                                            var image = e?.target?.files[0];
                                            var fileName = image.name;
                                            var idxDot = fileName.lastIndexOf(".") + 1;
                                            var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
                                            if(extFile=="jpg" || extFile=="jpeg" || extFile=="png" || extFile=="webp" || extFile=="svg"){
                                                setImage(e?.target?.files[0]);
                                                SetImageshow(URL.createObjectURL(e?.target?.files[0]))
                                            }else{
                                                toast.error("Invalid file format!")
                                            }
                                           }}/> 
                                           <button className='btn btn-theme'>Choose</button>
                                        </div>
                                      
                                       
                                    </div>
                                    
                                    <ul className='mb-0 ps-0'>
                                    {/* <i className='fa fa-times'></i> */}
                                            <li>jpeg <button className='btn'></button></li>
                                            <li>png <button className='btn'></button></li>
                                            <li>webp <button className='btn'></button></li>
                                            
                                            <li>jpg <button className='btn'></button></li>
                                        </ul>

                                    <div className='d-flex gap-2 jc-between'>
                                       {imageshow && <img src={imageshow} />} 
                                        {/* <img src={img1} />
                                        <img src={img1} /> */}
                                    </div>
                                    <button className='btn btn-theme mt-3'
                                        onClick={async()=>{
                                            await closedisputehook(singledispute?.ticketid).then(()=>{
                                                window.location.reload();
                                            })
                                            
                                        }}
                                    >Close dispute</button></>
                                        }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />

             </div>
        </div>
    )
}

export default Dispute