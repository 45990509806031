import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { getExperienceCategories, getExperienceFromLocal } from '../../../../Hooks/useUserExperienceHook';

const TypesOfExp = (props) =>{
    const[secondcate , setShowSecond] = useState(false);
    const [categories,setCategories] = useState([]);
    const [expCategories, setExpCategories] = useState([]);
    console.log('expCategories----------',expCategories,expCategories.length)
    const [disable, setDisable] = useState(false);
    useEffect(()=>{
        const localData = getExperienceFromLocal();
        console.log('localdatastypeofep',localData)
        setShowSecond(localData && localData.categories && localData.categories.length == 2?true:false);
        
        getExperienceCategories().then((data)=>{
            setCategories(data.content);
            // console.log(data.content, "data : ",data)
            (localData && localData.categories)?setExpCategories(localData.categories):setExpCategories([localData && localData.categories ? data.content[0] : '']);
        })
    },[]);

    console.log('expCategories.length',expCategories?.length)
    const addCategories = (val,index) => {
        console.log("val:",val,"index:",index,"expCategories",expCategories);
        // if(index == 0)
        // {
        //     setExpCategories([val])
        // }
        // else{
        //     setExpCategories([val])
        // }
        
        if(index == 0){
            if(expCategories?.length > 0)
            {
                if(!expCategories.includes(val))
                {
                    var arr = expCategories.map((value,ind)=>
                    {
                        console.log('values,indexs',val,value,ind,index)
                        if(ind == index)
                        {
                            return val;
                        }
                        else
                        {
                            return value;
                        }
                    })
                    console.log('expindexexssssssssss',arr,arr[0])
                    setExpCategories(arr);
                }
             
            }
            else
            {
                console.log("111")
                if(!expCategories.includes(val)){     
                    console.log("222")
                    expCategories[0] = val;
                    setDisable(true)
                    }
            }
            // expCategories.splice(0,1);
            
        }
        if(index == 1){
            
            if(expCategories.length > 0)
            {
                console.log('expcategories iiii' ,expCategories)
                if(!expCategories.includes(val)){
                    let arr = [0,1].map((value,ind)=>
                    {
                        // console.log('value arr',ind , index ,value,val)
                        // console.log('vlue detauls1 iiii',value)
                        console.log('vlue detauls2 iiii',val)

                        if(ind == index)
                        {
                            return val;
                        }
                        else
                        {
                            console.log('enter this',val)
                            return expCategories[ind];
                        }
                    })
                    console.log("arrrrrrrrrrrrrrrrr",expCategories,arr,arr[0]);
                setExpCategories(arr)
                }
            }
    
           else 
           {
           if(!expCategories.includes(val)){
             expCategories[1] = val;
             }
           }
        }
        console.log("expCategories :",expCategories);
    }
    console.log("expCategories length :",expCategories.length);

    return(
        <>
  {console.log('exprienceCategoreis jiji',expCategories)}
                <div className="min_hight_drop">
                    <h2 className='conthead'>What type experience will you host?</h2>
                    <p className='text-muted f-14'>Choose the category that best describe your experience. Add a second,
                        if you think it fits into another category.
                    </p>
                    <div className='h-55 mt-4'>
                        <select className='form-control' value={expCategories[0] && expCategories?.[0] &&expCategories?.[0]} onChange={(e)=>{{console.log('e.currentTargetValue',e.currentTarget.value)} addCategories(e.currentTarget.value,0)}}>
                        <option value="" disabled selected>Select your category</option>
                        {categories && categories.map((value)=><option>{value.type}</option>)}
                    </select>
                    <Button variant="link" className= {secondcate ?  "d-none" : "ps-0 adminblue mt-3 text-start f-14"}  onClick={() => setShowSecond(true)}>+ Add secondary category(optional)</Button>
                     <div className={secondcate ? "secondcate" : "d-none"}>
                   
                        <p className='mb-2 mt-4 themeclr f-14'>Secondary category (Optional)  <Button variant="link" className="p-0 themeclr" onClick={() => {setShowSecond(false);expCategories.splice(1,1);console.log("expCategories jiji",expCategories)}}>Remove</Button></p>
                        <select className='form-control' value={expCategories[1]&&expCategories[1]} onChange={(e)=>{addCategories(e.currentTarget.value,1)}}>
                            <option >Select categories</option>
                            {categories && categories.map((value)=><option>{value.type}</option>)}
                        </select>
                     </div>
                   <div className='mt-4'><button className='btn btn-theme' disabled={!expCategories[0]} onClick={() => {props.onSetLocal(expCategories,"categories");props.count()}}>Save & Continue</button></div> 
                    </div>
                    {/* <button onClick={()=>{window.location.href=`/host-dashboard/listings/venkateshjj01@gmail.com/experience`}}></button> */}
              </div>            
        {console.log("expCategories" , expCategories)}
        </>

    )
}

export default TypesOfExp