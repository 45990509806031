import React, { useState, useEffect } from "react";
import { Elements, LinkAuthenticationElement, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { FRONT_URL } from "../../config/env";
import { toast } from "react-hot-toast";

function Confirmhostsubscribepay(){
    const [message , setMessage] = useState("");
    const stripe = useStripe();
    const elements = useElements();

    const handlepay = async() => {
        const {error} = await stripe.confirmPayment({
            elements,
            
            confirmParams: {
              // Make sure to change this to your payment completion page
              return_url: `${FRONT_URL}/account`,
            },
          });

          if(error){
            setMessage(error?.message);
          }
          else{
            toast.success("Host subscribed successfully!..");
          }
    }

    return(
        <>
            <LinkAuthenticationElement id="link-authentication-element"
            />
            <PaymentElement id="payment-element" />
            <p className="mt-3">{message}</p>
            <div className="text-right">

            <button onClick={() =>handlepay()} className='btn btn-theme mt-3'>Pay Now</button>
            </div>
        </>
    )
}

export default Confirmhostsubscribepay;