import React,{useState} from 'react';
import {Modal,Button} from 'react-bootstrap'
import Scrollbars from 'react-custom-scrollbars';

const ScenicModal = (props) =>{
    const[scenic , setScenic] = useState(true)

    return(
        <>
         <Modal show={scenic} centered scrollable size="md" className='listingmodals modal_style scenic'>
        <Modal.Header className='border-0 pb-0'>
          <button type="button" className="btn close close_modal" onClick={() =>props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
      </Modal.Header>
          <Modal.Body>
            <h4 className='mb-0'>Scenic views</h4>
            <p className='text-muted mb-4'>Views so photo-worthy that they'll wow every guest.</p>
            <Scrollbars style={{  height: 300  }}>
            <div>
                <div class="form-check justify-content-end gap-2">
                    <label class="form-check-label" for="flexCheckChecked">
                        <p>Bay view</p>
                    </label>
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                </div>
                <div class="form-check justify-content-end gap-2">
                    <label class="form-check-label" for="flexCheckChecked">
                        <p>Beach view</p>
                    </label>
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"/>
                </div>
                <div class="form-check justify-content-end gap-2">
                    <label class="form-check-label" for="flexCheckChecked">
                        <p>Canal view</p>
                    </label>
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                </div>
                <div class="form-check justify-content-end gap-2">
                    <label class="form-check-label" for="flexCheckChecked">
                        <p>City skyline view</p>
                    </label>
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                </div>
                <div class="form-check justify-content-end gap-2">
                    <label class="form-check-label" for="flexCheckChecked">
                        <p>Courtyard view</p>
                    </label>
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                </div>
                <div class="form-check justify-content-end gap-2">
                    <label class="form-check-label" for="flexCheckChecked">
                        <p>Desert view</p>
                    </label>
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                </div>
                <div class="form-check justify-content-end gap-2">
                    <label class="form-check-label" for="flexCheckChecked">
                        <p>Garden view</p>
                    </label>
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                </div>
            </div>
        </Scrollbars>
          </Modal.Body>
          <Modal.Footer className='jc-between'>
            <button className='btn btn-theme' >Save</button>
            <Button className='text-dark' variant="link" >Cancel</Button>
          </Modal.Footer>
      </Modal></>
    )
}

export default ScenicModal