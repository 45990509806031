import React from "react";
import HostHeader from "../BecomeHost/AfterHost/HostHeader";
import Footer from "./Footer";
import { useState, useEffect } from "react";
import { getallcmsdatahook } from "../../Hooks/useUserHook";
// Import images

import aboutBanner from "../../Assets/images/About/1.png";
import dreamImg from "../../Assets/images/About/2.jpg";
import workplaceImg from "../../Assets/images/About/3.jpg";
import { Link } from "react-router-dom";
import { API_URL } from "../../config/env";
import Header from "./Header";

const Aboutus = () => {
  const [cms, setCms] = useState([]);
  useEffect(() => {
    async function fetchdata() {
      console.log("");
      await getallcmsdatahook().then((result) => {
        console.log("get all crm hook result", result?.data?.data);
        result?.data?.data?.map((data, i) => (data.sno = i + 1));
        setCms(result?.data?.data);
      });
    }
    fetchdata();
  }, []);
  const cmsFilter = (data) => {
    try {
      let filterdata = cms?.find(
        (el) => el.identifier == data && el?.status == "active"
      );
      console.log("filterdata_filterdata", filterdata, filterdata?.updatedAt);
      return filterdata;
    } catch (e) {
      console.log("cmsFilter_err", e);
    }
  };

  return cms && cms != 0 ? (
    <>
      <Header />

      {/* About Us */}

      <section className="">
        {/* Banner section */}
        <div className="container pad_y">
          <div className="row">
            <div className="col-lg-6">
              <img src={aboutBanner} alt="banner about" className="img-fluid" />
              {/* <img src={cmsFilter("aboutsbanner_01")?.path ? API_URL + cmsFilter("aboutsbanner_01")?.path.split('/')[1] + "/" + cmsFilter("aboutsbanner_01")?.path?.split('/')[2] + "/" +cmsFilter("aboutsbanner_01")?.image : <></>} alt="banner img" className="img-fluid" /> */}
            </div>
            <div className="col-lg-6 d-flex  justify-content-center align-items-center flex-row px-md-5 mt-5 mt-lg-0 ">
              <div>
                <h3 className="about_heads mb-3">
                  {/* {cmsFilter("aboutsbanner_01")?.name != undefined ? cmsFilter("aboutsbanner_01")?.name : ""} */}
                  We make living flexible <br />
                  for everyone
                </h3>
                <p className="about_banner_p">
                  The way you live should align with your goals. Renting an
                  apartment shouldn’t mean being tied to a lease but instead
                  give you an opportunity to see new perspectives. We want to
                  introduce you to an easier way to live.
                </p>
                {/* <p
                  dangerouslySetInnerHTML={{
                    __html:
                      cmsFilter("aboutsbanner_01")?.content != undefined
                        ? cmsFilter("aboutsbanner_01")?.content
                        : "",
                  }}
                /> */}
                {/* {cmsFilter("aboutsbanner_01")?.content != undefined ? cmsFilter("aboutsbanner_01")?.content : ""} */}
              </div>
            </div>
          </div>
        </div>

        {/* Revolutionized section */}

        <div className="container pad_y pad_top_zero">
          <div className="row">
            <div className="col-12">
              <p className="revolve_p">
                We’ve revolutionized the rental process from beginning to end by
                offering fully furnished, flexible-lease apartments for the new
                generation of renters.{" "}
                <span>
                  {" "}
                  With Landing, you never again have to navigate between
                  apartments and locations alone.
                </span>
              </p>
              {/* <p
                className="revolve_p"
                dangerouslySetInnerHTML={{
                  __html:
                    cmsFilter("aboutsbanner_01")?.content1 != undefined
                      ? cmsFilter("aboutsbanner_01")?.content1
                      : "",
                }}
              /> */}
            </div>
          </div>
        </div>

        {/* Count section */}

        <div className="about_count_bg pad_y mt-sm-5">
          <div className="container">
            <div className="row">
              {/* <div
                className="col-sm-7 col-lg-6 col-xl-5 col-xxl-4"
                dangerouslySetInnerHTML={{
                  __html:
                    cmsFilter("aboutsbanner_01")?.content2 != undefined
                      ? cmsFilter("aboutsbanner_01")?.content2
                      : "",
                }}
              /> */}
              <div className="col-sm-7 col-lg-6 col-xl-5 col-xxl-4">
                <p className="count_tle">
                  Landing has already built a nationwide network, and we’re
                  growing fast
                </p>
              </div>
              <div className="col-lg-10 my-4">
                <div className="row count_dls">
                  <div className="col-4">
                    <h2>20,000</h2>
                    <p>AVAILABLE APARTMENTS</p>
                  </div>
                  <div className="col-4 d-flex flex-column align-items-center align-items-xxl-start justify-content-start">
                    <div>
                      <h2>375+</h2>
                      <p>CITIES</p>
                    </div>
                  </div>
                  <div className="col-4 d-flex flex-column align-items-center align-items-xxl-start justify-content-start">
                    <div>
                      <h2>450+</h2>
                      <p>TEAM MEMBERS</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Dream section */}

        <div className="container pad_y mt-sm-5">
          <div className="row ">
            {/* {cmsFilter("aboutsbanner_02")?.multipleimage && (
              <div className="col-lg-6">
                
                <img
                  src={
                    cmsFilter("aboutsbanner_02")?.multipleimage &&
                    cmsFilter("aboutsbanner_02")?.path ? (
                      API_URL +
                      cmsFilter("aboutsbanner_02")?.path.split("/")[1] +
                      "/" +
                      cmsFilter("aboutsbanner_02")?.path?.split("/")[2] +
                      "/" +
                      cmsFilter("aboutsbanner_02")?.multipleimage[0]
                    ) : (
                      <></>
                    )
                  }
                  alt="banner about"
                  className="img-fluid img_bor_radius"
                />
              </div>
            )} */}
            <div className="col-lg-6">
              <img
                src={dreamImg}
                alt="banner about"
                className="img-fluid img_bor_radius"
              />
            </div>
            <div className="col-lg-6 d-flex  justify-content-center align-items-center flex-row px-md-5 mt-5 mt-lg-0 ">
              <div>
                <h3 className="dream_heads mb-3">The dream team</h3>
                <p className="dream_banner_p">
                  At Landing, we’re a team of hands-on problem solvers. We value
                  authenticity, radical long-term thinking, and most
                  importantly, we work hard for our members every single day.
                </p>
                {/* <p
                  className="dream_banner_p"
                  dangerouslySetInnerHTML={{
                    __html:
                      cmsFilter("aboutsbanner_02")?.content != undefined
                        ? cmsFilter("aboutsbanner_02")?.content
                        : "",
                  }}
                /> */}
              </div>
            </div>
          </div>
        </div>

        {/* Workplace  */}
        <div className="container pb-5 mb-sm-5 mt-lg-5">
          <div className="row d-flex flex-row-reverse">
            {/* {cmsFilter("aboutsbanner_02")?.multipleimage && (
              <div className="col-lg-6">
                <img
                  src={
                    cmsFilter("aboutsbanner_02")?.multipleimage &&
                    cmsFilter("aboutsbanner_02")?.path ? (
                      API_URL +
                      cmsFilter("aboutsbanner_02")?.path.split("/")[1] +
                      "/" +
                      cmsFilter("aboutsbanner_02")?.path?.split("/")[2] +
                      "/" +
                      cmsFilter("aboutsbanner_02")?.multipleimage[1]
                    ) : (
                      <></>
                    )
                  }
                  alt="banner about"
                  className="img-fluid img_bor_radius"
                />
              </div>
            )} */}
            <div className="col-lg-6">
              <img
                src={workplaceImg}
                alt="banner about"
                className="img-fluid img_bor_radius"
              />
            </div>
            <div className="col-lg-6 d-flex  justify-content-center align-items-center flex-row px-md-5 mt-5 mt-lg-0 ">
              <div>
                <h3 className="dream_heads mb-3">
                  Creating an inclusive workplace
                </h3>
                <p className="dream_banner_p">
                  The best work comes when different viewpoints meet a unified
                  vision. At Landing, we’re committed to creating a culture
                  where we can learn from our team members and work together to
                  create a product that feels like home.
                </p>

                {/* <p
                  className="dream_banner_p"
                  dangerouslySetInnerHTML={{
                    __html:
                      cmsFilter("aboutsbanner_02")?.content1 != undefined
                        ? cmsFilter("aboutsbanner_02")?.content1
                        : "",
                  }}
                /> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  ) : (
    <></>
  );
};

export default Aboutus;
