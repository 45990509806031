import React, { useState } from 'react';
import {Modal, Button, ProgressBar} from 'react-bootstrap'
import Scrollbars from 'react-custom-scrollbars';


import tick from '../../../../../Assets/images/tick.png'

const EmailVerify = (props) =>{
    const[verify, setVerify] = useState(true)
    const emailclose = () =>{
        props.close()
        props.onDismiss()
    }
    const showfirst = () =>{
    document.getElementById("firstmodal").style.visibility = "visible"
    props.onDismiss()

    }
    let tabChange = (val) => {
        let ele = document.querySelectorAll('input.otp');
        console.log(ele)
        if(ele[val-1].value != ''){
          ele[val].focus()
        }else if(ele[val-1].value == ''){
          ele[val-2].focus()
        }   
     }

    return(
        <>

        <Modal show={verify} centered size="md" className='modal_style'>
           <Modal.Header className='border-0 pb-0'>
               <button type="button" className="btn close close_modal" onClick={() => emailclose()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
  
          </Modal.Header>
          <Modal.Body className='d-scroll'>  
            <Scrollbars style={{  height: 300  }}>
                <h2>Enter your verification code</h2>
                <p className='my-3'>We emailed your code to vi•••••@gm•••••.com. It may take a minute to arrive.</p>
            <form action="" className="my-4 confirm">
              <input className="otp" type="text" onKeyUp={() => tabChange(1)} maxlength="1" />
              <input className="otp" type="text" onKeyUp={() => tabChange(2)} maxlength="1" />
              <input className="otp" type="text" onKeyUp={() => tabChange(3)} maxlength="1" />
              <input className="otp" type="text" onKeyUp={() => tabChange(4)} maxlength="1" />
            </form>
            <p className='mt-3'>Didn’t get it? <a href="#" className='themeclr'>Try again</a></p>
          
               </Scrollbars>
          </Modal.Body>
          <Modal.Footer className='justify-content-between'>
          <Button variant="link" onClick={() =>showfirst()} className="text-dark">Back</Button>
          <Button className='btn btn-theme'>Allow Pets</Button>
          </Modal.Footer>
      </Modal>
      </>
    )
}

export default EmailVerify
