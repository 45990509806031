import React,{useState} from 'react';
import { useEffect } from 'react';
import {Modal,Button} from 'react-bootstrap'
import Scrollbars from 'react-custom-scrollbars';

const GameConsole = (props) =>{
    const[game , setGame] = useState(true)
  

    return(
      <>
      <Modal show={game} centered scrollable size="md" className='listingmodals modal_style scenic'>
          <Modal.Header className='border-0 pb-0'>
            <button type="button" className="btn close close_modal" onClick={() =>props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
          </Modal.Header>
          <Modal.Body>
            <h4 className='mb-0'>Game console details</h4>
            {/* <Scrollbars style={{  height: 400  }}> */}
                <div>
                    <h6 className='my-4'>Type</h6>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Nintendo Switch</p>
                        </label>
                        <input class="form-check-input" type="checkbox" value="Portable air conditioning" id="flexCheckChecked"/>
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Nintendo Wii</p>
                        </label>
                        <input class="form-check-input" type="checkbox" value="Window AC unit" id="flexCheckChecked" />
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Nintendo Wii U</p>
                        </label>
                        <input class="form-check-input"  type="checkbox" value="Split-type ductless system" id="flexCheckChecked" />
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>PS2</p>
                        </label>
                        <input class="form-check-input" type="checkbox" value="Air conditioning details" id="flexCheckChecked" />
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>PS3</p>
                        </label>
                        <input class="form-check-input" type="checkbox" value="Air conditioning details" id="flexCheckChecked" />
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>PS4</p>
                        </label>
                        <input class="form-check-input" type="checkbox" value="Air conditioning details" id="flexCheckChecked" />
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>PS5</p>
                        </label>
                        <input class="form-check-input" type="checkbox" value="Air conditioning details" id="flexCheckChecked" />
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Xbox 360</p>
                        </label>
                        <input class="form-check-input" type="checkbox" value="Air conditioning details" id="flexCheckChecked" />
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Xbox One</p>
                        </label>
                        <input class="form-check-input" type="checkbox" value="Air conditioning details" id="flexCheckChecked" />
                    </div>
                    <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Xbox Series X</p>
                        </label>
                        <input class="form-check-input" type="checkbox" value="Air conditioning details" id="flexCheckChecked" />
                    </div>
                </div>
            {/* </Scrollbars> */}
          </Modal.Body>
          <Modal.Footer className='jc-between'>
          <button className='btn btn-theme' >Save</button>
            <Button className='text-dark' variant="link" >Cancel</Button>
          </Modal.Footer>
      </Modal>
      </>
    )
}

export default GameConsole