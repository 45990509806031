
import React, { useState } from 'react';

// import OwlCarousel from 'react-owl-carousel'
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
import Scrollbars from 'react-custom-scrollbars';


// const Filtermodal = React.lazy(() => import('../Common/Filter/Filtermodal.js'));

import Filtermodal from '../Common/Filter/Filtermodal.js'


const FilterExp = () =>{
    const [showfilter, setShowfilter] = useState(false);



    const categories = [
        {cat : "Art and Culture"},
        {cat : "Entertainment"},
        {cat : "Food and Drink"},
        {cat : "Sports"},
        {cat : "Tours"},
        {cat : "Sitseeing"},
        {cat : "Wellness"},
        {cat : "Sports"},
        {cat : "Tours"},
        {cat : "Sitseeing"},
        {cat : "Wellness"},
        {cat : "Sports"},
        {cat : "Tours"},
        {cat : "Sitseeing"},
        {cat : "Wellness"}
    ]
    return(
        <div id="filterexp" className='mt-3'>

             {showfilter && <Filtermodal  onDismiss={()=> setShowfilter(false)} />}
            <div className='container container_custom'>
                <div className='row align-items-center'>
                        <div className='filterscrol host'>

                            <div>
                                <p>Price</p>
                                <p>Language offered</p>
                                <p>Time of day</p>
                                </div>
                                <div className=' d-none-767 scroll'>
                                {/* <Scrollbars style={{ width: 500  }} className="calendar_scroll"> */}
                                                               {window.innerWidth > 1400 ? categories.slice(0,9).map((i) => <p>{i.cat}</p>) : window.innerWidth > 1199 ? categories.slice(0,7).map((i) => <p>{i.cat}</p>) : window.innerWidth > 991
                                    ? categories.slice(0,4).map((i) => <p>{i.cat}</p>) : window.innerWidth > 767 ?categories.slice(0,3).map((i) => <p>{i.cat}</p>) : "" }
 {/* {categories.map((i) =><p>{i.cat}</p>)  }                                */}
 {/* </Scrollbars> */}

                                </div>
                                <div className='text-end' >

                                  <button className='btn btn-theme filterbtn' onClick={() =>{setShowfilter(true);
                                console.log("buttonclick")} }><i class="fa fa-filter" aria-hidden="true"></i>
                                  <span className='ms-1' onClick={() => setShowfilter(true)}>Filter</span></button>

                                

                                </div>
                            </div>
                   
    
                </div>
               
            </div>
        </div>
    )
}


export default FilterExp