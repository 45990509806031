import React from "react";
import { Button,ProgressBar } from "react-bootstrap";

import Header from '../Components/Common/Header-new.js'
import Footer from '../Components/Common/Footer.js'


// import img1 from '../Assets/images/detail/img1.png'
// import img2 from '../Assets/images/detail/img2.png'
// import img3 from '../Assets/images/detail/img3.png'
// import img4 from '../Assets/images/detail/img4.png'
// import img5 from '../Assets/images/detail/img5.png'
import card from '../Assets/images/card.png'
import badge from '../Assets/images/badge.png'
import key from '../Assets/images/key.png'

import user from '../Assets/images/user.png'
import icon1 from '../Assets/images/filter/beaches.png'
import Datepicker from "./Common/Datepicker/Datepicker.js";
import GMaps from "./Maps/GMaps"



const Maps = () =>{
    return(
        <>   <Header />


        <div className="detailpage pagetop">
            <div className="container container_custom">
                <div className="d-flex justify-content-between">
                    <div>
                          <h2>Lily Beach Resort & Spa, Maldives, With Pool, AI</h2>
                          <Button variant="link" className="f-14 ps-0">Maldives</Button>
                    </div>
                    <div className="">
                        <Button variant="link">Share</Button>
                        <Button variant="link">Save</Button>
                    </div>
                </div>
                
                {/* <div className="row detail-img">
                    <div className="col-md-3"><img src={img1} /></div>
                    <div className="col-md-3"><img src={img2} /></div>
                    <div className="col-md-3">
                        <div className="row mb-2"><img src={img3} /></div>
                        <div className="row"><img src={img4} /></div>
                    </div>
                    <div className="col-md-3"><img src={img5} /></div>
                    
                </div> */}

                <div className="row mt-4">
                    <div className="col-md-7">
                    <div className="d-flex justify-content-between">
                    <div>
                          <h4 className="themeclr"><b>Dome hosted by Dorothy</b></h4>
                          <p className="txtgray"><span>6 guests</span>.<span>1 bedroom</span>.
                          <span>1 bed</span>.<span>1 bathroom</span>
                          
                          </p>
                    </div>
                    <div className="">
                       image
                    </div>
                </div>
                <hr className="themehr" />
                <div className="rentaldiv">
                    <div className="d-flex">
                        <img src={card} />
                        <div>
                            <h6 className="themeclr mb-0">Self check in</h6>
                            <p className="txtgray">This is one of the few places in the area with a pool.</p>
                        </div>
                    </div>
                    <div className="d-flex">
                        <img src={badge} />

                        <div>
                            <h6 className="themeclr mb-0">Muthukumar is a Superhost</h6>
                            <p className="txtgray">This is one of the few places in the area with a pool.</p>
                        </div>
                        
                    </div>
                    <div className="d-flex">
                        <img src={key} />

                        <div>
                            <h6 className="themeclr mb-0">Great check-in experiance</h6>
                            <p className="txtgray">This is one of the few places in the area with a pool.</p>
                        </div>
                        
                    </div>
                </div>
                <hr className="themehr" />
                <div>
                    <h6>Why you'll do?</h6>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit quidem odio eaque magnam iure nulla nemo! Praesentium, dolorem consequatur
                         Excepturi quasi, accusamus maiores culpa harum corrupti vero facilis adipisci quae</p>
                  <Button variant="link" className="ps-0">Learn More</Button>
                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit quidem odio eaque magnam iure nulla nemo! Praesentium, dolorem consequatur
                         Excepturi quasi, accusamus maiores culpa harum corrupti vero facilis adipisci quae</p>

                         <h6>The space</h6>
                    <p>Lorem ipsum dolor ti vero facilis adipisci quae</p>
                  <Button variant="link" className="ps-0">Learn More</Button>
                </div>
                <hr className="themehr" />
                <div >
                <h6>Where you'll sleep</h6>
                <div className="bordbox br-10">
                    {/* <h6 className="themeclr">Bed room</h6>
                    <p className="f-14 text-muted">1 Double bed</p> */}
                    <GMaps/>
                </div>
                <hr className="themehr" />
                
                <div>
                <h6>What's this place offers?</h6>
                <ul className="ps-0">
                    <li><span>icon</span><span>Wifi</span></li>
                    <li><span>icon</span><span>Pool</span></li>
                    <li><span>icon</span><span>Tv</span></li>
                    <li><span>icon</span><span>Air conditionimg</span></li>
                    <li><span>icon</span><span>Hair dryer</span></li>
                    <li><span>icon</span><span>Breakfast</span></li>



                </ul>
                <button className="btn btn-theme">Show all 14 amenities</button>

                <hr className="themehr" />

                <div>
                     <h6 className="themeclr">7 nights in MV</h6>
                    <p className="f-14 text-muted">jul 2,2022 - jul 9, 2022</p>
                    <Datepicker />
                </div>

                <hr className="themehr" />
                <div className="reviewsec">
                     <h5 className="">No reviews (yet)</h5>
                     <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex">
                                <span>Cleanliness</span>
                                <ProgressBar now={60} />
                            </div>
                        </div>
                        
                     </div>

                    
                </div>
               


                </div>
            

                </div>
                
                    </div>
                    <div className="col-md-5"></div>
                </div>
                <div className="row">
                <div className="review-det">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-10">
                                        <div className="prof-det">
                                            <div className="d-flex">
                                                <img src={user} />
                                                <div>
                                                    <h6>Charlotte</h6>
                                                    <p className="text-muted f-14">June 2022</p>
                                                </div>
                                            </div>
                                            <p className="text-muted">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed eligendi porro voluptates illum excepturi pariatur obcaecati ipsum et tempore aliquam explicabo, repellendus ad nisi,
                                                quia maiores ipsam temporibus iure nemo.</p>
                                                <Button variant="link">Show more</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-10">
                                        <div className="prof-det">
                                            <div className="d-flex">
                                                <img src={user} />
                                                <div>
                                                    <h6>Charlotte</h6>
                                                    <p className="text-muted f-14">June 2022</p>
                                                </div>
                                            </div>
                                            <p className="text-muted">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed eligendi porro voluptates illum excepturi pariatur obcaecati ipsum et tempore aliquam explicabo, repellendus ad nisi,
                                                quia maiores ipsam temporibus iure nemo.</p>
                                                <Button variant="link">Show more</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           
                        
                        </div>
                     </div>
                     <button className="btn btn-theme">Show all 14 reviews</button>
                </div>
                <hr className="themer" />
                <div className="row">
                    <h6 className="themeclr">What's included000000000</h6>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed eligendi porro voluptates illum
                         excepturi pariatur obcaecati ipsum et tempore aliquam explicabo, repellendus ad nisi, 
                        quia maiores ipsam temporibus iure nemo.</p>
                </div>
                <hr className="themer" />
                <div className="row">
                    <div className="col-md-8">
                        {/* <div className="d-flex">
                            <img src={} />
                            <div>
                                <span>JAmes</span>
                                <p className="text-muted">Host on Airbnb since 2022</p>
                            </div>
                        </div> */}
                    <p className="text-muted">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed eligendi porro voluptates illum
                        pore aliquam explicabo, repellendus ad nisi, 
                        quia maiores ipsam temporibus iure nemo.</p>
                        <p className="text-muted">Lo, repellendus ad nisi, 
                        quia maiores ipsam temporibus iure nemo.</p>
                        <p className="text-muted">Lorem iquam explicabo, repellendus ad nisi, 
                        quia maiores ipsam temporibus iure nemo.</p>
                        <Button variant="link" className="themeclr ps-0">read more</Button>
                    </div>
                </div>

            </div>

        </div>

        {/* <div className="nofification pagetop" >
         
            <div className="container container_custom">
                <div className="row justify-content-center">
                    <div className="col-md-7">
                        <h2 className="mb-5">Notifications</h2>
                        <ul className="ps-0">
                            <li>
                               
                                <div className="d-flex align-items-top justify-content-between">
                                <a href="#" className="text-dark under">
                                    <div className="d-flex">
                                         <img src={profimg} />
                                    <div>
                                    <p className="mb-0">Learn how responding quickly increases your number of bookings.</p>
                                    <p className="text-muted f-14 mb-0">1 August 2022</p>
                                    </div>
                                    </div>
                                      </a>
                                    <Button variant="link" className="under text-dark"> <span className="fa fa-times"></span> </Button>
                                </div>
                             
                                <hr className="themehr" />
                            </li>
                            <li>
                          
                                <div className="d-flex align-items-top justify-content-between">
                                <a href="#" className="text-dark under">
                                    <div className="d-flex">
                                    <img src={profimg} />
                                    <div>
                                    <p className="mb-0">
Connect with Facebook to complete your profile and make it easy to log in.</p>
                                    <p className="text-muted f-14 mb-0">13 July 2022</p>
                                    </div>
                                    </div>
                                    </a>
                                    <Button variant="link" className="under text-dark"> <span className="fa fa-times"></span> </Button>
                                </div>
                              
                                <hr className="themehr" />
                            </li>
                            <li>
                          
                                <div className="d-flex align-items-top justify-content-between">
                                <a href="#" className="text-dark under">
                                    <div className="d-flex">
                                    <img src={profimg} />
                                    <div>
                                    <p className="mb-0">Please confirm your email address by clicking on the link we've just emailed you. If you cannot find the email, you can request a new confirmation email or change your email address.</p>
                                    <p className="text-muted f-14 mb-0">18 July 2022</p>
                                    </div>
                                    </div>  
                                     </a>
                                    <Button variant="link" className="under text-dark"> <span className="fa fa-times"></span> </Button>
                                </div>
                             
                                <hr className="themehr" />
                            </li>
                            <li>
                          
                          <div className="d-flex align-items-top justify-content-between">
                          <a href="#" className="text-dark under">
                              <div className="d-flex">
                              <img src={profimg} />
                              <div>
                              <p className="mb-0">Invite a friend, earn $1,650.</p>
                              <p className="text-muted f-14 mb-0">18 June 2022</p>
                              </div>
                              </div>  
                               </a>
                              <Button variant="link" className="under text-dark"> <span className="fa fa-times"></span> </Button>
                          </div>
                       
                          <hr className="themehr" />
                      </li>
                           
                        </ul>
                    </div>
                </div>
            </div>
        </div> */}
        <Footer />
        </>
    )
}

export default Maps