import React, { useState } from 'react';
import {Accordion,Button,Dropdown,InputGroup,Form} from 'react-bootstrap'
import { useNavigate, Link } from 'react-router-dom';
import img1 from '../../../../../Assets/images/where/img1.png'
import HostHeader from '../../HostHeader';
import AcModal from './Modals/AcModal';
import BedLinen from './Modals/BedLinenModal';
import ClotheStorage from './Modals/ClotheStorage';
import CoffeeMaker from './Modals/CoffeeMaker';
import CotDetails from './Modals/CotDetails';
import DedicatedServiceModal from './Modals/DedicatedServiceModal';
import DryerDetailsModal from './Modals/DryerDetailsModal';
import ExcerciseEquipment from './Modals/ExcerciseEquipment';
import GameConsole from './Modals/GameConsole';
import HeadingModal from './Modals/HeatingModal';
import HotTubeDetails from './Modals/HotTubeDetails';
import KidsBooksModal from './Modals/KidsBooks';
import OvenDetails from './Modals/Oven';
import OvenDetals from './Modals/Oven';
import PoolDetails from './Modals/PoolDetails';
import Refrigerator from './Modals/Refrigerator';
import SoundSystem from './Modals/SoundSystem';
import StoveDetails from './Modals/StoveDetails';
import TvDetails from './Modals/TvDetails';
import WashingModal from './Modals/WashimgModal';
import BeachAccess from './Modals/BeachAccess';
import ResortAccess from './Modals/ResortAccess';
import SkiDetails from './Modals/SkiDetails';
import GardenDetails from './Modals/GardenDetails';
import GrillDetails from './Modals/GrillModal';
import OutdoorKitchen from './Modals/OurdoorKitchen';
import BalconyDetails from './Modals/BalconyDetail';
import FreeParking from './Modals/FreeParking';
import GymModal from './Modals/GymModal';
import ParkingOff from './Modals/ParkingOff';
import ParkingOn from './Modals/ParkingOn';
import Sauna from './Modals/Sauna';

const Amenities = () =>{
    const navigate = useNavigate();
    const navigatehosthome = () =>{
        navigate('/hosting/managespace')
    }
const[amenities, setAmenities] = useState(
    {"essentials": false},{"ac": false},{"cleaning": false},{"cooking" : false},{"workspace": false},
    {"dishes": false},{"dryer": false},{"hairdryer": false},{"heating": false},{"hottub": false},
    {"kitchen": false},{"pool": false},{"tv": false},{"washing": false},
    {"dedicatedworkspace": false},{"wifi": false},{"bath": false},{"bidet": false},
    {"bodysoap": false},{"cleaningproduct": false},{"conditioner": false}
    ,{"bathhairdryer": false},{"hotwater": false},{"outdoorshower": false},{"shampoo": false},
    {"showergel": false},{"bedessential" : false},{"bedlinen": false},{"clothestore" : false},
    {"beddryer" : false},{"dryerrack" : false},{"extrapillows": false},{"hanger" : false},
    {"iron":false} ,{"musqnet" : false},{"darkenshade" :false},{"safe" : false},
    {"bedwashing": false}, {"readings" : false},{"ethernet" : false} ,{"equipment" : false}, {"piano" : false},{"games" : false},
    {"pingpong" : false} , {"entertainpool": false} , {"player" : false} , {"entertv" : false},
    {"entersafe" : false} , {"enterwashing" : false},{"babybath" : false},{"babymonitor" : false},
    {"babysafty" : false} , {"babysitter" : false} ,{"boardgames" : false} , {"changingtable":false} , 
    {"kidsbook": false} ,{"kidstable" : false},{"cot" : false}, {"fireguard" : false},
    {"highchair":false},{"plugsocket" : false}, {"travelcot" : false},{"cornerguard": false},
    {"windowguard": false},{"heatingac" : false},{"fan":false},{"heatingcool": false},{"indoorfire": false},{"portablefan":false},
    {"coalarm" : false},{"fireextingusher":false},{"firstaidkit":false},{"bedroomlock":false}
    ,{"smokealarm":false},{"netwokpace":false},{"pocketwifi":false},{"officewifi":false},
    {"bakingsheet":false},{"barbecue":false},{"breadmaker":false},{"blender":false},
    {"coffee":false},{"coffeemaker":false},{"cookingbasics":false},{"diningtable":false},{"silverware":false}
    ,{"dishware":false},{"freezer":false},{"kettle":false},{"diningkitchen":false},{"microwave":false},{"firdge":false},
    {"oven": false},{"refrigerator":false},{"ricecooker":false},{"stove":false},
    {"toaster":false},{"compactor":false},{"glasses":false},{"beachaccess":false},{"lakeaccess":false}
    ,{"Launderette":false},{"privateentrance":false},{"resortaccess":false},
    {"skiinout":false},{"waterfront":false},{"garden":false},{"lift" : false},{"evcharger":false},
    {"parkingpremise":false},{"streetpark":false},{"gym":false},{"tubfacility":false},{"parkoff":false},
    {"parkon":false},{"poolfacility":false},{"privateroom":false},{"saunafacility":false},
    {"singlehome":false},{"breakfast":false},{"cleanoncheck":false},{"longtermstay":false},{"latedropoff" : false})

const[aircondition , setAircondition] = useState(false)
const[actype , setActype] = useState()
const[service , setService] = useState(false)
const[dryerdetails , setDryerDetails] = useState(false)
const[heating , setHeating] = useState(false)
const[hottube , setHotTubeDetails] = useState(false)
const[pool , setPool] = useState(false)
const[tvdetails , setTv] = useState(false)
const[washing, setWashing] = useState(false)
const[bedlinen , setBedLinen] = useState(false)

const[clothestores , setClotheStore] = useState(false)
const[equipment , setEquipment] = useState(false)
const[game , setGame] = useState(false)
const[sound , setSound] = useState(false)
const[kidsbooks , setKidsBooks] = useState(false)
const[cot , setCot] = useState(false)
const[coffee , setCoffee] = useState(false)
const[beach , setBeach] = useState(false)
const[oven , setOven] = useState(false)
const[fridge , setFridge] = useState(false)
const[stove , setStove] = useState(false)
const[resort , setResort] = useState(false)
const[ski , setSki] = useState(false)
const[garden , setGarden] = useState(false)
const[grill , setGrill] = useState(false)
const[outdoorkitchen , setOutdoorKitchen] = useState(false)
const[balcony , setBalcony] = useState(false)
const[parking , setParking] = useState(false)
const[gym , setGym] = useState(false)
const[parkingoff , setParkingOff] = useState(false)
const[parkingon , setParkingOn] = useState(false)
const[sauna , setSauna] = useState(false)



const getAcCont = (data) =>{
    setActype(data)
}
    return(

        <div className='managespace amenities-lists'>
        {dryerdetails && <DryerDetailsModal onDismiss ={() => setDryerDetails(false)} />}
        {aircondition && <AcModal onDismiss={() => setAircondition(false)} conts={getAcCont}/>}
        {service && <DedicatedServiceModal onDismiss={() => setService(false)} />}
        {heating && <HeadingModal onDismiss={() => setHeating(false)} />}
        {hottube && <HotTubeDetails onDismiss={() => setHotTubeDetails(false)}/>}
        {pool && <PoolDetails onDismiss={() => setPool(false)} />}
        {tvdetails && <TvDetails onDismiss={() => setTv(false)}/>}
        {washing && <WashingModal onDismiss={() => setWashing(false)} />}
        {bedlinen && <BedLinen onDismiss={() => setBedLinen(false)}/>}
        {clothestores && <ClotheStorage onDismiss={() => setClotheStore(false)} />}
       {equipment && <ExcerciseEquipment onDismiss={() => setEquipment(false)}/>}
        {game && <GameConsole onDismiss={() => setGame(false)}/>}
        {sound && <SoundSystem onDismiss={() => setSound(false)} />}
        {kidsbooks && <KidsBooksModal onDismiss={() => setKidsBooks(false)}/>}
        {cot && <CotDetails onDismiss={() => setCot(false)}/>}
        {coffee && <CoffeeMaker onDismiss={() => setCoffee(false)} />}
        {oven && <OvenDetails onDismiss={() => setOven(false)} />} 
        {fridge && <Refrigerator onDismiss={() => setFridge(false)} /> }
        {stove && <StoveDetails onDismiss={() => setStove(false)} />}
        {beach && <BeachAccess onDismiss={() => setBeach(false)} /> }
        {resort && <ResortAccess onDismiss={() => setResort(false)} />}
        {ski && <SkiDetails onDismiss={() => setSki(false)} />}
        {garden && <GardenDetails onDismiss={() => setGarden(false)} />}
        {grill && <GrillDetails onDismiss={() => setGrill(false)} />}
        {outdoorkitchen && <OutdoorKitchen onDismiss={() => setOutdoorKitchen(false)} />}
        {balcony && <BalconyDetails onDismiss={() => setBalcony(false)} />}
        {parking && <FreeParking onDismiss={() => setParking(false)} />}
        {gym && <GymModal onDismiss={() => setGym(false)} />}
        {parkingoff && <ParkingOff onDismiss={() => setParkingOff(false)} />}
        {parkingon && <ParkingOn onDismiss={() => setParkingOn(false)} />}
        {sauna && <Sauna onDismiss={() => setSauna(false)} />}

        <HostHeader />
        <div className='container container_theme mh-100vh-header '>
            <div className='row mt-4'>
                <div className='col-md-3'>
                    <div>
                        <h2>Just for Testing </h2>
                        <Dropdown className='listinglist'>
                            <Dropdown.Toggle variant="link" className='text-dark nounder ps-0' id="dropdown-basic">
                                <b>Charge listing</b>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className='py-0 px-4 listinglist-drop'>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1"><span className='fa fa-search'></span></InputGroup.Text>
                                <Form.Control
                                placeholder="Username"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                />
                            </InputGroup>
                                <Link to="/">kjf</Link>
                                <p>Listing</p>
                                <Dropdown.Item href="#/action-1" className='ps-0'>
                                    <div className='d-flex jc-between'>
                                        <div>
                                            <p>Just for texting</p>
                                            <p>kjhi</p>
                                        </div>
                                        <img src={img1}/>
                                    </div>
                                    <hr className='themehr my-0' />
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
                <div className='col-md-8'></div>
            </div>
            <div className='row '>
                <div className='col-md-3 stikyavail'>
                <Accordion defaultActiveKey="0" className='mngspace'>
                    <Accordion.Item eventKey="0">
                        <div className='gobackbtn '>
                            <Button variant='link' onClick={() => navigatehosthome()} className='nounder w-full py-3 ps-0 text-start text-dark'>
                                <span className='fa fa-chevron-left me-2'></span>Amenities
                            </Button >
                        </div>
                        <Accordion.Body className='pt-0'>
                            <ul>
                                <li><a className='text-dark nounder active' href="#popular">Popular</a></li>
                                <li><a className='text-dark nounder' href="#bathroom">Bathroom</a></li>
                                <li><a className='text-dark nounder' href="#laundry">Bathroom & Laundry</a></li>
                                <li><a className='text-dark nounder' href="#entertainment">Entertainment</a></li>
                                <li><a className='text-dark nounder' href="#family">Family</a></li>
                                <li><a className='text-dark nounder' href="#heating">Heating & Cooling</a></li>
                                <li><a className='text-dark nounder' href="#homesafty">Home safty</a></li>
                                <li><a className='text-dark nounder' href="#office">Internet & Office</a></li>
                                <li><a className='text-dark nounder' href="#kitchen">Kitchen & Dining</a></li>
                                <li><a className='text-dark nounder' href="#location">Location feature</a></li>
                                <li><a className='text-dark nounder' href="#outdoor">Outdoor</a></li>
                                <li><a className='text-dark nounder' href="#parking">Parking & Facilities</a></li>
                                <li><a className='text-dark nounder' href="#services">Servies</a></li>
                            </ul>
                        </Accordion.Body>
                    </Accordion.Item>
                   
                    </Accordion>
                </div>
                <div className='col-md-8 mb-4'>
                    <div id="popular">
                        <h6>Popular</h6>
                        <p className='text-muted f-14'>These are the amenities that guests typically look for in a stay.</p>
                        <div className='my-4'>
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Essentials</p>
                                    <p className='text-muted f-14'>Towels, bed sheets, soap, toilet paper, and pillows</p>
                                </div>
                                <div >
                                    <button className={amenities.essentials == true ? 'circlebtn fa fa-times me-2' : amenities.essentials == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['essentials']:false}))}}></button>
                                    <button className={amenities.essentials ?  "circlebtn fa fa-check active" : "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['essentials']:true}))}}></button>
                                </div>
                           </div>

                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Air conditioning</p>
                                    <p className='text-muted f-14'>{actype}</p>
                                </div>
                                <div >
                                    <button className={amenities.ac == true ? 'circlebtn fa fa-times me-2' : amenities.ac == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['ac']:false}))}}> </button>
                                    <button className={amenities.ac ?  "circlebtn fa fa-check active" : 'circlebtn fa fa-check'} onClick={() => {setAircondition(true); setAmenities(prevstate=>({...prevstate,['ac']:true}))}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Cleaning products</p>
                            </div>
                              
                                <div >
                                    <button className={amenities.cleaning == true ? 'circlebtn fa fa-times me-2' : amenities.cleaning == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['cleaning']:false}))}}></button>
                                    <button className={amenities.cleaning ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['cleaning']:true}))}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Cooking basics</p>
                                    <p className='text-muted f-14'>Pots and pans, oil, salt and pepper</p>
                                </div>
                               
                                <div >
                                    <button className={amenities.cooking == true ? 'circlebtn fa fa-times me-2' : amenities.cooking == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['cooking']:false}))}}></button>
                                    <button className={amenities.cooking ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['cooking']:true}))}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Dedicated workspace</p>
                                    <p className='text-muted f-14'>Guests have a desk or table that’s used just for working, along with a comfortable chair.</p>
                                </div>
                                <div >
                                    <button className={amenities.workspace == true ? 'circlebtn fa fa-times me-2' : amenities.workspace == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['workspace']:false}))}}></button>
                                    <button className={amenities.workspace ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['workspace']:true}));setService(true)}}></button>
                                </div>
                             
                            </div>

                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Dishes and silverware</p>
                                    <p className='text-muted f-14'>Bowls, chopsticks, plates, cups, etc.</p>
                                </div>
                                <div >
                                    <button className={amenities.dishes == true ? 'circlebtn fa fa-times me-2' : amenities.dishes == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['dishes']:false}))}}></button>
                                    <button className={amenities.dishes ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['dishes']:true}))}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Dryer</p>
                                </div>
                                <div >
                                    <button className={amenities.dryer == true ? 'circlebtn fa fa-times me-2' : amenities.dryer == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['dryer']:false}))}}></button>
                                    <button className={amenities.dryer ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['dryer']:true}));setDryerDetails(true)}}></button>
                                </div>
                              
                            </div>

                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Hair dryer</p>
                                </div>
                                <div >
                                    <button className={amenities.hairdryer == true ? 'circlebtn fa fa-times me-2' : amenities.hairdryer == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['hairdryer']:false}))}}></button>
                                    <button className={amenities.hairdryer ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['hairdryer']:true}))}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Heating</p>
                                </div>
                                <div>
                                    <button className={amenities.heating == true ? 'circlebtn fa fa-times me-2' : amenities.heating == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['heating']:false}))}}></button>
                                    <button className={amenities.heating ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['heating']:true})); setHeating(true)}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Hot tub</p>
                                </div>
                                <div >
                                    <button className={amenities.hottub == true ? 'circlebtn fa fa-times me-2' : amenities.hottub == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['hottub']:false}))}}></button>
                                    <button className={amenities.hottub ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['hottub']:true})); setHotTubeDetails(true)}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Kitchen</p>
                                    <p className='text-muted f-14'>Space where guests can cook their own meals.</p>
                                </div>
                                <div>
                                    <button className={amenities.kitchen == true ? 'circlebtn fa fa-times me-2' : amenities.kitchen == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['kitchen']:false}))}}></button>
                                    <button className={amenities.kitchen ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['kitchen']:true}))}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Pool</p>
                                    <p className='text-muted f-14'>Space where guests can cook their own meals.</p>
                                </div>
                                <div>
                                    <button className={amenities.pool == true ? 'circlebtn fa fa-times me-2' : amenities.pool == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['pool']:false}))}}></button>
                                    <button className={amenities.pool ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['pool']:true}));setPool(true)}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>TV</p>
                                    <p className='text-muted f-14'>Space where guests can cook their own meals.</p>
                                </div>
                                <div>
                                    <button className={amenities.tv == true ? 'circlebtn fa fa-times me-2' : amenities.tv == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['tv']:false}))}}></button>
                                    <button className={amenities.tv ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['tv']:true}));setTv(true)}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Washing machine</p>
                                    <p className='text-muted f-14'>Space where guests can cook their own meals.</p>
                                </div>
                                <div>
                                    <button className={amenities.washing == true ? 'circlebtn fa fa-times me-2' : amenities.washing == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['washing']:false}))}}></button>
                                    <button className={amenities.washing ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['washing']:true}));setWashing(true)}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Wifi</p>
                                    <p className='text-muted f-14'>Available throughout the listing</p>
                                </div>
                                <div >
                                    <button className={amenities.wifi == true ? 'circlebtn fa fa-times me-2' : amenities.wifi == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['wifi']:false}))}}></button>
                                    <button className={amenities.wifi ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['wifi']:true}))}}></button>
                                </div>
                              
                            </div>

                            <hr className='themehr' />
                        </div>
                    </div>  

                    <div id="bathroom">
                        <h6 className='my-5'>Bathroom</h6>
                        <div className='my-4'>
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Bath</p>
                               </div>
                                 <div>
                                    <button className={amenities.bath == true ? 'circlebtn fa fa-times me-2' : amenities.bath == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['bath']:false}))}}></button>
                                    <button className={amenities.bath ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['bath']:true}))}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />
                            
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Bidet</p>
                                </div>
                                <div>
                                    <button className={amenities.bidet == true ? 'circlebtn fa fa-times me-2' : amenities.bidet == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['bidet']:false}))}}></button>
                                    <button className={amenities.bidet ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['bidet']:true}))}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Body soap</p>
                                    <p className='text-muted f-14'>{actype}</p>
                                </div>
                                <div>
                                    <button className={amenities.bodysoap == true ? 'circlebtn fa fa-times me-2' : amenities.bodysoap == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['bodysoap']:false}))}}></button>
                                    <button className={amenities.bodysoap ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['bodysoap']:true}))}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Cleaning products</p>
                                </div>
                                <div>
                                    <button className={amenities.cleaningproduct == true ? 'circlebtn fa fa-times me-2' : amenities.cleaningproduct == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['cleaningproduct']:false}))}}></button>
                                    <button className={amenities.cleaningproduct ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['cleaningproduct']:true}))}}></button>
                                </div>
                            </div>

                            {/* <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div> 
                                    <p>Dedicated workspace</p>
                                    <p className='text-muted f-14'>Guests have a desk or table that’s used just for working, along with a comfortable chair.</p>
                                </div>
                                <div>
                                    <button className={amenities.dedicatedworkspace == true ? 'circlebtn fa fa-times me-2' : amenities.dedicatedworkspace == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['dedicatedworkspace']:false}))}}></button>
                                    <button className={amenities.dedicatedworkspace ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['dedicatedworkspace']:true}))}}></button>
                                </div>
                            </div> */}

                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Conditioner</p>
                                </div>
                                <div>
                                    <button className={amenities.conditioner == true ? 'circlebtn fa fa-times me-2' : amenities.conditioner == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['conditioner']:false}))}}></button>
                                    <button className={amenities.conditioner ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['conditioner']:true}))}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Hair dryer</p>
                                </div>
                                <div>
                                    <button className={amenities.hairdryer == true ? 'circlebtn fa fa-times me-2' : amenities.hairdryer == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['hairdryer']:false}))}}></button>
                                    <button className={amenities.hairdryer ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['hairdryer']:true}))}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Hot water</p>
                                </div>
                                <div>
                                    <button className={amenities.hotwater == true ? 'circlebtn fa fa-times me-2' : amenities.hotwater == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['hotwater']:false}))}}></button>
                                    <button className={amenities.hotwater ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['hotwater']:true}))}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Outdoor shower</p>
                                </div>
                                <div>
                                    <button className={amenities.outdoorshower == true ? 'circlebtn fa fa-times me-2' : amenities.outdoorshower == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['outdoorshower']:false}))}}></button>
                                    <button className={amenities.outdoorshower ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['outdoorshower']:true}))}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Shampoo</p>
                                </div>
                                <div>
                                    <button className={amenities.shampoo == true ? 'circlebtn fa fa-times me-2' : amenities.shampoo == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['shampoo']:false}))}}></button>
                                    <button className={amenities.shampoo ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['shampoo']:true}))}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Shower gel</p>
                                </div>
                                <div>
                                    <button className={amenities.showergel == true ? 'circlebtn fa fa-times me-2' : amenities.showergel == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['showergel']:false}))}}></button>
                                    <button className={amenities.showergel ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['showergel']:true}))}}></button>
                                </div>
                            </div>
                        </div>
                    </div>   

                     <div id="laundry">
                        <h6 className='my-5'>Bedroom and laundry</h6>
                        <div className='my-4'>
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Essentials</p>
                               </div>
                                 <div>
                                    <button className={amenities.bedessential == true ? 'circlebtn fa fa-times me-2' : amenities.bedessential == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['bedessential']:false}))}}></button>
                                    <button className={amenities.bedessential ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['bedessential']:true}))}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />
                            
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Bed linen</p>
                                </div>
                                <div>
                                    <button className={amenities.bedlinen == true ? 'circlebtn fa fa-times me-2' : amenities.bedlinen == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['bedlinen']:false}))}}></button>
                                    <button className={amenities.bedlinen ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['bedlinen']:true}));setBedLinen(true)}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Clothes storage</p>
                                    <p className='text-muted f-14'>clothes</p>
                                </div>
                                <div>
                                    <button className={amenities.clothestore == true ? 'circlebtn fa fa-times me-2' : amenities.clothestore == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['clothestore']:false}))}}></button>
                                    <button className={amenities.clothestore ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['clothestore']:true})); setClotheStore(true)}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Dryer</p>
                                </div>
                                <div>
                                    <button className={amenities.beddryer == true ? 'circlebtn fa fa-times me-2' : amenities.beddryer == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['beddryer']:false}))}}></button>
                                    <button className={amenities.beddryer ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['beddryer']:true}));setDryerDetails(true)}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Clothes drying rack</p>
                                </div>
                                <div>
                                    <button className={amenities.dryerrack == true ? 'circlebtn fa fa-times me-2' : amenities.dryerrack == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['dryerrack']:false}))}}></button>
                                    <button className={amenities.dryerrack ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['dryerrack']:true}))}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Extra pillows and blankets</p>
                                </div>
                                <div>
                                    <button className={amenities.extrapillows == true ? 'circlebtn fa fa-times me-2' : amenities.extrapillows == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['extrapillows']:false}))}}></button>
                                    <button className={amenities.extrapillows ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['extrapillows']:true}))}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Hangers</p>
                                </div>
                                <div>
                                    <button className={amenities.hanger == true ? 'circlebtn fa fa-times me-2' : amenities.hanger == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['hanger']:false}))}}></button>
                                    <button className={amenities.hanger ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['hanger']:true}))}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Iron</p>
                                </div>
                                <div>
                                    <button className={amenities.iron == true ? 'circlebtn fa fa-times me-2' : amenities.iron == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['iron']:false}))}}></button>
                                    <button className={amenities.iron ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['iron']:true}))}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Mosquito net</p>
                                </div>
                                <div>
                                    <button className={amenities.musqnet == true ? 'circlebtn fa fa-times me-2' : amenities.musqnet == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['musqnet']:false}))}}></button>
                                    <button className={amenities.musqnet ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['musqnet']:true}))}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Room-darkening shades</p>
                                </div>
                                <div>
                                    <button className={amenities.darkenshade == true ? 'circlebtn fa fa-times me-2' : amenities.darkenshade == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['darkenshade']:false}))}}></button>
                                    <button className={amenities.darkenshade ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['darkenshade']:true}))}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Safe</p>
                                </div>
                                <div>
                                    <button className={amenities.safe == true ? 'circlebtn fa fa-times me-2' : amenities.safe == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['safe']:false}))}}></button>
                                    <button className={amenities.safe ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['safe']:true}))}}></button>
                                </div>
                            </div>
                            <hr className='themehr' />
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Washing machine</p>
                                </div>
                                <div>
                                    <button className={amenities.bedwashing == true ? 'circlebtn fa fa-times me-2' : amenities.bedwashing == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['bedwashing']:false}))}}></button>
                                    <button className={amenities.bedwashing ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['bedwashing']:true}))}}></button>
                                </div>
                            </div>
                        </div>
                    </div>  

                    <div id="entertainment">
                        <h6 className='my-5'>Bedroom and laundry</h6>
                        <div className='my-4'>
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Books and reading material</p>
                               </div>
                                 <div>
                                    <button className={amenities.readings == true ? 'circlebtn fa fa-times me-2' : amenities.readings == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['readings']:false}))}}></button>
                                    <button className={amenities.readings ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['readings']:true}))}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />
                            
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Ethernet connection</p>
                                </div>
                                <div>
                                    <button className={amenities.ethernet == true ? 'circlebtn fa fa-times me-2' : amenities.ethernet == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['ethernet']:false}))}}></button>
                                    <button className={amenities.ethernet ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['ethernet']:true}));setBedLinen(true)}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Exercise equipment</p>
                                    <p className='text-muted f-14'>clothes</p>
                                </div>
                                <div>
                                    <button className={amenities.equipment == true ? 'circlebtn fa fa-times me-2' : amenities.equipment == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['equipment']:false}))}}></button>
                                    <button className={amenities.equipment ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['equipment']:true})); setEquipment(true)}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Games console</p>
                                </div>
                                <div>
                                    <button className={amenities.games == true ? 'circlebtn fa fa-times me-2' : amenities.games == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['games']:false}))}}></button>
                                    <button className={amenities.games ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['games']:true}));setGame(true)}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Piano</p>
                                </div>
                                <div>
                                    <button className={amenities.piano == true ? 'circlebtn fa fa-times me-2' : amenities.piano == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['piano']:false}))}}></button>
                                    <button className={amenities.piano ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['piano']:true}))}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Ping pong table</p>
                                </div>
                                <div>
                                    <button className={amenities.pingpong == true ? 'circlebtn fa fa-times me-2' : amenities.pingpong == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['pingpong']:false}))}}></button>
                                    <button className={amenities.pingpong ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['pingpong']:true}))}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Pool table</p>
                                </div>
                                <div>
                                    <button className={amenities.entertainpool == true ? 'circlebtn fa fa-times me-2' : amenities.entertainpool == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['entertainpool']:false}))}}></button>
                                    <button className={amenities.entertainpool ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['entertainpool']:true}))}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Record player</p>
                                </div>
                                <div>
                                    <button className={amenities.player == true ? 'circlebtn fa fa-times me-2' : amenities.player == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['player']:false}))}}></button>
                                    <button className={amenities.player ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['player']:true}))}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Sound system</p>
                                </div>
                                <div>
                                    <button className={amenities.sounds == true ? 'circlebtn fa fa-times me-2' : amenities.sounds == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['sounds']:false}))}}></button>
                                    <button className={amenities.sounds ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['sounds']:true}));setSound(true)}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>TV</p>
                                </div>
                                <div>
                                    <button className={amenities.entertv == true ? 'circlebtn fa fa-times me-2' : amenities.entertv == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['entertv']:false}))}}></button>
                                    <button className={amenities.entertv ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['entertv']:true}));setTv(true)}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Safe</p>
                                </div>
                                <div>
                                    <button className={amenities.entersafe == true ? 'circlebtn fa fa-times me-2' : amenities.entersafe == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['entersafe']:false}))}}></button>
                                    <button className={amenities.entersafe ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['entersafe']:true}))}}></button>
                                </div>
                            </div>
                            <hr className='themehr' />
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Washing machine</p>
                                </div>
                                <div>
                                    <button className={amenities.enterwashing == true ? 'circlebtn fa fa-times me-2' : amenities.enterwashing == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['enterwashing']:false}))}}></button>
                                    <button className={amenities.enterwashing ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['enterwashing']:true}))}}></button>
                                </div>
                            </div>
                            <hr className='themehr' />
                        
                        </div>
                        

                    </div>   

                    <div id="family">
                        <h6 className='my-5'>Family</h6>
                        <div className='my-4'>
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Baby bath</p>
                               </div>
                                 <div>
                                    <button className={amenities.babybath == true ? 'circlebtn fa fa-times me-2' : amenities.babybath == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['babybath']:false}))}}></button>
                                    <button className={amenities.babybath ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['babybath']:true}))}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />
                            
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Baby monitor</p>
                                </div>
                                <div>
                                    <button className={amenities.babymonitor == true ? 'circlebtn fa fa-times me-2' : amenities.babymonitor == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['babymonitor']:false}))}}></button>
                                    <button className={amenities.babymonitor ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['babymonitor']:true}));setBedLinen(true)}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Baby safety gates</p>
                                    <p className='text-muted f-14'>clothes</p>
                                </div>
                                <div>
                                    <button className={amenities.babysafty == true ? 'circlebtn fa fa-times me-2' : amenities.babysafty == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['babysafty']:false}))}}></button>
                                    <button className={amenities.babysafty ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['babysafty']:true})); setEquipment(true)}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Babysitter recommendations</p>
                                </div>
                                <div>
                                    <button className={amenities.babysitter == true ? 'circlebtn fa fa-times me-2' : amenities.babysitter == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['babysitter']:false}))}}></button>
                                    <button className={amenities.babysitter ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['babysitter']:true}));setGame(true)}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Board games</p>
                                </div>
                                <div>
                                    <button className={amenities.boardgames == true ? 'circlebtn fa fa-times me-2' : amenities.boardgames == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['boardgames']:false}))}}></button>
                                    <button className={amenities.boardgames ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['boardgames']:true}))}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Changing table</p>
                                </div>
                                <div>
                                    <button className={amenities.changingtable == true ? 'circlebtn fa fa-times me-2' : amenities.changingtable == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['changingtable']:false}))}}></button>
                                    <button className={amenities.changingtable ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['changingtable']:true}))}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Children’s books and toys</p>
                                </div>
                                <div>
                                    <button className={amenities.kidsbooks == true ? 'circlebtn fa fa-times me-2' : amenities.kidsbooks == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['kidsbooks']:false}))}}></button>
                                    <button className={amenities.kidsbooks ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['kidsbooks']:true}));setKidsBooks(true)}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Children’s tableware</p>
                                </div>
                                <div>
                                    <button className={amenities.kidstable == true ? 'circlebtn fa fa-times me-2' : amenities.kidstable == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['kidstable']:false}))}}></button>
                                    <button className={amenities.kidstable ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['kidstable']:true}))}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Cot</p>
                                </div>
                                <div>
                                    <button className={amenities.cot == true ? 'circlebtn fa fa-times me-2' : amenities.cot == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['cot']:false}))}}></button>
                                    <button className={amenities.cot ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['cot']:true}));setCot(true)}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Fireplace guards</p>
                                </div>
                                <div>
                                    <button className={amenities.fireguard == true ? 'circlebtn fa fa-times me-2' : amenities.fireguard == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['fireguard']:false}))}}></button>
                                    <button className={amenities.fireguard ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['fireguard']:true}));setTv(true)}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>High chair</p>
                                </div>
                                <div>
                                    <button className={amenities.highchair == true ? 'circlebtn fa fa-times me-2' : amenities.highchair == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['highchair']:false}))}}></button>
                                    <button className={amenities.highchair ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['highchair']:true}))}}></button>
                                </div>
                            </div>
                            <hr className='themehr' />
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Plug socket covers</p>
                                </div>
                                <div>
                                    <button className={amenities.plugsocket == true ? 'circlebtn fa fa-times me-2' : amenities.plugsocket == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['plugsocket']:false}))}}></button>
                                    <button className={amenities.plugsocket ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['plugsocket']:true}))}}></button>
                                </div>
                            </div>
                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Travel cot</p>
                                </div>
                                <div>
                                    <button className={amenities.travelcot == true ? 'circlebtn fa fa-times me-2' : amenities.travelcot == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['travelcot']:false}))}}></button>
                                    <button className={amenities.travelcot ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['travelcot']:true}))}}></button>
                                </div>
                            </div>
                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Table corner guards</p>
                                </div>
                                <div>
                                    <button className={amenities.cornergaurd == true ? 'circlebtn fa fa-times me-2' : amenities.cornergaurd == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['cornergaurd']:false}))}}></button>
                                    <button className={amenities.cornergaurd ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['cornergaurd']:true}))}}></button>
                                </div>
                            </div>
                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Window guards</p>
                                </div>
                                <div>
                                    <button className={amenities.windowguard == true ? 'circlebtn fa fa-times me-2' : amenities.windowguard == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['windowguard']:false}))}}></button>
                                    <button className={amenities.windowguard ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['windowguard']:true}))}}></button>
                                </div>
                            </div>
                        </div>
                    </div> 

                    <div id="heating">
                        <h6 className='my-5'>Heating and Cooling</h6>
                        <div className='my-4'>
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Air conditioning</p>
                               </div>
                                 <div>
                                    <button className={amenities.heatingac == true ? 'circlebtn fa fa-times me-2' : amenities.heatingac == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['heatingac']:false}))}}></button>
                                    <button className={amenities.heatingac ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['heatingac']:true}));setAircondition(true)}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />
                            
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Ceiling fan</p>
                                </div>
                                <div>
                                    <button className={amenities.fan == true ? 'circlebtn fa fa-times me-2' : amenities.fan == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['fan']:false}))}}></button>
                                    <button className={amenities.fan ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['fan']:true}))}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Heating</p>
                                    <p className='text-muted f-14'>clothes</p>
                                </div>
                                <div>
                                    <button className={amenities.heatingcooling == true ? 'circlebtn fa fa-times me-2' : amenities.heatingcooling == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['heatingcooling']:false}))}}></button>
                                    <button className={amenities.heatingcooling ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['heatingcooling']:true})); setHeating(true)}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Indoor fireplace</p>
                                </div>
                                <div>
                                    <button className={amenities.indoorfire == true ? 'circlebtn fa fa-times me-2' : amenities.indoorfire == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['indoorfire']:false}))}}></button>
                                    <button className={amenities.indoorfire ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['indoorfire']:true}))}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Portable fans</p>
                                </div>
                                <div>
                                    <button className={amenities.portablefan == true ? 'circlebtn fa fa-times me-2' : amenities.portablefan == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['portablefan']:false}))}}></button>
                                    <button className={amenities.portablefan ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['portablefan']:true}))}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />

                          
                        </div>
                    </div> 

                    <div id="homesafty">
                        <h6 className='my-5'>Home safety</h6>
                        <div className='my-4'>
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Carbon monoxide alarm</p>
                                    <p className='f-14 text-muted'>Check your local laws, which may require a working carbon monoxide detector in every room.</p>
                                    
                               </div>
                                 <div>
                                    <button className={amenities.coalaram == true ? 'circlebtn fa fa-times me-2' : amenities.coalaram == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['coalaram']:false}))}}></button>
                                    <button className={amenities.coalaram ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['coalaram']:true}))}}></button>
                                </div>
                            </div>
                            <hr className='themehr' />
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Fire extinguisher</p>
                                </div>
                                <div>
                                    <button className={amenities.fireextingusher == true ? 'circlebtn fa fa-times me-2' : amenities.fireextingusher == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['fireextingusher']:false}))}}></button>
                                    <button className={amenities.fireextingusher ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['fireextingusher']:true}))}}></button>
                                </div>
                            </div>
                            <hr className='themehr' />
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>First aid kit</p>
                                    <p className='text-muted f-14'>clothes</p>
                                </div>
                                <div>
                                    <button className={amenities.firstaidkit == true ? 'circlebtn fa fa-times me-2' : amenities.firstaidkit == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['firstaidkit']:false}))}}></button>
                                    <button className={amenities.firstaidkit ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['firstaidkit']:true}))}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Lock on bedroom door</p>
                                    <p className='f-14 text-muted'>Private room can be locked for safety and privacy</p>
                        
                                </div>
                                <div>
                                    <button className={amenities.bedroomlock == true ? 'circlebtn fa fa-times me-2' : amenities.bedroomlock == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['bedroomlock']:false}))}}></button>
                                    <button className={amenities.bedroomlock ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['bedroomlock']:true}))}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Smoke alarm</p>
                                    <p className='f-14 text-muted'>Check your local laws, which may require a working smoke detector in every room.</p>
                                </div>
                                <div>
                                    <button className={amenities.smokealarm == true ? 'circlebtn fa fa-times me-2' : amenities.smokealarm == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['smokealarm']:false}))}}></button>
                                    <button className={amenities.smokealarm ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['smokealarm']:true}))}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />

                          
                        </div>
                    </div> 

                    <div id="office">
                        <h6 className='my-5'>Internet and Office</h6>
                        <div className='my-4'>
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Dedicated workspace</p>
                                    <p className='f-14 text-muted'>Check your local laws, which may require a working carbon monoxide detector in every room.</p>  
                                </div>
                                 <div>
                                    <button className={amenities.networkspace == true ? 'circlebtn fa fa-times me-2' : amenities.networkspace == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['networkspace']:false}))}}></button>
                                    <button className={amenities.networkspace ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['networkspace']:true}));setService(true)}}></button>
                                </div>
                            </div>
                            <hr className='themehr' />
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Pocket wifi</p>
                                </div>
                                <div>
                                    <button className={amenities.pocketwifi == true ? 'circlebtn fa fa-times me-2' : amenities.pocketwifi == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['pocketwifi']:false}))}}></button>
                                    <button className={amenities.pocketwifi ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['pocketwifi']:true}))}}></button>
                                </div>
                            </div>
                            <hr className='themehr' />
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Wifi</p>
                                    <p className='text-muted f-14'>clothes</p>
                                </div>
                                <div>
                                    <button className={amenities.officewifi == true ? 'circlebtn fa fa-times me-2' : amenities.officewifi == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['officewifi']:false}))}}></button>
                                    <button className={amenities.officewifi ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['officewifi']:true}))}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />
                        </div>
                    </div>

                    <div id="kitchen">
                        <h6 className='my-5'>Kitchen and dining</h6>
                        <div className='my-4'>
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Baking sheet</p>
                                </div>
                                 <div>
                                    <button className={amenities.bakingsheet == true ? 'circlebtn fa fa-times me-2' : amenities.bakingsheet == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['bakingsheet']:false}))}}></button>
                                    <button className={amenities.bakingsheet ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{setAmenities(prevstate=>({...prevstate,['bakingsheet']:true}))}}></button>
                                </div>
                            </div>
                            <hr className='themehr' />
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Barbecue utensils</p>
                                    <p className='text-muted f-14'>Grill, charcoal, bamboo skewers/iron skewers, etc.</p>
                                </div>
                                <div>
                                    <button className={amenities.barbecue == true ? 'circlebtn fa fa-times me-2' : amenities.barbecue == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['barbecue']:false}))}}></button>
                                    <button className={amenities.barbecue ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['barbecue']:true}))}}></button>
                                </div>
                            </div>
                            <hr className='themehr' />
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Bread maker</p>
                                </div>
                                <div>
                                    <button className={amenities.breadmaker == true ? 'circlebtn fa fa-times me-2' : amenities.breadmaker == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['breadmaker']:false}))}}></button>
                                    <button className={amenities.breadmaker ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['breadmaker']:true}))}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Blender</p>
                                </div>
                                <div>
                                    <button className={amenities.blender == true ? 'circlebtn fa fa-times me-2' : amenities.blender == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['blender']:false}))}}></button>
                                    <button className={amenities.blender ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['blender']:true}))}}></button>
                                </div>
                            </div>
                            <hr className='themehr' />
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Coffee</p>
                                </div>
                                <div>
                                    <button className={amenities.kitchencofee == true ? 'circlebtn fa fa-times me-2' : amenities.kitchencofee == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['kitchencofee']:false}))}}></button>
                                    <button className={amenities.kitchencofee ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['kitchencofee']:true}));setCoffee(true)}}></button>
                                </div>
                            </div>
                            <hr className='themehr' />
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Coffee maker</p>
                                </div>
                                <div>
                                    <button className={amenities.coffeemaker == true ? 'circlebtn fa fa-times me-2' : amenities.coffeemaker == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['coffeemaker']:false}))}}></button>
                                    <button className={amenities.coffeemaker ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['coffeemaker']:true}))}}></button>
                                </div>
                            </div>
                            <hr className='themehr' />
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Cooking basics</p>
                                </div>
                                <div>
                                    <button className={amenities.cookingbasics == true ? 'circlebtn fa fa-times me-2' : amenities.cookingbasics == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['cookingbasics']:false}))}}></button>
                                    <button className={amenities.cookingbasics ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['cookingbasics']:true}))}}></button>
                                </div>
                            </div>
                            <hr className='themehr' />
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Dining table</p>
                                </div>
                                <div>
                                    <button className={amenities.diningtable == true ? 'circlebtn fa fa-times me-2' : amenities.diningtable == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['diningtable']:false}))}}></button>
                                    <button className={amenities.diningtable ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['diningtable']:true}))}}></button>
                                </div>
                            </div>
                            <hr className='themehr' />
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Dishes and silverware</p>
                                    <p className='f-14 text-muted'>Bowls, chopsticks, plates, cups, etc.</p>
                                </div>
                                <div>
                                    <button className={amenities.silverware == true ? 'circlebtn fa fa-times me-2' : amenities.silverware == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['silverware']:false}))}}></button>
                                    <button className={amenities.silverware ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['silverware']:true}))}}></button>
                                </div>
                            </div>
                            <hr className='themehr' />
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Dishwasher</p>
                                </div>
                                <div>
                                    <button className={amenities.dishware == true ? 'circlebtn fa fa-times me-2' : amenities.dishware == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['dishware']:false}))}}></button>
                                    <button className={amenities.dishware ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['dishware']:true}))}}></button>
                                </div>
                            </div>
                            <hr className='themehr' />
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Freezer</p>
                                </div>
                                <div>
                                    <button className={amenities.freezer == true ? 'circlebtn fa fa-times me-2' : amenities.freezer == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['freezer']:false}))}}></button>
                                    <button className={amenities.freezer ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['freezer']:true}))}}></button>
                                </div>
                            </div>
                            <hr className='themehr' />
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Kettle</p>
                                </div>
                                <div>
                                    <button className={amenities.kettle == true ? 'circlebtn fa fa-times me-2' : amenities.kettle == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['kettle']:false}))}}></button>
                                    <button className={amenities.kettle ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['kettle']:true}))}}></button>
                                </div>
                            </div>
                            <hr className='themehr' />
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Kitchen</p>
                                    <p className='f-14 text-muted'>Space where guests can cook their own meals</p>
                                </div>
                                <div>
                                    <button className={amenities.diningkitchen == true ? 'circlebtn fa fa-times me-2' : amenities.diningkitchen == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['diningkitchen']:false}))}}></button>
                                    <button className={amenities.diningkitchen ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['diningkitchen']:true}))}}></button>
                                </div>  
                            </div>
                            <hr className='themehr' />
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Microwave</p>
                                </div>
                                <div>
                                    <button className={amenities.microwave == true ? 'circlebtn fa fa-times me-2' : amenities.microwave == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['microwave']:false}))}}></button>
                                    <button className={amenities.microwave ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['microwave']:true}))}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Mini fridge</p>
                                </div>
                                <div>
                                    <button className={amenities.fridge == true ? 'circlebtn fa fa-times me-2' : amenities.fridge == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['fridge']:false}))}}></button>
                                    <button className={amenities.fridge ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['fridge']:true}))}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Oven</p>
                                </div>
                                <div>
                                    <button className={amenities.ovan == true ? 'circlebtn fa fa-times me-2' : amenities.ovan == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['ovan']:false}))}}></button>
                                    <button className={amenities.ovan ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['ovan']:true}));setOven(true)}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Refrigerator</p>
                                </div>
                                <div>
                                    <button className={amenities.refrigerator == true ? 'circlebtn fa fa-times me-2' : amenities.refrigerator == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['refrigerator']:false}))}}></button>
                                    <button className={amenities.refrigerator ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['refrigerator']:true}));setFridge(true)}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Rice cooker</p>
                                </div>
                                <div>
                                    <button className={amenities.ricecooker == true ? 'circlebtn fa fa-times me-2' : amenities.ricecooker == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['ricecooker']:false}))}}></button>
                                    <button className={amenities.ricecooker ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['ricecooker']:true}))}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Stove</p>
                                </div>
                                <div>
                                    <button className={amenities.stove == true ? 'circlebtn fa fa-times me-2' : amenities.stove == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['stove']:false}))}}></button>
                                    <button className={amenities.stove ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['stove']:true}));setStove(true)}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Toater</p>
                                </div>
                                <div>
                                    <button className={amenities.toaster == true ? 'circlebtn fa fa-times me-2' : amenities.toaster == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['toaster']:false}))}}></button>
                                    <button className={amenities.toaster ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['toaster']:true}))}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Waste compactor</p>
                                </div>
                                <div>
                                    <button className={amenities.compactor == true ? 'circlebtn fa fa-times me-2' : amenities.compactor == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['compactor']:false}))}}></button>
                                    <button className={amenities.compactor ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['compactor']:true}))}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Wine glasses</p>
                                </div>
                                <div>
                                    <button className={amenities.glasses == true ? 'circlebtn fa fa-times me-2' : amenities.glasses == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['glasses']:false}))}}></button>
                                    <button className={amenities.glasses ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['glasses']:true}))}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />
                        </div>
                    </div>

                    <div id="location">
                        <h6 className='my-5'>Location features</h6>
                        <div className='my-4'>
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Beach access</p>
                                    <p className='f-14 text-muted'>Guests can enjoy a nearby beach</p>
                                </div>
                                 <div>
                                    <button className={amenities.beachaccess == true ? 'circlebtn fa fa-times me-2' : amenities.beachaccess == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['beachaccess']:false}))}}></button>
                                    <button className={amenities.beachaccess ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{setAmenities(prevstate=>({...prevstate,['beachaccess']:true})); setBeach(true)}}></button>
                                </div>
                            </div>
                            <hr className='themehr' />
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Lake access</p>
                                    <p className='text-muted f-14'>Guests can get to a lake using a path or dock</p>
                                </div>
                                <div>
                                    <button className={amenities.lakeaccess == true ? 'circlebtn fa fa-times me-2' : amenities.lakeaccess == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['lakeaccess']:false}))}}></button>
                                    <button className={amenities.lakeaccess ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['lakeaccess']:true}))}}></button>
                                </div>
                            </div>
                            <hr className='themehr' />
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Launderette nearby</p>
                                </div>
                                <div>
                                    <button className={amenities.launderette == true ? 'circlebtn fa fa-times me-2' : amenities.launderette == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['launderette']:false}))}}></button>
                                    <button className={amenities.launderette ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['launderette']:true}))}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Private entrance</p>
                                    <p className='f-14 text-muted'>Separate street or building entrance</p>
                                </div>
                                <div>
                                    <button className={amenities.privateentrance == true ? 'circlebtn fa fa-times me-2' : amenities.privateentrance == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['privateentrance']:false}))}}></button>
                                    <button className={amenities.privateentrance ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['privateentrance']:true}))}}></button>
                                </div>
                            </div>
                            <hr className='themehr' />
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Resort access</p>
                                    <p className='f-14 text-muted'>Guests can use nearby resort facilities </p>
                                </div>
                                <div>
                                    <button className={amenities.resortaccess == true ? 'circlebtn fa fa-times me-2' : amenities.resortaccess == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['resortaccess']:false}))}}></button>
                                    <button className={amenities.resortaccess ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['resortaccess']:true}));setResort(true)}}></button>
                                </div>
                            </div>
                            <hr className='themehr' />
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Ski-in/Ski-out</p>
                                    <p className='f-14 text-muted'>Guests can access ski lifts without driving or taking paid transport</p>
                                </div>
                                <div>
                                    <button className={amenities.skiinout == true ? 'circlebtn fa fa-times me-2' : amenities.skiinout == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['skiinout']:false}))}}></button>
                                    <button className={amenities.skiinout ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['skiinout']:true}));setSki(true)}}></button>
                                </div>
                            </div>
                            <hr className='themehr' />
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Waterfront</p>
                                    <p className='f-14 text-muted'>Right next to a body of water</p>
                                </div>
                                <div>
                                    <button className={amenities.waterfront == true ? 'circlebtn fa fa-times me-2' : amenities.waterfront == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['waterfront']:false}))}}></button>
                                    <button className={amenities.waterfront ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['waterfront']:true}))}}></button>
                                </div>
                            </div>
                            <hr className='themehr' />
                            
                        </div>
                    </div>

                    <div id="outdoor">
                        <h6 className='my-5'>Outdoor</h6>
                        <div className='my-4'>
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Garden</p>
                                    <p className='f-14 text-muted'>An open space on the property usually covered in grass</p>
                                </div>
                                 <div>
                                    <button className={amenities.garden == true ? 'circlebtn fa fa-times me-2' : amenities.garden == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['garden']:false}))}}></button>
                                    <button className={amenities.garden ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{setAmenities(prevstate=>({...prevstate,['garden']:true})); setGarden(true)}}></button>
                                </div>
                            </div>
                            <hr className='themehr' />
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>BBQ grill</p>
                                </div>
                                <div>
                                    <button className={amenities.grill == true ? 'circlebtn fa fa-times me-2' : amenities.grill == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['grill']:false}))}}></button>
                                    <button className={amenities.grill ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['grill']:true}));setGrill(true)}}></button>
                                </div>
                            </div>
                            <hr className='themehr' />
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Beach essentials</p>
                                    <p className='f-14 text-muted'>Beach towels, umbrella, beach blanket, snorkelling gear</p>
                                </div>
                                <div>
                                    <button className={amenities.beachessetial == true ? 'circlebtn fa fa-times me-2' : amenities.beachessetial == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['beachessetial']:false}))}}></button>
                                    <button className={amenities.beachessetial ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['beachessetial']:true}))}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Bikes</p>
                                </div>
                                <div>
                                    <button className={amenities.bike == true ? 'circlebtn fa fa-times me-2' : amenities.bike == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['bike']:false}))}}></button>
                                    <button className={amenities.bike ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['bike']:true}))}}></button>
                                </div>
                            </div>
                            <hr className='themehr' />
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Boat berth</p>
                                </div>
                                <div>
                                    <button className={amenities.boat == true ? 'circlebtn fa fa-times me-2' : amenities.boat == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['boat']:false}))}}></button>
                                    <button className={amenities.boat ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['boat']:true}))}}></button>
                                </div>
                            </div>
                            <hr className='themehr' />
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Fire pit</p>
                                </div>
                                <div>
                                    <button className={amenities.firepit == true ? 'circlebtn fa fa-times me-2' : amenities.firepit == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['firepit']:false}))}}></button>
                                    <button className={amenities.firepit ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['firepit']:true}))}}></button>
                                </div>
                            </div>
                            <hr className='themehr' />
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Hammock</p>
                                </div>
                                <div>
                                    <button className={amenities.hammock == true ? 'circlebtn fa fa-times me-2' : amenities.hammock == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['hammock']:false}))}}></button>
                                    <button className={amenities.hammock ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['hammock']:true}))}}></button>
                                </div>
                            </div>
                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Kayak</p>
                                </div>
                                <div>
                                    <button className={amenities.kayak == true ? 'circlebtn fa fa-times me-2' : amenities.kayak == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['kayak']:false}))}}></button>
                                    <button className={amenities.kayak ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['kayak']:true}))}}></button>
                                </div>
                            </div>
                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Outdoor dining area</p>
                                </div>
                                <div>
                                    <button className={amenities.outdoordining == true ? 'circlebtn fa fa-times me-2' : amenities.outdoordining == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['outdoordining']:false}))}}></button>
                                    <button className={amenities.outdoordining ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['outdoordining']:true}))}}></button>
                                </div>
                            </div>
                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Outdoor furniture</p>
                                </div>
                                <div>
                                    <button className={amenities.outdoorfurniture == true ? 'circlebtn fa fa-times me-2' : amenities.outdoorfurniture == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['outdoorfurniture']:false}))}}></button>
                                    <button className={amenities.outdoorfurniture ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['outdoorfurniture']:true}))}}></button>
                                </div>
                            </div>
                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Outdoor kitchen</p>
                                </div>
                                <div>
                                    <button className={amenities.outdoorkitchen == true ? 'circlebtn fa fa-times me-2' : amenities.outdoorkitchen == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['outdoorkitchen']:false}))}}></button>
                                    <button className={amenities.outdoorkitchen ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['outdoorkitchen']:true}));setOutdoorKitchen(true)}}></button>
                                </div>
                            </div>
                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Patio or balcony</p>
                                </div>
                                <div>
                                    <button className={amenities.patio == true ? 'circlebtn fa fa-times me-2' : amenities.patio == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['patio']:false}))}}></button>
                                    <button className={amenities.patio ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['patio']:true}));setBalcony(true)}}></button>
                                </div>
                            </div>
                            <hr className='themehr' />
                        </div>
                    </div>

                     <div id="parking">
                        <h6 className='my-5'>Parking and facilities</h6>
                        <div className='my-4'>
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Lift</p>
                                    <p className='f-14 text-muted'>The home or building has a lift that’s at least 52 inches (132cm) deep and a doorway at least 32 inches (81cm) wide.</p>
                                </div>
                                 <div>
                                    <button className={amenities.lift == true ? 'circlebtn fa fa-times me-2' : amenities.lift == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['lift']:false}))}}></button>
                                    <button className={amenities.lift ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{setAmenities(prevstate=>({...prevstate,['lift']:true})); }}></button>
                                </div>
                            </div>
                            <hr className='themehr' />
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>EV charger</p>
                                    <p className='f-14 text-muted'>Guests can charge their electric vehicles on the property.</p>
                                </div>
                                <div>
                                    <button className={amenities.evcharger == true ? 'circlebtn fa fa-times me-2' : amenities.evcharger == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['evcharger']:false}))}}></button>
                                    <button className={amenities.evcharger ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['evcharger']:true}))}}></button>
                                </div>
                            </div>
                            <hr className='themehr' />
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Free parking on premises</p>
                               </div>
                                <div>
                                    <button className={amenities.parkingpremise == true ? 'circlebtn fa fa-times me-2' : amenities.parkingpremise == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['parkingpremise']:false}))}}></button>
                                    <button className={amenities.parkingpremise ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['parkingpremise']:true}));setParking(true)}}></button>
                                </div>
                            </div>

                            <hr className='themehr' />
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Free on-street parking</p>
                                </div>
                                <div>
                                    <button className={amenities.onstreetpark == true ? 'circlebtn fa fa-times me-2' : amenities.onstreetpark == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['onstreetpark']:false}))}}></button>
                                    <button className={amenities.onstreetpark ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['onstreetpark']:true}))}}></button>
                                </div>
                            </div>
                            <hr className='themehr' />
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Gym</p>
                                    <p className='text-muted'>Private</p>
                                </div>
                                <div>
                                    <button className={amenities.gymfacility == true ? 'circlebtn fa fa-times me-2' : amenities.gymfacility == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['gymfacility']:false}))}}></button>
                                    <button className={amenities.gymfacility ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['gymfacility']:true}));setGym(true)}}></button>
                                </div>
                            </div>
                            <hr className='themehr' />
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Hot tub</p>
                                </div>
                                <div>
                                    <button className={amenities.tubfacility == true ? 'circlebtn fa fa-times me-2' : amenities.tubfacility == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['tubfacility']:false}))}}></button>
                                    <button className={amenities.tubfacility ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['tubfacility']:true}));setHotTubeDetails(true)}}></button>
                                </div>
                            </div>
                            <hr className='themehr' />
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Paid parking off premises</p>
                                </div>
                                <div>
                                    <button className={amenities.parkoff == true ? 'circlebtn fa fa-times me-2' : amenities.parkoff == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['parkoff']:false}))}}></button>
                                    <button className={amenities.parkoff ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['parkoff']:true}));setParkingOff(true)}}></button>
                                </div>
                            </div>
                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Paid parking on premises</p>
                                </div>
                                <div>
                                    <button className={amenities.parkon == true ? 'circlebtn fa fa-times me-2' : amenities.parkon == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['parkon']:false}))}}></button>
                                    <button className={amenities.parkon ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['parkon']:true}));setParkingOn(true)}}></button>
                                </div>
                            </div>
                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Pool</p>
                                </div>
                                <div>
                                    <button className={amenities.poolfacility == true ? 'circlebtn fa fa-times me-2' : amenities.poolfacility == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['poolfacility']:false}))}}></button>
                                    <button className={amenities.poolfacility ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['poolfacility']:true}));setPool(true)}}></button>
                                </div>
                            </div>
                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Private living room</p>
                                </div>
                                <div>
                                    <button className={amenities.privateroom == true ? 'circlebtn fa fa-times me-2' : amenities.privateroom == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['privateroom']:false}))}}></button>
                                    <button className={amenities.privateroom ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['privateroom']:true}))}}></button>
                                </div>
                            </div>
                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Sauna</p>
                                </div>
                                <div>
                                    <button className={amenities.saunafacility == true ? 'circlebtn fa fa-times me-2' : amenities.saunafacility == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['saunafacility']:false}))}}></button>
                                    <button className={amenities.saunafacility ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['saunafacility']:true}));setSauna(true)}}></button>
                                </div>
                            </div>
                            <hr className='themehr' />

                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Single level home</p>
                                    <p className='f-14 text-muted'>No stairs in home</p>
                                </div>
                                <div>
                                    <button className={amenities.singlehome == true ? 'circlebtn fa fa-times me-2' : amenities.singlehome == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['singlehome']:false}))}}></button>
                                    <button className={amenities.singlehome ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['singlehome']:true}))}}></button>
                                </div>
                            </div>
                            <hr className='themehr' />
                        </div>
                    </div>

                    <div id="services">
                        <h6 className='my-5'>Services</h6>
                        <div className='my-4'>
                      
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Breakfast</p>
                                    <p className='text-muted f-14'>Breakfast is provided</p>
                                </div>
                                <div>
                                    <button className={amenities.breakfast == true ? 'circlebtn fa fa-times me-2' : amenities.breakfast == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['breakfast']:false}))}}></button>
                                    <button className={amenities.breakfast ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['breakfast']:true}))}}></button>
                                </div>
                            </div>
                            <hr className='themehr' />
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Cleaning before checkout</p>
                                </div>
                                <div>
                                    <button className={amenities.cleaningoncheck == true ? 'circlebtn fa fa-times me-2' : amenities.cleaningoncheck == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['cleaningoncheck']:false}))}}></button>
                                    <button className={amenities.cleaningoncheck ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['cleaningoncheck']:true}))}}></button>
                                </div>
                            </div>
                            <hr className='themehr' />
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Long-term stays allowed</p>
                                    <p className='text-muted f-14'>Allow stays of 28 days or more</p>
                                </div>
                                <div>
                                    <button className={amenities.longtermstay == true ? 'circlebtn fa fa-times me-2' : amenities.longtermstay == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['longtermstay']:false}))}}></button>
                                    <button className={amenities.longtermstay ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['longtermstay']:true}))}}></button>
                                </div>
                            </div>
                            <hr className='themehr' />
                            <div className='elists d-flex jc-between'>
                                <div>
                                    <p>Luggage drop-off allowed</p>
                                    <p className='text-muted f-14'>For guests' convenience when they are arriving early or departing late</p>
                                </div>
                                <div>
                                    <button className={amenities.latedropoff == true ? 'circlebtn fa fa-times me-2' : amenities.latedropoff == false ? "circlebtn fa fa-times me-2 active" : "circlebtn fa fa-times me-2"} onClick={() =>{ setAmenities(prevstate=>({...prevstate,['latedropoff']:false}))}}></button>
                                    <button className={amenities.latedropoff ?  "circlebtn fa fa-check active" :  "circlebtn fa fa-check " } onClick={() =>{ setAmenities(prevstate=>({...prevstate,['latedropoff']:true}));setParkingOff(true)}}></button>
                                </div>
                            </div>

                        </div>
                    </div>

            </div>
                
        </div>
    </div>
</div>
    )
}

export default Amenities