import React, { useState } from 'react';
import {Modal, Button} from 'react-bootstrap'
const Datepicker = React.lazy(() => import('../../../Common/Datepicker/Datepicker.js'));

const UpdateCalendarModal = (props) =>{
    const[updatecal, setUpdatecal] = useState(true)
    return(
        <Modal show={updatecal} centered scrollable className='modal_style' size="lg">
        <Modal.Header className='border-0 pb-0'>
          <button type="button" className="btn close close_modal" onClick={() =>props.onDismiss()}><span aria-hidden="true">×</span></button>
      </Modal.Header>
          <Modal.Body>
          <div className='bg-white'>
                <div className='loca'>
                    <h4>Update your calendar</h4>
                    <p className='f-14'>Choose dates to block so your calendar reflects when you’re able to host.</p>
                    <div className='mt-5'>
                        <Datepicker />
                    </div>
                </div>
          </div>
          </Modal.Body>
          <Modal.Footer className='d-flex justify-content-between'>
                   
                        <Button variant="Link" >Cancel</Button>
                        <Button className='btn-theme'>Save</Button>
                   
          </Modal.Footer>
      </Modal>
    )
}

export default UpdateCalendarModal