import React, { Component, useState, useEffect } from "react";
import Scrollbars from "react-custom-scrollbars";
import CameraModal from "../../Common/Modals/CameraModal";
import { Form } from "react-bootstrap";

const LastQuest = (props) => {
  const [showcammodal, setShowCamModal] = useState(false);
  // const [legalList, setLegalList] = useState([]);
  const [legalList, setLegalList] = useState("");
  console.log("sadfhaksdfkasdfh", legalList);

  const setData = (e) => {
    console.log("valasdfasdfasdf", e.target.value);
    let data = e.target.value;
    setLegalList(data);
    props.onLastQuest(data);
  };
  // const setData = (val) => {
  //     let arrayData = [];
  //     arrayData = legalList;
  //     arrayData.push(val)
  //     setLegalList(arrayData);
  //     props.onLastQuest(arrayData);
  // }

  // const removeData = (val) => {
  //     const index = legalList.indexOf(val);
  //     const updated = legalList.splice(index,1);
  //     console.log("legalList:",legalList);
  //     setLegalList(legalList);
  //     props.onLastQuest(legalList);
  // }

  useEffect(() => {
    console.log("localStorage.getItem(legal)", localStorage.getItem("legal"));
    setLegalList(
      localStorage.getItem("legal") ? localStorage.getItem("legal") : legalList
    );
    localStorage.getItem("legal")
      ? props.onLastQuest(localStorage.getItem("legal"))
      : localStorage.removeItem("legal");
  }, []);

  return (
    <div className="col-xl-12 col-lg-12 col-md-12 lastquestion">
      {showcammodal && <CameraModal onDismiss={() => setShowCamModal(false)} />}
      <div className="placetype mb-5 lastquest placetype_heoght_fix">
        <Scrollbars
          style={{ height: "calc(100vh - 320px)" }}
          className="scroll"
        >
          <div
            className="row  px-3  align-items-center  pb-5"
            data-aos="fade-up"
          >
            <div>
              <h4 className="mb-4">
                Do you have any of these at your place?
                <button
                  className="fa fa-info-circle ms-2"
                  onClick={() => setShowCamModal(true)}
                />
              </h4>
              <ul className="ps-0">
                {props.data &&
                  props.data.content.map((val) => (
                    <li className="d-flex">
                      <tr>
                        {val?.status != "Inactive" && (
                          <td>
                            <input
                              type="radio"
                              name="cancellation"
                              value={val.type}
                              checked={legalList.includes(val.type)}
                              onChange={(e) => {
                                setData(e);
                              }}
                            />{" "}
                            {val.type}{" "}
                          </td>
                        )}
                      </tr>
                    </li>
                  ))}
              </ul>
              <h4 className="my-3"> Some important things to know</h4>

              {/* <p>
                Be sure to comply with your local laws and review FlutterPads's{" "}
                <a href="#" className="text-dark">
                  <b>nondiscrimination policy</b>
                </a>{" "}
                and
                <a href="#" className="text-dark">
                  <b> guest and Host fees.</b>{" "}
                </a>{" "}
                Update your{" "}
                <a href="#" className="text-dark">
                  {" "}
                  <b> cancellation policy</b>
                </a>{" "}
                after you publish.
              </p> */}
              <p>
                Be sure to comply with your local laws and review FlutterPads's
                nondiscrimination policy and guest and Host fees. Update your
                cancellation policy after you publish.
              </p>
            </div>
          </div>
        </Scrollbars>
      </div>
    </div>
  );
};

export default LastQuest;
